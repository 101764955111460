<div class="container-fluid">
    <div class="content">
        <app-crm-breadcrumb *ngIf="!checkCustomer" s_breadcrumb="Service Summary" (emit)="cancelFunction()">
        </app-crm-breadcrumb>
        <app-crm-breadcrumb *ngIf="checkCustomer" s_breadcrumb="Self Service" [t_breadcrumb]="'Service Summary'"
            (emit)="goCrm()">
        </app-crm-breadcrumb>

        <div>
            <div class="d-flex flex-wrap">
                <div class="mb-3 me-2 " [matMenuTriggerFor]="bytypemenu">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;">
                        {{bytypename}}
                    </button>
                </div>
                <mat-menu #bytypemenu="matMenu" xPosition="before" class="menuproject">
                    <button class="mat-item" (click)="bytype('type')">By Types</button>
                    <button class="mat-item" (click)="bytype('product')">By Products</button>
                    <button class="mat-item" (click)="bytype('project')">By Projects</button>
                    <button class="mat-item" (click)="bytype('priority')">By Priority</button>
                    <button class="mat-item" (click)="bytype('customer')">By Customers</button>
                </mat-menu>
                <div *ngIf="!checkCustomer" class="mb-3 me-2 " [matMenuTriggerFor]="internalmenu">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;">
                        {{internalname}}
                    </button>
                </div>
                <mat-menu #internalmenu="matMenu" xPosition="before" class="menuproject">
                    <button class="mat-item" (click)="checkInternal('all')">All</button>
                    <button class="mat-item" (click)="checkInternal('exclude')">Customers</button>
                    <button class="mat-item" (click)="checkInternal('internal')">Internal </button>
                </mat-menu>
                <div *ngIf="viewType != '003'" class="mb-3 me-2 " [matMenuTriggerFor]="projectmenu">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                        (click)="removeProJect()">
                        {{projectname != 'project' ? projectname : 'Project' }}
                    </button>
                </div>
                <mat-menu #projectmenu="matMenu" xPosition="before" class="menuproject">
                    <div class="input-group cl-project-new  mb-3" (click)="$event.stopPropagation();">
                        <input class="clas-project" type="text" placeholder="Search " [(ngModel)]="projectsearch"
                            style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;"
                            autocomplete="off">
                    </div>
                    <button class="mat-item" (click)="changeproject('project')">-</button>
                    <button class="mat-item" *ngFor="let proj of projects | crmFilter : projectsearch:text1:text2:14;"
                        (click)="changeproject(proj)">{{proj.name}}</button>
                </mat-menu>
                <div *ngIf="viewType != '002'" class="mb-3 me-2 " [matMenuTriggerFor]="productmenu">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                        (click)="removeProduct()">
                        {{productname != 'product' ? productname : 'Product' }}
                    </button>
                </div>

                <mat-menu #productmenu="matMenu" xPosition="before">
                    <div class="input-group cl-project-new me-2 mb-3" (click)="$event.stopPropagation();">
                        <input class="clas-project" type="text" placeholder="Search" [(ngModel)]="productsearch"
                            style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;"
                            autocomplete="off">
                    </div>
                    <button class="mat-item" (click)="changeproduct('product')">-</button>
                    <button class="mat-item"
                        *ngFor="let proj of productListdata | crmFilter : productsearch:text1:text2:15;"
                        (click)="changeproduct(proj)">{{proj.name}}</button>
                </mat-menu>

                <div *ngIf="viewType != '004'" class="mb-3 me-2 " [matMenuTriggerFor]="prioritymenu">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                        (click)="removePriority()">
                        {{priorityname != 'priority' ? priorityname : 'Priority' }}
                    </button>
                </div>
                <mat-menu #prioritymenu="matMenu" xPosition="before">
                    <div class="input-group cl-project-new me-2 mb-3" (click)="$event.stopPropagation();">
                        <input class="clas-project" type="text" placeholder="Search" [(ngModel)]="prioritysearch"
                            style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;"
                            autocomplete="off">
                    </div>
                    <button class="mat-item" (click)="changepriority('priority')">-</button>
                    <button class="mat-item" *ngFor="let pri of priority | crmFilter : prioritysearch:text1:text2:22;"
                        (click)="changepriority(pri)">{{pri.name}}</button>
                </mat-menu>




                <div *ngIf="viewType != '001'" class="mb-3 me-2 " [matMenuTriggerFor]="servicetypmenu">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                        (click)="removeProduct()">
                        {{servicename != 'Type' ? servicename : 'Type' }}
                    </button>
                </div>
                <mat-menu #servicetypmenu="matMenu" xPosition="before">
                    <div class="input-group cl-project-new me-2 mb-3" (click)="$event.stopPropagation();">
                        <input class="clas-project" type="text" placeholder="Search" [(ngModel)]="serviceSearch"
                            style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;"
                            autocomplete="off">
                    </div>
                    <button class="mat-item" (click)="changeservicetype('product')">-</button>
                    <button class="mat-item"
                        *ngFor="let service of servicetypelist | crmFilter : serviceSearch:text1:text2:15;"
                        (click)="changeservicetype(service)">{{service.name}}</button>
                </mat-menu>
                <div class="mb-3 me-2 " [matMenuTriggerFor]="customermenu" *ngIf="!checkCustomer && viewType != '005'">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton2" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                        [disabled]="checkCustomer" (click)="removeCust()">
                        {{selectcustomername != 'customer' ? selectcustomername : 'Customer' }}
                    </button>
                </div>
                <mat-menu #customermenu="matMenu" xPosition="before">
                    <div class="input-group cl-project-new me-2 mb-3" (click)="$event.stopPropagation();">
                        <input class="clas-project" type="text" placeholder="Search " [(ngModel)]="customersearch"
                            style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;"
                            autocomplete="off">
                    </div>
                    <button class="mat-item" (click)="changecustomer('customer')">-</button>
                    <button class="mat-item" *ngFor="let cus of customers  | crmFilter : customersearch:text1:text2:16;"
                        (click)="changecustomer(cus)">{{cus.customername}}</button>
                </mat-menu>

                <!-- Customer Branch -->
                <div class="mb-3 me-2 " [matMenuTriggerFor]="branchmenu" *ngIf="viewType != '005' && custobranchid == ''">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton2" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                        [disabled]="checkBranch" (click)="removeBranch()">
                        {{selectbranchname != 'branch' ? selectbranchname : 'Branch' }}
                    </button>
                </div>
                
                <mat-menu #branchmenu="matMenu" xPosition="before">
                    <div class="input-group cl-project-new me-2 mb-3" (click)="$event.stopPropagation();">
                        <input class="clas-project" type="text" placeholder="Search " [(ngModel)]="branchsearch"
                            style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;"
                            autocomplete="off">
                    </div>
                    <button class="mat-item" (click)="changeBranch('branch')">-</button>
                    <button class="mat-item"
                        *ngFor="let branch of branchlist  | crmFilter : branchsearch:text1:text2:25;"
                        (click)="changeBranch(branch)">{{branch.branchname}}</button>
                </mat-menu>
                <!-- Customer Branch -->

                <div class="mb-3 me-2 " [matMenuTriggerFor]="customergroupmenu"
                    *ngIf="!checkCustomer && viewType != '005'">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton2" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                        [disabled]="checkCustomer" (click)="removeCustgroup()">
                        {{selectcustomergroupname != 'customergroup' ? selectcustomergroupname : 'Customer Group' }}
                    </button>
                </div>
                <mat-menu #customergroupmenu="matMenu" xPosition="before">
                    <div class="input-group cl-project-new me-2 mb-3" (click)="$event.stopPropagation();">
                        <input class="clas-project" type="text" placeholder="Search " [(ngModel)]="customergroupsearch"
                            style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;"
                            autocomplete="off">
                    </div>
                    <button class="mat-item" (click)="changecustomergroup('customergroup')">-</button>
                    <button class="mat-item"
                        *ngFor="let cus of customergrouplist  | crmFilter : customergroupsearch:text1:text2:24;"
                        (click)="changecustomergroup(cus)">{{cus.name}}</button>
                </mat-menu>

                <div class="mb-3 me-2 " *ngIf="checkCustomer">
                    <input type="text" class="form-control" [(ngModel)]="customername" step="1"
                        [disabled]="checkCustomer" style="border-color: #87929d;min-width: 100px;">
                </div>
                <div class="mb-3 me-2 " [matMenuTriggerFor]="monthlys" *ngIf="checkdaterange">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButto" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;">
                        {{selectthree}}
                    </button>
                </div>
                <mat-menu #monthlys="matMenu" xPosition="before">
                    <button class="mat-item" (click)="changeThree('year')">Year</button>
                    <button class="mat-item" (click)="changeThree('quarterly')">Quarterly</button>
                    <button class="mat-item" (click)="changeThree('monthly')">Monthly</button>
                </mat-menu>
                <div class="  all  mb-3" *ngIf="checkdaterange">
                    <input type="date" class="form-control" id="balancestartdate" name="balancestartdate"
                        [(ngModel)]="balancestartdate" step="1" style="border-color: #87929d;min-width: 100px;"
                        (change)="startdatechange()">
                </div>
                <div class="all  mb-3" *ngIf="checkdaterange">
                    <input type="date" class="form-control" id="balanceenddate" name="balanceenddate"
                        [(ngModel)]="balanceenddate" step="1" style="border-color: #87929d;min-width: 100px;"
                        (change)="enddatechange()">
                </div>
                <div *ngIf="!checkdaterange" class="mb-3 me-2 " [matMenuTriggerFor]="datemenu">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton2" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;">
                        {{datename}}
                    </button>
                </div>
                <mat-menu #datemenu="matMenu" xPosition="before">
                    <button class="mat-item" (click)="changedate('date')">Date</button>
                    <button class="mat-item" (click)="changedate('between')">Between</button>
                </mat-menu>
                <div class="  all  mb-3" *ngIf="checkdate && !checkdaterange">
                    <input type="date" class="form-control" id="startdate" name="startdate" [(ngModel)]="startdate"
                        step="1" style="border-color: #87929d;min-width: 100px;">
                </div>
                <div class="  all  mb-3" *ngIf="!checkdaterange">
                    <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="enddate" step="1"
                        style="border-color: #87929d;min-width: 100px;">
                </div>
                <div *ngIf="searchData.description" class="mb-3 me-2 btn-group ">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="AdvandSearchDate()">
                        <!-- Description: -->
                        {{searchData.description}}
                    </button>
                    <button (click)="closeAdvanSearch(1)" type="button" class="btn btn-secondary "
                        style="background-color: #87929d;border-color:#87929d">
                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>
                </div>
                <div *ngIf="searchData.recurring" class="mb-3 me-2 btn-group ">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="AdvandSearchDate()">
                        Recurring
                    </button>
                    <button (click)="closeAdvanSearch(2)" type="button" class="btn btn-secondary "
                        style="background-color: #87929d;border-color:#87929d">
                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>
                </div>
                <div *ngIf="searchData.picName" class="mb-3 me-2 btn-group ">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="AdvandSearchDate()">
                        <!-- Assigned To : -->
                        {{searchData.picName}}
                    </button>
                    <button (click)="closeAdvanSearch(5)" type="button" class="btn btn-secondary "
                        style="background-color: #87929d;border-color:#87929d">
                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>
                </div>
                <div *ngIf="searchData.assignedtome" class="mb-3 me-2 btn-group ">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="AdvandSearchDate()">
                        Assigned To Me
                    </button>
                    <button (click)="closeAdvanSearch(6)" type="button" class="btn btn-secondary "
                        style="background-color: #87929d;border-color:#87929d">
                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>
                </div>
                <div *ngIf="searchData.detail" class="mb-3 me-2 btn-group ">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="AdvandSearchDate()">
                        <!-- Detail: -->
                        {{searchData.detail}}
                    </button>
                    <button (click)="closeAdvanSearch(3)" type="button" class="btn btn-secondary "
                        style="background-color: #87929d;border-color:#87929d">
                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>
                </div>
                <div *ngIf="searchData.reportedbyid" class="mb-3 me-2 btn-group ">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="AdvandSearchDate()">
                        <!-- Reportedby: -->
                        {{searchData.reportedby}}
                    </button>
                    <button (click)="closeAdvanSearch(4)" type="button" class="btn btn-secondary "
                        style="background-color: #87929d;border-color:#87929d">
                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>
                </div>
                <div *ngIf="searchData.assignedtome == true || searchData.picName || searchData.recurring
       || searchData.reportedbyid ||  searchData.description || searchData.detail"
                    class="reset me-2 btn-group mb-3 align-items-center" role="group" aria-label="Basic example"
                    (click)="removeSearch()" style="cursor: pointer;">
                    Reset
                </div>
                <!-- <div *ngIf="searchData.description" class=" btn-group  mb-2 " role="group" aria-label="Basic example">
                    <button type="button" class="btn " style="background-color: #87929d;border-color:#87929d" (click)="AdvandSearchDate()">Description:{{searchData.description}}</button>
                </div> -->
                <div class=" mb-2 all   " style=" align-items:end;">
                    <div class="btn btn-custom " (click)="AdvandSearchDate()" matTooltip="Advanced Search">
                        <!-- <mat-icon>filter_alt</mat-icon> -->
                        <i class="fa fa-filter" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="col-md-1 mb-3 all  justify-content-end " style=" align-items:end;width: 100px;">
                    <button type="button" class=" btn " (click)="search()" style="color: var(--white-background) !important;
                    background-color:var(--main-color) !important;
                    align-items: center;
                    justify-content: center;">
                        Search
                    </button>
                </div>
                <!-- <div class="btn" (click)="AdvandSearchDate()" matTooltip="Advanced Search">
                    <i class="fa fa-filter" aria-hidden="true" style="width:24px;height: 24px;font-size: 20px;line-height: 1.2;"></i>
                </div> -->
            </div>
            <div class="card card-body my-card" *ngIf="gettingSummary" style="flex-direction: column;">
                <div class="d-flex justify-content-center align-items-center" style="height: 300px;">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
            <div class="card card-body my-card" [ngClass]="{'dis': gettingSummary ,'dis1':!gettingSummary}"
                style="flex-direction:column;align-items: center;">
                <div id="checkRespon">

                    <div class="d-flex flex-wrap" [ngClass]="{'between': isMobileView ,'between2':!isMobileView}">
                        <div class="form-check" [ngClass]="{'me-5': !isMobileView, 'me-1': isMobileView }">
                            <label class="radio-inline">
                                <input class="form-check-input" type="radio" (change)="selected($event)"
                                    value="completedplus" [checked]="showVal == 'completedplus'">
                                Completed+
                            </label>
                        </div>
                        <div class="form-check" [ngClass]="{'me-5': !isMobileView, 'me-1': isMobileView }">
                            <label class="radio-inline">
                                <input class="form-check-input" type="radio" (change)="selected($event)" value="wipplus"
                                    [checked]="showVal == 'wipplus'">
                                Open
                            </label>
                        </div>
                        <div class="form-check" [ngClass]="{'me-5': !isMobileView, 'me-1': isMobileView }">
                            <label class="radio-inline">
                                <input class="form-check-input" type="radio" (change)="selected($event)" value="table"
                                    [checked]="showVal == 'table'">
                                Details
                            </label>
                        </div>
                        <div class="form-check me-auto" [ngClass]="{'me-5': !isMobileView, 'me-1': isMobileView }">
                            <label class="radio-inline">
                                <input class="form-check-input" type="radio" (change)="selected($event)" value="chart"
                                    [checked]="showVal == 'chart'">
                                Chart
                            </label>
                        </div>

                    </div>


                    <div *ngIf="showVal == 'chart'"
                        style="padding: 20px;border-radius: 20px;height: 9000px;background: white;"
                        [ngStyle]="{'height': barChartData.length < 5 ? barChartData.length * 50 + 'px' : barChartData.length < 10 ? barChartData.length * 50 + 'px' : barChartData.length * 20 + 'px'}">
                        <canvas id="myChart"></canvas>
                    </div>


                    <div class="d-flex flex-wrap" style="margin: auto;width: 80%;" *ngIf="showVal == 'table'">
                    </div>
                    <div class=" re-table-detail" style="margin: auto;" [ngClass]="{'table-fix':  isMobileView}"
                        *ngIf="showVal == 'table'">
                        <table class="table table-borderless">
                            <thead class="table-header" style="position: sticky;top: 50px;white-space: nowrap;">
                                <tr>
                                    <td scope="col" class="col-lg-1 col-md-4.5"></td>
                                    <td scope="col" style="text-align: center;" class="col-lg-1 col-md-1.5">Opening
                                        Balance
                                    </td>
                                    <td scope="col" style="text-align: center;" class="col-lg-1 col-md-1.5">Net New</td>
                                    <td scope="col" style="text-align: center;" class="col-lg-1 col-md-1.5">Open</td>
                                    <td scope="col" style="text-align: center;" class="col-lg-1 col-md-1.5">Completed
                                    </td>
                                    <td scope="col" style="text-align: center;" class="col-lg-1 col-md-1.5">Closed </td>
                                    <td scope="col" style="text-align: center;" class="col-lg-1 col-md-1.5">Completed+
                                    </td>
                                    <td scope="col" class="col-lg-1 col-md-1.5">Total <i class="fa fa-info-circle"
                                            aria-hidden="true" matTooltip="Open +  Completed+"></i></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="showTableData.length == 0">
                                    <td colspan="7" class="nodata">Empty</td>
                                </tr>
                                <tr *ngFor="let i of showTableData">
                                    <td>
                                        <span>{{i.name}}</span>
                                    </td>
                                    <td id="td-data" (click)="Open(i,'1')">
                                        <div class="d-flex justify-content-between">
                                    <td class="word">
                                        {{i.openingbalance}}
                                    </td>
                                    <td style="margin-left: 4px;">

                                        <span style="margin-right: 4px;"
                                            class="text-success">{{i.openingbalancepercent}}</span>
                                    </td>
                    </div>
                    </td>
                    <td id="td-data" (click)="Open(i,'3')">
                        <div class="d-flex justify-content-between">
                    <td class="word">
                        {{i.netnew}}
                    </td>
                    <td style="margin-left: 4px;">
                        <span style="margin-right: 4px;" class="text-success">{{i.netnewpercent}}</span>
                    </td>
                </div>
                </td>
                <td (click)="Open(i,'2')">
                    <div id="td-data" class="d-flex justify-content-between">
                <td class="word">
                    <div>{{i.open}}</div><br>

                </td>

                <td style="margin-left: 4px;">
                    <span style="margin-right: 4px;color:var(--main-color)">{{i.openpercent}}</span>
                </td>
            </div>
            <div class="d-flex justify-content-between" style="text-decoration: none;color: var(--main-color);">
                <div class="word">
                    C: <span class="critical"> {{i.critical}}</span>
                </div>
                <div></div>
            </div>

            </td>
            <td id="td-data" (click)="Open(i,'7')">
                <div class="d-flex justify-content-between">
            <td class="word">
                {{i.completedcount}}
            </td>
            <td style="margin-left: 4px;">
                <span style="margin-right: 4px;" class="text-success">{{i.completed}}</span>
            </td>
        </div>
        </td>
        <td id="td-data" (click)="Open(i,'5')">
            <div class="d-flex justify-content-between">
        <td class="word">
            {{i.closedcount}}
        </td>
        <td style="margin-left: 4px;">
            <span style="margin-right: 4px;" class="text-success">{{i.closed}}</span>
        </td>
    </div>
    </td>
    <td id="td-data" (click)="Open(i,'8')">
        <div class="d-flex justify-content-between">
    <td class="word">
        {{i.completedclosed}}
    </td>
    <td style="margin-left: 4px;">
        <span style="margin-right: 4px;" class="text-success">{{i.percentvalue}}</span>
    </td>
</div>
</td>

<td style="text-align: center;">
    <span id="td-data" class="words" (click)="Open(i,'6', i.name)" style="margin-left: 4px;">
        {{i.total}}
    </span>
</td>
</tr>
</tbody>
</table>
</div>
<div class=" re-table-total" style="margin: auto;" [ngClass]="{'table-fix':  isMobileView}"
    *ngIf="showVal == 'completedplus'">
    <table class="table  table-borderless">
        <thead class="table-header" style="position: sticky;top: 50px;white-space: nowrap;">
            <tr>
                <!--  mat-sort-header="type" -->
                <td scope="col" class="col-lg-8"></td>
                <td scope="col" style="text-align: center;" class="col-lg-2">Completed+</td>
                <td scope="col" style="text-align: center;" class="col-lg-2">Total </td>
            </tr>
        </thead>
        <!-- -->
        <tbody>
            <tr *ngIf="showTableData.length == 0">
                <td colspan="3" class="nodata">Empty</td>
            </tr>
            <tr *ngFor="let i of showTableData">
                <td>
                    <span>{{i.name}}</span>
                </td>
                <td id="td-data" (click)="Open(i,'8')">
                    <div class="d-flex justify-content-between">
                <td class="word">
                    {{i.completedclosed}}
                </td>
                <td style="margin-left: 4px;">
                    <!-- <span id="td-data" style="margin-right: 4px;"  class="text-success" [innerHTML]="i.percentvalue"></span> -->
                    <span style="margin-right: 4px;" class="text-success">{{i.percentvalue}}</span>
                </td>
</div>
</td>
<td style="text-align: center;">
    <span id="td-data " class="words" (click)="Open(i,'6', i.name)" style="margin-left: 4px;">
        {{i.total}}
    </span>
</td>
</tr>
</tbody>
</table>
</div>
<div class=" re-table-total" style="margin: auto" [ngClass]="{'table-fix':  isMobileView}" *ngIf="showVal == 'wipplus'">
    <table class="table  table-borderless">
        <thead class="table-header" style="position: sticky;top: 50px;white-space: nowrap;">
            <tr>
                <!--  mat-sort-header="type" -->
                <td scope="col" class="col-lg-8"></td>
                <td scope="col" style="text-align: center;" class="col-lg-2">Open</td>
                <td scope="col" style="text-align: center;" class="col-lg-2">Total </td>
            </tr>
        </thead>
        <!-- -->
        <tbody>
            <tr *ngIf="showTableData.length == 0">
                <td colspan="3" class="nodata">Empty</td>
            </tr>
            <tr *ngFor="let i of showTableData">
                <td>
                    <span>{{i.name}}</span>
                </td>
                <td (click)="Open(i,'2')">
                    <div id="td-data" class="d-flex justify-content-between">
                <td class="word">
                    {{i.open}}
                </td>
                <td style="margin-left: 4px;">
                    <!-- <span id="td-data" style="margin-right: 4px;"  class="text-success" [innerHTML]="i.openpercent"> </span> -->
                    <span style="margin-right: 4px;color:var(--main-color)">{{i.openpercent}}</span>
                </td>
</div>

</td>
<td style="text-align: center;">
    <span id="td-data" class="words" (click)="Open(i,'6', i.name)" style="margin-left: 4px;">
        {{i.total}}
    </span>
</td>
</tr>
</tbody>
</table>
</div>
</div>

</div>
<div id="checkRespon2">

    <div class="card card-body my-card" [ngClass]="{'dis': gettingSummary ,'dis1':!gettingSummary}"
        style="flex-direction:column;align-items: center;">


        <div class="d-flex flex-wrap" [ngClass]="{'between': isMobileView ,'between2':!isMobileView}">
            <div class="form-check" [ngClass]="{'me-5': !isMobileView, 'me-1': isMobileView }">
                <label class="radio-inline">
                    <input class="form-check-input" type="radio" (change)="selected($event)" value="completedplus"
                        [checked]="showVal == 'completedplus'">
                    Completed+
                </label>
            </div>
            <div class="form-check" [ngClass]="{'me-5': !isMobileView, 'me-1': isMobileView }">
                <label class="radio-inline">
                    <input class="form-check-input" type="radio" (change)="selected($event)" value="wipplus"
                        [checked]="showVal == 'wipplus'">
                    Open
                </label>
            </div>
            <div class="form-check" [ngClass]="{'me-5': !isMobileView, 'me-1': isMobileView }">
                <label class="radio-inline">
                    <input class="form-check-input" type="radio" (change)="selected($event)" value="table"
                        [checked]="showVal == 'table'">
                    Details
                </label>
            </div>
            <div class="form-check me-auto" [ngClass]="{'me-5': !isMobileView, 'me-1': isMobileView }">
                <label class="radio-inline">
                    <input class="form-check-input" type="radio" (change)="selected($event)" value="chart"
                        [checked]="showVal == 'chart'">
                    Chart
                </label>
            </div>

        </div>

        <div *ngIf="showVal == 'chart'" style="padding: 20px;border-radius: 20px;height: 9000px;background: white;"
            [ngStyle]="{'height': barChartData.length < 5 ? barChartData.length * 50 + 'px' : barChartData.length < 10 ? barChartData.length * 50 + 'px' : barChartData.length * 20 + 'px'}">
            <canvas id="myChart1"></canvas>
        </div>
        <div class="table-responsive  re-table-detail" style="margin: auto;" [ngClass]="{'table-fix':  isMobileView}"
            *ngIf="showVal == 'table'">
            <table class="table   table-borderless">
                <thead class="table-header" style="position: sticky;top: 0;">
                    <tr>

                        <td scope="col" class="col-lg-1 col-md-4.5"></td>
                        <td scope="col" style="text-align: center;" class="col-lg-1 col-md-1.5">Opening Balance </td>
                        <td scope="col" style="text-align: center;" class="col-lg-1 col-md-1.5">Net New</td>
                        <td scope="col" style="text-align: center;" class="col-lg-1 col-md-1.5">Open</td>
                        <td scope="col" style="text-align: center;" class="col-lg-1 col-md-1.5">Completed</td>
                        <td scope="col" style="text-align: center;" class="col-lg-1 col-md-1.5">Closed </td>
                        <td scope="col" style="text-align: center;" class="col-lg-1 col-md-1.5">Completed+</td>
                        <td scope="col" class="col-lg-1 col-md-1.5">Total <i class="fa fa-info-circle"
                                aria-hidden="true" matTooltip="Open +  Completed+"></i> </td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="showTableData.length == 0">
                        <td colspan="7" class="nodata">Empty</td>
                    </tr>
                    <tr *ngFor="let i of showTableData">
                        <td>
                            <span>{{i.name}}</span>
                        </td>
                        <td id="td-data" (click)="Open(i,'1')">
                            <div class="d-flex justify-content-between">
                                <div class="word">
                                    <div>
                                        {{i.openingbalance}}
                                    </div>
                                </div>
                                <div style="margin-left: 4px;width: 50%;">
                                    <span style="margin-right: 4px;"
                                        class="text-success">{{i.openingbalancepercent}}</span>
                                </div>
                            </div>
                        </td>
                        <td id="td-data" (click)="Open(i,'3')">
                            <div class="d-flex justify-content-between">
                        <td class="word">
                            {{i.netnew}}
                        </td>
                        <td style="margin-left: 4px;">
                            <span style="margin-right: 4px;" class="text-success">{{i.netnewpercent}}</span>
                        </td>
        </div>
        </td>
        <td (click)="Open(i,'2')">
            <div id="td-data" class="d-flex justify-content-between">
        <td class="word">
            {{i.open}}
        </td>
        <td style="margin-left: 4px;">
            <span style="margin-right: 4px;color:var(--main-color)">{{i.openpercent}}</span>
        </td>
    </div>

    </td>

    <td id="td-data" (click)="Open(i,'7')">
        <div class="d-flex justify-content-between">
    <td class="word">
        {{i.completedcount}}
    </td>
    <td style="margin-left: 4px;">
        <span style="margin-right: 4px;" class="text-success">{{i.completed}}</span>
    </td>
</div>
</td>
<td id="td-data" (click)="Open(i,'5')">
    <div class="d-flex justify-content-between">
<td class="word">
    {{i.closedcount}}
</td>
<td style="margin-left: 4px;">

    <span style="margin-right: 4px;" class="text-success"> {{i.closed}}</span>
</td>
</div>
</td>
<td id="td-data" (click)="Open(i,'8')">
    <div class="d-flex justify-content-between">
<td class="word">
    {{i.completedclosed}}
</td>
<td style="margin-left: 4px;">
    <span style="margin-right: 4px;" class="text-success">{{i.percentvalue}}</span>
</td>
</div>
</td>
<td style="text-align: center;">
    <span id="td-data" class="words" (click)="Open(i,'6', i.name)" style="margin-left: 4px;">
        {{i.total}}
    </span>
</td>

</tr>
</tbody>
</table>
</div>
<div class=" re-table-total" style="margin: auto;" [ngClass]="{'table-fix':  isMobileView}"
    *ngIf="showVal == 'completedplus'">
    <table class="table  table-borderless">
        <thead class="table-header" style="position: sticky;top: 0;">
            <tr>
                <!--  mat-sort-header="type" -->
                <td scope="col" class="col-lg-8"></td>
                <td scope="col" style="text-align: center;" class="col-lg-2">Completed+</td>
                <td scope="col" style="text-align: center;" class="col-lg-2">Total </td>
            </tr>
        </thead>
        <!-- -->
        <tbody>
            <tr *ngIf="showTableData.length == 0">
                <td colspan="3" class="nodata">Empty</td>
            </tr>
            <tr *ngFor="let i of showTableData">
                <td>
                    <span>{{i.name}}</span>
                </td>
                <td id="td-data" (click)="Open(i,'8')">
                    <div class="d-flex justify-content-between">
                <td class="word">
                    {{i.completedclosed}}
                </td>
                <td style="margin-left: 4px;">
                    <!-- <span id="td-data" style="margin-right: 4px;"  class="text-success" [innerHTML]="i.percentvalue"></span> -->
                    <span style="margin-right: 4px;" class="text-success">{{i.percentvalue}}</span>
                </td>
</div>
</td>
<td style="text-align: center;">
    <span id="td-data" class="words" (click)="Open(i,'6', i.name)" style="margin-left: 4px;">
        {{i.total}}
    </span>
</td>
</tr>
</tbody>
</table>
</div>
<div *ngIf="showVal == 'wipplus'" class=" re-table-total overflow-auto" style="margin: auto;"
    [ngClass]="{'table-fix':  isMobileView}">
    <table class="table  table-borderless">
        <thead class="table-header" style="position: sticky;top: 0;">
            <tr>
                <td scope="col" class="col-lg-8"></td>
                <td scope="col" style="text-align: center;" class="col-lg-2">Open</td>
                <td scope="col" style="text-align: center;" class="col-lg-2">Total </td>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="showTableData.length == 0">
                <td colspan="3" class="nodata">Empty</td>
            </tr>
            <tr *ngFor="let i of showTableData">
                <td>
                    <span>{{i.name}}</span>
                </td>
                <td (click)="Open(i,'2')">
                    <div id="td-data" class="d-flex justify-content-between">
                <td class="word">
                    {{i.open}}
                </td>
                <td style="margin-left: 4px;">
                    <!-- <span id="td-data" style="margin-right: 4px;"  class="text-success" [innerHTML]="i.openpercent"> </span> -->
                    <span style="margin-right: 4px;color:var(--main-color)">{{i.openpercent}}</span>
                </td>
</div>

</td>
<!-- <td id="td-data" (click)="Open(i,'2')">
                                    <span>{{i.wipplus}}</span>
                                </td> -->
<td style="text-align: center;">
    <span id="td-data" class="words" (click)="Open(i,'6', i.name)" style="margin-left: 4px;">
        {{i.total}}
    </span>
</td>
</tr>
</tbody>
</table>
</div>

</div>


</div>
</div>
</div>
</div>