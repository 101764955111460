import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class Cs365DashboardComponent
  implements OnInit, OnDestroy, AfterContentChecked {
  private subs = new SubSink();
  mobileViewWidth: number = 426;
  isMobileView: boolean = false;
  customercare: boolean = false;
  customers: any = [];
  loadCustomer: boolean = false;
  customerAry: string = '';
  selectCustomerID: any = [];
  arrays: any = [];
  permission: any = [];
  organizations: any = [];
  orgid: any = '';
  orgName: any = '';
  admin: boolean = false;
  orgList: any = [];
  checkorg: boolean = false;
  customerdata: any = [];
  customerid: any = '';
  servicetype: boolean = false;
  servicestatus: boolean = false;
  checkselfservice: boolean = true;
  items = [
    { id: 1, name: 'eh' },
    { id: 2, name: 'hello' },
    { id: 4, name: 'myanmar' },
  ]

  id = 2;
  CrmList = [
    {
      title: 'Service',
      card: [
        {
          appid: '1',
          appname: 'Service Tickets',
          icon: 'assets/images/service.png',
          routname: 'cs365/service-tickets',
          value: '001',
        },
        {
          appid: '2',
          appname: 'Service Summary',
          icon: 'assets/images/servicesummary.png',
          routname: 'service/summary',
          value: '002',
        },

        // { "appid": "5", "appname": "Service Monthly Report", "icon": "assets/images/summaryreport.png", "routname": "service/servicereport", "value": "009" },
        // // { "appid": "5", "appname": "Monthly Earlier Report", "icon": "assets/images/summaryreport.png", "routname": "service/earlierreport", "value": "009" },
        // { "appid": "10", "appname": "Service Type", "icon": "assets/images/servicetype.png", "routname": "service/servicetype", "value": "003" },
        // { "appid": "11", "appname": "Service Status", "icon": "assets/images/servicestatus.png", "routname": "service/status", "value": "004" },
        // { "appid": "12", "appname": "Service Overage", "icon": "assets/images/overagesetup.png", "routname": "service/serviceoverage", "value": "005" },
        // { "appid": "12", "appname": "Service Overage Report", "icon": "assets/images/overage.png", "routname": "service/serviceoveragereport", "value": "005" },
        // { "appid": "8", "appname": "Customers", "icon": "assets/images/customers.png", "routname": "service/customers", "value": "008" },
        // { "appid": "13", "appname": "Service Manhour Detail", "icon": "assets/images/overagesetup.png", "routname": "service/servicemanhourdetailreport", "value": "005" },
      ],
    },
  ];

  appList1: any = [
    {
      title: 'Service',
      card: [
        {
          appid: '4',
          appname: 'Service Tickets',
          icon: 'assets/images/service.png',
          routname: 'cs365/service-tickets',
          value: '001',
        },
        // {
        //   appid: '5',
        //   appname: 'Service Summary',
        //   icon: 'assets/images/servicesummary.png',
        //   routname: 'service/summary',
        //   value: '002',
        // },
        {
          appid: '3',
          appname: 'Label',
          icon: 'assets/images/servicesummary.png',
          routname: 'cs365/label',
          value: '002',
        },
        {
          appid: '4',
          appname: 'Products',
          icon: 'assets/images/product.png',
          routname: 'cs365/products',
          value: '002',
        },
        {
          appid: '5',
          appname: 'Warranty',
          icon: 'assets/images/servicesummary.png',
          routname: 'cs365/warranty',
          value: '002',

        },
        {
          appid: '6',
          appname: 'Service Warranty',
          icon: 'assets/images/servicesummary.png',
          routname: 'cs365/service-warranty',
          value: '002',

        }
      ],
    },
  ];

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else if (window.innerWidth > this.mobileViewWidth) {
      this.isMobileView = false;
    } else {
      this.isMobileView = false;
    }
  }

  constructor(
    private router: Router,
    public allinoneService: AllInOneService,
    private ref: ChangeDetectorRef,
    private titleService: Title,
  ) {
    console.log('hello')
    this.titleService.setTitle('CS365');
    this.allinoneService.changeFavicon('assets/images/cslogo.ico');
  }

  ngOnInit(): void {
    this.organizations = this.allinoneService.orgsCRm;
    this.customerdata = this.allinoneService.getCustomer();
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.allinoneService.activeroute = '/adminconsole';
  }

  goRoute(i: any, path: string): void {
    this.router.navigateByUrl(path);
  }
}
