import { formatDate } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-leave-summary-report',
  templateUrl: './leave-summary-report.component.html',
  styleUrls: ['./leave-summary-report.component.scss'],
})
export class LeaveSummaryReportComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  mobileViewWidth: number = 426;
  isMobileView: boolean = false;

  openfilter: boolean = false;

  departmentList: string[] = ['All'];
  divisionList: string[] = ['All'];
  teamidList: string[] = ['All'];
  costCenterList: string[] = ['All'];
  subDivisionList: string[] = ['All'];
  gettingMemberPosition: boolean = false;
  getMemberSub!: Subscription;

  formatToShow = 'yyyy-MM-dd';
  d = new Date();
  month = (this.d.getMonth() + 1).toString().padStart(2, '0');
  day = this.d.getDate().toString().padStart(2, '0');
  //for filter date
  aweekBefore = new Date(this.d).setDate(new Date(this.d).getDate() - 7);
  aweekBeforeDate = new Date(this.aweekBefore);

  currentStartDate = formatDate(
    new Date(this.aweekBefore),
    this.formatToShow,
    'en-US'
  ).toString();
  tdyDate = formatDate(new Date(), this.formatToShow, 'en-US').toString();

  currentYear = new Date().getFullYear();

  searchText: string = '';
  calenderListLoading: boolean = false;

  itemPerPage: number = 100;
  currentPage: number = 1;

  searchLoading: boolean = false;
  isFocus: boolean = false;
  isAdmin: boolean = false;

  organizations: any = [];
  summaryData: any = [];
  ccList: any = [];

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];

  yearList: any = [];
  allCalendarList: any[] = [];

  subscription!: Subscription;
  subscriptions = new SubSink();

  userStatusFilterList = this.allinoneService.userStatusFilterList;

  reportForm = {
    orgid: '',
    startdate: this.currentStartDate,
    enddate: this.tdyDate,
    // year: this.currentYear,
    year: '-',
    calendarid: '',
    department: 'All',
    division: 'All',
    teamid: 'All',
    costcenter: 'All',
    subdivision: 'All',
    status: '001',
  };

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private filter: FilterPipe
  ) {
    this.route.data.subscribe((data) => {
      if (data.role == 'admin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        this.allinoneService.isOfficeView = true;
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    // this.organizations = this.allinoneService.orgs;
    // this.getYearList();
    // this.reportForm.orgid = this.allinoneService.selectedOrg
    //   ? this.allinoneService.selectedOrg
    //   : this.organizations[0].orgid;
    if (this.isAdmin) {
      // this.organizations = this.allinoneService.orgs;
      // this.reportForm.orgid = this.allinoneService.selectedOrg
      //   ? this.allinoneService.selectedOrg
      //   : this.organizations[0].orgid;
      this.organizations = this.allinoneService.orgs;
      this.reportForm.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;
    } else {
      this.organizations = this.allinoneService.orgsCRm;
      this.reportForm.orgid = this.allinoneService.selected_orgcrm
        ? this.allinoneService.selected_orgcrm.orgid
        : this.organizations[0].orgid;
    }

    this.getCcList();
    this.getCalendarList();
    // this.search();
  }

  getCalendarList() {
    this.calenderListLoading = true;
    let data = {
      orgid: this.reportForm.orgid,
      domainid: this.allinoneService.getDomain().domainid,
      active: '',
      roleid: this.allinoneService.getSelectedRole(),
    };
    this.subscriptions.sink = this.kunyekService.getCalendar(data).subscribe(
      (res) => {
        if (res.returncode == 300) {
          this.calenderListLoading = false;
          let _yearAll: any = [];
          _yearAll = res['calendarlist'];
          this.yearList = _yearAll.filter(
            (data: any) => data.active && data.valid
          );
          this.reportForm.calendarid = this.yearList[0]['calendarid'];
          this.reportForm.year = this.yearList[0]['year'];
          this.allCalendarList = res.calendarlist;
          console.log('you form');
          console.log(this.yearList[0]['calendarid']);
          console.log(this.reportForm.calendarid);
          this.search();
        } else {
          this.calenderListLoading = false;
        }
      },
      (err) => {
        this.calenderListLoading = false;
      }
    );
  }

  toggleFilter() {
    if (
      this.departmentList.length < 2
      //  ||
      // this.divisionList.length < 2 ||
      // this.teamidList.length < 2 ||
      // this.costCenterList.length < 2 ||
      // this.subDivisionList.length < 2
    ) {
      this.getMemberPosition();
    }
    this.openfilter = !this.openfilter;
  }

  departmentChange(dep: any) {
    if (this.reportForm.department != dep) {
      this.reportForm.department = dep;
      this.reportForm.division = 'All';
      this.reportForm.teamid = 'All';
      this.reportForm.costcenter = 'All';
      this.reportForm.subdivision = 'All';
    }
  }

  divisionChange(divi: any) {
    if (this.reportForm.division != divi) {
      this.reportForm.division = divi;
      this.reportForm.teamid = 'All';
      this.reportForm.costcenter = 'All';
      this.reportForm.subdivision = 'All';
    }
  }

  teamidChange(teamid: any) {
    if (this.reportForm.teamid != teamid) {
      this.reportForm.teamid = teamid;
      this.reportForm.costcenter = 'All';
      this.reportForm.subdivision = 'All';
    }
  }

  costCenterChange(costcenter: any) {
    if (this.reportForm.costcenter != costcenter) {
      this.reportForm.costcenter = costcenter;
      this.reportForm.subdivision = 'All';
    }
  }

  subDivisionChange(subdivision: any) {
    if (this.reportForm.subdivision != subdivision) {
      this.reportForm.subdivision = subdivision;
    }
  }

  getMemberPosition() {
    this.gettingMemberPosition = true;
    const data = {
      orgid: this.reportForm.orgid,
    };
    this.getMemberSub && this.getMemberSub.unsubscribe();
    this.getMemberSub = this.kunyekService.getMemberPosition(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.departmentList = this.departmentList.concat(res.departmentlist);
          this.divisionList = this.divisionList.concat(res.divisionlist);
          this.teamidList = this.teamidList.concat(res.teamidlist);
          this.costCenterList = this.costCenterList.concat(res.costcenterlist);
          this.subDivisionList = this.subDivisionList.concat(res.subdivision);
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingMemberPosition = false;
      },
      (err) => {
        this.gettingMemberPosition = false;
      }
    );
  }

  // getYearList() {
  //   for (var i = 0; i < 4; i++) {
  //     var y = this.currentYear - i;
  //     this.yearList.push(y);
  //   }
  // }

  yearChange(year: any) {
    this.reportForm.year = year.year;
    this.reportForm.calendarid = year.calendarid;
    this.search();
  }

  gotoOffice() {
    this.router.navigateByUrl(`adminconsole/office`);
  }

  search() {
    this.searchLoading = true;
    this.summaryData = [];
    const data = {
      // startdate: this.allinoneService.formatDate(this.reportForm.startdate),
      // enddate: this.allinoneService.formatDate(this.reportForm.enddate),
      startdate: '',
      enddate: '',
      year: this.reportForm.year.toString(),
      calendarid: this.reportForm.calendarid,
      orgid: this.reportForm.orgid,
      // roleid: this.allinoneService.getSelectedRole(),
      roleid: '',
      department:
        !this.openfilter || this.reportForm.department == 'All'
          ? ''
          : this.reportForm.department,
      division:
        !this.openfilter || this.reportForm.division == 'All'
          ? ''
          : this.reportForm.division,
      teamid:
        !this.openfilter || this.reportForm.teamid == 'All'
          ? ''
          : this.reportForm.teamid,
      costcenter:
        !this.openfilter || this.reportForm.costcenter == 'All'
          ? ''
          : this.reportForm.costcenter,
      subdivision:
        !this.openfilter || this.reportForm.subdivision == 'All'
          ? ''
          : this.reportForm.subdivision,
      activestatus: this.openfilter ? this.reportForm.status : '001',
    };
    this.subscription && this.subscription.unsubscribe();
    this.subscription = this.kunyekService
      .getLeaveSummaryReport(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            console.log(`Leave summary report: ${JSON.stringify(res)}`)
            res.list.map((x: any) => {
              var post = this.ccList.find((item: any) => item.id == x.typeid);
              var postname = post ? post.name : x.typename;
              if (x.requestlist.length > 0) {
                x.requestlist.map((y: any) => {
                  const noofdays = Number.isNaN(parseFloat(y.noofdays))
                    ? 0
                    : parseFloat(y.noofdays);
                  // 0
                  const bd = Number.isNaN(parseFloat(y.broughtforward))
                    ? 0
                    : parseFloat(y.broughtforward);
                  // 0
                  const op = Number.isNaN(parseFloat(y.opening))
                    ? 0
                    : parseFloat(y.opening);
                  // 2
                  // const el = y.opening == '' ? noofdays + bd : op + bd;
                  // new leave opening
                  // const el = noofdays + bd + op;
                  const el = noofdays;
                  const total = Number((el + bd + op).toFixed(1));
                  const ul = Number.isNaN(parseFloat(y.taken))
                    ? 0
                    : parseFloat(y.taken);
                  const temp = {
                    department: x.department,
                    division: x.division,
                    teamid : x.teamid,
                    costcenter : x.costcenter,
                    employeeid: x.employeeid,
                    joineddate: x.joineddate,
                    post: x.post,
                    postname: postname,
                    type: x.type,
                    typeid: x.typeid,
                    userid: x.userid,
                    username: x.username,
                    leavetype: y.requestsubtype,
                    // noofdays == 0? opening == '' ? opening : '-' : el
                    // entitledleave: noofdays == 0 ? '-' : el,
                    broughtforward:
                      y.broughtforward === '' ? '-' : y.broughtforward,
                    opening: y.opening === '' ? '-' : y.opening,
                    // entitledleave:
                    //   noofdays == 0 ? (y.opening == '' ? '-' : y.opening) : el,
                    entitledleave: el,
                    usedleave: ul,
                    // balanceleave: noofdays == 0 ? '-': el - ul,
                    // balanceleave:
                    //   noofdays == 0
                    //     ? y.opening == ''
                    //       ? '-'
                    //       : y.opening
                    //     : total - ul,
                    balanceleave: parseFloat((total - ul).toFixed(1)),
                  };
                  this.summaryData.push(temp);
                });
              } else {
                console.log('hereeee');
                const temp1 = {
                  department: x.department,
                  division: x.division,
                  teamid : x.teamid,
                  costcenter : x.costcenter,
                  employeeid: x.employeeid,
                  joineddate: x.joineddate,
                  post: x.post,
                  postname: postname,
                  type: x.type,
                  typeid: x.typeid,
                  userid: x.userid,
                  username: x.username,
                  leavetype: '-',
                  broughtforward: '-',
                  opening: '-',
                  entitledleave: '-',
                  usedleave: '-',
                  balanceleave: '-',
                };
                this.summaryData.push(temp1);
              }
            });
            this.pg[3].count = this.summaryData.length;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.searchLoading = false;
        },
        (err) => {
          this.searchLoading = false;
        }
      );
  }

  sortData(sort: Sort) {
    const data = this.summaryData;
    if (!sort.active || sort.direction === '') {
      this.summaryData = data;
      return;
    }
    this.summaryData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'employeeid':
          return this.allinoneService.compare(
            a.employeeid,
            b.employeeid,
            isAsc
          );

        case 'postname':
          return this.allinoneService.compare(a.postname, b.postname, isAsc);
        case 'leavetype':
          return this.allinoneService.compare(a.leavetype, b.leavetype, isAsc);
        case 'entitledleave':
          return this.allinoneService.compare(
            a.entitledleave,
            b.entitledleave,
            isAsc
          );
        case 'broughtforward':
          return this.allinoneService.compare(
            a.broughtforward,
            b.broughtforward,
            isAsc
          );
        case 'opening':
          return this.allinoneService.compare(a.opening, b.opening, isAsc);
        case 'usedleave':
          return this.allinoneService.compare(a.usedleave, b.usedleave, isAsc);
        case 'balanceleave':
          return this.allinoneService.compare(
            a.balanceleave,
            b.balanceleave,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  getCcList() {
    const data = {
      orgid: this.reportForm.orgid,
    };
    this.kunyekService.getClassCodeList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.ccList = res.datalist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
      },
      (err) => {}
    );
  }

  clear() {
    this.searchText = '';
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.currentPage = 1;
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  refresh() {
    this.search();
  }

  downloadSheet() {
    var dataToExport = this.filter.transform(
      this.summaryData,
      this.searchText,
      'LeaveSummaryReport'
    );
    var data: any = [];
    var name =
      'Leave Summary Report (' +
      this.reportForm.startdate +
      ' - ' +
      this.reportForm.enddate +
      ').xlsx';
    dataToExport.map((user: any) => {
      data.push({
        'User ID': user.userid,
        Name: user.username,
        'Employee ID': user.employeeid,
        Post: user.postname,
        Department: user.department,
        Division: user.division,
        'Team ID': user.teamid,
        'Cost Center' : user.costcenter,
        'Leave Type': user.leavetype,
        'Brought Forward': user.broughtforward,
        'Entitled Leave': user.entitledleave,
        Opening: user.opening,
        'Used Leave': user.usedleave,
        'Balanced Leave': user.balanceleave,
      });
    });

    this.allinoneService.exportEcecl(data, name);
  }
}
