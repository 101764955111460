import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { SubSink } from 'subsink';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-office-roster',
  templateUrl: './office-roster.component.html',
  styleUrls: ['./office-roster.component.scss']
})
export class OfficeRosterComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  isAdmin: boolean = false;
  isMobileView: boolean = false;
  addRoster : boolean = false;
  isEditing : boolean = false;
  saveLoading: boolean = false;
  getListSubscription!: Subscription;
  getRosterSubscription! : Subscription;
  subscriptions = new SubSink();
  isFocus : boolean = false;

  shiftListLoading : boolean = false;

  searchText: string = '';
  orgid: string = '';

  shiftList : any = [];

  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  currentPage: number = 1;

  organizations: any = [];

  rosterListLoading = false;
  rosterList: any = []

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  rosterForm = {
    rosterid : '',
    name : '',
    description : '',
    monday : '',
    tuesday : '',
    wednesday : '',
    thursday : '',
    friday : '',
    saturday : '',
    sunday : '',
    default : false,
  }
  rosterFormSubmitted = false;

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private filterPipe: FilterPipe
  ) {
    this.onResize(event);
    this.route.data.subscribe((data) => {
      if (data.role == 'admin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        this.allinoneService.isOfficeView = true;
      }
    });
  }

  ngOnInit(): void {
    if (this.isAdmin) {
      this.organizations = this.allinoneService.orgs;
      this.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;
    } else {
      this.organizations = this.allinoneService.orgsCRm;
      this.orgid = this.allinoneService.selected_orgcrm
        ? this.allinoneService.selected_orgcrm.orgid
        : this.organizations[0].orgid;
    }
    this.getRosterList();
    this.getShiftList();

  }

  ngOnDestory() : void {
    this.getListSubscription && this.getListSubscription.unsubscribe();
    this.getRosterSubscription && this.getRosterSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.allinoneService.isOfficeView = false;
  }

  getShiftList() {
    this.shiftListLoading = true;
    const data = {
      orgid: this.orgid,
    };
    this.getListSubscription && this.getListSubscription.unsubscribe();
    this.getListSubscription = this.kunyekService.getShift(data).subscribe(
      (res: any) => {
        console.log("Roster List");
        
        console.log(res);
        
        if (res.returncode == '300') {
          this.shiftList = res.datalist;
          console.log(this.shiftList);
          
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.shiftListLoading = false;
      },
      (err) => {
        this.shiftListLoading = false;
      }
    );
  }

  getRosterList() {
    this.rosterListLoading = true;
    const data = {
      orgid : this.orgid,
    };
    this.getRosterSubscription && this.getRosterSubscription.unsubscribe();
    this.getRosterSubscription = this.kunyekService.getRoster(data).subscribe(
      (res : any) => {
        if(res.returncode == '300') {
          this.rosterList = res.datalist
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.rosterListLoading = false;
      },
      (err) => {
        this.rosterListLoading = false;
      }
    )
  }

  submitRoster() {
    this.rosterFormSubmitted = true;
    if(this.rosterForm.name == "") {
      return
    }
    if(this.rosterForm.monday == "" && this.rosterForm.tuesday == "" && this.rosterForm.wednesday == "" && this.rosterForm.thursday == "" && this.rosterForm.friday == "" && this.rosterForm.saturday == "" && this.rosterForm.sunday == "") {
      this.messageService.openSnackBar(
        "Please select shift for at least one day.",
        'warn'
      );
      return;
    }
    this.saveRoster();
  }

  saveRoster() {
    this.saveLoading = true;

    var shiftDataList = [this.rosterForm.monday, this.rosterForm.tuesday, this.rosterForm.wednesday, this.rosterForm.thursday, this.rosterForm.friday, this.rosterForm.saturday, this.rosterForm.sunday]
    var uniShiftList = [...new Set(shiftDataList)];
    var shiftData = {}
    
    for(var i = 0 ; i < uniShiftList.length; i++ ) {
      for(var x = 0; x < this.shiftList.length; x++) {
        if(uniShiftList[i] == this.shiftList[x]['shiftid']) {
          let a = this.shiftList[x]['shiftid'];
          Object.assign(shiftData, {
            [uniShiftList[i]] : this.shiftList[x]
          })
        }
      }
    }

    var data =  {
      orgid : this.orgid,
      rosterid : this.rosterForm.rosterid,
      name : this.rosterForm.name.toString().trim(),
      description : this.rosterForm.description.toString().trim(),
      monday : this.rosterForm.monday,
      tuesday : this.rosterForm.tuesday,
      wednesday : this.rosterForm.wednesday,
      thursday : this.rosterForm.thursday,
      friday : this.rosterForm.friday,
      saturday : this.rosterForm.saturday,
      sunday : this.rosterForm.sunday,
      default : this.rosterForm.default,
      shiftdata : shiftData
    }
    
    console.log("Your data");
    console.log(data);

    var fun = this.isEditing
    ? this.kunyekService.updateRoster(data)
      : this.kunyekService.createRoster(data);
    this.subscriptions.sink = fun.subscribe(
      (res:any) => {
        if(res.returncode == '300') {
          if(this.isEditing) {
            var index = this.rosterList.findIndex(
              (x:any) => x.rosterid == this.rosterForm.rosterid
            );
            if(index > -1) {
              this.rosterList[index].name = data.name;
              this.rosterList[index].description = data.description;
              this.rosterList[index].monday = data.monday;
              this.rosterList[index].tuesday = data.tuesday;
              this.rosterList[index].wednesday = data.wednesday;
              this.rosterList[index].thursday = data.thursday;
              this.rosterList[index].friday = data.friday;
              this.rosterList[index].saturday = data.saturday;
              this.rosterList[index].sunday = data.sunday;
              this.rosterList[index].default = data.default;
            } else {
              this.refresh();
            }
          } else {
            var tempRoster = { ...data, rosterid : res.rosterid };
            this.rosterList.push(tempRoster);
          }
          this.cancel();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err) => {
        this.messageService.openSnackBar('Server Error', 'warn');
        this.saveLoading = false;
      }
    )
    
  }

  sortData(sort: Sort) {
    const data = this.rosterList;
    if (!sort.active || sort.direction === '') {
      this.rosterList = data;
      return;
    }
    this.rosterList = data.sort((a : any, b: any) => {
      const isAsc = sort.direction === 'asc';
      if(sort.active == 'name') {
        return this.allinoneService.compare(a.name, b.name, isAsc);
      } else if(sort.active == 'description') {
        return this.allinoneService.compare(a.description, b.description, isAsc);
      }
      return 0;
    })
  }

  editRoster(roster : any) {
    this.isEditing = true;
    this.addRoster = true;
    this.rosterForm = {
      rosterid : roster.rosterid,
      name : roster.name,
      description : roster.description,
      monday : roster.monday,
      tuesday : roster.tuesday,
      wednesday : roster.wednesday,
      thursday : roster.thursday,
      friday : roster.friday,
      saturday : roster.saturday,
      sunday : roster.sunday,
      default : roster.default
    }
  }

  deleteRoster(rosterid: string, index:number) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe((dialogResult : any) => {
      if(dialogResult) {
        this.rosterListLoading = true;
        const data = {
          orgid : this.orgid,
          rosterid : rosterid 
        }
        this.kunyekService.deleteRoster(data).subscribe(
          (res : any) => {
            if(res.returncode == '300') {
              var i = this.rosterList.findIndex(
                (x:any) => x.rosterid == rosterid
              );
              this.rosterList.splice(i, 1);
              // this.rosterList.splice(index, 1);
              if (
                Math.round(this.rosterList.length / this.itemPerPage) <
                this.currentPage
              ) {
                this.currentPage = this.currentPage - 1;
              }
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.rosterListLoading = false;
          },
          (err) => {
            this.rosterListLoading = false;
          }
        )
      }
    })
  }

  newRoster() {
    this.addRoster = true;
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  clear() {
    this.searchText = ""
  }

  refresh() {
    this.currentPage = 1;
    this.getRosterList();
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.currentPage = 1;
  }

  cancel() {
    this.addRoster = false;
    this.isEditing = false;
    this.rosterFormSubmitted = false;
    this.saveLoading = false;
    this.rosterForm = {
      rosterid : '',
      name : '',
      description : '',
      monday : '',
      tuesday : '',
      wednesday : '',
      thursday : '',
      friday : '',
      saturday : '',
      sunday : '',
      default : false
    }
  }

}
