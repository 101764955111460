<div class="container-fluid">
  <div class="content">

    <app-hcm-breadcrumb *ngIf="allinoneService.isAttendanceView" [s_breadcrumb]="'Position Control'"
      [t_breadcrumb]="pcAddorNot ? isEditing? 'Edit Position Control':  'New Position Control': ''" (emit)="cancel()">
    </app-hcm-breadcrumb>

    <app-team-breadcrumb *ngIf="!allinoneService.isAttendanceView" [s_breadcrumb]="'Position Control'"
      [t_breadcrumb]="pcAddorNot ? isEditing? 'Edit Position Control':  'New Position Control': ''" (emit)="cancel()">
    </app-team-breadcrumb>


    <div class="d-flex flex-wrap">
      <div class="mb-3">
        <button *ngIf="!pcAddorNot" type="button" class="btn btn-custom" (click)="pcAdd()">
          <i class="fa fa-plus me-1"></i>Add
        </button>
      </div>
      <div class="mb-3 ms-2" *ngIf="!pcAddorNot">
        <button type="button" class="btn btn-custom" (click)="downloadSheet()" title="Export Position Control To Excel">
          <img class="excel-icon" src="../../../assets/images/excel.png">
          Export
        </button>
      </div>
      <div *ngIf="pcAddorNot" class="close-save-button">
        <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
          Cancel
        </button>
        <app-button-loader class="me-2" (click)="submitPc()" [button_text]="'Save'" [load_button_text]="'Saving'"
          [isLoading]="saveLoading">
        </app-button-loader>
      </div>
      <div *ngIf="!pcAddorNot && showAysnc" class="mb-3 ms-2">
        <button class="btn btn-custom" (click)="syncData()">
          Sync Data from HCMEE
        </button>
      </div>
      <div *ngIf="!pcAddorNot && showAysncFromNeo" class="mb-3 ms-2">
        <button class="btn btn-custom" (click)="syncData(true)">
          Sync Data from Neo Payroll
        </button>
      </div>
    </div>

    <ng-container *ngIf="pcAddorNot">
      <div class="card card-body my-card mt-3">
        <div class="row">
          <div class="{{isEditing &&  memberForm.newuserid != '' ? 'mb-3 col-md-3' : 'mb-3 col-md-4'}}">

            <label for="userid" class="form-label">User ID {{memberSelected ? '('+selectedMemberName+' - '+
              selectedMemberEID+')' : ''}}
            </label>
            <!-- <label for="userid" class="form-label">User ID {{memberSelected ? newUserID ? '('+selectedMemberName+', ' + newUserID +' - '+
              selectedMemberEID+')' : '('+selectedMemberName+' - '+
              selectedMemberEID+')' : ''}}
            </label> -->
            <input type="text" class="form-control" id="userid" name="userid"
              placeholder="john@domain.com, +959********" [(ngModel)]="memberForm.userid" required #userid="ngModel"
              [ngClass]="{ 'is-invalid': memberformSubmitted && userid.invalid }" [disabled]="isEditing"
              [matAutocomplete]="memberAutoComplete" (ngModelChange)="changeUserid()">
            <mat-autocomplete #memberAutoComplete="matAutocomplete">
              <ng-container *ngFor="let member of memberList">
                <mat-option [value]="member.userid" (click)="selectMember(member)">
                  {{member.userid}}{{ member.newuserid ? ', ' + member.newuserid : '' }}{{member.username ? ' - ' + member.username : ''}}
                </mat-option>
              </ng-container>

            </mat-autocomplete>

            <div class="invalid-feedback"
              *ngIf="userid.invalid || (userid.dirty || userid.touched) && memberformSubmitted">
              <div *ngIf="userid.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div>
          </div>

          <div *ngIf="isEditing && memberForm.newuserid != ''" class="{{isEditing &&  memberForm.newuserid != '' ? 'mb-3 col-md-3' : 'mb-3 col-md-4'}}">
            <label for="newuserid" class="form-label">New User ID
            </label>
            <div class="input-group">
              <input type="text" class="form-control" id="newuserid" name="newuserid"
              [(ngModel)]="memberForm.newuserid"
                disabled>
            </div>
          </div>

          <!-- <div class="mb-3 col-md-6">
            <label for="type" class="form-label cc-label" (click)="openClassCodeForm()">
              Post
            </label>
            <select class="form-select" id="type" name="type" [(ngModel)]="memberForm.post" required #type="ngModel"
              [ngClass]="{ 'is-invalid': memberformSubmitted && type.invalid }" [disabled]="gettingCcList">
              <option value="">-</option>
              <option *ngFor="let cc of ccList; trackBy: trackById" value="{{cc.id}}"
                [selected]="cc.id == memberForm.post">{{cc.name}}
              </option>
            </select>
            <mat-progress-bar mode="indeterminate" *ngIf="gettingCcList"></mat-progress-bar>

            <div class="invalid-feedback" *ngIf="type.invalid || (type.dirty || type.touched) && memberformSubmitted">
              <div *ngIf="type.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div>
          </div> -->

          <div class="{{isEditing &&  memberForm.newuserid != '' ? 'mb-3 col-md-3' : 'mb-3 col-md-4'}}">
            <label for="post" class="form-label">
              Post
            </label>
            <input type="text" class="form-control" id="post" name="post" placeholder="Enter Post"
              [(ngModel)]="memberForm.post" [matAutocomplete]="postAutoComplete" #post="ngModel"
              [ngClass]="{ 'is-invalid': memberformSubmitted && post.invalid }" required>
            <mat-autocomplete #postAutoComplete="matAutocomplete">
              <ng-container
                *ngFor="let post of postList | filter : memberForm.post:'memberDepartmentAutocomplete'">
                <mat-option [value]="post">
                  {{post}}
                </mat-option>
              </ng-container>

            </mat-autocomplete>
            <div class="invalid-feedback"
              *ngIf="branch.invalid || (branch.dirty || branch.touched) && memberformSubmitted">
              <div *ngIf="branch.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div>
          </div>

          <div class="mb-3 col-md-4">
            <label for="branch" class="form-label">
              Branch
            </label>
            <input type="text" class="form-control" id="branch" name="branch" placeholder="Enter Branch"
              [(ngModel)]="memberForm.branch" [matAutocomplete]="branchAutoComplete" #branch="ngModel"
              [ngClass]="{ 'is-invalid': memberformSubmitted && branch.invalid }" required>
            <mat-autocomplete #branchAutoComplete="matAutocomplete">
              <ng-container
                *ngFor="let branch of branchList | filter : memberForm.branch:'memberDepartmentAutocomplete'">
                <mat-option [value]="branch">
                  {{branch}}
                </mat-option>
              </ng-container>

            </mat-autocomplete>
            <div class="invalid-feedback"
              *ngIf="branch.invalid || (branch.dirty || branch.touched) && memberformSubmitted">
              <div *ngIf="branch.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div>
          </div>

          <div class="mb-3 col-md-4">
            <label for="department" class="form-label">
              Department
            </label>
            <input type="text" class="form-control" id="department" name="department" placeholder="Enter Department"
              [(ngModel)]="memberForm.department" [matAutocomplete]="departmentAutocomplele" #department="ngModel"
              required [ngClass]="{ 'is-invalid': memberformSubmitted && department.invalid }">
            <mat-autocomplete #departmentAutocomplele="matAutocomplete">
              <ng-container
                *ngFor="let dep of departmentList | filter : memberForm.department:'memberDepartmentAutocomplete'">
                <mat-option [value]="dep" *ngIf="dep != 'All'">
                  {{dep}}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
            <div class="invalid-feedback"
              *ngIf="department.invalid || (department.dirty || department.touched) && memberformSubmitted">
              <div *ngIf="department.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div>
          </div>
          <div class="mb-3 col-md-4">
            <label for="division" class="form-label">
              Division
            </label>
            <input type="text" class="form-control" id="division" name="division" placeholder="Enter Division"
              [(ngModel)]="memberForm.division" [matAutocomplete]="divisionAutoComplete">
            <mat-autocomplete #divisionAutoComplete="matAutocomplete">
              <ng-container
                *ngFor="let division of divisionList | filter : memberForm.division:'memberDepartmentAutocomplete'">
                <mat-option [value]="division">
                  {{division}}
                </mat-option>
              </ng-container>

            </mat-autocomplete>
          </div>
          <div class="mb-3 col-md-4">
            <label for="section" class="form-label">
              Section
            </label>
            <input type="text" class="form-control" id="section" name="section" placeholder="Enter Section"
              [(ngModel)]="memberForm.section" [matAutocomplete]="sectionAutoComplete">
            <mat-autocomplete #sectionAutoComplete="matAutocomplete">
              <ng-container
                *ngFor="let section of sectionList | filter : memberForm.section:'memberDepartmentAutocomplete'">
                <mat-option [value]="section">
                  {{section}}
                </mat-option>
              </ng-container>

            </mat-autocomplete>
          </div>
          <div class="mb-3 col-md-4">
            <label for="costcenter" class="form-label">
              Cost Center
            </label>
            <input type="text" class="form-control" id="costcenter" name="costcenter" placeholder="Enter Cost Center"
              [(ngModel)]="memberForm.costcenter" [matAutocomplete]="costcenterAutoComplete">
            <mat-autocomplete #costcenterAutoComplete="matAutocomplete">
              <ng-container
                *ngFor="let costcenter of costcenterList | filter : memberForm.costcenter:'memberDepartmentAutocomplete'">
                <mat-option [value]="costcenter">
                  {{costcenter}}
                </mat-option>
              </ng-container>

            </mat-autocomplete>
          </div>
          <div class="mb-3 col-md-4">
            <label for="subdivision" class="form-label">
              Sub Division
            </label>
            <input type="text" class="form-control" id="subdivision" name="subdivision" placeholder="Enter Sub Division"
              [(ngModel)]="memberForm.subdivision" [matAutocomplete]="subdivisionAutoComplete">
            <mat-autocomplete #subdivisionAutoComplete="matAutocomplete">
              <ng-container
                *ngFor="let subdivision of subdivisionList | filter : memberForm.subdivision:'memberDepartmentAutocomplete'">
                <mat-option [value]="subdivision">
                  {{subdivision}}
                </mat-option>
              </ng-container>

            </mat-autocomplete>
          </div>
          <div class="mb-3 col-md-4">
            <label for="type" class="form-label cc-label" (click)="openPayLevelForm()">
              Pay Level
            </label>
            <select class="form-select" id="paylevel" name="paylevel" [(ngModel)]="memberForm.paylevel"
              [disabled]="gettingPaylevel">
              <option value="">-</option>
              <option *ngFor="let paylevel of paylevelList" [value]="paylevel.paylevelid"
                [selected]="paylevel.paylevelid == memberForm.paylevel">{{paylevel.name}}
              </option>
            </select>
            <mat-progress-bar mode="indeterminate" *ngIf="gettingPaylevel"></mat-progress-bar>
          </div>
          <div class="mb-3 col-md-4">
            <label for="effectivedate" class="form-label">
              Effective Date
            </label>
            <!-- <input class="form-control" type="date" id='effectivedate' name='effectivedate'
              [(ngModel)]="memberForm.effectivedate" required #effectivedate="ngModel"
              [ngClass]="{ 'is-invalid': memberformSubmitted && effectivedate.invalid }"
              [min]="memberForm.mineffectivedate" max="9999-12-31"> -->
              <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
            [bsConfig]="generalDatePickerConfig" readonly [minDate]="memberForm.mineffectivedate"
            [(ngModel)]="memberForm.effectivedate" style="min-width: 200px;" required #effectivedate="ngModel" [ngClass]="{ 'is-invalid': memberformSubmitted && effectivedate.invalid }">
            <div class="invalid-feedback"
              *ngIf="effectivedate.invalid || (effectivedate.dirty || effectivedate.touched) && memberformSubmitted">
              <div *ngIf="effectivedate.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div>
          </div>

          <div class="mb-3 col-md-4">
            <label for="employmenttype" class="form-label cc-label" (click)="openMemberTypeForm('001')">
              Employment Type
            </label>
            <select class="form-select" aria-label="Select Employment Type" id="employmenttype" name="employmenttype"
              [(ngModel)]="memberForm.employmenttype" [disabled]="gettingMemberTypes" #employmenttype="ngModel" required
              [ngClass]="{ 'is-invalid': memberformSubmitted && employmenttype.invalid }">
              <option value=''>-</option>
              <option *ngFor="let etype of employmentTypes" value="{{etype.id}}"
                [selected]="etype.id == memberForm.employmenttype">
                {{etype.name}}</option>
            </select>
            <div class="invalid-feedback"
              *ngIf="employmenttype.invalid || (employmenttype.dirty || employmenttype.touched) && memberformSubmitted">
              <div *ngIf="employmenttype.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div>
            <mat-progress-bar mode="indeterminate" *ngIf="gettingMemberTypes"></mat-progress-bar>
          </div>

          <div class="mb-3 col-md-4">
            <label for="transactiontype" class="form-label cc-label" (click)="openMemberTypeForm('002')">
              Transaction Type
            </label>
            <select class="form-select" aria-label="Select Transaction Type" id="transactiontype" name="transactiontype"
              [(ngModel)]="memberForm.transactiontype" [disabled]="gettingMemberTypes" #transactiontype="ngModel"
              required [ngClass]="{ 'is-invalid': memberformSubmitted && transactiontype.invalid }">
              <option value=''>-</option>
              <option *ngFor="let ttype of transactionTypes" value="{{ttype.id}}"
                [selected]="ttype.id == memberForm.transactiontype">
                {{ttype.name}}</option>
            </select>
            <div class="invalid-feedback"
              *ngIf="transactiontype.invalid || (transactiontype.dirty || transactiontype.touched) && memberformSubmitted">
              <div *ngIf="transactiontype.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div>
            <mat-progress-bar mode="indeterminate" *ngIf="gettingMemberTypes"></mat-progress-bar>
          </div>

          <div class="mb-3 col-md-12">
            <label for="remark" class="form-label">
              Remark
            </label>
            <textarea type="text" class="form-control" id="remark" name="remark" placeholder="Enter Remark"
              [(ngModel)]="memberForm.remark" rows="3"></textarea>
          </div>



        </div>
      </div>
      <div class="card card-body my-card mt-3"
        *ngIf="(isEditing && memberForm.status == '1') || (!isEditing && memberSelected)">
        <div class="info ms-0 mb-3">History</div>
        <div class="table-responsive">
          <table class="table table-responsive table-borderless">
            <thead class="table-header">
              <tr>
                <td scope="col" class="col-lg-2 col-md-2">Employment</td>
                <td scope="col" class="col-lg-2 col-md-2">Transaction</td>
                <td scope="col" class="col-lg-2 col-md-2">Effective Date</td>
                <td scope="col" class="col-lg-2 col-md-2">Post</td>
                <td scope="col" class="col-lg-2 col-md-2">Branch</td>
                <td scope="col" class="col-lg-2 col-md-2">Department</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="historyList.length == 0">
                <td colspan="10" class="nodata">Empty</td>
              </tr>
              <tr *ngFor="let item of historyList; let index = index">
                <td class="td-data">
                  <span [title]="item.employmenttypename">{{item.employmenttypename}}</span>
                </td>
                <td class="td-data">
                  <span [title]="item.transactiontypename">{{item.transactiontypename}}</span>
                </td>
                <td class="td-data">
                  <span
                    [title]="allinoneService.formatDBToShow(item.effectivedate)">{{allinoneService.formatDBToShow(item.effectivedate)}}</span>
                </td>
                <td class="td-data">
                  <span [title]="item.post">{{item.post}}</span>
                </td>
                <td class="td-data">
                  <span [title]="item.branch">{{item.branch}}</span>
                </td>
                <td class="td-data">
                  <span [title]="item.department">{{item.department}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </ng-container>

    <ng-container *ngIf="!pcAddorNot">
      <div class="mb-2 d-flex flex-wrap justify-content-start align-items-center">

        <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search with User ID/Name/ID" aria-label="Search"
            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
          <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <div class="dropdown" [ngClass]="{'ms-2' : !isMobileView, 'ms-0': isMobileView}">
          <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <div c style="text-align: center;">Page size</div>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li class="dropdown-item" *ngFor="let page of pg">
              <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
                id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
              <label for="pg{{page.name}}" class="col-10 p-1">
                {{page.name}}
              </label>
            </li>
          </ul>
        </div>
        <div class="ms-2">
          <button class="btn btn-custom" (click)="getPcList()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        <div class="line"></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{(pcList | filter: searchText: 'positioncontrol').length}}</span>
        </div>
      </div>
      <div class="input-group mb-2" *ngIf="isMobileView">
        <input class="form-control" type="text" placeholder="&#xf002; Search with User ID/Name/ID" aria-label="Search"
          (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
          [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
        <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
          [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
          <mat-icon class="c-icon">close</mat-icon>
        </button>
      </div>
      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless" *ngIf="!pcAddorNot">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="userid">User ID</td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="username">Name</td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="employeeid">ID</td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="employmenttypename">Employment</td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="transactiontypename">Transaction</td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="effectivedate">Effective Date</td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="postname">Post</td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="branch">Branch</td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="department">Department</td>
              <td scope="col" class="d-flex justify-content-center">Actions</td>
            </tr>
          </thead>
          <tbody *ngIf="pcListLoading">
            <tr>
              <td colspan="10" class="nodata">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!pcListLoading">
            <tr *ngIf="pcList.length == 0">
              <td colspan="10" class="nodata">Empty</td>
            </tr>
            <tr
              *ngFor="let item of (pcList | filter: searchText: 'positioncontrol') | paginate: { itemsPerPage: itemPerPage, currentPage: p }; let index = index"
              [style.color]="item.status == '1' ? 'black' : 'gray'">
              <td class="td-data td-edit" (click)="editPc(item)" style="max-width: fit-content;">
                <div class="d-flex justify-content-center align-items-start flex-column">
                  <span class="data-overflow" [title]="item.userid">{{item.userid}} </span>
                  <span class="data-overflow" *ngIf="item.newuserid">{{item.newuserid}} </span>
                </div>
                
              </td>
              <td class="td-data">
                <span [title]="item.username">{{item.username}} </span>
              </td>
              <td class="td-data">
                <span [title]="item.employeeid">{{item.employeeid}} </span>
              </td>
              <td class="td-data">
                <span [title]="item.employmenttypename">{{item.employmenttypename}}</span>
              </td>
              <td class="td-data">
                <span [title]="item.transactiontypename">{{item.transactiontypename}}</span>
              </td>
              <td class="td-data">
                <span
                  [title]="allinoneService.formatDBToShow(item.effectivedate)">{{allinoneService.formatDBToShow(item.effectivedate)}}</span>
              </td>
              <td class="td-data">
                <span [title]="item.post">{{item.post}}</span>
              </td>
              <td class="td-data">
                <span [title]="item.branch">{{item.branch}}</span>
              </td>
              <td class="td-data">
                <span [title]="item.department">{{item.department}}</span>
              </td>
              <td class="d-flex justify-content-center align-items-center ">
                <button class="btn btn-outline-primary me-2" (click)="editPc(item)">
                  <i class='fa fa-edit'></i>
                </button>
                <button class="btn btn-outline-danger" (click)="deletePc(item.id, index)"
                  [disabled]="item.status == '1'">
                  <i class='fa fa-trash-o'></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls class="paginate" (pageChange)="p = $event"
        *ngIf="(pcList | filter: searchText: 'positioncontrol').length > itemPerPage" previousLabel="Prev"
        nextLabel="Next">
      </pagination-controls>
    </ng-container>
  </div>
</div>