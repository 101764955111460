<!-- <button type="button" (click)="test()" >test</button>
<button type="button" (click)="test2()" >test2</button> -->
<div class="container-fluid">
  <div class="content">
    <!-- <ng-container *ngIf="!isAdmin">
      <app-breadcrumb [s_breadcrumb]="'Attendance Report'" (click)="cleanUp()">
      </app-breadcrumb>
    </ng-container>
    <ng-container *ngIf="isAdmin">
      <app-hcm-breadcrumb [s_breadcrumb]="'Attendance Report'" (click)="cleanUp()">
      </app-hcm-breadcrumb>
    </ng-container> -->

    <ng-container *ngIf="allinoneService.isAttendanceView">
      <app-hcm-breadcrumb [s_breadcrumb]="'Attendance'" (click)="cleanUp()">
      </app-hcm-breadcrumb>
    </ng-container>

    <ng-container *ngIf="!allinoneService.isAttendanceView">
      <app-team-breadcrumb [s_breadcrumb]="'Attendance'" (click)="cleanUp()">
      </app-team-breadcrumb>
    </ng-container>


    <div class="mt-3 d-flex flex-wrap justify-content-between align-items-center">
      <div class="d-flex flex-wrap mb-3">
        <div class="form-check  me-5">
          <label class="radio-inline">
            <input class="form-check-input" type="radio" name="displaytype" id="summary" [(ngModel)]="displayMode"
              value="Summary" (ngModelChange)="displayModeChanged()">Summary
          </label>
        </div>
        <div class="form-check me-5">
          <label class="radio-inline">
            <input class="form-check-input" type="radio" name="displaytype" id="daterange" [(ngModel)]="displayMode"
              value="Daterange" (ngModelChange)="displayModeChanged()">Date
            Range
          </label>
        </div>
        <div class="form-check me-5">
          <label class="radio-inline">
            <input class="form-check-input" type="radio" name="displaytype" id="absentreport" [(ngModel)]="displayMode"
              value="Absentreport" (ngModelChange)="displayModeChanged()">Absent Report
          </label>
        </div>
        <div class="form-check me-5">
          <label class="radio-inline">
            <input class="form-check-input" type="radio" name="displaytype" id="location" [(ngModel)]="displayMode"
              value="Location" (ngModelChange)="displayModeChanged()">Location
          </label>
        </div>
        <!-- Project and Job -->
        <div class="form-check me-5">
          <label class="radio-inline">
            <input class="form-check-input" type="radio" name="displaytype" id="project" [(ngModel)]="displayMode"
              value="Project" (ngModelChange)="displayModeChanged()">Project
          </label>
        </div>
        <div class="form-check me-5">
          <label class="radio-inline">
            <input class="form-check-input" type="radio" name="displaytype" id="checkin" [(ngModel)]="displayMode"
              value="CheckIn" (ngModelChange)="displayModeChanged()">Member
          </label>
        </div>
      </div>
      <div class="mb-3" *ngIf="displayMode == 'Summary'">
        <div class="d-flex flex-wrap-align-items-center">
          <button type="button" class="btn btn-custom" (click)="importSheet()" title="Import from Excel"
            *ngIf="importStatus == '001' || importStatus == '005'">
            <img class="excel-icon" src="../../../assets/images/excel.png">
            Import
          </button>
          <div class="d-flex align-items-center" *ngIf="importStatus == '002' || importStatus == '003'">
            <div class="btn-group" role="group">
              <button type="button" class="btn btn-outline-black" disabled>
                <i class="fas fa-circle-notch fa-spin me-2" *ngIf="importStatus == '002'"></i>
                <img src="../../../assets/images/upload.gif" width="20px" class="me-2" *ngIf="importStatus == '003'">

                {{importStatus == '002' ? 'Checking' : 'Uploading'}}
              </button>
              <button type="button" class="btn btn-outline-black" (click)="getImportStatus()">
                <i class="fas fa-sync-alt" [ngClass]="{'fa-spin' : refreshingImportStatus}"></i>
              </button>
            </div>
          </div>
          <button type="button" class="btn btn-outline-danger" (click)="importSheet(true)" *ngIf="importStatus == '004'"
            style="width: auto;">
            <i class="fas fa-exclamation-circle me-2"></i>
            Import Error
          </button>
          <!-- <div class="mb-3"> -->
          <div class="line"></div>
          <!-- </div> -->
          <button class="btn btn-custom" (click)="callSummary()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </div>
      <div *ngIf="displayMode != 'Summary'">
        <div class="d-flex flex-wrap align-items-center">
          <div class="mb-3">
            <button *ngIf="displayMode !='Location'" type="button" class="btn btn-custom" (click)="downloadSheet()"
              title="Export To Excel">
              <img class="excel-icon" src="../../../assets/images/excel.png">
              Export
            </button>
            <button *ngIf="displayMode =='Location'" type="button" class="btn btn-custom" (click)="downloadLocSheet()"
              title="Export To Excel">
              <img class="excel-icon" src="../../../assets/images/excel.png">
              Export
            </button>
            <!-- <a *ngIf="displayMode =='Location'" [href]="locfileurl" download="locationreport.xlsx"><button type="button"
                class="btn btn-custom" title="Export To Excel">
                <img class="excel-icon" src="../../../assets/images/excel.png">
                Export
              </button></a> -->
          </div>
          <div class="mb-3">
            <div class="line"></div>
          </div>
          <div *ngIf="displayMode == 'Project'" class="mb-3">
            <div class="btn btn-custom me-3" (click)="filterByDate()">
              Filter by Date
            </div>
          </div>
          <div *ngIf="displayMode == 'Summary'" class="mb-3">
            <app-button-loader [button_text]="'Search'" [load_button_text]="'Searching'"
              [isLoading]="searchLoadingSummary" (click)="search()">
            </app-button-loader>
          </div>
          <div *ngIf="displayMode == 'Daterange'" class="mb-3">
            <app-button-loader [button_text]="'Search'" [load_button_text]="'Searching'" [isLoading]="searchLoadingDR"
              (click)="search()">
            </app-button-loader>
          </div>
          <div *ngIf="displayMode == 'Absentreport'" class="mb-3">
            <app-button-loader [button_text]="'Search'" [load_button_text]="'Searching'" [isLoading]="searchLoadingAR"
              (click)="search()">
            </app-button-loader>
          </div>
          <div *ngIf="displayMode == 'Location'" class="mb-3">
            <app-button-loader [button_text]="'Search'" [load_button_text]="'Searching'" [isLoading]="searchLoadingLOC"
              (click)="search()">
            </app-button-loader>
          </div>
          <div *ngIf="displayMode == 'Project'" class="mb-3">
            <app-button-loader [button_text]="'Search'" [load_button_text]="'Searching'"
              [isLoading]="searchLoadingProject" (click)="search()">
            </app-button-loader>
          </div>
          <div *ngIf="displayMode == 'CheckIn'" class="mb-3">
            <app-button-loader [button_text]="'Search'" [load_button_text]="'Searching'"
              [isLoading]="searchLoadingMember" (click)="search()">
            </app-button-loader>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-wrap">


      <div *ngIf="displayMode == 'Project'">
        <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="projectmenu">
          <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
            aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
            Project&nbsp;:&nbsp;{{ getcheckinForm.projectname.length > 20 ? (getcheckinForm.projectname |
            slice:0:20)+'...' : getcheckinForm.projectname}}
            <mat-menu #projectmenu="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let proj of projects;let i = index" (click)="projectChange(proj, i)">
                {{ proj.name }}
              </button>
            </mat-menu>
            <span *ngIf="gettingProjects" class="spinner-border spinner-border-sm button-spinner mx-1" role="status"
              aria-hidden="true"></span>
          </button>
        </div>
      </div>

      <div *ngIf="displayMode == 'Project'">
        <div class="ms-2 filtter-btn align-items-center mb-3" [matMenuTriggerFor]="jobsmenu">
          <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
            aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
            Jobs&nbsp;:&nbsp;{{ getcheckinForm.jobname.length > 20 ? (getcheckinForm.jobname |
            slice:0:20)+'...' : getcheckinForm.jobname}}
            <mat-menu #jobsmenu="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let job of jobs; let i = index" (click)="jobChange(job, i)">
                {{ job.name }}
              </button>
            </mat-menu>
            <span *ngIf="gettingJobs" class="spinner-border spinner-border-sm button-spinner mx-1" role="status"
              aria-hidden="true"></span>
          </button>
        </div>
      </div>


      <!-- <div class="col-md-4 mb-3" *ngIf="displayMode == 'CheckIn'">
        <label for="userid" (click)="chooseIDtype()" class="form-label id-text d-flex align-items-center">
          {{IDText}} <mat-icon style="margin-left: 5px; font-size:15px; height:15px; width:15px;">arrow_drop_down
          </mat-icon>
        </label>
        <input type="text" class="form-control" id="userid" name="userid" placeholder="{{IDPlaceholder}}"
          [(ngModel)]="getcheckinForm.userid" required #userid="ngModel"
          [ngClass]="{ 'is-invalid': atFormSubmitted && userid.invalid }">
        <div class="invalid-feedback" *ngIf="userid.invalid || (userid.dirty || userid.touched)">
          <div *ngIf="userid.errors?.required">
            {{allinoneService.input_err_msg}}
          </div>
        </div>
      </div> -->

      <div *ngIf="displayMode == 'CheckIn'" class="d-flex">

        <div class="filtter-btn me-2 align-items-center mb-3" [matMenuTriggerFor]="memberTypeMenu">
          <button
            class="d-flex align-items-center justify-content-center btn btn-secondary dropdown-toggle advanced-filter-btn"
            type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle
            style="color: white; min-width: 100px">
            Search By&nbsp;:&nbsp;{{ getcheckinForm.membertype}}

            <mat-menu #memberTypeMenu="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let type of ['User Name', 'User ID', 'Employee ID']"
                (click)="chooseIDtype(type)">
                {{ type }}
              </button>
            </mat-menu>
          </button>

        </div>
        <div class="input-group me-2 mb-3" style="width: 250px !important">
          <input class="form-control" type="text" placeholder="{{IDPlaceholder}}" aria-label="Search" required
            #userid="ngModel" (focus)="memberfocus = true" (blur)="memberfocus = false"
            [(ngModel)]="getcheckinForm.userid">

          <!-- <button class="btn btn-outline-secondary c-clear" type="button" (click)="chooseIDtype()"
          [ngClass]="{ 'c-clear3': memberfocus, 'c-clear': !memberfocus }"
            >
            <mat-icon class="c-icon" style="color: #2e86c1;">sync_alt</mat-icon>
          </button> -->
          <!-- <div class="invalid-feedback" >
            <div>
              {{allinoneService.input_err_msg}}
            </div>
          </div> -->
        </div>
      </div>

      <!-- <div class="mb-3 {{ displayMode=='Summary' ? 'col-md-3' : displayMode=='CheckIn' ? 'col-md-4' : 'col-md-6' }}"
        *ngIf="displayMode !='Project'"> -->
      <div class="mb-3 me-2" *ngIf="displayMode !='Project'">
          <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
              [bsConfig]="generalDatePickerConfig" [bsConfig]="{containerClass: 'custom-placement'}" readonly
              [(ngModel)]="getcheckinForm.startdate" style="min-width: 200px;" required #startdate="ngModel"
              (ngModelChange)="displayMode=='Summary' ? callSummary() : startdateChange() ">

        <div class="invalid-feedback" *ngIf="startdate.invalid || (startdate.dirty || startdate.touched)">
          <div *ngIf="startdate.errors?.required">
            {{allinoneService.input_err_msg}}
          </div>
        </div>
      </div>

      
      <div class="mb-3 me-2"
        *ngIf="displayMode=='Daterange' || displayMode == 'CheckIn' || displayMode=='Absentreport' || displayMode=='Location' ">

          <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
              [bsConfig]="generalDatePickerConfig" readonly
              [(ngModel)]="getcheckinForm.enddate" [minDate]="getcheckinForm.startdate" [maxDate]="minDate" style="min-width: 200px;" required #enddate="ngModel"
              >

        <div class="invalid-feedback" *ngIf="enddate.invalid || (enddate.dirty || enddate.touched)">
          <div *ngIf="enddate.errors?.required">
            {{allinoneService.input_err_msg}}
          </div>
        </div>
      </div>

      <ng-container *ngIf="displayMode == 'Summary' ">

        <div class="d-flex justify-content-center align-items-center ">
          <div class="filtter-btn me-2 align-items-center mb-3" [matMenuTriggerFor]="departmentmenu">
            <button
              class="d-flex align-items-center justify-content-center btn btn-secondary dropdown-toggle advanced-filter-btn"
              type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle
              style="color: white; min-width: 100px">
              Department&nbsp;:&nbsp;{{ getcheckinForm.department.length > 20 ? (getcheckinForm.department |
              slice:0:20)+'...' : getcheckinForm.department}}

              <mat-menu #departmentmenu="matMenu" xPosition="before">
                <button mat-menu-item *ngFor="let department of departmentList"
                  (click)="summaryDepartmentChange(department)">
                  {{ department }}
                </button>
              </mat-menu>
              <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1"
                role="status" aria-hidden="true"></span>
            </button>

          </div>
          <div class="me-2 filtter-btn align-items-center mb-3" [matMenuTriggerFor]="divisionmenu">
            <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
              Division&nbsp;:&nbsp;{{ getcheckinForm.division.length > 22 ? (getcheckinForm.division |
              slice:0:22)+'...' : getcheckinForm.division}}
              <mat-menu #divisionmenu="matMenu" xPosition="before">
                <button mat-menu-item *ngFor="let division of divisionList" (click)="summaryDivisionChange(division)">
                  {{ division }}
                </button>
              </mat-menu>
              <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1"
                role="status" aria-hidden="true"></span>
            </button>
          </div>
          <div class="me-2 filtter-btn align-items-center mb-3" [matMenuTriggerFor]="teamidmenu">
            <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
              Team ID&nbsp;:&nbsp;{{ getcheckinForm.teamid.length > 22 ? (getcheckinForm.teamid |
              slice:0:22)+'...' : getcheckinForm.teamid}}
              <mat-menu #teamidmenu="matMenu" xPosition="before">
                <button mat-menu-item *ngFor="let teamid of teamidList" (click)="summaryTeamidChange(teamid)">
                  {{ teamid }}
                </button>
              </mat-menu>
              <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1"
                role="status" aria-hidden="true"></span>
            </button>
          </div>
          <div class="me-2 filtter-btn align-items-center mb-3" [matMenuTriggerFor]="costcenterMenu">
            <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
              Cost Center&nbsp;:&nbsp;{{ getcheckinForm.costcenter.length > 22 ? (getcheckinForm.costcenter |
              slice:0:22)+'...' : getcheckinForm.costcenter}}
              <mat-menu #costcenterMenu="matMenu" xPosition="before">
                <button mat-menu-item *ngFor="let costcenter of costCenterList"
                  (click)="summaryCostCenterChange(costcenter)">
                  {{ costcenter }}
                </button>
              </mat-menu>
              <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1"
                role="status" aria-hidden="true"></span>
            </button>
          </div>
          <div class="me-2 filtter-btn align-items-center mb-3" [matMenuTriggerFor]="subdivisionMenu">
            <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
              Sub Division&nbsp;:&nbsp;{{ getcheckinForm.subdivision.length > 22 ? (getcheckinForm.subdivision |
              slice:0:22)+'...' : getcheckinForm.subdivision}}
              <mat-menu #subdivisionMenu="matMenu" xPosition="before">
                <button mat-menu-item *ngFor="let subdivision of subDivisionList"
                  (click)="summarySubDivisionChange(subdivision)">
                  {{ subdivision }}
                </button>
              </mat-menu>
              <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1"
                role="status" aria-hidden="true"></span>
            </button>
          </div>

          <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="statusMenu">
            <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
              Type&nbsp;:&nbsp;{{ getcheckinForm.status == '001' ? 'Active' : 'Inactive' }}
              <mat-menu #statusMenu="matMenu" xPosition="before">
                <button mat-menu-item *ngFor="let status of userStatusFilterList" (click)="summaryUserTypeChange(status.id)">
                  {{ status.name }}
                </button>
              </mat-menu>
            </button>
          </div>

        </div>

      </ng-container>

      <ng-container *ngIf="displayMode == 'Daterange' || displayMode=='Absentreport' || displayMode == 'Location'">


        <div class="d-flex justify-content-center align-items-center ">
          <div class="me-2 filtter-btn align-items-center mb-3" [matMenuTriggerFor]="departmentmenu">
            <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
              Department&nbsp;:&nbsp;{{ displayMode == 'Daterange' || displayMode=='Absentreport' || displayMode ==
              'Location'
              ? getcheckinForm.drdepartment.length > 20 ? (getcheckinForm.drdepartment |
              slice:0:20)+'...' : getcheckinForm.drdepartment
              : getcheckinForm.department.length > 20 ? (getcheckinForm.department |
              slice:0:20)+'...' : getcheckinForm.department}}
              <mat-menu #departmentmenu="matMenu" xPosition="before">
                <button mat-menu-item *ngFor="let department of departmentList"
                  (click)="displayMode == 'Daterange' || displayMode=='Absentreport' || displayMode == 'Location' ? daterangeDepartmentChange(department) : departmentChange(department) ">
                  {{ department }}
                </button>
              </mat-menu>
              <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1"
                role="status" aria-hidden="true"></span>
            </button>
          </div>
          <div class="me-2 filtter-btn align-items-center mb-3" [matMenuTriggerFor]="divisionmenu">
            <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
              Division&nbsp;:&nbsp;{{ displayMode == 'Daterange' || displayMode=='Absentreport' || displayMode ==
              'Location'
              ? getcheckinForm.drdivision.length > 22 ? (getcheckinForm.drdivision |
              slice:0:22)+'...' : getcheckinForm.drdivision
              : getcheckinForm.division.length > 22 ? (getcheckinForm.division |
              slice:0:22)+'...' : getcheckinForm.division}}
              <mat-menu #divisionmenu="matMenu" xPosition="before">
                <button mat-menu-item *ngFor="let division of divisionList"
                  (click)="displayMode == 'Daterange' || displayMode=='Absentreport' || displayMode == 'Location' ? daterangeDivisionChange(division) : divisionChange(division) ">
                  {{ division }}
                </button>
              </mat-menu>
              <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1"
                role="status" aria-hidden="true"></span>
            </button>
          </div>
          <div
            class="{{displayMode == 'Location' ? 'filtter-btn align-items-center mb-3 me-2' : 'filtter-btn align-items-center mb-3 me-2'}}"
            [matMenuTriggerFor]="teamidmenu">
            <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
              Team ID&nbsp;:&nbsp;{{ displayMode == 'Daterange' || displayMode=='Absentreport' || displayMode ==
              'Location'
              ? getcheckinForm.drteamid.length > 22 ? (getcheckinForm.drteamid |
              slice:0:22)+'...' : getcheckinForm.drteamid
              : getcheckinForm.teamid.length > 22 ? (getcheckinForm.teamid |
              slice:0:22)+'...' : getcheckinForm.teamid}}
              <mat-menu #teamidmenu="matMenu" xPosition="before">
                <button mat-menu-item *ngFor="let teamid of teamidList"
                  (click)="displayMode == 'Daterange' || displayMode=='Absentreport' || displayMode == 'Location' ? daterangeTeamidChange(teamid) :teamidChange(teamid)">
                  {{ teamid }}
                </button>
              </mat-menu>
              <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1"
                role="status" aria-hidden="true"></span>
            </button>
          </div>

          <div
            class="{{displayMode == 'Location' ? 'filtter-btn align-items-center mb-3 me-2' : 'filtter-btn align-items-center mb-3 me-2'}}"
            [matMenuTriggerFor]="costcentermenu">
            <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
              Cost Center&nbsp;:&nbsp;{{ displayMode == 'Daterange' || displayMode=='Absentreport' || displayMode ==
              'Location'
              ? getcheckinForm.drcostcenter.length > 22 ? (getcheckinForm.drcostcenter |
              slice:0:22)+'...' : getcheckinForm.drcostcenter
              : getcheckinForm.costcenter.length > 22 ? (getcheckinForm.costcenter |
              slice:0:22)+'...' : getcheckinForm.costcenter}}
              <mat-menu #costcentermenu="matMenu" xPosition="before">
                <button mat-menu-item *ngFor="let costcenter of costCenterList"
                  (click)="displayMode == 'Daterange' || displayMode=='Absentreport' || displayMode == 'Location' ? datecostCenterChange(costcenter) :costCenterChange(costcenter)">
                  {{ costcenter }}
                </button>
              </mat-menu>
              <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1"
                role="status" aria-hidden="true"></span>
            </button>
          </div>

          <div
            class="{{displayMode == 'Location' ? 'filtter-btn align-items-center mb-3 me-2' : 'filtter-btn align-items-center mb-3 me-2'}}"
            [matMenuTriggerFor]="subdivisionmenu">
            <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
              Sub Division&nbsp;:&nbsp;{{ displayMode == 'Daterange' || displayMode=='Absentreport' || displayMode ==
              'Location'
              ? getcheckinForm.drsubdivision.length > 22 ? (getcheckinForm.drsubdivision |
              slice:0:22)+'...' : getcheckinForm.drsubdivision
              : getcheckinForm.subdivision.length > 22 ? (getcheckinForm.subdivision |
              slice:0:22)+'...' : getcheckinForm.subdivision}}
              <mat-menu #subdivisionmenu="matMenu" xPosition="before">
                <button mat-menu-item *ngFor="let subdivision of subDivisionList"
                  (click)="displayMode == 'Daterange' || displayMode=='Absentreport' || displayMode == 'Location' ? datesubDivisionChange(subdivision) :subDivisionChange(subdivision)">
                  {{ subdivision }}
                </button>
              </mat-menu>
              <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1"
                role="status" aria-hidden="true"></span>
            </button>
          </div>

          <div class="{{displayMode == 'Location' ? 'filtter-btn align-items-center mb-3 me-2' : 'filtter-btn align-items-center mb-3 me-2'}}" [matMenuTriggerFor]="statusMenu">
            <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
              Type&nbsp;:&nbsp;{{ getcheckinForm.drstatus == '001' ? 'Active' : 'Inactive' }}
              <mat-menu #statusMenu="matMenu" xPosition="before">
                <button mat-menu-item *ngFor="let status of userStatusFilterList" (click)="getcheckinForm.drstatus = status.id">
                  {{ status.name }}
                </button>
              </mat-menu>
            </button>
          </div>

        </div>


        <div *ngIf="displayMode == 'Location'">

          <div class="filtter-btn align-items-center me-2 mb-3" [matMenuTriggerFor]="locationmenu">
            <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
              Location&nbsp;:&nbsp;{{ getcheckinForm.locname.length > 22 ? (getcheckinForm.locname|
              slice:0:22)+'...' : getcheckinForm.locname}}
              <mat-menu #locationmenu="matMenu" xPosition="before">
                <button mat-menu-item *ngFor="let loc of locations" (click)="locationChange(loc)">
                  {{ loc.name }}
                </button>
              </mat-menu>
              <span *ngIf="gettingLocList" class="spinner-border spinner-border-sm button-spinner mx-1" role="status"
                aria-hidden="true"></span>
            </button>
          </div>
        </div>
        <div *ngIf="displayMode == 'Location'">

          <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="typemenu">
            <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
              Check In Type&nbsp;:&nbsp;{{ getcheckinForm.typename.length > 22 ? (getcheckinForm.typename|
              slice:0:22)+'...' : getcheckinForm.typename}}
              <mat-menu #typemenu="matMenu" xPosition="before">
                <button mat-menu-item *ngFor="let type of typeList" (click)="typeChange(type)">
                  {{ type.name }}
                </button>
              </mat-menu>
            </button>
          </div>
        </div>
      </ng-container>



    </div>
    <!-- end -->

    <div class="card card-body my-card" *ngIf="displayMode=='Summary'">
      <div class="d-flex justify-content-center align-items-center" *ngIf="gettingSummary" style="height: 300px;">
        <div class="spinner-border download" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="d-flex flex-wrap align-items-center justify-content-evenly" *ngIf="!gettingSummary">
        <div>
          <div class="d-flex flex-wrap mb-3 subtyp-radio">
            <div class="form-check  me-5">
              <label class="radio-inline">
                <input class="form-check-input" type="radio" name="displaysubtype" id="employee"
                  [(ngModel)]="getcheckinForm.subtype" value="Employee" (ngModelChange)="typeChanged()">Employee
              </label>
            </div>
            <div class="form-check me-5">
              <label class="radio-inline">
                <input class="form-check-input" type="radio" name="displaysubtype" id="counts"
                  [(ngModel)]="getcheckinForm.subtype" value="Counts" (ngModelChange)="typeChanged()">Counts
              </label>
            </div>
          </div>
          <div class="m-3">

            <div style="display: block">
              <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                [plugins]="barChartPlugins" [legend]="barChartLegend"
                [colors]="getcheckinForm.subtype == 'Employee' ? barChartColors : barChartCountColors "
                [chartType]="barChartType" style="width: 600px;
                          height: 400px;">
              </canvas>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <div class="card me-3 mt-2 custom-card" (click)="viewDetails('timein')">
            <div class="card-header custom-card-header">
              Time In
            </div>
            <div class="card-body custom-card-body" *ngIf="getcheckinForm.subtype == 'Employee'">
              {{summaryData.usertimein}}
            </div>
            <div class="card-body custom-card-body" *ngIf="getcheckinForm.subtype == 'Counts'">
              {{summaryData.timein}}
            </div>
          </div>
          <div class="card me-3 mt-2 custom-card" (click)="viewDetails('active')"
            *ngIf="getcheckinForm.subtype=='Employee'">
            <div class="card-header custom-card-header">
              Active
            </div>
            <div class="card-body custom-card-body">
              {{summaryData.useractive}}
            </div>
          </div>
          <div class="card me-3 mt-2 custom-card" (click)="viewDetails('timeout')">
            <div class="card-header custom-card-header">
              Time Out
            </div>
            <div class="card-body custom-card-body" *ngIf="getcheckinForm.subtype == 'Employee'">
              {{summaryData.usertimeout}}
            </div>
            <div class="card-body custom-card-body" *ngIf="getcheckinForm.subtype == 'Counts'">
              {{summaryData.timeout}}
            </div>
          </div>
          <div class="card me-3 mt-2 custom-card" (click)="viewDetails('checkin')">
            <div class="card-header custom-card-header">
              Check In
            </div>
            <div class="card-body custom-card-body" *ngIf="getcheckinForm.subtype == 'Employee'">
              {{summaryData.usercheckin}}
            </div>
            <div class="card-body custom-card-body" *ngIf="getcheckinForm.subtype == 'Counts'">
              {{summaryData.checkin}}
            </div>
          </div>
          <div class="card me-3 mt-2 custom-card" (click)="viewDetails('activity')">
            <div class="card-header custom-card-header">
              Activity
            </div>
            <div class="card-body custom-card-body" *ngIf="getcheckinForm.subtype == 'Employee'">
              {{summaryData.useractivity}}
            </div>
            <div class="card-body custom-card-body" *ngIf="getcheckinForm.subtype == 'Counts'">
              {{summaryData.activity}}
            </div>
          </div>
          <div class="card me-3 mt-2 custom-card" (click)="viewDetails('leave')"
            *ngIf="getcheckinForm.subtype=='Employee'">
            <div class="card-header custom-card-header">
              Leave
            </div>
            <div class="card-body custom-card-body">
              {{summaryData.leave}}
            </div>
          </div>
          <div class="card me-3 mt-2 custom-card" (click)="viewDetails('notimein')"
            *ngIf="getcheckinForm.subtype=='Employee'">
            <div class="card-header custom-card-header">
              Absence
            </div>
            <div class="card-body custom-card-body">
              {{summaryData.notimein}}
            </div>
          </div>
          <div class="card me-3 mt-2 custom-card" (click)="viewDetails('noactivity')"
            *ngIf="getcheckinForm.subtype=='Employee'">
            <div class="card-header custom-card-header">
              No Activity
            </div>
            <div class="card-body custom-card-body">
              {{summaryData.noactivity}}
            </div>
          </div>
          <div class="card me-3 mt-2 custom-card" style="width: auto !important;"
            *ngIf="getcheckinForm.subtype=='Employee'">
            <div class="card-header custom-card-header">
              Total Employees
            </div>
            <div class="card-body custom-card-body">
              {{summaryData.total}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="" *ngIf="displayMode=='Daterange'">
      <div class="mb-2 d-flex flex-wrap justify-content-start align-items-center">
        <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search with User ID/Name/Employee ID"
            aria-label="Search" (focus)="isFocusDR = true" (blur)="isFocusDR = false" [(ngModel)]="searchTextDR"
            [ngClass]="{'c-search-input-with-clear' : searchTextDR }" style="font-family: FontAwesome,Ubuntu;"
            (ngModelChange)="p = 1">
          <button class="btn btn-outline-secondary " type="button" *ngIf="searchTextDR" (click)="clear()"
            [ngClass]="{'c-clear' : isFocusDR, 'c-clear-not-focus': !isFocusDR}">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <div class="dropdown " [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView}">
          <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <div c style="text-align: center;">Page size</div>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li class="dropdown-item" *ngFor="let page of daterangePG">
              <input type="radio" name="pg-count" value="{{page.count}}" (change)="changeDrPageCount($event)"
                id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
              <label for="pg{{page.name}}" class="col-10 p-1">
                {{page.name}}
              </label>
            </li>
          </ul>
        </div>
        <div class="line"></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{(daterangeShowData |
            filter: searchTextDR:15).length}}</span>
        </div>
      </div>

      <div class="input-group mb-2" *ngIf="isMobileView">
        <input class="form-control" type="text" placeholder="&#xf002; Search  with User ID/Name/Employee ID/Days/Hours"
          aria-label="Search" (focus)="isFocusDR = true" (blur)="isFocusDR = false" [(ngModel)]="searchTextDR"
          [ngClass]="{'c-search-input-with-clear' : searchTextDR }" style="font-family: FontAwesome,Ubuntu;"
          (ngModelChange)="p = 1">
        <button class="btn btn-outline-secondary " type="button" *ngIf="searchTextDR" (click)="clear()"
          [ngClass]="{'c-clear' : isFocusDR, 'c-clear-not-focus': !isFocusDR}">
          <mat-icon class="c-icon">close</mat-icon>
        </button>
      </div>
      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless" *ngIf="displayMode=='Daterange'">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="employeeid">Employee ID</td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="userid">User ID</td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="username">Name</td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="days">Total Attendance Days</td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="iohours">Total Time In/Out Hours
              </td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="totalhours">Actual Working Hours
              </td>
              <td scope="col" class="col-lg-1 col-md-1 text-center" mat-sort-header="leave">Leave
              </td>
              <td scope="col" class="col-lg-1 col-md-1 text-center" mat-sort-header="absence">Absence
              </td>
              <!-- <td scope="col" class="col-lg-1 col-md-1 text-center" mat-sort-header="halfdayabsence">Half Day Absence
              </td> -->
              <!-- <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="absence">Total Absence Days
              </td> -->
            </tr>

          </thead>
          <tbody *ngIf="gettingDaterangeData">
            <tr>
              <td colspan="8" class="nodata">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!gettingDaterangeData">
            <tr *ngIf="daterangeShowData.length==0">
              <td colspan="8" class="nodata">Empty</td>
            </tr>
            <tr
              *ngFor="let item of daterangeShowData |filter: searchTextDR:15 | paginate: { itemsPerPage: itemPerPageDR ,currentPage: p }; let index=index">
              <td class="td-data">
                <span [title]="item.employeeid">{{item.employeeid}} </span>
              </td>
              <td class="td-data">
                <span [title]="item.userid">{{item.userid}} </span>
              </td>
              <td class="td-data">
                <span [title]="item.username">{{item.username}} </span>
              </td>

              <td class="td-edit td-data"
                (click)="viewDateRangeDetails( 'timeinout', item.userid, item.username,item.days, item.hours, item.iohours)">
                <span [title]="item.days">{{item.days}} Day(s)</span>
              </td>
              <td class="td-edit td-data"
                (click)="viewDateRangeDetails( 'io', item.userid, item.username,item.days, item.hours, item.iohours)">
                <span [title]="item.iohours">{{item.iohours}}</span>
              </td>
              <!-- <td class="td-edit td-data" (click)="viewDateRangeDetails( 'task', item.userid, item.username,item.days, item.hours, item.iohours)">
                                <span [title]="item.hours">{{item.hours}}</span>
                            </td> -->
              <td class="td-data">
                <span [title]="item.totalhours">{{item.totalhours}}</span>
              </td>

              <td class="td-edit" style="text-align: center;" (click)="absenceLeaveDetails('Leave', item)">
                <span [title]="item.leavecounts">{{item.leavecounts}}</span>
              </td>

              <td class="td-edit" style="text-align: center;" (click)="absenceLeaveDetails('Absence', item)">
                <span [title]="item.absencedays">{{item.absencedays}}</span>
              </td>
              <!-- <td class="td-data">
                <span [title]="item.notimeincounts">{{item.notimeincounts}}</span>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls class="paginate" (pageChange)="p=$event" *ngIf="daterangeShowData.length> itemPerPageDR"
        previousLabel="Prev" nextLabel="Next">
      </pagination-controls>
    </div>

    <!-- location start -->
    <div *ngIf="displayMode == 'Location'">
      <div class="d-flex flex-wrap justify-content-between">
        <div>
          <div class="mb-2 d-flex flex-wrap justify-content-start align-items-center">

            <!-- search box web view -->
            <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
              <input class="form-control" type="text" placeholder="&#xf002; Search with User ID/Name/Employee ID"
                aria-label="Search" (focus)="isFocusLOC = true" (blur)="isFocusLOC = false" [(ngModel)]="searchTextLOC"
                [ngClass]="{'c-search-input-with-clear' : searchTextLOC }" style="font-family: FontAwesome,Ubuntu;"
                (ngModelChange)="currentPageLoc = 1">
              <button class="btn btn-outline-secondary " type="button" *ngIf="searchTextLOC" (click)="clear()"
                [ngClass]="{'c-clear' : isFocusLOC, 'c-clear-not-focus': !isFocusLOC}">
                <mat-icon class="c-icon">close</mat-icon>
              </button>
            </div>

            <div class="dropdown " [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView}">
              <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                aria-expanded="false" title="Page Size">
                <mat-icon>filter_list</mat-icon>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <div c style="text-align: center;">Page size</div>
                </li>
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li class="dropdown-item" *ngFor="let page of locPG">
                  <input type="radio" name="pg-count" value="{{page.count}}" (change)="changeLocPageCount($event)"
                    id="pg{{page.name}}" class="p-1" [checked]="page.count==itemPerPageLoc">
                  <label for="pg{{page.name}}" class="col-10 p-1">
                    {{page.name}}
                  </label>
                </li>
              </ul>
            </div>
            <div class="line"></div>
            <div class="d-flex align-items-center">
              <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{ (locShowData |
                filter: searchTextLOC:15).length}}</span>
            </div>
          </div>
          <div class="input-group mb-2" *ngIf="isMobileView">
            <input class="form-control" type="text" placeholder="&#xf002; Search with User ID/Name/Employee ID"
              aria-label="Search" (focus)="isFocusLOC = true" (blur)="isFocusLOC = false" [(ngModel)]="searchTextLOC"
              [ngClass]="{'c-search-input-with-clear' : searchTextLOC }" style="font-family: FontAwesome,Ubuntu;">
            <button class="btn btn-outline-secondary " type="button" *ngIf="searchTextLOC" (click)="clear()"
              [ngClass]="{'c-clear' : isFocusLOC, 'c-clear-not-focus': !isFocusLOC}">
              <mat-icon class="c-icon">close</mat-icon>
            </button>
          </div>
        </div>
        <pagination-controls class="paginate" (pageChange)="currentPageLoc=$event"
          *ngIf="locShowData.length> itemPerPageLoc" previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
      </div>

      <div class="table-responsive" matSort (matSortChange)="sortLocData($event)">
        <table class="table table-responsive table-borderless" *ngIf="displayMode=='Location'">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-lg-3 col-md-2" mat-sort-header="employeeid">Employee ID</td>
              <td scope="col" class="col-lg-3 col-md-2" mat-sort-header="userid">User ID</td>
              <td scope="col" class="col-lg-3 col-md-1" mat-sort-header="username">Name</td>
              <td scope="col" class="col-lg-3 col-md-1" mat-sort-header="department">Department</td>
              <td scope="col" class="col-lg-3 col-md-1" mat-sort-header="division">Division</td>
              <td scope="col" class="col-lg-3 col-md-1" mat-sort-header="type">Type</td>
              <td scope="col" class="col-lg-3 col-md-1" mat-sort-header="date">Date</td>
              <td scope="col" class="col-lg-3 col-md-1" mat-sort-header="teamid">Time</td>
              <td scope="col" class="col-lg-3 col-md-1" mat-sort-header="location">Location</td>
            </tr>
          </thead>
          <tbody *ngIf="gettingLocData">
            <tr>
              <td colspan="9" class="nodata">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!gettingLocData">
            <tr *ngIf="locShowData.length==0">
              <td colspan="8" class="nodata">Empty</td>
            </tr>
            <tr
              *ngFor="let item of locShowData |filter: searchTextLOC:15 | paginate: { itemsPerPage: itemPerPageLoc ,currentPage: currentPageLoc }; let index=index">
              <td class="td-data">
                <span [title]="item.employeeid">{{item.employeeid}} </span>
              </td>
              <td class="td-data">
                <span [title]="item.userid">{{item.userid}} </span>
              </td>
              <td class="td-data">
                <span [title]="item.username">{{item.username}} </span>
              </td>

              <td class="td-data">
                <span [title]="item.department">{{item.department}} </span>
              </td>
              <td class="td-data">
                <span [title]="item.division">{{item.division}} </span>
              </td>
              <td class="td-data">
                <span [title]="item.showtype">{{ item.showtype }}</span>
              </td>
              <td class="td-data">
                <span [title]="item.showdate">{{item.showdate}}
                </span>
              </td>
              <td class="td-data">
                <span [title]="item.showtime">{{item.showtime}} </span>
              </td>
              <!-- <td class="td-data">
                <span [title]="item.iotype">{{item.iotype =='in' ? 'Time In' : item.iotype =='out' ? 'Time Out' : 'Check
                  In' }} </span>
              </td> -->

              <!-- <td class="td-data">
                <span [title]="allinoneService.formatDBToShow(item.date)">{{allinoneService.formatDBToShow(item.date)}}
                </span>
              </td> -->

              <!-- <td class="td-data">
                <span [title]="item.location">{{item.location}} </span>
              </td> -->
              <!-- <td class="td-data">
                <ng-container *ngIf="item.location != '' || (item.lat != '' && item.long != '')">
                  <a *ngIf="item.lat && item.long"
                    href="https://www.google.com/maps/search/?api=1&query={{item.lat}},{{item.long}}" target="_blank"
                    [ngClass]="{'red' : item.locationtype != '3'}">
                    <span class="d-flex flex-column loc">
                      <div *ngIf="item.location">
                        <span
                          *ngIf="item.locationtype == '1' || item.locationtype == '2' || item.locationtype=='3' || item.locationtype == '5'">@</span>
                        <span *ngIf="item.locationtype == '4'">#</span>
                        {{item.location}}
                      </div>
                      <div class="td-location">
                        {{item.lat}}, {{item.long}}
                      </div>
                    </span>
                  </a>
                  <span *ngIf="item.lat == '' && item.long == ''" [ngClass]="{'red' : item.locationtype != '3'}">
                    <span
                      *ngIf="item.locationtype == '1' || item.locationtype == '2' || item.locationtype=='3' || item.locationtype == '5'">@</span>
                    <span *ngIf="item.locationtype == '4'">#</span>
                    {{item.location}}
                  </span>
                </ng-container>
                <ng-container *ngIf="item.location == '' && item.lat == '' && item.long == ''">
                  <span class="gray">Unknown Location</span>
                </ng-container>
              </td> -->
              <td class="td-data">
                <a *ngIf="item.location != 'Unknown Location'"
                  href="https://www.google.com/maps/search/?api=1&query={{item.latlong}}" target="_blank"
                  [ngClass]="{'red' : !item.orglocation}">
                  <span class="d-flex flex-column loc">
                    <div *ngIf="item.location">
                      <!-- <span *ngIf="item.orglocation">@</span> -->
                      <!-- <span *ngIf="item.locationtype == '4'">#</span> -->
                      {{item.location}}
                    </div>
                    <div class="td-location">
                      {{item.latlong}}
                    </div>
                  </span>
                </a>
                <ng-container *ngIf="item.location == 'Unknown Location'">
                  <span class="gray">{{ item.location }}</span>
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
    <!-- loc end -->

    <div class="" *ngIf="displayMode=='Absentreport'">
      <div class="mb-2 d-flex flex-wrap justify-content-start align-items-center">
        <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search with User ID/Name/Employee ID"
            aria-label="Search" (focus)="isFocusAS = true" (blur)="isFocusAS = false" [(ngModel)]="searchTextAS"
            [ngClass]="{'c-search-input-with-clear' : searchTextAS }" style="font-family: FontAwesome,Ubuntu;"
            (ngModelChange)="currentPageAS = 1">
          <button class="btn btn-outline-secondary " type="button" *ngIf="searchTextAS" (click)="clear()"
            [ngClass]="{'c-clear' : isFocusAS, 'c-clear-not-focus': !isFocusAS}">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <div class="dropdown " [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView}">
          <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <div c style="text-align: center;">Page size</div>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li class="dropdown-item" *ngFor="let page of absentPG">
              <input type="radio" name="pg-count" value="{{page.count}}" (change)="changeASPageCount($event)"
                id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
              <label for="pg{{page.name}}" class="col-10 p-1">
                {{page.name}}
              </label>
            </li>
          </ul>
        </div>
        <div class="line"></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{(absentData | filter:
            searchTextAS:15).length}}</span>
        </div>
      </div>

      <div class="input-group mb-2" *ngIf="isMobileView">
        <input class="form-control" type="text" placeholder="&#xf002; Search  with User ID/Name/Employee ID/Days/Hours"
          aria-label="Search" (focus)="isFocusAS = true" (blur)="isFocusAS = false" [(ngModel)]="searchTextAS"
          [ngClass]="{'c-search-input-with-clear' : searchTextAS }" style="font-family: FontAwesome,Ubuntu;"
          (ngModelChange)="currentPageAS = 1">
        <button class="btn btn-outline-secondary " type="button" *ngIf="searchTextAS" (click)="clear()"
          [ngClass]="{'c-clear' : isFocusAS, 'c-clear-not-focus': !isFocusAS}">
          <mat-icon class="c-icon">close</mat-icon>
        </button>
      </div>


      <!-- <div class="input-group mb-2" *ngIf="isMobileView">
        <input class="form-control" type="text" placeholder="&#xf002; Search  with User ID/Name/Employee ID/Days/Hours"
          aria-label="Search" (focus)="isFocusDR = true" (blur)="isFocusDR = false" [(ngModel)]="searchTextDR"
          [ngClass]="{'c-search-input-with-clear' : searchTextDR }" style="font-family: FontAwesome,Ubuntu;"
          (ngModelChange)="p = 1">
        <button class="btn btn-outline-secondary " type="button" *ngIf="searchTextDR" (click)="clear()"
          [ngClass]="{'c-clear' : isFocusDR, 'c-clear-not-focus': !isFocusDR}">
          <mat-icon class="c-icon">close</mat-icon>
        </button>
      </div> -->
      <div class="table-responsive" matSort (matSortChange)="sortAbsentData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">

            <tr>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="employeeid">Employee ID</td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="userid">User ID</td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="username">Name</td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="rank">Post</td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="department">Department
              </td>
              <td scope="col" class="col-lg-1 col-md-1 text-center" mat-sort-header="divisiion">Division
              </td>
              <td scope="col" class="col-lg-1 col-md-1 text-center" mat-sort-header="absencedate">Absent Date
              </td>
              <td scope="col" class="col-lg-1 col-md-1 text-center" mat-sort-header="halfdayabsent">
              </td>
            </tr>
          </thead>
          <tbody *ngIf="gettingDaterangeData">
            <tr>
              <td colspan="8" class="nodata">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!gettingDaterangeData">
            <tr *ngIf="absentData.length==0">
              <td colspan="8" class="nodata">Empty</td>
            </tr>
            <tr
              *ngFor="let item of absentData |filter: searchTextAS:15 | paginate: { itemsPerPage: itemPerPageAS ,currentPage: currentPageAS }; let index=index">
              <td class="td-data">
                <span [title]="item.employeeid">{{item.employeeid}} </span>
              </td>
              <td class="td-data">
                <span [title]="item.userid">{{item.userid}} </span>
              </td>
              <td class="td-data">
                <span [title]="item.username">{{item.username}} </span>
              </td>
              <td class="td-data">
                <span [title]="item.type">{{item.type}} </span>
              </td>
              <td class="td-data">
                <span [title]="item.department">{{item.department}} </span>
              </td>
              <td class="td-data">
                <span [title]="item.division">{{item.division}} </span>
              </td>
              <td class="td-data">
                <span [title]="allinoneService.formatDBToShow(item.date)">{{allinoneService.formatDBToShow(item.date)}}
                </span>
              </td>
              <td class="td-data">
                <span [title]="item.halfdayabsent">{{ item.halfdayabsent ? 'Half day absent' : '' }}</span>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls class="paginate" (pageChange)="currentPageAS=$event" *ngIf="absentData.length> itemPerPageAS"
        previousLabel="Prev" nextLabel="Next">
      </pagination-controls>
    </div>

    <div class="" *ngIf="displayMode=='Project'">
      <div class="mb-2 d-flex flex-wrap justify-content-start align-items-center">
        <div class="d-flex flex-wrap">

          <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
            <input class="form-control" type="text" placeholder="&#xf002; Search with User ID/Name/Employee ID"
              aria-label="Search" (focus)="isFocusPJ = true" (blur)="isFocusPJ = false" [(ngModel)]="searchTextPJ"
              [ngClass]="{'c-search-input-with-clear' : searchTextPJ }" style="font-family: FontAwesome,Ubuntu;">
            <button class="btn btn-outline-secondary " type="button" *ngIf="searchTextPJ" (click)="clear()"
              [ngClass]="{'c-clear' : isFocusPJ, 'c-clear-not-focus': !isFocusPJ}">
              <mat-icon class="c-icon">close</mat-icon>
            </button>
          </div>
          <div class="dropdown " [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView}">
            <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
              aria-expanded="false" title="Page Size">
              <mat-icon>filter_list</mat-icon>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <div c style="text-align: center;">Page size</div>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li class="dropdown-item" *ngFor="let page of projectPG">
                <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePjPageCount($event)"
                  id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
                <label for="pg{{page.name}}" class="col-10 p-1">
                  {{page.name}}
                </label>
              </li>
            </ul>
          </div>
          <div class="line"></div>
          <div class="d-flex align-items-center">
            <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{projData.length}}</span>
          </div>
          <div class="line" *ngIf="projStartDate && projEndDate"></div>
          <div class="d-flex align-items-center">
            <span *ngIf="projStartDate && projEndDate" class="datefilter" (click)="filterByDate()">
              {{allinoneService.formatDBToShow(allinoneService.formatDate(projStartDate))}} -
              {{allinoneService.formatDBToShow(allinoneService.formatDate(projEndDate))}}
            </span>
            <mat-icon *ngIf="projStartDate && projEndDate" class="remove-date"
              style="margin-left: 10px;cursor: pointer;" (click)="removeDate()">
              close</mat-icon>
          </div>
        </div>
      </div>
      <div class="input-group mb-2" *ngIf="isMobileView">
        <input class="form-control" type="text" placeholder="&#xf002; Search with User ID/Name/Employee ID"
          aria-label="Search" (focus)="isFocusPJ = true" (blur)="isFocusPJ = false" [(ngModel)]="searchTextPJ"
          [ngClass]="{'c-search-input-with-clear' : searchTextPJ }" style="font-family: FontAwesome,Ubuntu;">
        <button class="btn btn-outline-secondary " type="button" *ngIf="searchTextPJ" (click)="clear()"
          [ngClass]="{'c-clear' : isFocusPJ, 'c-clear-not-focus': !isFocusPJ}">
          <mat-icon class="c-icon">close</mat-icon>
        </button>
      </div>
      <div class="table-responsive" matSort (matSortChange)="sortProjData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="employeeid">Employee ID</td>
              <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="userid">User ID</td>
              <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="username">Name</td>
              <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="hours">Total Hours</td>
            </tr>
          </thead>
          <tbody *ngIf="gettingProjData">
            <tr>
              <td colspan="4" class="nodata">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!gettingProjData">
            <tr *ngIf="projData.length==0">
              <td colspan="4" class="nodata">Empty</td>
            </tr>
            <tr
              *ngFor="let item of projData| filter: searchTextPJ:14 | paginate: { itemsPerPage: itemPerPagePJ, currentPage: q }; let index=index">
              <td class="td-data">
                <span [title]="item.employeeid">{{item.employeeid}} </span>
              </td>
              <td class="td-data">
                <span [title]="item.userid">{{item.userid}} </span>
              </td>
              <td class="td-data">
                <span [title]="item.username">{{item.username}} </span>
              </td>
              <td class="td-edit td-data" (click)="viewTaskDetails(item)">
                <span [title]="item.hours">{{item.hours}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls class="paginate" (pageChange)="q=$event" *ngIf="projData.length> itemPerPagePJ"
        previousLabel="Prev" nextLabel="Next">
      </pagination-controls>
    </div>
    <div class="" *ngIf="displayMode == 'CheckIn'">
      <div *ngIf="sortedArray.length>0">
        <div *ngFor="let user of sortedArray;let j=index;">
          <div class="d-flex justify-content-between align-items-center user-title" (click)="hideMemberCheckIn(j)"
            style="cursor: pointer;border-bottom: 1px solid white;">
            <div>
              {{user.username}} ({{user.userid}})

            </div>
            <div class="d-flex" style="margin-right: 6px;">
              <mat-icon *ngIf="!user.show">keyboard_arrow_right</mat-icon>
              <mat-icon *ngIf="user.show">keyboard_arrow_down</mat-icon>
            </div>

          </div>

          <div class="d-flex" *ngIf="user.show">
            <div>
              <div class="user-waterfall d-flex">
                <div class="user-waterfall-item ">
                </div>

              </div>
            </div>
            <div class="flex-fill">
              <div *ngFor="let x of user.datalist;let i=index;" class=" checkin-box">
                <div class="waterfall">
                  <div class="user-waterfall-dot">

                  </div>
                </div>

                <div class="d-flex justify-content-between date-header" (click)="hide(j,i)"
                  [class.redbackground]="x.isoffday">

                  <div class="float-start ms-2 p-1" style="color: white;">
                    {{x.showdate}} <i class="fas fa-calendar-times ms-2" *ngIf="x.isoffday"></i>
                  </div>
                  <div class="float-end  p-1 me-3">
                    <i *ngIf="x.show" class="fa fa-caret-down" aria-hidden="true"
                      style="color:white;cursor: pointer;font-size: 1.3em;"></i>
                    <i *ngIf="!x.show" class="fa fa-caret-right" aria-hidden="true"
                      style="color:white;cursor: pointer;font-size: 1.3em;"></i>

                  </div>
                </div>
                <div *ngIf="x.data.length == 0" class="row">
                  <div class="nodata emp">Empty</div>
                </div>
                <div *ngFor="let y of x.data;">
                  <div class="p-2 checkinList d-flex justify-content-between" *ngIf="x.show" style="cursor: pointer;">
                    <div class="d-flex ">

                      <!-- <div class="float-start col-1" (click)="showActivity(y,y.iotype)"> -->
                      <div class="me-3">

                        <ng-container *ngIf="!y.backdate">
                          <i class="far fa-circle" aria-hidden="true" style="color: red;" *ngIf="y.iotype=='out'"></i>
                          <i class="far fa-circle" aria-hidden="true" style="color: green;" *ngIf="y.iotype=='in'"></i>
                          <i class="fa fa-circle" aria-hidden="true" style="color: grey; font-size: 16px;"
                            *ngIf="y.iotype=='checkin'"></i>
                        </ng-container>
                        <ng-container *ngIf="y.backdate">
                          <mat-icon class="mat-checkin-icon" aria-hidden="true" style="color: red;"
                            *ngIf="y.iotype=='out'">timelapse</mat-icon>
                          <mat-icon class="mat-checkin-icon" aria-hidden="true" style="color: green;"
                            *ngIf="y.iotype=='in'">timelapse</mat-icon>
                          <mat-icon class="mat-checkin-icon" aria-hidden="true" style="color: grey;"
                            *ngIf="y.iotype=='checkin'">timelapse</mat-icon>
                        </ng-container>
                        <i class="fa fa-qrcode" aria-hidden="true" style="color: black;" *ngIf="y.iotype=='event'"></i>
                        <i class="fa fa-calendar" aria-hidden="true" style="color: black;"
                          *ngIf="y.iotype=='activity'"></i>
                        <div class="waterfall d-flex" *ngIf="y.child.length>0">
                          <div class="waterfall-item">
                          </div>

                        </div>
                      </div>



                      <div *ngIf="y.iotype=='checkin' || y.iotype=='in' || y.iotype=='out'" class="">
                        <div class="d-flex flex-column">
                          <div class="d-flex checkin-items">
                            <div class="me-4 d-flex justify-content-between checkin-items" style="min-width: 40vw;">
                              <div class="d-flex flex-column">
                                <!-- <div class="d-flex">
                                  <b>{{allinoneService.formatTimeToShow(y.starttime)}} </b>
                                  <span aria-hidden="true" *ngIf="y.fromscan && y.scantype != '002'"
                                    class="d-flex ms-2 align-items-center">
                                    <img src="../../../assets/images/checkin/scan_checkin.png" width="13px">
                                  </span>
                                  <span aria-hidden="true" *ngIf="y.fromscan && y.scantype == '002'"
                                    class="d-flex ms-2 align-items-center">
                                    <img src="../../../assets/images/checkin/fingerprint.png" width="13px">
                                  </span>
                                  <small class="ms-2" style="color: gray;"
                                    *ngIf="y.typename!='Check In'">{{y.typename}}</small>
                                </div> -->
                                <div class="d-flex">
                                  <b>{{allinoneService.formatTimeToShow(y.starttime)}} </b>
                                  <span aria-hidden="true" *ngIf="y.fromscan && y.scantype == '001'"
                                    class="d-flex ms-2 align-items-center">
                                    <img src="../../../assets/images/checkin/scan_facedevice.png" width="13px">
                                  </span>
                                  <span aria-hidden="true" *ngIf="y.fromscan && y.scantype == '002'"
                                    class="d-flex ms-2 align-items-center">
                                    <img src="../../../assets/images/checkin/scan_fingerprint.png" width="13px">
                                  </span>
                                  <span aria-hidden="true" *ngIf="y.fromscan && y.scantype == '003'"
                                    class="d-flex ms-2 align-items-center">
                                    <img src="../../../assets/images/checkin/scan_faceai.png" width="13px">
                                  </span>
                                  <span aria-hidden="true" *ngIf="y.fromscan && y.scantype == '004'"
                                    class="d-flex ms-2 align-items-center">
                                    <img src="../../../assets/images/checkin/scan_qr.png" width="13px">
                                  </span>
                                  <!-- <span aria-hidden="true" *ngIf="y.fromscan && y.scantype == '002'"
                                    class="d-flex ms-2 align-items-center">
                                    <img src="../../../assets/images/checkin/fingerprint.png" width="13px">
                                  </span> -->
                                  <!-- <small class="ms-2" style="color: gray;"
                                    *ngIf="y.typename!='Check In'">{{y.typename}}</small> -->
                                  <small class="ms-2" style="color: gray;" *ngIf="y.iotype!='checkin'">{{y.iotype ==
                                    'in' ? 'Time In' : "Time Out"}}</small>
                                  <!-- *** -->
                                  <div *ngIf="y.backdatecount != '' && y.backdatecount != '0'"
                                    class="ms-3 backdatecount">
                                    -&nbsp;{{y.backdatecount}}&nbsp;{{y.backdatecount == '1' ? 'day' : 'days'}}
                                  </div>
                                </div>
                                <div *ngIf="y.orgid!=''" class="my-1">
                                  <span class="orgname p-1 ">
                                    {{y.orgname}}

                                  </span>
                                </div>
                                <div *ngIf="y.taskid!=''">
                                  Task ID: {{y.autoid}} - {{y.taskdescription}}
                                </div>
                                <div *ngIf="y.fromscan && y.scantype != '002' && y.scantype != ''"
                                  class="d-flex align-items-center">
                                  <div *ngIf="y.fromscan && y.scantype != '004' && y.scantype != ''"
                                    class="d-flex align-items-center">
                                    <div *ngIf="!y.temperaturestatus" class="temp-status redbackground me-1">
                                      Fever
                                    </div>
                                    <div class="temp-status me-2" [ngClass]="{'redbackground' : !y.temperaturestatus}">
                                      <img src="../../../assets/images/checkin/thermometer.png" width="15px"
                                        class="me-1">{{y.temperature}}
                                    </div>
                                    <div class="d-flex align-items-center">
                                      <img *ngIf="y.markstatus" src="../../../assets/images/checkin/mask_check.png"
                                        width="15px">
                                      <img *ngIf="!y.markstatus" src="../../../assets/images/checkin/mask_cancel.png"
                                        width="15px">
                                    </div>
                                  </div>
                                </div>


                              </div>

                              <div class="">
                                <ng-container
                                  *ngIf="y.location!= '' && y.lat == '' && y.long == ''; else otherTemplate">
                                  <span class="red-text">
                                    {{y.location}}
                                  </span>
                                </ng-container>
                                <ng-template #otherTemplate>
                                  <span
                                    *ngIf="y.locationtype == '1' || y.locationtype == '2' || y.locationtype == '3' || y.locationtype == '5'">
                                    @
                                  </span>
                                  <span *ngIf="y.locationtype == '4'">
                                    #
                                  </span>
                                  <span style="color: #9c9c9c;" *ngIf="y.location==''">
                                    {{y.lat}} - {{y.long}}
                                  </span>
                                  <span *ngIf="y.location!='' && y.lat && y.long">
                                    {{y.location}}<br> <span style="color: #9c9c9c;">({{y.lat}} -
                                      {{y.long}})</span>
                                  </span>
                                </ng-template>
                              </div>

                            </div>
                            <div class="" style="min-width: 15vw;">
                              <div [innerHTML]="allinoneService.replaceEnter(y.description)">

                              </div>
                            </div>
                          </div>

                          <div *ngIf="y.child.length>0" class="endbox">
                            <div class="connect-waterfall d-flex" *ngIf="y.child.length>0">
                              <div class="connect-waterfall-item">
                              </div>

                            </div>
                            <div>
                              <b>{{allinoneService.formatTimeToShow(y.child[0].starttime)}}</b> [
                              <small>
                                {{calEndTime(y.date,y.starttime,y.child[0].date,y.child[0].starttime)}}

                              </small>]
                            </div>

                          </div>
                          <div *ngIf="y.timeoutduration.length>0">

                            [
                            <small>
                              {{calDuration(y.timeoutduration)}}

                            </small>]

                          </div>

                        </div>
                        <!-- class="float-start col-10"> -->


                      </div>
                      <div *ngIf="y.iotype=='event'" class="d-flex checkin-items">
                        <div class="d-flex checkin-items justify-content-between me-4" style="min-width: 40vw;">



                          <div class="d-flex flex-column">
                            <div class="event-date">
                              <b>{{y.eventname}}</b><br>
                              <b>{{y.eventstartdate | date:"dd/MM/yyyy hh:mm a" }} -
                                {{y.eventenddate |
                                date:"dd/MM/yyyy hh:mm a" }}</b>

                            </div>
                            <div *ngIf="y.orgid!=''" class="my-1">
                              <span class="orgname p-1 ">
                                {{y.orgname}}

                              </span>
                            </div>
                            <div *ngIf="y.taskid!=''">
                              Task ID: {{y.autoid}} - {{y.taskdescription}}
                            </div>


                          </div>


                          <div style="min-width: 10.7rem;">
                            <div style="color: #9c9c9c;" *ngIf="y.eventlocation==''">
                              @{{y.eventlat}} - {{y.eventlong}}
                            </div>
                            <div *ngIf="y.eventlocation!=''">
                              @{{y.eventlocation}} <br>
                              <!-- <span style="color: #9c9c9c;">{{y.eventlat}} -
                                                                {{y.eventlong}}</span> -->
                            </div>
                          </div>

                        </div>
                        <div class="" style="min-width: 15vw;">
                          <div [innerHTML]="allinoneService.replaceEnter(y.eventdescription)">

                          </div>
                        </div>
                      </div>
                      <div *ngIf="y.iotype=='activity'" class="d-flex checkin-items">
                        <div class="d-flex checkin-items " style="min-width: 42.4vw;">
                          <div class="d-flex flex-column">
                            <div class="d-flex">
                              <div>
                                <!-- <b>{{y.eventname}}</b> -->
                                <b>{{allinoneService.formatTimeToShow(y.starttime)}} -
                                  {{allinoneService.formatTimeToShow(y.endtime)}}</b>

                              </div>
                              <div *ngIf="y.backdatecount != '' && y.backdatecount != '0'" class="ms-3 backdatecount">
                                -&nbsp;{{y.backdatecount}}&nbsp;{{y.backdatecount == '1' ? 'day' : 'days'}}
                              </div>
                            </div>
                            <div *ngIf="y.orgid!=''" class="my-1">
                              <span class="orgname p-1 ">
                                {{y.orgname}}

                              </span>
                            </div>
                            <div *ngIf="y.taskid!=''">
                              Task ID: {{y.autoid}} - {{y.taskdescription}}
                            </div>
                            <div *ngIf="y.ticketno?.length > 0" class="my-1 d-flex flex-wrap" style="row-gap: 5px;">
                              <div *ngFor="let ticket of y.ticketno" class="me-2 priority"
                                (click)="openTicketDetails(ticket)">
                                #{{ticket}}
                              </div>
                            </div>
                          </div>

                        </div>
                        <div class="pe-3" style="word-break: break-all">
                          {{y.description}}
                        </div>
                      </div>

                    </div>
                  </div>

                </div>


              </div>
            </div>


          </div>



        </div>


      </div>
      <div *ngIf="sortedArray.length==0">
        <hr>
        <div class="d-flex m-auto p-4" style="justify-content: center;">
          Empty
        </div>
      </div>
    </div>

  </div>
</div>