import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-office-holiday',
  templateUrl: './office-holiday.component.html',
  styleUrls: ['./office-holiday.component.scss'],
})
export class OfficeHolidayComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  mobileViewWidth: number = 490;
  itemPerPage: number = 10;
  currentPage: number = 1;

  addHoliday: boolean = false;
  isEditing: boolean = false;
  isLoading: boolean = false;
  isMobileView: boolean = false;
  isFocus: boolean = false;
  holidayListLoading: boolean = false;
  formSubmitted: boolean = false;
  isAdmin: boolean = false;

  searchText: string = '';

  holidayList: any[] = [];
  organizations: any[] = [];

  generalDatePickerConfig: any = this.allinoneService.datePickerConfig;

  currentDate = new Date(Date.now());

  holidayForm: any = {
    holidayid: '',
    domainid: '',
    orgid: '',
    holiday: '',
    // startdate: this.allinoneService.formatDBToShowInEdit(
    //   this.allinoneService.getCurrentDateToDB()
    // ),
    // enddate: this.allinoneService.formatDBToShowInEdit(
    //   this.allinoneService.getCurrentDateToDB()
    // ),
    startdate: this.currentDate,
    enddate: this.currentDate,
    oildate: '',
  };

  subscriptions = new SubSink();

  constructor(
    public allinoneService: AllInOneService,
    public kunyekService: KunyekService,
    public messageService: MessageService,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog
  ) {
    this.route.data.subscribe((data) => {
      if (data.role == 'admin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        this.allinoneService.isOfficeView = true;
      }
    });
  }
  ngOnDestroy(): void {
    this.allinoneService.isOfficeView = false;

    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    }
    this.holidayForm.domainid = this.allinoneService.getDomain().domainid;
    if (this.isAdmin) {
      // this.organizations = this.allinoneService.orgs;
      // this.holidayForm.orgid = this.allinoneService.selectedOrg
      //   ? this.allinoneService.selectedOrg
      //   : this.organizations[0].orgid;
      this.organizations = this.allinoneService.orgs;
      this.holidayForm.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;
    } else {
      this.organizations = this.allinoneService.orgsCRm;
      this.holidayForm.orgid = this.allinoneService.selected_orgcrm
        ? this.allinoneService.selected_orgcrm.orgid
        : this.organizations[0].orgid;
    }
    this.getHoliday();
  }

  getHoliday() {
    this.holidayListLoading = true;
    var data = {
      domainid: this.holidayForm.domainid,
      orgid: this.holidayForm.orgid,
      roleid: this.allinoneService.getSelectedRole()
    };
    this.subscriptions.sink = this.kunyekService.getHolidayList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.holidayList = res.holidaylist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.holidayListLoading = false;
      },
      (err) => {
        this.holidayListLoading = false;
      }
    );
  }

  gotoOffice() {
    this.router.navigateByUrl(`adminconsole/office`);
  }

  cancel() {
    this.addHoliday = false;
    this.isEditing = false;
    this.formSubmitted = false;
    this.holidayForm = {
      holidayid: '',
      domainid: this.holidayForm.domainid,
      orgid: this.holidayForm.orgid,
      holiday: '',
      // startdate: this.holidayForm.startdate,
      // enddate: this.holidayForm.enddate,
      startdate: this.currentDate,
      enddate: this.currentDate,
      oildate: '',
    };
  }

  clear() {
    this.searchText = '';
  }

  refresh() {
    this.currentPage = 1;
    this.getHoliday();
  }

  sortData(sort: Sort) {
    const data = this.holidayList;
    if (!sort.active || sort.direction === '') {
      this.holidayList = data;
      return;
    }
    this.holidayList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.allinoneService.compare(a.year, b.year, isAsc);
        case 'startdate':
          return this.allinoneService.compare(a.startdate, b.startdate, isAsc);
        case 'enddate':
          return this.allinoneService.compare(a.enddate, b.enddate, isAsc);
        case 'oildate':
          return this.allinoneService.compare(a.active, b.active, isAsc);
        default:
          return 0;
      }
    });
  }

  newHoliday() {
    this.addHoliday = true;
  }

  submitHoliday() {
    this.formSubmitted = true;
    if (this.holidayForm.holiday == '') {
      return;
    }
    if (this.holidayForm.startdate > this.holidayForm.enddate) {
      return this.messageService.openSnackBar(
        'Please select a valid end date.',
        'warn'
      );
    }
    this.isLoading = true;
    var data = {
      holidayid: this.holidayForm.holidayid,
      domainid: this.holidayForm.domainid,
      orgid: this.holidayForm.orgid,
      name: this.holidayForm.holiday,
      startdate: this.allinoneService.parseDate(this.holidayForm.startdate),
      enddate: this.allinoneService.parseDate(this.holidayForm.enddate),
      oildate: this.holidayForm.oildate
        ? this.allinoneService.parseDate(this.holidayForm.oildate)
        : '',
      roleid: this.allinoneService.getSelectedRole()
    };
    var fun = this.isEditing
      ? this.kunyekService.editHoliday(data)
      : this.kunyekService.addHoliday(data);
    this.subscriptions.sink = fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.cancel();
          this.refresh();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  removeOildate() {
    this.holidayForm.oildate = '';
  }

  editHoliday(holiday: any) {
    this.addHoliday = true;
    this.isEditing = true;
    this.holidayForm = {
      holidayid: holiday.holidayid,
      domainid: this.holidayForm.domainid,
      orgid: this.holidayForm.orgid,
      holiday: holiday.name,
      startdate: this.allinoneService.parseDateToShowInEdit(holiday.startdate),
      enddate: this.allinoneService.parseDateToShowInEdit(holiday.enddate),
      oildate: holiday.oildate
        ? this.allinoneService.parseDateToShowInEdit(holiday.oildate)
        : '',
    };
  }

  deleteHoliday(index: number) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.holidayListLoading = true;
        const data = {
          orgid: this.holidayForm.orgid,
          domainid: this.holidayForm.domainid,
          holidayid: this.holidayList[index].holidayid,
          roleid: this.allinoneService.getSelectedRole()
        };
        this.kunyekService.deleteHoliday(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.holidayList.splice(index, 1);
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.holidayListLoading = false;
          },
          (err) => {
            this.holidayListLoading = false;
          }
        );
      }
    });
  }
}
