import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from '../../services/all-in-one.service';
import { KunyekService } from '../../services/kunyek.service';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @ViewChild('choosefile') choosefile: any;

  isError: boolean = false;
  saveLoading: boolean = false;
  reupload: boolean = false;

  fileSelected: any = '';
  filename: string = '';
  filetype: string = '';
  orgid: string = '';
  domainid: string = '';
  errorData: any;
  type : string = ''; // 001- hierarchy, 002 - workpolicy , 003 - attendance, 004 - approval leave

  allowedFiletype = ['xlsx'];

  progress: number = 0;

  constructor(
    private messageService: MessageService,
    private allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    public dialogRef: MatDialogRef<FileUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isError = data.isError;
    this.orgid = data.orgid;
    this.domainid = data.domainid;
    this.errorData = data.errorData;
    this.type = data.type
  }

  ngOnInit(): void {
  }

  uploadFile(event: any) {
    var filetype = event[0].name.split('.').pop();
    if (this.allowedFiletype.includes(filetype)) {
      this.fileSelected = event[0];
      this.filename = this.fileSelected.name;
      this.filetype = filetype;
    } else {
      this.messageService.openSnackBar(
        'This file type is not allowed.',
        'warn'
      );
    }
    this.choosefile.nativeElement.value = '';
  }

  onFileSelected(event: any) {
    var filetype = event.target.files[0].name.split('.').pop();
    if (this.allowedFiletype.includes(filetype)) {
      this.fileSelected = event.target.files[0];
      this.filename = this.fileSelected.name;
      this.filetype = filetype;
    } else {
      this.messageService.openSnackBar(
        'This file type is not allowed.',
        'warn'
      );
    }
    this.choosefile.nativeElement.value = '';
  }

  uploadfile() {
    if (!this.fileSelected) {
      return this.messageService.openSnackBar('Please upload a file.', 'warn');
    }
    this.saveLoading = true;
    this.dialogRef.disableClose = true;
    var filename = this.allinoneService.getCurrentDateToDB() + this.allinoneService.getCurrentTimeToDB2() + '_' + this.filename;
    this.allinoneService
      .fileUploadToS3WithPathForImageAndProgress(
        this.fileSelected,
        filename,
        'importfiles/origin'
      )
      .on('httpUploadProgress', (progress) => {
        let progressPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );
        console.log(progressPercentage);
        this.progress = progressPercentage;
      })
      .send((err: any, data: any) => {
        if (err) {
          console.log('There was an error uploading your file: ', err);
          //Fail
          this.progress = 0;
          this.saveLoading = false;
          this.dialogRef.disableClose = false;

          this.messageService.openSnackBar(
            'Error while uploading your file.',
            'warn'
          );
          return false;
        }
        this.submitImport(filename);
        return true;
      });
  }

  submitImport(filename: string) {
    var type = '';
    if(this.type == '001') {
      type = '001'
    } else if(this.type == '002') {
      type = '006'
    } else if(this.type == '003') {
      type = '007'
    } else if(this.type == '004') {
      type = '009'
    }
    var data = {
      orgid: this.orgid,
      domainid: this.domainid,
      filename: filename,
      type : type,
      importstatusid: this.errorData.importstatusid,
    };
    var fun = this.kunyekService.addHierarchImport(data);
    if(this.type == '001') {
      fun = this.kunyekService.addHierarchImport(data);
    } else if(this.type == '002') {
      fun = this.kunyekService.addWpImport(data);
    } else if(this.type == '003') {
      fun = this.kunyekService.addCheckinImport(data);
    } else if(this.type == '004') {
      fun = this.kunyekService.addApprovalLeaveImport(data);
    }
    console.log("Your data");
    console.log(data);
    
    fun.subscribe((res: any) => {
      if(res.returncode == '300'){
        this.dialogRef.close(true);
      }
      else{
        this.messageService.openSnackBar(res.message || res.status || 'Server Error', 'warn');
      }
      this.saveLoading = false;
      this.dialogRef.disableClose = false;
    },err => {
      this.saveLoading = false;
      this.dialogRef.disableClose = false;
    })
  }

  cancelImport(){
    this.saveLoading = true;
    this.dialogRef.disableClose = true;
    var data = {
      orgid: this.orgid,
      domainid: this.domainid,
      importstatusid: this.errorData.importstatusid,
    };
    var fun = this.kunyekService.updateHierarchyImport(data);
    if(this.type == '001') {
      fun = this.kunyekService.updateHierarchyImport(data);
    } else if(this.type == '002') {
      fun = this.kunyekService.updateWpImport(data);
    } else if(this.type == '003') {
      fun = this.kunyekService.updateCheckinImport(data);
    } else if(this.type == '004') {
      fun = this.kunyekService.updateApprovalLeaveImport(data);
    }
    fun.subscribe((res: any) => {
      if(res.returncode == '300'){
        this.dialogRef.close(true);
      }
      else{
        this.messageService.openSnackBar(res.message || res.status || 'Server Error', 'warn');
      }
      this.saveLoading = false;
      this.dialogRef.disableClose = false;
    },err => {
      this.saveLoading = false;
      this.dialogRef.disableClose = false;
    })
  }

  onDismiss() {
    if(this.saveLoading){
      return;
    }
    this.dialogRef.close();
  }

  reuploadFile() {
    this.reupload = true;
  }

  downloadSample(){
    if(this.type == '001'){
      window.open(this.allinoneService.bucketUrl + 'templates/HierarchySampleTemplate.xlsx', '_blank');
    } else if(this.type == '002') {
      window.open(this.allinoneService.bucketUrl + 'templates/WorkPolicySampleTemplate.xlsx', '_blank');
    }
    else if(this.type == '003'){
      window.open(this.allinoneService.bucketUrl + 'templates/CheckInSampleTemplate.xlsx', '_blank');
    }
    else if(this.type == '004') {
      window.open(this.allinoneService.bucketUrl + 'templates/LeaveSampleTemplate.xlsx', '_blank');
    }
  }

  //1 - origin, 2- error
  downloadFile(type: number) {
    if (type == 1) {
      window.open(this.errorData.originfilelink, '_blank');
    } else {
      window.open(this.errorData.errorfilelink, '_blank');
    }
  }
}
