import { NgModule } from '@angular/core';
import { RouterModule, Routes, RouteReuseStrategy } from '@angular/router';
// import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { EventsComponent } from './admin/events/events.component';
import { LocationsComponent } from './admin/locations/locations.component';
import { MembersComponent } from './admin/members/members.component';
import { OrganizationsComponent } from './admin/organizations/organizations.component';
import { LoginComponent } from './admin/login/login.component';
import { VerifyComponent } from './admin/verify/verify.component';
import { AuthGuard } from './shared/auth/auth.guard';
import { ContactsTracingComponent } from './admin/contacts-tracing/contacts-tracing.component';
import { DomainComponent } from './admin/domain/domain.component';
import { QrLoginComponent } from './admin/qr-login/qr-login.component';
import { ChatComponent } from './admin/chat/chat.component';
import { CheckInComponent } from './admin/check-in/check-in.component';
import { DomainsComponent } from './admin/domains/domains.component';
import { AdminsRootComponent } from './admin/admins-root/admins-root.component';
import { ServerMaintenanceComponent } from './admin/server-maintenance/server-maintenance.component';
import { EulaComponent } from './admin/eula/eula.component';
import { PrivacyPolicyComponent } from './admin/privacy-policy/privacy-policy.component';
import { AdminsComponent } from './admin/admins/admins.component';
import { ProjectsComponent } from './admin/projects/projects.component';
import { JobsComponent } from './admin/jobs/jobs.component';
import { PostsComponent } from './admin/posts/posts.component';
import { SocialPostsComponent } from './admin/social-posts/social-posts.component';
// import { AttendancesComponent } from './admin/attendances/attendances.component';
import { CountsComponent } from './admin/counts/counts.component';
import { NeoPrivacyPolicyComponent } from './admin/neo-privacy-policy/neo-privacy-policy.component';
import { NeoHomeComponent } from './admin/neo-home/neo-home.component';
import { CheckInMembersComponent } from './admin/check-in-members/check-in-members.component';
// import { PayrollSharedModule } from 'projects/payroll/src/app/app.module';
import { HierarchyComponent } from './admin/hierarchy/hierarchy.component';
import { CheckInAttendencesComponent } from './admin/check-in-attendences/check-in-attendences.component';
import { CheckInAttendancesDetailsComponent } from './admin/check-in-attendances-details/check-in-attendances-details.component';
import { CurtomRouteReuseStrategy } from './shared/services/route-reuse.service';
import { CheckInDaterangeDetailsComponent } from './admin/check-in-daterange-details/check-in-daterange-details.component';
import { CheckInProjectDetailsComponent } from './admin/check-in-project-details/check-in-project-details.component';
import { VotingQrComponent } from './voting/voting-qr/voting-qr.component';
import { VotingComponent } from './voting/voting/voting.component';
import { VotingViewComponent } from './voting/voting-view/voting-view.component';
import { VotingRoundSummaryComponent } from './voting/voting-round-summary/voting-round-summary.component';
import { VotersComponent } from './voting/voters/voters.component';
import { VotedListComponent } from './voting/voted-list/voted-list.component';
import { AttendancesComponent } from './admin/attendances/attendances.component';
import { CampaignComponent } from './voting/campaign/campaign.component';
import { NotificationComponent } from './noti-module/notification/notification.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { ProjectReportComponent } from './contributor/project-report/project-report.component';
import { ProjectReportDetailComponent } from './contributor/project-report-detail/project-report-detail.component';

//CRM Module
import { CrmCustomerComponent } from './crm/crm-customer/crm-customer.component';
import { CrmPipelineComponent } from './crm/crm-pipeline/crm-pipeline.component';
import { DealComponent } from './crm/deal/deal.component';
import { LeadComponent } from './crm/lead/lead.component';
import { CrmSummaryComponent } from './crm/crm-summary/crm-summary.component';
import { CrmDashboardComponent } from './crm/crm-dashboard/crm-dashboard.component';
import { CrmComponent } from './crm/crm/crm.component';
import { CrmProductComponent } from './crm/crm-product/crm-product.component';
import { CrmPersonInChargeComponent } from './crm/crm-person-in-charge/crm-person-in-charge.component';
import { CrmServiceFormComponent } from './crm/crm-service-form/crm-service-form.component';
import { CrmStatusFormComponent } from './crm/status-form/status-form.component';
import { CrmCustomerInfoComponent } from './crm/crm-customer-info/crm-customer-info.component';
import { Connect365privacypolicyComponent } from './connect365privacypolicy/connect365privacypolicy.component';
import { LogisticsDashboardComponent } from './logistics/logistics-dashboard/logistics-dashboard.component';
import { LogisticsOrderComponent } from './logistics/logistics-order/logistics-order.component';
import { CrmDashboardServiceComponent } from './crm/crm-dashboard-service/crm-dashboard-service.component';
import { LogisticsBatchComponent } from './logistics/logistics-batch/logistics-batch.component';
import { LogisticsAssignComponent } from './logistics/logistics-assign/logistics-assign.component';
import { RoleComponent } from './admin/role/role.component';
import { RequestTypeComponent } from './admin/office/request-type/request-type.component';
import { OfficeDashboardComponent } from './admin/office/office-dashboard/office-dashboard.component';
import { CrmWorkFlowComponent } from './crm/crm-work-flow/crm-work-flow.component';
import { OfficeLeaveEntitlementComponent } from './admin/office/office-leave-entitlement/office-leave-entitlement.component';
import { KhubPrivacyComponent } from './khub-privacy/khub-privacy.component';
import { OfficeLeaveTemplateComponent } from './admin/office/office-leave-template/office-leave-template.component';
import { OfficeLeaveCalendarComponent } from './admin/office/office-leave-calendar/office-leave-calendar.component';
import { DeliPrivacyComponent } from './deli-privacy/deli-privacy.component';
import { OfficeHolidayComponent } from './admin/office/office-holiday/office-holiday.component';
import { Hi365PrivacyComponent } from './hi365-privacy/hi365-privacy.component';
import { OfficeLeaveOpeningComponent } from './admin/office/office-leave-opening/office-leave-opening.component';
import { KhubEulaComponent } from './khub-eula/khub-eula.component';
import { OfficeRequestComponent } from './admin/office/office-request/office-request.component';
import { OfficeApprovalComponent } from './admin/office/office-approval/office-approval.component';
import { DeliEulaComponent } from './deli-eula/deli-eula.component';
import { ConnecthcmPrivacyComponent } from './connecthcm-privacy/connecthcm-privacy.component';
import { OfficeWorkingHourComponent } from './admin/office/office-working-hour/office-working-hour.component';
import { UsermappingComponent } from './admin/usermapping/usermapping.component';
import { QrSetupComponent } from './qr360/qr-setup/qr-setup.component';
import { QrListViewComponent } from './qr360/qr-list-view/qr-list-view.component';
import { MemberPositionControlComponent } from './admin/member-position-control/member-position-control.component';
import { OfficeLeaveSummaryComponent } from './admin/office/office-leave-summary/office-leave-summary.component';
import { LeaveSummaryReportComponent } from './admin/office/leave-summary-report/leave-summary-report.component';
import { CheckinQrComponent } from './admin/checkin-qr/checkin-qr.component';
import { OfficeAnniversaryComponent } from './admin/office/office-anniversary/office-anniversary.component';
import { OfficeCalculateLeaveComponent } from './admin/office/office-calculate-leave/office-calculate-leave.component';
import { OfficeUserRequestComponent } from './admin/office/office-user-request/office-user-request.component';
import { EmployeeCentralComponent } from './admin/employee-central/employee-central.component';
import { CrmCustomerGroupComponent } from './crm/crm-customer-group/crm-customer-group.component';
import { AppRestrictionComponent } from './admin/app-restriction/app-restriction.component';
import { OfficeShiftComponent } from './admin/office/office-shift/office-shift.component';
import { OfficeRosterComponent } from './admin/office/office-roster/office-roster.component';
import { OfficeWorkpolicyComponent } from './admin/office/office-workpolicy/office-workpolicy.component';

import { VehicleComponent } from './admin/transporation/vehicle/vehicle.component';
import { PickupPointComponent } from './admin/transporation/pickup-point/pickup-point.component';
import { CarWayScheduleComponent } from './admin/transporation/car-way-schedule/car-way-schedule.component';
import { FerryRequestComponent } from './admin/transporation/ferry-request/ferry-request.component';
import { DailyRequestComponent } from './admin/transporation/daily-request/daily-request.component';
import { TransportationDashboardComponent } from './admin/transporation/transportation-dashboard/transportation-dashboard.component';
import { EmployeeidMappingComponent } from './admin/employeeid-mapping/employeeid-mapping.component';
import { OfficeLeavePolicyComponent } from './admin/office/office-leave-policy/office-leave-policy.component';
import { AttendanceQrComponent } from './admin/attendance-qr/attendance-qr.component';
import { AttendanceQrViewComponent } from './admin/attendance-qr-view/attendance-qr-view.component';
import { UserQrListViewComponent } from './qr360/user-qr-list-view/user-qr-list-view.component';
import { LateAndEarlyoutPolicyComponent } from './admin/office/late-and-earlyout-policy/late-and-earlyout-policy.component';
import { WorkplicyConfigComponent } from './admin/office/workplicy-config/workplicy-config.component';
import { LateAndEarlyoutReportComponent } from './admin/office/late-and-earlyout-report/late-and-earlyout-report.component';
import { EarlyoutReportComponent } from './admin/office/earlyout-report/earlyout-report.component';
import { DropoffPointComponent } from './admin/transporation/dropoff-point/dropoff-point.component';
import { CarWayScheduleDetailComponent } from './admin/transporation/car-way-schedule-detail/car-way-schedule-detail.component';
import { EmployeeRequestionComponent } from './admin/employee-requestion/employee-requestion.component';
import { RequisitionRequestComponent } from './admin/office/requisition-request/requisition-request.component';
import { RequisitionApprovalComponent } from './admin/office/requisition-approval/requisition-approval.component';
import { RequisitionRolesComponent } from './admin/office/requisition-roles/requisition-roles.component';
import { HrRequisitionComponent } from './admin/office/hr-requisition/hr-requisition.component';
import { AbsentreportComponent } from './admin/office/absentreport/absentreport.component';
import { OfficeRoomsComponent } from './admin/office/office-rooms/office-rooms.component';
import { OfficeDesksComponent } from './admin/office/office-desks/office-desks.component';
import { DriverComponent } from './admin/transporation/driver/driver.component';
import { TimecardReportComponent } from './admin/office/timecard-report/timecard-report.component';
import { TimeInOutReportComponent } from './admin/office/time-in-out-report/time-in-out-report.component';
import { RulesAndRegulationComponent } from './admin/office/rules-and-regulation/rules-and-regulation.component';
import { OutstandingRequestsComponent } from './admin/transporation/outstanding-requests/outstanding-requests.component';
import { UseridMappingComponent } from './admin/userid-mapping/userid-mapping.component';
import { ExportDetailListComponent } from './crm/export-detail-list/export-detail-list.component';
import { IncompleteReportComponent } from './admin/office/incomplete-report/incomplete-report.component';
import { RecruitmentsComponent } from './admin/office/recruitments/recruitments.component';
import { ReasonComponent } from './admin/office/reason/reason.component';
import { ServiceMonthlyReportComponent } from './crm/service-monthly-report/service-monthly-report.component';
import { ServiceOverageSetupComponent } from './crm/service-overage-setup/service-overage-setup.component';
import { ServiceOverageReportComponent } from './crm/service-overage-report/service-overage-report.component';
import { LeaveCountReportComponent } from './admin/office/leave-count-report/leave-count-report.component';
import { OfficeLeaveOpeningFormComponent } from './admin/office/office-leave-opening-form/office-leave-opening-form.component';
import { OfficeLeaveOpeingHistoryComponent } from './admin/office/office-leave-opeing-history/office-leave-opeing-history.component';
import { ManhourDetailReportComponent } from './crm/manhour-detail-report/manhour-detail-report.component';
import { CheckinReportComponent } from './admin/office/checkin-report/checkin-report.component';
import { LateEarlyoutDataFormComponent } from './admin/office/late-earlyout-data-form/late-earlyout-data-form.component';
import { LateEarlyoutTimeReportComponent } from './admin/office/late-earlyout-time-report/late-earlyout-time-report.component';
import { OfficeCalendarComponent } from './admin/office/office-calendar/office-calendar.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LateEarlyoutReportPolicyComponent } from './admin/office/late-earlyout-report-policy/late-earlyout-report-policy.component';
import { RmsPrivacyComponent } from './rms-privacy/rms-privacy.component';
import { DeviceIdComponent } from './admin/device-id/device-id.component';
import { Cs365DashboardComponent } from './crm/cs365/dashboard/dashboard.component';
import { Cs365ServiceTicketsComponent } from './crm/cs365/service-tickets/service-tickets.component';
import { LabelSetupComponent } from './crm/cs365/label-setup/label-setup.component';
import { AccessoriesRequestComponent } from './admin/office/accessories-request/accessories-request.component';
import { PayPeriodComponent } from './admin/pay-period/pay-period.component';
import { ReferenceDashboardComponent } from './admin/reference-dashboard/reference-dashboard.component';
import { TeamDashboardComponent } from './admin/team-dashboard/team-dashboard.component';
import { AllInOneService } from './shared/services/all-in-one.service';
import { WarrantyComponent } from './admin/warranty/warranty.component';
import { InvalidPermissionComponent } from './admin/invalid-permission/invalid-permission.component';
import { ServiceWarrantyComponent } from './crm/cs365/service-warranty/service-warranty.component';
import { AccessoriesApprovalComponent } from './admin/office/accessories-approval/accessories-approval.component';
import { OfficeTravelDetailComponent } from './admin/office/office-travel-detail/office-travel-detail.component';
import { MemberDashboardComponent } from './members/member-dashboard/member-dashboard.component';
import { MemberRegisterComponent } from './members/member-register/member-register.component';
import { MemberRequestlistComponent } from './members/memberl_requestlist/member_requestlist.component';
import { MonthlyfeesetupComponent } from './members/monthlyfeesetup/monthlyfeesetup.component';
import { MemberPaymentComponent } from './members/member-payment/member-payment.component';
import { MonthlyfeeListComponent } from './members/monthlyfee-list/monthlyfee-list.component';
import { MemberGuestRegisterFormComponent } from './members/member-guest-register-form/member-guest-register-form.component';
import { MemberApprovalListComponent } from './members/member-approval-list/member-approval-list.component';
import { MemberListComponent } from './members/member-list/member-list.component';
import { RegisterComponent } from './members/register/register.component';
import { PublicRegisterStatusComponent } from './members/public-register-status/public-register-status.component';
import { SummerSwimFormComponent } from './members/summer-swim-form/summer-swim-form.component';
import { SpecialSwimFormComponent } from './members/special-swim-form/special-swim-form.component';
import { TempSwimFormComponent } from './members/temp-swim-form/temp-swim-form.component';
import { TempDetailComponent } from './members/temp-detail/temp-detail.component';
import { GymFormComponent } from './members/gym-form/gym-form.component';
import { GymDetailComponent } from './members/gym-detail/gym-detail.component';
import { GymMemberPreviewComponent } from './members/gym-member-preview/gym-member-preview.component';
import { SummerSwimmerListComponent } from './members/summer-swimmer-list/summer-swimmer-list.component';
import { SpecialSwimListComponent } from './members/special-swim-list/special-swim-list.component';
import { SpecialSwimDetailComponent } from './members/special-swim-detail/special-swim-detail.component';
import { SpecialSwimmerPreviewComponent } from './members/special-swimmer-preview/special-swimmer-preview.component';
import { GymListComponent } from './members/gym-list/gym-list.component';
import { TempSwimmerListComponent } from './members/temp-swimmer-list/temp-swimmer-list.component';
import { TempSwimmerPreviewComponent } from './members/temp-swimmer-preview/temp-swimmer-preview.component';
import { GeneralPaymentInvoiceFormComponent } from './members/general-payment-invoice-form/general-payment-invoice-form.component';
import { GeneralPaymentInvoiceListComponent } from './members/general-payment-invoice-list/general-payment-invoice-list.component';
import { GeneralPaymentDetailComponent } from './members/general-payment-detail/general-payment-detail.component';
import { GeneralPaymentInvoiceUpdateComponent } from './members/general-payment-invoice-update/general-payment-invoice-update.component';
import { LeaveFormComponent } from './members/leave-form/leave-form.component';
import { LeaveListComponent } from './members/leave-list/leave-list.component';
import { LeaveDetailComponent } from './members/leave-detail/leave-detail.component';
import { SummerSwimmerDetailComponent } from './members/summer-swimmer-detail/summer-swimmer-detail.component';
import { SummerSwimmerPreviewComponent } from './members/summer-swimmer-preview/summer-swimmer-preview.component';
import { GuestFormComponent } from './members/guest-form/guest-form.component';
import { GuestListComponent } from './members/guest-list/guest-list.component';
import { GuestDetailComponent } from './members/guest-detail/guest-detail.component';
import { BatchListComponent } from './members/batch-list/batch-list.component';
import { BatchFormComponent } from './members/batch-form/batch-form.component';
import { BatchDetailComponent } from './members/batch-detail/batch-detail.component';

const routes: Routes = [
  // { path: '', component: ServerMaintenanceComponent },

  // Voting Page
  { path: 'voting/:roundid', component: VotingQrComponent },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: 'voting/:roundid/vote', component: VotingComponent },
  { path: '', component: QrLoginComponent },
  { path: 'eula', component: EulaComponent },
  { path: 'privacy', component: PrivacyPolicyComponent },
  { path: 'connect365-privacy', component: Connect365privacypolicyComponent },
  { path: 'rms-privacy', component: RmsPrivacyComponent },
  {
    path: 'khub-privacy',
    component: KhubPrivacyComponent,
    data: {
      appname: 'KHub Edx',
    },
  },
  {
    path: 'miod-privacy',
    component: KhubPrivacyComponent,
    data: {
      appname: 'MIoD',
    },
  },
  {
    path: 'uwl-privacy',
    component: KhubPrivacyComponent,
    data: {
      appname: 'UWL',
    },
  },
  {
    path: 'yueco-privacy',
    component: KhubPrivacyComponent,
    data: {
      appname: 'YUEco',
    },
  },
  {
    path: 'yufl-privacy',
    component: KhubPrivacyComponent,
    data: {
      appname: 'YUFL',
    },
  },
  { path: 'khub-eula', component: KhubEulaComponent },
  { path: 'deli-privacy', component: DeliPrivacyComponent },
  { path: 'deli-eula', component: DeliEulaComponent },
  { path: 'hi365-privacy', component: Hi365PrivacyComponent },
  { path: 'connecthcm-privacy', component: ConnecthcmPrivacyComponent },
  { path: 'login', component: LoginComponent },
  { path: 'invalid', component: InvalidPermissionComponent },

  { path: 'verify', component: VerifyComponent },
  { path: 'qrlogin', component: QrLoginComponent },
  { path: 'neo-privacy-policy', component: NeoPrivacyPolicyComponent },
  { path: 'neovision', component: NeoHomeComponent },
  {
    path: 'adminconsole',
    component: AdminDashboardComponent,
    canActivate: [AuthGuard],
  },
  { path: 'domain', component: DomainComponent, canActivate: [AuthGuard] },
  // { path: 'domains', component: DomainsComponent, canActivate: [AuthGuard] },
  {
    path: 'references/domains',
    component: DomainsComponent,
    canActivate: [AuthGuard],
  },
  { path: 'admins', component: AdminsComponent, canActivate: [AuthGuard] },
  // {
  //   path: 'rootadmins',
  //   component: AdminsRootComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'rolemanagement/rootadmins',
    component: AdminsRootComponent,
    canActivate: [AuthGuard],
  },
  //////////////////////// [ "FOR CONNECT 365" ]//////////////////////////////////

  { path: 'home', redirectTo: 'posts' },
  { path: 'teams', redirectTo: 'posts' },
  { path: 'timeandattendance', redirectTo: 'posts' },
  { path: 'requestapproval', redirectTo: 'posts' },
  { path: 'references', redirectTo: 'posts' },
  { path: 'rolemanagement', redirectTo: 'posts' },

  //////////////////////// [ "FOR CONNECT 365" ]//////////////////////////////////

  //////////////////////// [ "FOR NEO TEAMS" ]//////////////////////////////////

  // { path: 'home', redirectTo: 'teams' },
  // { path: 'posts', redirectTo: 'teams' },
  // { path: 'checkin', redirectTo: 'teams' },
  // { path: 'message', redirectTo: 'teams' },
  // { path: 'office', redirectTo: 'teams' },

  //////////////////////// [ "FOR NEO TEAMS" ]//////////////////////////////////

  { path: 'home', redirectTo: 'teams' },

  // {
  //   path: 'organizations',
  //   component: OrganizationsComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'references/organizations',
    component: OrganizationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'teams/locations',
    component: LocationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'teams/users',
    component: MembersComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'User',
    },
  },
  {
    path: 'references/users',
    component: MembersComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'User',
    },
  },
  // {
  //   path: 'hxm/employees',
  //   component: MembersComponent,
  //   canActivate: [AuthGuard],
  //   data: {
  //     breadcrumb: 'Employee',
  //   },
  // },
  {
    path: 'teams/employees',
    component: MembersComponent,
    canActivate: [AuthGuard],
    data: {
      // breadcrumb: 'Employee',
      breadcrumb: 'User',
    },
  },
  {
    path: 'hxm/recruitment/requisitions/:userid',
    component: MembersComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Employee',
      requisition: true,
    },
  },
  // {
  //   path: 'hxm/positioncontrol',
  //   component: MemberPositionControlComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'teams/positioncontrol',
    component: MemberPositionControlComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'teams/usermapping',
    component: UsermappingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'contacttracing',
    component: ContactsTracingComponent,
    canActivate: [AuthGuard],
  },
  { path: 'message', component: ChatComponent, canActivate: [AuthGuard] },
  // {
  //   path: 'hxm/checkinattendances',
  //   component: CheckInAttendencesComponent,
  //   canActivate: [AuthGuard],
  //   data: {
  //     role: 'hradmin',
  //     shouldReuseRoute: true,
  //     reuseRoutesFrom: [
  //       // 'checkinattendances',
  //       'hxm/checkinattendances/summary/:orgid/:type/:department/:division/:teamid/:costcenter/:subdivision/:date',
  //       'hxm/checkinattendances/daterange/:orgid/:type/:date/:userdata',
  //       'hxm/checkinattendances/task/:orgid/:type/:userdata',
  //     ],
  //   },
  // },
  {
    path: 'timeandattendance/checkinattendances',
    component: CheckInAttendencesComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'hradmin',
      shouldReuseRoute: true,
      reuseRoutesFrom: [
        // 'checkinattendances',
        'hxm/checkinattendances/summary/:orgid/:type/:department/:division/:teamid/:costcenter/:subdivision/:date',
        'hxm/checkinattendances/daterange/:orgid/:type/:date/:userdata',
        'hxm/checkinattendances/task/:orgid/:type/:userdata',
      ],
    },
  },
  {
    path: 'checkinattendances',
    component: CheckInAttendencesComponent,
    canActivate: [AuthGuard],
    data: {
      shouldReuseRoute: true,
      reuseRoutesFrom: [
        // 'checkinattendances',
        'checkinattendances/summary/:orgid/:type/:department/:division/:teamid/:costcenter/:subdivision/:date',
        'checkinattendances/daterange/:orgid/:type/:date/:userdata',
        'checkinattendances/task/:orgid/:type/:userdata',
      ],
    },
  },
  {
    path: 'redirect',
    redirectTo: 'checkinattendances',
  },
  {
    path: 'checkin-qr/:qrdata',
    component: CheckinQrComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'hxm/checkinattendances/summary/:orgid/:type/:department/:division/:teamid/:costcenter/:subdivision/:date',
    component: CheckInAttendancesDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'hradmin',
    },
  },
  {
    path: 'hxm/checkinattendances/daterange/:orgid/:type/:date/:userdata',
    component: CheckInDaterangeDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'hradmin',
    },
  },
  {
    path: 'hxm/checkinattendances/task/:orgid/:type/:userdata',
    component: CheckInProjectDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'hradmin',
    },
  },
  {
    path: 'checkinattendances/summary/:orgid/:type/:department/:division/:teamid/:costcenter/:subdivision/:date',
    component: CheckInAttendancesDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'checkinattendances/daterange/:orgid/:type/:date/:userdata',
    component: CheckInDaterangeDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'checkinattendances/task/:orgid/:type/:userdata',
    component: CheckInProjectDetailsComponent,
    canActivate: [AuthGuard],
  },
  // Check In For Members
  {
    path: 'checkinmembers',
    component: CheckInMembersComponent,
    canActivate: [AuthGuard],
  },
  { path: 'checkin', component: CheckInComponent, canActivate: [AuthGuard] },
  { path: 'create-post', component: PostsComponent, canActivate: [AuthGuard] },
  { path: 'posts', component: SocialPostsComponent, canActivate: [AuthGuard] },
  {
    path: 'event/attendance',
    component: AttendancesComponent,
    canActivate: [AuthGuard],
  },
  { path: 'counts', component: CountsComponent, canActivate: [AuthGuard] },

  // {
  //   path: 'hxm/hierarchy',
  //   component: HierarchyComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'teams/hierarchy',
    component: HierarchyComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'admin/campaign/:camid',
    component: VotingViewComponent,
    data: {
      shouldReuseRoute: true,
      reuseRoutesFrom: [
        'admin/campaign/:camid',
        'admin/campaign/:camid/:roundid/summary',
        'admin/campaign/:camid/voter',
      ],
    },
  },
  {
    path: 'admin/campaign/:camid/:roundid/summary',
    component: VotingRoundSummaryComponent,
  },
  {
    path: 'admin/campaign/:camid/voter',
    component: VotersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/campaign/:camid/:roundid/summary/voters',
    component: VotedListComponent,
  },

  {
    path: 'admin/campaign',
    component: CampaignComponent,
  },

  // { path: 'payroll', loadChildren: '../../projects/payroll/src/app/app.module#PayrollSharedModule' },

  // { path: 'posts/details', component: SocialPostsDetailComponent, canActivate: [AuthGuard] },

  // {
  //   path: 'domain/notification',
  //   component: NotificationComponent,
  //   canActivate: [AuthGuard],
  //   data: { type: 'domain' },
  // },
  {
    path: 'references/notification',
    component: NotificationComponent,
    canActivate: [AuthGuard],
    data: { type: 'domain' },
  },

  {
    path: 'teams/notification',
    component: NotificationComponent,
    canActivate: [AuthGuard],
    data: { type: 'org' },
  },

  {
    path: 'service',
    component: CrmDashboardServiceComponent,
    canActivate: [AuthGuard],
    data: { type: 'org' },
  },
  {
    path: 'sales',
    component: CrmDashboardComponent,
    canActivate: [AuthGuard],
    data: { type: 'org' },
  },
  {
    path: 'sales/service/detaillist',
    component: ExportDetailListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sales/pipelines',
    component: CrmPipelineComponent,
    canActivate: [AuthGuard],
    data: { type: 'org' },
  },
  {
    path: 'sales/customers',
    component: CrmCustomerComponent,
    canActivate: [AuthGuard],
    data: { type: 'org' },
  },
  {
    path: 'service/customers',
    component: CrmCustomerComponent,
    canActivate: [AuthGuard],
    data: { type: 'org' },
  },
  {
    path: 'sales/leads',
    component: LeadComponent,
    canActivate: [AuthGuard],
    data: { type: 'org' },
  },
  {
    path: 'sales/deals',
    component: DealComponent,
    canActivate: [AuthGuard],
    data: { type: 'org' },
  },
  {
    path: 'service/service-tickets',
    component: CrmComponent,
    canActivate: [AuthGuard],
    data: { type: 'org' },
  },
  // summary
  {
    path: 'service/summary',
    component: CrmSummaryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'service/serviceoverage',
    component: ServiceOverageSetupComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'service/serviceoveragereport',
    component: ServiceOverageReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'service/servicemanhourdetailreport',
    component: ManhourDetailReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'service/servicereport',
    component: ServiceMonthlyReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sales/service/products',
    component: CrmProductComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sales/service/pic',
    component: CrmPersonInChargeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'service/servicetype',
    component: CrmServiceFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'service/status',
    component: CrmStatusFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'service/customerinfo',
    component: CrmCustomerInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sales/service/workflow',
    component: CrmWorkFlowComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sales/customergroup',
    component: CrmCustomerGroupComponent,
    canActivate: [AuthGuard],
    data: { type: 'org' },
  },
  {
    path: 'contributor/crm',
    component: CrmComponent,
    canActivate: [AuthGuard],
    data: { type: 'contributor' },
  },

  {
    path: 'teams/events',
    component: EventsComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'org/projects',
    component: ProjectsComponent,
    canActivate: [AuthGuard],
    data: { type: 'org' },
  },

  {
    path: 'teams/projects',
    component: ProjectsComponent,
    canActivate: [AuthGuard],
    data: { type: 'org' },
  },

  {
    path: 'contributor/projects',
    component: ProjectsComponent,
    canActivate: [AuthGuard],
    data: { type: 'contributor' },
  },
  {
    path: 'teams/jobs',
    component: JobsComponent,
    canActivate: [AuthGuard],
    data: { type: 'org' },
  },
  {
    path: 'contributor/jobs',
    component: JobsComponent,
    canActivate: [AuthGuard],
    data: { type: 'contributor' },
  },

  // Attendance Qr
  {
    path: 'teams/attendanceqr',
    component: AttendanceQrComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'attendanceqr/:domainid/:orgid/:qrid',
    component: AttendanceQrViewComponent,
    // canActivate: [AuthGuard],
  },

  //Coupon
  {
    path: 'contributor/project-report',
    component: ProjectReportComponent,
    canActivate: [AuthGuard],
    data: {
      shouldReuseRoute: true,
      reuseRoutesFrom: [
        'contributor/project-report/task/:orgid/:type/:userdata',
      ],
    },
  },

  {
    path: 'contributor/project-report/task/:orgid/:type/:userdata',
    component: ProjectReportDetailComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'logistics',
    component: LogisticsDashboardComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'logistics/order',
    component: LogisticsOrderComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'logistics/batch',
    component: LogisticsBatchComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'logistics/assign',
    component: LogisticsAssignComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'adminconsole/office',
    component: OfficeDashboardComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'office',
    component: OfficeDashboardComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'requestapproval/requesttype',
    component: RequestTypeComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },
  {
    path: 'hxm/requesttype/leavepolicy',
    component: OfficeLeavePolicyComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'office/requesttype',
    component: RequestTypeComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'office/requesttype/leavepolicy',
    component: OfficeLeavePolicyComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'requestapproval/leaveentitlement',
    component: OfficeLeaveEntitlementComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'office/leaveentitlement',
    component: OfficeLeaveEntitlementComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'requestapproval/leavetemplate',
    component: OfficeLeaveTemplateComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'office/leavetemplate',
    component: OfficeLeaveTemplateComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'requestapproval/leavecalendar',
    component: OfficeLeaveCalendarComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'office/leavecalendar',
    component: OfficeLeaveCalendarComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'requestapproval/leaveopening',
    component: OfficeLeaveOpeningComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
      shouldReuseRoute: true,
      reuseRoutesFrom: [
        'requestapproval/leaveopening/form/:data',
        'requestapproval/leaveopening/history/:data',
      ],
    },
  },

  {
    path: 'office/leaveopening',
    component: OfficeLeaveOpeningComponent,
    canActivate: [AuthGuard],
    data: {
      shouldReuseRoute: true,
      reuseRoutesFrom: [
        'office/leaveopening/form/:data',
        'office/leaveopening/history/:data',
      ],
    },
  },

  {
    path: 'requestapproval/leaveopening/form/:data',
    component: OfficeLeaveOpeningFormComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'requestapproval/leaveopening/history/:data',
    component: OfficeLeaveOpeingHistoryComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'office/leaveopening/form/:data',
    component: OfficeLeaveOpeningFormComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'office/leaveopening/history/:data',
    component: OfficeLeaveOpeingHistoryComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'timeandattendance/holiday',
    component: OfficeHolidayComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'office/holiday',
    component: OfficeHolidayComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'office/request',
    component: OfficeUserRequestComponent,
    canActivate: [AuthGuard],
  },

  // {
  //   path: 'hxm/approval',
  //   component: OfficeApprovalComponent,
  //   canActivate: [AuthGuard],
  //   data: {
  //     role: 'admin',
  //   },
  // },
  {
    path: 'requestapproval/approval',
    component: OfficeApprovalComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'office/approval',
    component: OfficeApprovalComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'timeandattendance/workinghour',
    component: OfficeWorkingHourComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'office/workinghour',
    component: OfficeWorkingHourComponent,
    canActivate: [AuthGuard],
  },

  // {
  //   path: 'hxm/leave-summary-report',
  //   component: LeaveSummaryReportComponent,
  //   canActivate: [AuthGuard],
  //   data: {
  //     role: 'admin',
  //   },
  // },
  {
    path: 'timeandattendance/leave-summary-report',
    component: LeaveSummaryReportComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'office/leave-summary-report',
    component: LeaveSummaryReportComponent,
    canActivate: [AuthGuard],
  },

  // {
  //   path: 'hxm/serviceyears',
  //   component: OfficeAnniversaryComponent,
  //   canActivate: [AuthGuard],
  //   data: {
  //     role: 'admin',
  //   },
  // },
  {
    path: 'teams/serviceyears',
    component: OfficeAnniversaryComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'office/shift',
    component: OfficeShiftComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'timeandattendance/shift',
    component: OfficeShiftComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'office/roster',
    component: OfficeRosterComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'timeandattendance/roster',
    component: OfficeRosterComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'office/calendar',
    component: OfficeCalendarComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'timeandattendance/calendar',
    component: OfficeCalendarComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'office/workpolicy',
    component: OfficeWorkpolicyComponent,
    canActivate: [AuthGuard],
  },

  // {
  //   path: 'hxm/workpolicy',
  //   component: OfficeWorkpolicyComponent,
  //   canActivate: [AuthGuard],
  //   data: {
  //     role: 'admin',
  //   },
  // },

  {
    path: 'teams/workpolicy',
    component: OfficeWorkpolicyComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'office/serviceyears',
    component: OfficeAnniversaryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'requestapproval/calculateleave',
    component: OfficeCalculateLeaveComponent,
    canActivate: [AuthGuard],
  },

  // {
  //   path: 'hxm/attendanceid',
  //   component: EmployeeidMappingComponent,
  //   canActivate: [AuthGuard],
  //   data: {
  //     role: 'admin',
  //   },
  // },
  {
    path: 'timeandattendance/attendanceid',
    component: EmployeeidMappingComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'office/request',
    component: OfficeUserRequestComponent,
    canActivate: [AuthGuard],
    data: {
      requesttypeid: '',
    },
  },
  {
    path: 'office/leave',
    component: OfficeUserRequestComponent,
    canActivate: [AuthGuard],
    data: {
      requesttypeid: 'NCMLEIWHRNVIE',
    },
  },

  {
    path: 'office/workfromhome',
    component: OfficeUserRequestComponent,
    canActivate: [AuthGuard],
    data: {
      requesttypeid: 'EUOMXJSOQNCUWI',
    },
  },
  {
    path: 'office/off-in-lieu',
    component: OfficeUserRequestComponent,
    canActivate: [AuthGuard],
    data: {
      requesttypeid: 'OMCHDFYUEJBAWCQ',
    },
  },

  {
    path: 'office/late',
    component: OfficeUserRequestComponent,
    canActivate: [AuthGuard],
    data: {
      requesttypeid: 'KDIEVNEIADEOHDWJ',
    },
  },

  {
    path: 'office/earlyout',
    component: OfficeUserRequestComponent,
    canActivate: [AuthGuard],
    data: {
      requesttypeid: 'IUEBCHDUWKNSGP',
    },
  },

  {
    path: 'office/late/backdate',
    component: LateEarlyoutDataFormComponent,
    canActivate: [AuthGuard],
    data: {
      requesttypeid: 'KDIEVNEIADEOHDWJ',
      isLateRequest: true,
    },
  },

  {
    path: 'office/earlyout/backdate',
    component: LateEarlyoutDataFormComponent,
    canActivate: [AuthGuard],
    data: {
      requesttypeid: 'IUEBCHDUWKNSGP',
      isLateRequest: false,
    },
  },

  {
    path: 'office/late-earlyout-data-form',
    component: LateEarlyoutDataFormComponent,
    canActivate: [AuthGuard],
    data: {
      requesttypeid: 'NCMLEIWHRNVIE',
    },
  },

  {
    path: 'office/claim',
    component: OfficeUserRequestComponent,
    canActivate: [AuthGuard],
    data: { requesttypeid: 'WOEIEPPLMXNW' },
  },

  { path: 'qr360', component: QrListViewComponent, canActivate: [AuthGuard] },

  {
    path: 'hxm',
    component: EmployeeCentralComponent,
    canActivate: [AuthGuard],
  },

  // {
  //   path: 'apprestriction',
  //   component: AppRestrictionComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'references/apprestriction',
    component: AppRestrictionComponent,
    canActivate: [AuthGuard],
  },

  // transporation
  {
    path: 'transportation/driver',
    component: DriverComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'transportation/vehicles',
    component: VehicleComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'transportation/pickuppoints',
    component: PickupPointComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'transportation/dropoffpoints',
    component: DropoffPointComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'transportation/carwayschedules',

    component: CarWayScheduleComponent,

    canActivate: [AuthGuard],

    data: {
      shouldReuseRoute: true,

      reuseRoutesFrom: ['transportation/carwayschedules/:carwayid'],
    },
  },

  {
    path: 'transportation/carwayschedules/:carwayid',

    component: CarWayScheduleDetailComponent,

    canActivate: [AuthGuard],
  },
  {
    path: 'transportation/ferryrequests',
    component: FerryRequestComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'transportation/dailyrequests',
    component: DailyRequestComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'transportation',
    component: TransportationDashboardComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'transportation/outstandingrequests',
    component: OutstandingRequestsComponent,
    canActivate: [AuthGuard],
  },

  // Late and earlyout policy
  {
    path: 'lateandearlyout',
    component: LateAndEarlyoutPolicyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'timeandattendance/lateandearlyout',
    component: LateAndEarlyoutPolicyComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },
  {
    path: 'latereport',
    component: LateAndEarlyoutReportComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'hxm/latereport',
  //   component: LateAndEarlyoutReportComponent,
  //   canActivate: [AuthGuard],
  //   data: {
  //     role: 'admin',
  //   },
  // },
  {
    path: 'timeandattendance/latereport',
    component: LateAndEarlyoutReportComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  // Earlyout report
  {
    path: 'earlyoutreport',
    component: EarlyoutReportComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'hxm/earlyoutreport',
  //   component: EarlyoutReportComponent,
  //   canActivate: [AuthGuard],
  //   data: {
  //     role: 'admin',
  //   },
  // },
  {
    path: 'timeandattendance/earlyoutreport',
    component: EarlyoutReportComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  // Workpolicy config
  {
    path: 'workpolicyconfig',
    component: WorkplicyConfigComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'timeandattendance/workpolicyconfig',
    component: WorkplicyConfigComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  // Requisition template
  {
    path: 'timeandattendance/requisitiontemplates',
    component: EmployeeRequestionComponent,
    canActivate: [AuthGuard],
    data: { role: 'admin' },
  },

  //Requisition request
  {
    path: 'office/requisitionrequest',
    component: RequisitionRequestComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  //Requisition approval
  // {
  //   path : 'hxm/requisitionapproval',
  //   component : RequisitionApprovalComponent,
  //   canActivate : [AuthGuard],
  //   data : {
  //     role : 'admin'
  //   }
  // },

  // Requisition Roles
  {
    path: 'timeandattendance/requisition-posts',
    component: RequisitionRolesComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  // Requisition approval
  {
    path: 'office/requisitionapproval',
    component: RequisitionApprovalComponent,
    canActivate: [AuthGuard],
    data: { role: 'admin' },
  },

  // hr requisition
  // {
  //   path: 'hxm/recruitment',
  //   component: RecruitmentsComponent,
  //   canActivate: [AuthGuard],
  //   data: { role: 'admin' },
  // },
  {
    path: 'teams/recruitment',
    component: RecruitmentsComponent,
    canActivate: [AuthGuard],
    data: { role: 'admin' },
  },

  // {
  //   path: 'hxm/recruitment/requisitions',
  //   component: HrRequisitionComponent,
  //   canActivate: [AuthGuard],
  //   data: {
  //     role: 'admin',
  //     shouldReuseRoute: true,
  //     reuseRoutesFrom: [
  //       'hxm/recruitment/requisitions/:userid',
  //     ],
  //   },
  // },

  {
    path: 'teams/recruitment/requisitions',
    component: HrRequisitionComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
      shouldReuseRoute: true,
      reuseRoutesFrom: ['hxm/recruitment/requisitions/:userid'],
    },
  },

  {
    path: 'teams/recruitment/reasons',
    component: ReasonComponent,
    canActivate: [AuthGuard],
    data: { role: 'admin' },
  },
  // Absentreport
  // {
  //   path: 'hxm/absentreport',
  //   component: AbsentreportComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'timeandattendance/absentreport',
    component: AbsentreportComponent,
    canActivate: [AuthGuard],
  },

  // room and desk
  {
    path: 'references/rooms',
    component: OfficeRoomsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'references/desks',
    component: OfficeDesksComponent,
    canActivate: [AuthGuard],
  },

  // attendance redirect
  { path: 'attendance', redirectTo: 'attendance/checkin' },

  {
    path: 'attendance/checkin',
    component: CheckInComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'attendance/locations',
    component: LocationsComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'attendance/office',
    component: OfficeDashboardComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'attendance/office/leave',
    component: OfficeUserRequestComponent,
    canActivate: [AuthGuard],
    data: {
      requesttypeid: 'NCMLEIWHRNVIE',
    },
  },

  {
    path: 'attendance/office/approval',
    component: OfficeApprovalComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'attendance/adminconsole',
    component: AdminDashboardComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'attendance/hxm',
    component: EmployeeCentralComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'attendance/hxm/employees',
    component: MembersComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Employee',
    },
  },

  {
    path: 'attendance/hxm/hierarchy',
    component: HierarchyComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'attendance/hxm/positioncontrol',
    component: MemberPositionControlComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'attendance/hxm/leaveentitlement',
    component: OfficeLeaveEntitlementComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'attendance/hxm/leaveopening',
    component: OfficeLeaveOpeningComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
      shouldReuseRoute: true,
      reuseRoutesFrom: [
        'attendance/hxm/leaveopening/form/:data',
        'attendance/hxm/leaveopening/history/:data',
      ],
    },
  },

  {
    path: 'attendance/hxm/leaveopening/form/:data',
    component: OfficeLeaveOpeningFormComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'attendance/hxm/leaveopening/history/:data',
    component: OfficeLeaveOpeingHistoryComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'attendance/hxm/workpolicy',
    component: OfficeWorkpolicyComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'attendance/hxm/approval',
    component: OfficeApprovalComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'attendance/hxm/checkinattendances',
    component: CheckInAttendencesComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'hradmin',
      shouldReuseRoute: true,
      reuseRoutesFrom: [
        // 'checkinattendances',
        'attendance/hxm/checkinattendances/summary/:orgid/:type/:department/:division/:teamid/:costcenter/:subdivision/:date',
        'attendance/hxm/checkinattendances/daterange/:orgid/:type/:date/:userdata',
        'attendance/hxm/checkinattendances/task/:orgid/:type/:userdata',
      ],
    },
  },

  {
    path: 'attendance/hxm/checkinattendances/summary/:orgid/:type/:department/:division/:teamid/:costcenter/:subdivision/:date',
    component: CheckInAttendancesDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'hradmin',
    },
  },
  {
    path: 'attendance/hxm/checkinattendances/daterange/:orgid/:type/:date/:userdata',
    component: CheckInDaterangeDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'hradmin',
    },
  },
  {
    path: 'attendance/hxm/checkinattendances/task/:orgid/:type/:userdata',
    component: CheckInProjectDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'hradmin',
    },
  },

  {
    path: 'attendance/hxm/leave-summary-report',
    component: LeaveSummaryReportComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'attendance/hxm/leavetemplate',
    component: OfficeLeaveTemplateComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'attendance/hxm/leavecalendar',
    component: OfficeLeaveCalendarComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'attendance/hxm/holiday',
    component: OfficeHolidayComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'attendance/hxm/workpolicyconfig',
    component: WorkplicyConfigComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'attendance/hxm/roster',
    component: OfficeRosterComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'attendance/hxm/shift',
    component: OfficeShiftComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'attendance/hxm/requesttype',
    component: RequestTypeComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'admin',
    },
  },

  {
    path: 'attendance/hxm/timeinoutreport',
    component: TimeInOutReportComponent,
    canActivate: [AuthGuard],
  },

  // time card report
  // {
  //   path: 'hxm/timecard-report',
  //   component: TimecardReportComponent,
  //   canActivate: [AuthGuard],
  // },

  {
    path: 'timeandattendance/timecard-report',
    component: TimecardReportComponent,
    canActivate: [AuthGuard],
  },

  // {
  //   path: 'hxm/late-time-report',
  //   component: LateEarlyoutTimeReportComponent,
  //   canActivate: [AuthGuard],
  //   data: {
  //     isFromLate: true,
  //   },
  // },
  {
    path: 'timeandattendance/late-time-report',
    component: LateEarlyoutTimeReportComponent,
    canActivate: [AuthGuard],
    data: {
      isFromLate: true,
    },
  },

  {
    path: 'timeandattendance/earlyout-time-report',
    component: LateEarlyoutTimeReportComponent,
    canActivate: [AuthGuard],
    data: {
      isFromLate: false,
    },
  },

  {
    path: 'timeandattendance/late-policy-report',
    component: LateEarlyoutReportPolicyComponent,
    canActivate: [AuthGuard],
    data: {
      isFromLate: true,
    },
  },

  {
    path: 'timeandattendance/earlyout-policy-report',
    component: LateEarlyoutReportPolicyComponent,
    canActivate: [AuthGuard],
    data: {
      isFromLate: false,
    },
  },

  {
    path: 'timeandattendance/attendancereport',
    component: CheckinReportComponent,
    canActivate: [AuthGuard],
  },

  // Time in out report
  {
    path: 'timeandattendance/timeinoutreport',
    component: TimeInOutReportComponent,
    canActivate: [AuthGuard],
  },

  // Rules and Regulation
  {
    path: 'references/rules-and-regulations',
    component: RulesAndRegulationComponent,
    canActivate: [AuthGuard],
  },

  // User ID Mapping
  // {
  //   path: 'useridmapping',
  //   component: UseridMappingComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'references/useridmapping',
    component: UseridMappingComponent,
    canActivate: [AuthGuard],
  },

  // Incomplete Report List
  // {
  //   path: 'hxm/incompletereport',
  //   component: IncompleteReportComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'timeandattendance/incompletereport',
    component: IncompleteReportComponent,
    canActivate: [AuthGuard],
  },

  // Incomplete Report List
  // {
  //   path: 'hxm/leavecountreport',
  //   component: LeaveCountReportComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'timeandattendance/leavecountreport',
    component: LeaveCountReportComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'teams/device-info',
    component: DeviceIdComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'cs365',
    component: Cs365DashboardComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'cs365/service-tickets',
    component: Cs365ServiceTicketsComponent,
    canActivate: [AuthGuard],
    data: { type: 'org' },
  },
  {
    path: 'cs365/label',
    component: LabelSetupComponent,
    canActivate: [AuthGuard],
    data: { type: 'org' },
  },

  {
    path: 'requestapproval/hr-request',
    component: AccessoriesRequestComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'office/hr-request',
    component: AccessoriesRequestComponent,
    canActivate: [AuthGuard],
    data: { isFromOffice: true },
  },

  {
    path: 'requestapproval/hr-approval',
    component: AccessoriesApprovalComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'office/hr-approval',
    component: AccessoriesApprovalComponent,
    canActivate: [AuthGuard],
    data: { isFromOffice: true },
  },

  {
    path: 'hxm/pay-period',
    component: PayPeriodComponent,
    canActivate: [AuthGuard],
  },

  // start here

  {
    path: 'teams',
    component: TeamDashboardComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'timeandattendance',
    component: TeamDashboardComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'requestapproval',
    component: TeamDashboardComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'employeecentral',
    component: TeamDashboardComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'references',
    component: TeamDashboardComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'rolemanagement',
    component: TeamDashboardComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'cs365/warranty',
    component: WarrantyComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'cs365/products',
    component: CrmProductComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cs365/service-warranty',
    component: ServiceWarrantyComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'office/cash-request',
    component: OfficeUserRequestComponent,
    canActivate: [AuthGuard],
    data: { requesttypeid: 'QINDJSUWOMXSA' },
  },
  {
    path: 'office/cash-approval',
    component: OfficeApprovalComponent,
    canActivate: [AuthGuard],
    data: { requesttypeid: 'QINDJSUWOMXSA' },
  },
  {
    path: 'members',
    component: MemberDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'payment',
    component: MemberDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'swim',
    component: MemberDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'gym',
    component: MemberDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'leave',
    component: MemberDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'members/register',
    component: MemberRequestlistComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "members/monthlyfeeslist",
    component: MonthlyfeeListComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'members/payment',
    component: MemberPaymentComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'members/approval',
    component: MemberApprovalListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'members/list',
    component: MemberListComponent,

    canActivate: [AuthGuard],
  },
  {
    path: 'members/userregister',
    component: RegisterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/userregister-status',
    component: PublicRegisterStatusComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/summerswimmer',
    component: SummerSwimmerListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/summer-swim-form',
    component: SummerSwimFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/summerswimmer/new',
    component: SummerSwimFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/summerswimmer/:id/detail',
    component: SummerSwimmerDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/summerswimmer/preview/:id',
    component: SummerSwimmerPreviewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/specialswim',
    component: SpecialSwimListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/special-swim-form',
    component: SpecialSwimFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/specialswim/new',
    component: SpecialSwimFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/specialswim/:id/detail',
    component: SpecialSwimDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/specialswim/preview/:id',
    component: SpecialSwimmerPreviewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/templist',
    component: TempSwimmerListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/temp-swim-form',
    component: TempSwimFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/templist/:id/detail',
    component: TempDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/templist/preview/:id',
    component: TempSwimmerPreviewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/templist/new',
    component: TempSwimFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/gymlist',
    component: GymListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/gym-form',
    component: GymFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/gymlist/new',
    component: GymFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/gymlist/:id/detail',
    component: GymDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/gymlist/preview/:id',
    component: GymMemberPreviewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/paymentinvoice/new',
    component: GeneralPaymentInvoiceFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/paymentinvoice',
    component: GeneralPaymentInvoiceListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/paymentinvoice/edit/:paymentid',
    component: GeneralPaymentInvoiceUpdateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/paymentinvoice/:paymentid',
    component: GeneralPaymentDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/leave',
    component: LeaveFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'members/leavelist',
    component: LeaveListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'members/leavelist/:leaveid/detail',
    component: LeaveDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/leavelist/new',
    component: LeaveFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/guest-form',
    component: GuestFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/guestlist',
    component: GuestListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/guestlist/new',
    component: GuestFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/guestlist/:id/detail',
    component: GuestDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/batchlist',
    component: BatchListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/batchlist/new',
    component: BatchFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/batchlist/:id/detail',
    component: BatchDetailComponent,
    canActivate: [AuthGuard]
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  // imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'}), PayrollSharedModule.forRoot()],
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CurtomRouteReuseStrategy,
    },
  ],
})
export class AppRoutingModule { }
