import { formatDate } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, SingleDataSet, Color } from 'ng2-charts';
import { Subscription } from 'rxjs';
import { FileUploadComponent } from 'src/app/shared/components/file-upload/file-upload.component';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { CheckInProjectDaterangeComponent } from '../check-in-project-daterange/check-in-project-daterange.component';
import { CheckinTicketDetailsComponent } from '../checkin-ticket-details/checkin-ticket-details.component';
import { LeaveAbsenceDetailViewComponent } from '../leave-absence-detail-view/leave-absence-detail-view.component';
import { Workbook } from 'exceljs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-check-in-attendences',
  templateUrl: './check-in-attendences.component.html',
  styleUrls: ['./check-in-attendences.component.css'],
})
export class CheckInAttendencesComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    // scales: { yAxes: [{}], xAxes: [{}] },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 5,
            // Create scientific notation labels
            // callback: function(value, index, values) {
            //     return value + ' €';
            // }
          },
        },
      ],
      xAxes: [
        {
          // categoryPercentage: 1.0,
          // barPercentage: 0.6
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
    legend: {
      position: 'right',
      labels: {
        fontSize: 13,
        fontFamily: 'Ubuntu',
        padding: 20,
        usePointStyle: true,
      },
    },
  };

  public barChartLabels: Label[] = [''];
  public barChartType: ChartType = 'bar';
  // public barChartType: ChartType = 'horizontalBar';
  public barChartLegend = true;
  public barChartPlugins = [];
  locations: any = [
    {
      name: 'All',
      locid: '',
    },
  ];
  typeList: any = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'Time In/Out',
      value: 'inout',
    },
    {
      name: 'Check In',
      value: 'checkin',
    },
  ];
  gettingLocList: boolean = false;

  public barChartData: ChartDataSets[] = [
    { data: [0], label: 'Total' },
    { data: [0], label: 'Time In' },
    { data: [0], label: 'Time Out' },
    { data: [0], label: 'Check In' },
    { data: [0], label: 'Activity' },
    { data: [0], label: '' },
  ];

  // public barChartColors = [
  //   {
  //     backgroundColor: ['#11698E', '#089302', 'red'],
  //   },
  // ];
  public barChartColors: Color[] = [
    { backgroundColor: '#11698E' },
    { backgroundColor: 'green' },
    { backgroundColor: '#01ff01' },
    { backgroundColor: 'red' },
    { backgroundColor: 'grey' },
    { backgroundColor: '#ee9b00' },
    { backgroundColor: '#d5bdaf' },
  ];

  public barChartCountColors: Color[] = [
    // { backgroundColor: '#11698E' },
    { backgroundColor: 'green' },
    { backgroundColor: 'red' },
    { backgroundColor: 'grey' },
    { backgroundColor: '#ee9b00' },
    { backgroundColor: '#d5bdaf' },
  ];

  dayNames = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];
  displayMode = 'Summary';

  tasktype = 'project';
  formatToShow = 'yyyy-MM-dd';
  piedateFormat = 'dd/MM/yyyy';
  dateFormat = 'yyyyMMdd';
  timeFormat = 'hh:mm:ss';
  apitimeFormat = 'hh:mm:ss aa';

  p: number = 1;
  currentPageAS: number = 1;
  currentPageLoc: number = 1;
  q: number = 1;
  itemPerPageDR: number = 100;
  itemPerPageLoc: number = 100;
  itemPerPagePJ: number = 100;
  itemPerPageAS: number = 100;
  mobileViewWidth: any = 426;
  IDText: string = 'User ID';
  IDPlaceholder: string = 'Enter User Name';
  AuthPlaceholder: string = 'User ID';

  memberfocus: boolean = false;

  generalDatePickerConfig: any = this.allinoneService.datePickerConfig;

  // searchLoading: boolean = false;
  searchLoadingSummary: boolean = false;
  searchLoadingDR: boolean = false;
  searchLoadingAR: boolean = false;
  searchLoadingLOC: boolean = false;
  searchLoadingProject: boolean = false;
  searchLoadingMember: boolean = false;

  gettingSummary: boolean = false;
  gettingDaterangeData: boolean = false;
  gettingLocData: boolean = false;
  gettingProjData: boolean = false;
  gettingProjects: boolean = false;
  gettingJobs: boolean = false;
  formSubmitted: boolean = false;
  isFocusDR: boolean = false;
  isFocusAS: boolean = false;
  isFocusPJ: boolean = false;
  isFocusLOC: boolean = false;
  isMobileView: boolean = false;
  isuserID: boolean = true;
  gettingMemberPosition: boolean = false;
  viewAllDRData: boolean = true;
  isAdmin: boolean = false;

  organizations: any[] = [];
  daterangeData: any[] = [];
  absentData: any[] = [];
  daterangeShowData: any[] = [];
  locShowData: any[] = [];
  projData: any[] = [];
  projects: any[] = [
    {
      name: '-',
      projectid: '',
    },
  ];
  jobs: any[] = [
    {
      name: '-',
      jobid: '',
    },
  ];
  dateArray = [''];
  sumType: any[] = ['Employee', 'Counts'];

  statusInterval: any;

  domainid: string = this.allinoneService.getDomain().domainid;
  importData: any;
  importStatus: string = '000'; //000- Server Error, 001-Success, 002-Progress, 003-Upload, 004-Error, 005-Cancel
  refreshingImportStatus: boolean = false;

  subscriptions = new SubSink();
  // key: any = '';
  // start: number = 0;
  // end: number = Number(this.itemPerPageLoc);
  // filename: string = '';
  locTotal: number = 0;
  // locfileurl: string = '';
  // locsearchfilename: string = '';
  // locsearchfileurl: string = '';
  // isSearch: boolean = false;

  summaryData = {
    total: '',
    timein: '',
    timeout: '',
    usertimein: '',
    useractive: '',
    usertimeout: '',
    checkin: '',
    usercheckin: '',
    activity: '',
    useractivity: '',
    other: '',
    userother: '',
    notimein: '',
    noactivity: '',
    leave: '',
  };

  daterangePG = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];

  locPG = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];
  absentPG = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];

  projectPG = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];

  departmentList: string[] = ['All'];
  divisionList: string[] = ['All'];
  teamidList: string[] = ['All'];
  costCenterList: string[] = ['All'];
  subDivisionList: string[] = ['All'];

  summaryStartDate: any = '';

  selectedOrg: any = '';
  selectedOrg1: any = '';
  selectedOrg2: any = '';
  selectedOrg3: any = '';
  searchTextPJ: any = '';
  searchTextDR: any = '';
  searchTextAS: any = '';
  searchTextLOC: any = '';
  projStartDate: any = '';
  projEndDate: any = '';

  nextDisabled: boolean = false;
  lastPage: any = '';
  primaryKey: any = '';
  currentPage: number = 1;
  isrefresh: boolean = false;
  itemPerPage: any = 100;

  // d = new Date();
  // month = (this.d.getMonth() + 1).toString().padStart(2, '0');
  // day = this.d.getDate().toString().padStart(2, '0');
  // todayDate = '' + this.d.getFullYear() + this.month + this.day;
  //for filter date
  // aweekBefore = new Date(this.d).setDate(new Date(this.d).getDate() - 7);
  // aweekBeforeDate = new Date(this.aweekBefore);
  // checkInStartDate =
  //   this.aweekBeforeDate.getFullYear() +
  //   (this.aweekBeforeDate.getMonth() + 1).toString().padStart(2, '0') +
  //   this.aweekBeforeDate.getDate().toString().padStart(2, '0') +
  //   '';
  // checkInEndDate = this.d.getFullYear() + this.month + this.day + '';
  //show today date
  // showTodayDate =
  //   this.day +
  //   '/' +
  //   this.month +
  //   '/' +
  //   this.d.getFullYear() +
  //   ' ' +
  //   this.dayNames[this.d.getDay()];
  // dateRangeStartDate: any = '';
  // minDate = '';

  // currentEndDate = formatDate(
  //   Date.now(),
  //   this.formatToShow,
  //   'en-US'
  // ).toString();
  currentEndDate = new Date(Date.now());


  // currentStartDate = formatDate(
  //   new Date(this.aweekBefore),
  //   this.formatToShow,
  //   'en-US'
  // ).toString();
  tdyDate = formatDate(new Date(), this.formatToShow, 'en-US').toString();

  atFormDate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
  atFormStartTime = formatDate(Date.now(), this.timeFormat, 'en-US').toString();
  // atFormEndTime = formatDate(
  //   new Date(this.d).setHours(new Date(this.d).getHours() + 1),
  //   this.timeFormat,
  //   'en-US'
  // ).toString();

  // websockect
  // connectSocketLoading: boolean = true;
  connectSocketLoadingDR: boolean = true;
  connectSocketLoadingAR: boolean = true;
  connectSocketLoadingLOC: boolean = true;
  connectSocketLoadingSummary: boolean = true;
  connectSocketLoadingMember: boolean = true;

  // private socket: WebSocket | undefined;
  private socketDR: WebSocket | undefined;
  private socketAR: WebSocket | undefined;
  private socketLOC: WebSocket | undefined;
  private socketSummary: WebSocket | undefined;
  private socketMember: WebSocket | undefined;

  // public socketConnectionStatus: string = 'disconnected';
  public socketConnectionStatusDR: string = 'disconnected';
  public socketConnectionStatusAR: string = 'disconnected';
  public socketConnectionStatusLOC: string = 'disconnected';
  public socketConnectionStatusSummary: string = 'disconnected';
  public socketConnectionStatusMember: string = 'disconnected';

  // public socketReceivedMessage: string = '';
  public socketReceivedMessageDR: string = '';
  public socketReceivedMessageAR: string = '';
  public socketReceivedMessageLOC: string = '';
  public socketReceivedMessageSummary: string = '';
  public socketReceivedMessageMember: string = '';

  // connectionId: string = '';
  connectionIdDR: string = '';
  connectionIdAR: string = '';
  connectionIdLOC: string = '';
  connectionIdSummary: string = '';
  connectionIdMember: string = '';

  // drsocketfileurl: any = '';
  drsocketfileurlDR: any = '';
  drsocketfileurlAR: any = '';
  drsocketfileurlLOC: any = '';
  drsocketfileurlMember: any = '';

  // updateCId: boolean = false;
  updateCIdDR: boolean = false;
  updateCIdAR: boolean = false;
  updateCIdLOC: boolean = false;
  updateCIdSummary: boolean = false;
  updateCIdMember: boolean = false;

  // socketConnectTime: any;
  socketConnectTimeDR: any;
  socketConnectTimeAR: any;
  socketConnectTimeLOC: any;
  socketConnectTimeSummary: any;
  socketConnectTimeMember: any;

  // Date
  d = new Date();
  dateRangeStartDate: any = '';
  // minDate = '';
  minDate = new Date();
  aweekBefore = new Date(this.d).setDate(new Date(this.d).getDate() - 7);
  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );

  // currentStartDate = formatDate(
  //   new Date(this.aweekBefore),
  //   this.formatToShow,
  //   'en-US'
  // ).toString();

  currentStartDate = new Date(this.aweekBefore);

  userStatusFilterList = this.allinoneService.userStatusFilterList;

  getcheckinForm = {
    startdate:
      this.displayMode == 'Summary'
        ? this.currentEndDate
        : this.currentStartDate,
    enddate: this.currentEndDate,
    domain: this.allinoneService.getDomain().shortcode,
    domainid: this.allinoneService.getDomain().domainid,
    orgid: '',
    orgname: '',
    projindex: '',
    projectname: '-',
    projectid: '',
    jobindex: '',
    jobname: '-',
    jobid: '',
    subtype: 'Employee',
    userid: '',
    department: 'All',
    division: 'All',
    teamid: 'All',
    costcenter: 'All',
    subdivision: 'All',
    status: '001',
    drdepartment: 'All',
    drdivision: 'All',
    drteamid: 'All',
    drcostcenter: 'All',
    drsubdivision: 'All',
    drstatus: '001',
    locname: 'All',
    locid: '',
    type: '',
    typename: 'All',
    membertype: 'User Name',
  };

  dataList: any;
  atFormSubmitted = false;
  sortedArray: any = [];

  summarySubscription!: Subscription;

  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private filter: FilterPipe,
    private http: HttpClient
  ) {
    this.route.data.subscribe((data) => {
      if (data.role == 'hradmin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl(
            this.allinoneService.isAttendanceView ? '/attendance/hxm' : '/hxm'
          );
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.cleanUp();
  }

  cleanUp() {
    this.subscriptions.unsubscribe();
    this.summarySubscription && this.summarySubscription.unsubscribe();
    this.socketDR?.close(3001);
    this.socketAR?.close(3001);
    this.socketLOC?.close(3001);
    this.socketSummary?.close(3001);
    this.socketMember?.close(3001);
    clearInterval(this.statusInterval);
    // clearTimeout(this.socketConnectTime);
    clearTimeout(this.socketConnectTimeDR);
    clearTimeout(this.socketConnectTimeAR);
    clearTimeout(this.socketConnectTimeLOC);
    clearTimeout(this.socketConnectTimeSummary);
    clearTimeout(this.socketConnectTimeMember);
  }

  ngOnInit(): void {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    }
    this.organizations = this.allinoneService.orgs;
    this.getcheckinForm.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;


    this.selectedOrg = this.getcheckinForm.orgid;
    this.selectedOrg1 = this.getcheckinForm.orgid;
    this.selectedOrg2 = this.getcheckinForm.orgid;
    this.getcheckinForm.orgname = this.organizations.filter(
      (x: any) => x.orgid == this.getcheckinForm.orgid
    )[0].name;

    this.summaryStartDate = this.getcheckinForm.startdate;
    this.dateRangeStartDate = this.currentStartDate;

    console.log(this.getcheckinForm);
    var currentEndDate = formatDate(
      this.getcheckinForm.startdate,
      this.piedateFormat,
      'en-US'
    ).toString();
    var addDays = 35;
    // var addDays = 31;
    var date = new Date(this.dateRangeStartDate);
    console.log(date);
    date.setTime(date.getTime() + addDays * 24 * 60 * 60 * 1000);
    console.log(date);
    // this.minDate = formatDate(date, this.formatToShow, 'en-US').toString();
    this.minDate = date;

    // var date = new Date(this.getcheckinForm.startdate);

    this.barChartOptions.title = {
      display: true,
      position: 'top',
      fontFamily: 'Ubuntu',
      fontSize: 15,
      text: this.getcheckinForm.orgname + ' - ' + currentEndDate,
      fullWidth: false,
    };
    this.getImportStatus();
    this.statusInterval = setInterval(() => {
      if (
        this.importStatus != '001' &&
        this.importStatus != '005' &&
        this.importStatus != '000'
      ) {
        this.getImportStatus();
      }
    }, 5000);
    this.getMemberPosition();
    // this.getSummary();
    this.connectWebSocketSummary();
    this.closeSocketWithTimeSummary();
    this.getProjects();
    this.getLocation();
  }

  connectWebSocketDateRange() {
    console.log('Calling Websocket');
    this.searchLoadingDR = true;
    this.connectSocketLoadingDR = true;
    const webSocketURL = this.allinoneService.attendanceSocketUrl;

    this.socketDR = new WebSocket(webSocketURL);

    this.socketDR.addEventListener('open', (event) => {
      console.log('WebSocket connection opened!');
      this.connectSocketLoadingDR = false;
      // this.socketDR?.send('001');
      this.socketConnectionStatusDR = 'connected';
    });

    this.socketDR.addEventListener('message', (event) => {
      // console.log('Received message:', event.data);
      this.socketReceivedMessageDR = event.data;

      var tempData = JSON.parse(event.data);

      if (tempData.connectionId) {
        this.connectionIdDR = tempData.connectionId;

        if (this.updateCIdDR) {
          this.updateConnectionID();
        }
        this.searchWithDateRangeWithSocket(false);
      } else {
        this.drsocketfileurlDR = tempData.fileurl;

        if (this.drsocketfileurlDR) {
          this.http.get(this.drsocketfileurlDR).subscribe((res: any) => {
            console.log('Your get data res');
            console.log(res);
            this.daterangeData = res;
            this.daterangeShowData = this.daterangeData;
            this.daterangePG[3].count = res.length;

            this.searchLoadingDR = false;
            this.connectionIdDR = '';
            this.socketDR?.close(3001);
            clearTimeout(this.socketConnectTimeDR);
          });
        }
      }

      console.log('c id');
      console.log(this.connectionIdDR);
    });

    this.socketDR.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      this.socketConnectionStatusDR = 'error';
    });

    this.socketDR.addEventListener('close', (event) => {
      console.log('WebSocket connection closed!');
      console.log(event);
      this.socketConnectionStatusDR = 'disconnected';
      if (event.code != 3001) {
        this.connectionIdDR = '';
        this.updateCIdDR = true;
        this.connectWebSocketDateRange();
      }
    });
  }

  connectWebSocketAbsentReport() {
    console.log('Calling Websocket');
    this.searchLoadingAR = true;
    this.connectSocketLoadingAR = true;
    const webSocketURL = this.allinoneService.attendanceSocketUrl;

    this.socketAR = new WebSocket(webSocketURL);

    this.socketAR.addEventListener('open', (event) => {
      console.log('WebSocket connection opened!');
      this.connectSocketLoadingAR = false;
      // this.socketAR?.send('001');
      this.socketConnectionStatusAR = 'connected';
    });

    this.socketAR.addEventListener('message', (event) => {
      // console.log('Received message:', event.data);
      this.socketReceivedMessageAR = event.data;

      var tempData = JSON.parse(event.data);

      if (tempData.connectionId) {
        this.connectionIdAR = tempData.connectionId;

        if (this.updateCIdAR) {
          this.updateConnectionID();
        }
        this.searchWithDateRangeWithSocket(true);
      } else {
        this.drsocketfileurlAR = tempData.fileurl;

        if (this.drsocketfileurlAR) {
          this.http.get(this.drsocketfileurlAR).subscribe((res: any) => {
            console.log('Your get data res');
            console.log(res);
            this.absentData = res;
            this.daterangePG[3].count = res.length;

            this.searchLoadingAR = false;
            this.connectionIdAR = '';
            this.socketAR?.close(3001);
            clearTimeout(this.socketConnectTimeAR);
          });
        }
      }

      console.log('c id');
      console.log(this.connectionIdAR);
    });

    this.socketAR.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      this.socketConnectionStatusAR = 'error';
    });

    this.socketAR.addEventListener('close', (event) => {
      console.log('WebSocket connection closed!');
      console.log(event);
      this.socketConnectionStatusAR = 'disconnected';
      if (event.code != 3001) {
        this.connectionIdAR = '';
        this.updateCIdAR = true;
        this.connectWebSocketAbsentReport();
      }
    });
  }

  connectWebSocketLocation() {
    console.log('Calling Websocket');
    this.searchLoadingLOC = true;
    this.connectSocketLoadingLOC = true;
    const webSocketURL = this.allinoneService.attendanceSocketUrl;

    this.socketLOC = new WebSocket(webSocketURL);

    this.socketLOC.addEventListener('open', (event) => {
      console.log('WebSocket connection opened!');
      this.connectSocketLoadingLOC = false;
      // this.socketLOC?.send('001');
      this.socketConnectionStatusLOC = 'connected';
    });

    this.socketLOC.addEventListener('message', (event) => {
      // console.log('Received message:', event.data);
      this.socketReceivedMessageLOC = event.data;

      var tempData = JSON.parse(event.data);

      if (tempData.connectionId) {
        this.connectionIdLOC = tempData.connectionId;

        if (this.updateCIdLOC) {
          this.updateConnectionID();
        }
        this.searchWithLoc();
      } else {
        this.drsocketfileurlLOC = tempData.fileurl;

        if (this.drsocketfileurlLOC) {
          this.http.get(this.drsocketfileurlLOC).subscribe((res: any) => {
            console.log('Your get data res');
            console.log(res);
            this.locShowData = res;
            this.locTotal = res.length;
            this.gettingLocData = false;

            this.searchLoadingLOC = false;
            this.connectionIdLOC = '';
            this.socketLOC?.close(3001);
            clearTimeout(this.socketConnectTimeLOC);
          });
        }
      }

      console.log('c id');
      console.log(this.connectionIdLOC);
    });

    this.socketLOC.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      this.socketConnectionStatusLOC = 'error';
    });

    this.socketLOC.addEventListener('close', (event) => {
      console.log('WebSocket connection closed!');
      console.log(event);
      this.socketConnectionStatusLOC = 'disconnected';
      if (event.code != 3001) {
        this.connectionIdLOC = '';
        this.updateCIdLOC = true;
        this.connectWebSocketLocation();
      }
    });
  }

  connectWebSocketMember() {
    console.log('Calling Websocket');
    this.searchLoadingMember = true;
    this.connectSocketLoadingMember = true;
    const webSocketURL = this.allinoneService.attendanceSocketUrl;

    this.socketMember = new WebSocket(webSocketURL);

    this.socketMember.addEventListener('open', (event) => {
      console.log('WebSocket connection opened!');
      this.connectSocketLoadingMember = false;
      // this.socketLOC?.send('001');
      this.socketConnectTimeMember = 'connected';
    });

    this.socketMember.addEventListener('message', (event) => {
      console.log('Received message:', event.data);
      this.socketReceivedMessageMember = event.data;

      var tempData = JSON.parse(event.data);

      if (tempData.connectionId) {
        this.connectionIdMember = tempData.connectionId;

        if (this.updateCIdMember) {
          this.updateConnectionID();
        }
        this.getCheckInList();
      } else {
        this.drsocketfileurlMember = tempData.fileurl;

        if (this.drsocketfileurlMember) {
          this.sortedArray = [];
          this.http.get(this.drsocketfileurlMember).subscribe((res: any) => {
            this.dataList = res.reverse();
            if (this.dataList.length > 0) {
              var userList: any = [];
              for (var user = 0; user < this.dataList.length; user++) {
                var checkUserData: any = userList.filter(
                  (a: any) => a.userid == this.dataList[user].userid
                );

                if (checkUserData.length == 0) {
                  userList.push({
                    userid: this.dataList[user].userid,
                    username: this.dataList[user].username,
                  });
                }
              }
              userList = userList.sort();
              for (var x = 0; x < userList.length; x++) {
                var namedCheckInarray = [];
                this.dateArray = [];

                var userCheckInDataList = this.dataList.filter(
                  (a: any) => a.userid == userList[x].userid
                );

                for (var i = 0; i < userCheckInDataList.length; i++) {
                  var resc: any = this.dateArray.filter(
                    (x) => x == userCheckInDataList[i].date
                  );
                  console.log(resc.length);

                  if (resc.length == 0) {
                    this.dateArray.push(userCheckInDataList[i].date);
                    var ddate = userCheckInDataList[i].date;
                    var y = ddate.slice(0, 4);
                    var m = ddate.slice(4, 6);
                    var day = ddate.slice(6, 9);
                    var sd = new Date(y + '-' + m + '-' + day);
                    var cdata: any = userCheckInDataList.filter(
                      (a: any) => a.date == userCheckInDataList[i].date
                    );
                    var obj = {
                      date: userCheckInDataList[i].date,
                      showdate:
                        day +
                        '/' +
                        m +
                        '/' +
                        y +
                        ' ' +
                        this.dayNames[sd.getDay()],
                      data: cdata,
                      show: true,
                      isoffday: sd.getDay() == 0 || sd.getDay() == 6,
                    };
                    namedCheckInarray.push(obj);
                    namedCheckInarray.sort(
                      // (a:any,b:any)=>parseInt(a.date)<parseInt(b.date)
                      (a: any, b: any) => {
                        {
                          let value = parseInt(b.date) - parseInt(a.date);
                          return value;
                        }
                      }
                    );
                  }
                }

                this.sortedArray.push({
                  userid: userList[x].userid,
                  username: userList[x].username,
                  datalist: namedCheckInarray,
                  show: false,
                });

                if (this.sortedArray.length == 1) {
                  this.sortedArray[0].show = true;
                }

              }

            }

            this.searchLoadingMember = false;
            this.connectionIdMember = '';
            this.socketMember?.close(3001);
            clearTimeout(this.socketConnectTimeMember);
          });
        }
      }

      console.log('c id');
      console.log(this.connectionIdLOC);
    });

    this.socketMember.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      this.socketConnectionStatusMember = 'error';
    });

    this.socketMember.addEventListener('close', (event) => {
      console.log('WebSocket connection closed!');
      console.log(event);
      this.socketConnectionStatusMember = 'disconnected';
      if (event.code != 3001) {
        this.connectionIdMember = '';
        this.updateCIdMember = true;
        this.connectWebSocketMember();
      }
    });
  }

  callSummary() {
    this.socketSummary?.close(3001);
    this.connectSocketLoadingSummary = true;
    this.socketConnectionStatusSummary = 'disconnected';
    this.connectionIdSummary = '';
    this.socketReceivedMessageSummary = '';
    this.updateCIdSummary = false;
    clearTimeout(this.socketConnectTimeSummary);
    this.connectWebSocketSummary();
    this.closeSocketWithTimeSummary();
  }

  connectWebSocketSummary() {
    console.log('Calling Websocket For Summary');
    this.searchLoadingSummary = true;
    this.gettingSummary = true;
    this.connectSocketLoadingSummary = true;
    const webSocketURL = this.allinoneService.attendanceSocketUrl;

    this.socketSummary = new WebSocket(webSocketURL);

    this.socketSummary.addEventListener('open', (event) => {
      console.log('WebSocket connection opened!');
      this.connectSocketLoadingSummary = false;
      // this.socketSummary?.send('001');
      this.socketConnectionStatusSummary = 'connected';
    });

    this.socketSummary.addEventListener('message', (event) => {
      // console.log('Received message:', event.data);
      this.socketReceivedMessageSummary = event.data;

      var tempData = JSON.parse(event.data);

      if (tempData.connectionId) {
        this.connectionIdSummary = tempData.connectionId;

        if (this.updateCIdSummary) {
          this.updateConnectionID();
        }
        this.getSummary();
      } else {
        if (tempData.returncode == 300) {
          this.summaryData = {
            total: tempData.orgusercounts,
            timein: tempData.timeincounts,
            timeout: tempData.timeoutcounts,
            usertimein: tempData.timeinusercounts,
            useractive: tempData.activeusercounts,
            usertimeout: tempData.timeoutusercounts,
            checkin: tempData.checkincounts,
            usercheckin: tempData.checkinusercounts,
            activity: tempData.activitycounts,
            useractivity: tempData.activityusercounts,
            other: tempData.othercounts,
            userother: tempData.otherusercounts,
            notimein: tempData.notimeinusercounts,
            noactivity: tempData.noactivityusercounts,
            leave: tempData.leavecounts,
          };

          // this.pieChartData = [parseInt(this.summaryData.total), parseInt(this.summaryData.timein), parseInt(this.summaryData.timeout)]
          if (this.getcheckinForm.subtype == 'Employee') {
            this.barChartData = [
              { data: [parseInt(this.summaryData.total)], label: 'Total' },
              {
                data: [parseInt(this.summaryData.usertimein)],
                label: 'Time In',
              },
              {
                data: [parseInt(this.summaryData.useractive)],
                label: 'Active',
              },
              {
                data: [parseInt(this.summaryData.usertimeout)],
                label: 'Time Out',
              },
              {
                data: [parseInt(this.summaryData.usercheckin)],
                label: 'Check In',
              },
              {
                data: [parseInt(this.summaryData.useractivity)],
                label: 'Activity',
              },
              {
                data: [parseInt(this.summaryData.leave)],
                label: 'Leave',
              },
              // {
              //   data: [parseInt(this.summaryData.notimein)],
              //   label: 'No Time In',
              // },
              // {
              //   data: [parseInt(this.summaryData.noactivity)],
              //   label: 'No Activity',
              // },
            ];
          } else {
            this.barChartData = [
              // { data: [parseInt(this.summaryData.total)], label: 'Total' },
              { data: [parseInt(this.summaryData.timein)], label: 'Time In' },
              {
                data: [parseInt(this.summaryData.timeout)],
                label: 'Time Out',
              },
              {
                data: [parseInt(this.summaryData.checkin)],
                label: 'Check In',
              },
              {
                data: [parseInt(this.summaryData.activity)],
                label: 'Activity',
              },
            ];
          }
          this.gettingSummary = false;
          this.socketSummary?.close(3001);
          clearTimeout(this.socketConnectTimeSummary);
        }
      }

      console.log('c id');
      console.log(this.connectionIdSummary);
    });

    this.socketSummary.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      this.gettingSummary = false;
      this.socketConnectionStatusSummary = 'error';
    });

    this.socketSummary.addEventListener('close', (event) => {
      console.log('WebSocket connection closed!');
      console.log(event);
      this.socketConnectionStatusSummary = 'disconnected';
      if (event.code != 3001) {
        this.connectionIdSummary = '';
        this.gettingSummary = false;
        this.updateCIdSummary = true;
        this.connectWebSocketSummary();
      }
    });
  }

  updateConnectionID() {
    var t = '001';
    if (this.displayMode == 'Daterange') {
      t = '001';
    } else if (this.displayMode == 'Absentreport') {
      t = '001';
    } else if (this.displayMode == 'Location') {
      t = '006';
    } else if (this.displayMode == 'Summary') {
      t = '012';
    }
    var data = {};
    if (this.displayMode == 'Daterange') {
      data = {
        connectionid: this.connectionIdDR,
        type: t,
      };
    } else if (this.displayMode == 'Absentreport') {
      data = {
        connectionid: this.connectionIdAR,
        type: t,
      };
    } else if (this.displayMode == 'Location') {
      data = {
        connectionid: this.connectionIdLOC,
        type: t,
      };
    } else if (this.displayMode == 'Summary') {
      data = {
        connectionid: this.connectionIdSummary,
        type: t,
      };
    }
    this.kunyekService.updateConnectionID(data).subscribe((res: any) => {
      console.log('id updated res');
      // this.updateCId = false;
      if (this.displayMode == 'Daterange') {
        this.updateCIdDR = false;
      } else if (this.displayMode == 'Absentreport') {
        this.updateCIdAR = false;
      } else if (this.displayMode == 'Location') {
        this.updateCIdLOC = false;
      } else if (this.displayMode == 'Summary') {
        this.updateCIdSummary = false;
      }
      console.log(res);
    });
  }

  summaryDepartmentChange(dep: any) {
    if (this.getcheckinForm.department != dep) {
      this.getcheckinForm.department = dep;
      this.getcheckinForm.division = 'All';
      this.getcheckinForm.teamid = 'All';
      this.getcheckinForm.costcenter = 'All';
      this.getcheckinForm.subdivision = 'All';
      // this.getSummary();
      this.callSummary();
    }
  }

  summaryDivisionChange(divi: any) {
    if (this.getcheckinForm.division != divi) {
      this.getcheckinForm.division = divi;
      this.getcheckinForm.teamid = 'All';
      this.getcheckinForm.costcenter = 'All';
      this.getcheckinForm.subdivision = 'All';
      // this.getSummary();
      this.callSummary();
    }
  }

  summaryTeamidChange(teamid: any) {
    if (this.getcheckinForm.teamid != teamid) {
      this.getcheckinForm.teamid = teamid;
      this.getcheckinForm.costcenter = 'All';
      this.getcheckinForm.subdivision = 'All';
      // this.getSummary();
      this.callSummary();
    }
  }

  summaryCostCenterChange(costcenter: any) {
    if (this.getcheckinForm.costcenter != costcenter) {
      this.getcheckinForm.costcenter = costcenter;
      this.getcheckinForm.subdivision = 'All';
      // this.getSummary();
      this.callSummary();
    }
  }

  summarySubDivisionChange(subdivision: any) {
    if (this.getcheckinForm.subdivision != subdivision) {
      this.getcheckinForm.subdivision = subdivision;
      // this.getSummary();
      this.callSummary();
    }
  }

  summaryUserTypeChange(type: any) {
    if (this.getcheckinForm.status != type) {
      this.getcheckinForm.status = type;
      this.callSummary();
    }
  }

  daterangeDepartmentChange(dep: any) {
    if (this.getcheckinForm.drdepartment != dep) {
      this.getcheckinForm.drdepartment = dep;
      this.getcheckinForm.drdivision = 'All';
      this.getcheckinForm.drteamid = 'All';
      this.getcheckinForm.drcostcenter = 'All';
      this.getcheckinForm.drsubdivision = 'All';
    }
  }

  daterangeDivisionChange(divi: any) {
    if (this.getcheckinForm.drdivision != divi) {
      this.getcheckinForm.drdivision = divi;
      this.getcheckinForm.drteamid = 'All';
      this.getcheckinForm.drcostcenter = 'All';
      this.getcheckinForm.drsubdivision = 'All';
    }
  }

  daterangeTeamidChange(teamid: any) {
    if (this.getcheckinForm.drteamid != teamid) {
      this.getcheckinForm.drteamid = teamid;
      this.getcheckinForm.drcostcenter = 'All';
      this.getcheckinForm.drsubdivision = 'All';
    }
  }

  datecostCenterChange(costcenter: any) {
    if (this.getcheckinForm.drcostcenter != costcenter) {
      this.getcheckinForm.drcostcenter = costcenter;
      this.getcheckinForm.drsubdivision = 'All';
    }
  }

  datesubDivisionChange(subdivision: any) {
    if (this.getcheckinForm.drsubdivision != subdivision) {
      this.getcheckinForm.drsubdivision = subdivision;
    }
  }

  departmentChange(dep: any) {
    if (this.getcheckinForm.department != dep) {
      this.getcheckinForm.department = dep;
      this.getcheckinForm.division = 'All';
      this.getcheckinForm.teamid = 'All';
      this.getcheckinForm.costcenter = 'All';
      this.getcheckinForm.division = 'All';
    }
  }

  divisionChange(divi: any) {
    if (this.getcheckinForm.division != divi) {
      this.getcheckinForm.division = divi;
      this.getcheckinForm.teamid = 'All';
      this.getcheckinForm.costcenter = 'All';
      this.getcheckinForm.division = 'All';
    }
  }

  teamidChange(teamid: any) {
    if (this.getcheckinForm.teamid != teamid) {
      this.getcheckinForm.teamid = teamid;
      this.getcheckinForm.costcenter = 'All';
      this.getcheckinForm.division = 'All';
    }
  }

  costCenterChange(costcenter: any) {
    if (this.getcheckinForm.costcenter != costcenter) {
      this.getcheckinForm.costcenter = costcenter;
      this.getcheckinForm.division = 'All';
    }
  }

  subDivisionChange(subdivision: any) {
    if (this.getcheckinForm.subdivision != subdivision) {
      this.getcheckinForm.subdivision = subdivision;
    }
  }

  locationChange(loc: any) {
    if (this.getcheckinForm.locid != loc.locid) {
      this.getcheckinForm.locname = loc.name;
      this.getcheckinForm.locid = loc.locid;
    }
  }

  typeChange(type: any) {
    if (this.getcheckinForm.type != type.value) {
      this.getcheckinForm.type = type.value;
      this.getcheckinForm.typename = type.name;
    }
  }

  projectChange(proj: any, index: any) {
    this.getcheckinForm.projindex = index;
    if (this.getcheckinForm.projectid != proj.projectid) {
      this.getcheckinForm.projectid = proj.projectid;
      this.getcheckinForm.projectname = proj.name;
      if (proj.projectid != '') {
        this.getJobs();
      }
    }
  }

  jobChange(proj: any, index: any) {
    this.getcheckinForm.jobindex = index;
    if (this.getcheckinForm.jobid != proj.jobid) {
      this.getcheckinForm.jobid = proj.jobid;
      this.getcheckinForm.jobname = proj.name;
    }
  }

  startdateChange() {
    var currentEndDate = formatDate(
      this.getcheckinForm.startdate,
      this.piedateFormat,
      'en-US'
    ).toString();
    var addDays = 35;
    var date = new Date(this.getcheckinForm.startdate);
    date.setTime(date.getTime() + addDays * 24 * 60 * 60 * 1000);
    // this.minDate = formatDate(date, this.formatToShow, 'en-US').toString();
    this.minDate = date;
    if (
      this.getcheckinForm.enddate > this.minDate ||
      this.getcheckinForm.enddate < this.getcheckinForm.startdate
    ) {
      this.getcheckinForm.enddate = this.getcheckinForm.startdate;
    }
  }

  // pageChange(event: any) {
  //   // console.log(event);
  //   if (this.nextDisabled == true) {
  //     if (event < this.lastPage) {
  //       this.currentPageLoc = event;
  //       if (event < 1) {
  //       } else {
  //         this.nextDisabled = false;
  //       }
  //     }
  //   } else {
  //     console.log('else con');

  //     if (event == this.lastPage && this.lastPage != '') {
  //       this.currentPageLoc = event;
  //       this.nextDisabled = true;
  //     } else {
  //       this.currentPageLoc = event;
  //       if (event > this.locShowData.length / this.itemPerPageLoc) {
  //         this.searchLocWithPagination();
  //       }
  //       else {
  //         this.nextDisabled = false;
  //       }
  //     }
  //   }
  // }

  // resetPagination() {
  //   this.currentPageLoc = 1;
  //   this.lastPage = '';
  //   this.nextDisabled = false;
  // }

  getLocation() {
    this.locations = [
      {
        name: 'All',
        locid: '',
      },
    ];
    this.gettingLocList = true;
    // data = {
    //   "global": "",
    //   "domainid": this.eventForm.domain,
    //   "orgid": [this.eventForm.org],
    //   "userid": ""
    // }
    // if (this.role == "300" || this.eventForm.org != "") {
    var data = {
      global: '',
      domainid: '',
      orgid: [this.getcheckinForm.orgid],
      personal: '',
    };
    // }
    this.kunyekService.getLocations(data).subscribe((res: any) => {
      if (res.returncode == '300') {
        console.log('loc res');

        console.log(res);

        res.datalist.map((loc: any) => {
          this.locations.push({
            locid: loc.locid,
            lat: loc.lat,
            lng: loc.lng,
            name: loc.name,
            range: loc.range,
            type: loc.type,
            typeid: loc.typeid,
            description: loc.description,
          });
        });
        this.gettingLocList = false;
      } else {
        this.gettingLocList = false;
        this.messageService.openSnackBar(res.message, 'warn');
      }
    });
  }

  locChanged(event: any) {
    this.getcheckinForm.locid = event.target.value;
  }

  changeDrPageCount(event: any) {
    this.itemPerPageDR = event.target.value;
    this.p = 1;
  }

  changeLocPageCount(event: any) {
    this.itemPerPageLoc = event.target.value;
    this.p = 1;
    this.currentPageLoc = 1;
    // this.locTotal = 0;
    // this.start = 0;
    // this.end = Number(this.itemPerPageLoc);
    // this.searchWithLoc();
  }

  changeASPageCount(event: any) {
    this.itemPerPageAS = event.target.value;
    this.currentPageAS = 1;
  }

  changePjPageCount(event: any) {
    this.itemPerPagePJ = event.target.value;
    this.q = 1;
  }

  viewDetails(type: string) {
    console.log('this fun');

    var date = formatDate(
      new Date(this.getcheckinForm.startdate),
      this.dateFormat,
      'en-US'
    ).toString();
    const department =
      this.getcheckinForm.department == 'All'
        ? 'all'
        : this.getcheckinForm.department;
    const division =
      this.getcheckinForm.division == 'All'
        ? 'all'
        : this.getcheckinForm.division;
    const teamid =
      this.getcheckinForm.teamid == 'All' ? 'all' : this.getcheckinForm.teamid;
    const costcenter =
      this.getcheckinForm.costcenter == 'All' ? 'all' : this.getcheckinForm.costcenter;
    const subdivision =
      this.getcheckinForm.subdivision == 'All' ? 'all' : this.getcheckinForm.subdivision;

    console.log('here >>>');
    console.log(costcenter);
    console.log(subdivision);


    if (this.getcheckinForm.subtype == 'Employee') {
      console.log('1');

      this.router.navigate([
        (this.isAdmin
          ? this.allinoneService.isAttendanceView
            ? '/attendance/hxm'
            : '/hxm'
          : '') +
        '/checkinattendances/summary/' +
        this.getcheckinForm.orgid +
        '/user' +
        type +
        '/' +
        department +
        '/' +
        division +
        '/' +
        teamid +
        '/' +
        costcenter +
        '/' +
        subdivision +
        '/' +
        date,
      ]);
    } else {
      console.log('2');
      this.router.navigate([
        (this.isAdmin
          ? this.allinoneService.isAttendanceView
            ? '/attendance/hxm'
            : '/hxm'
          : '') +
        '/checkinattendances/summary/' +
        this.getcheckinForm.orgid +
        '/' +
        type +
        '/' +
        department +
        '/' +
        division +
        '/' +
        teamid +
        '/' +
        costcenter +
        '/' +
        subdivision +
        '/' +
        date,
      ]);
    }
  }

  viewDateRangeDetails(
    type: string,
    userid: string,
    username: string,
    days: string,
    hours: string,
    iohours: string
  ) {
    var startdate = formatDate(
      new Date(this.getcheckinForm.startdate),
      this.dateFormat,
      'en-US'
    ).toString();
    var enddate = formatDate(
      new Date(this.getcheckinForm.enddate),
      this.dateFormat,
      'en-US'
    ).toString();
    var tempUser = {
      userid: userid,
      username: username,
      hours: hours,
      days: days,
      iohours: iohours,
    };
    var user = this.allinoneService.replaceAll(
      this.allinoneService.encrypt(JSON.stringify(tempUser)),
      '/',
      '###'
    );

    this.router.navigate([
      (this.isAdmin
        ? this.allinoneService.isAttendanceView
          ? '/attendance/hxm'
          : '/hxm'
        : '') +
      '/checkinattendances/daterange/' +
      this.getcheckinForm.orgid +
      '/' +
      type +
      '/' +
      startdate +
      enddate +
      '/' +
      user,
    ]);
  }

  viewTaskDetails(item: any) {
    var type = this.tasktype;
    var tempUser = {
      userid: item.userid,
      username: item.username,
      hours: item.hours,
      projectname: this.projects[parseInt(this.getcheckinForm.projindex)].name,
    };
    var user = this.allinoneService.replaceAll(
      this.allinoneService.encrypt(JSON.stringify(tempUser)),
      '/',
      '###'
    );
    sessionStorage.setItem('projdetails', JSON.stringify(item.datalist));
    this.router.navigate([
      (this.isAdmin
        ? this.allinoneService.isAttendanceView
          ? '/attendance/hxm'
          : '/hxm'
        : '') +
      '/checkinattendances/task/' +
      this.getcheckinForm.orgid +
      '/' +
      type +
      '/' +
      user,
    ]);
  }

  getProjects() {
    return new Promise((resolve: any) => {
      this.gettingProjects = true;
      this.projects = [
        {
          name: '-',
          projectid: '',
        },
      ];
      this.jobs = [
        {
          name: '-',
          jobid: '',
        },
      ];
      const data = {
        orgid: this.getcheckinForm.orgid,
        domainid: this.allinoneService.getDomain().domainid,
      };
      this.subscriptions.sink = this.kunyekService
        .getAllProjects(data)
        .subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              // now
              // this.projects = res.data;
              for (var i = 0; i < res.data.length; i++) {
                this.projects.push(res.data[i]);
              }

              this.getcheckinForm.projindex =
                this.projects.length > 0 ? '0' : '';
              if (
                this.getcheckinForm.projindex != '' &&
                this.getcheckinForm.projindex != '0'
              ) {
                this.getJobs();
              }
              resolve(true);
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
              resolve(false);
            }
            this.gettingProjects = false;
          },
          (err) => {
            resolve(false);
            this.gettingProjects = false;
          }
        );
    });
  }

  getJobs() {
    this.gettingJobs = true;
    this.jobs = [
      {
        name: '-',
        jobid: '',
      },
    ];
    this.getcheckinForm.jobindex = '';
    this.getcheckinForm.jobname = '-';
    this.getcheckinForm.jobid = '';
    const data = {
      projectid:
        this.projects[parseInt(this.getcheckinForm.projindex)].projectid,
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.getcheckinForm.orgid,
    };
    this.kunyekService.getAllJobs(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.jobs = res.data;
          for (var i = 0; i < res.data.length; i++) {
            this.jobs.push(res.data[i]);
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingJobs = false;
      },
      (err) => {
        this.gettingJobs = false;
      }
    );
  }

  projChanged(event: any) {
    this.getJobs();
  }

  jobChanged(event: any) { }

  // closeSocketWithTime() {
  //   this.socketConnectTime = setTimeout(() => {
  //     if (this.socketConnectionStatus != 'disconnected') {
  //       console.log('Time out');

  //       this.socketDR?.close(3001);
  //       this.socketAR?.close(3001);
  //       this.socketLOC?.close(3001);
  //       this.socketSummary?.close(3001);
  //       this.connectionId = '';
  //       this.messageService.openSnackBar('Request Time Out', 'warn');
  //       this.searchLoading = false;
  //     }
  //   }, this.allinoneService.reportSocketTimeoutMins * 60 * 1000);
  // }

  closeSocketWithTimeDR() {
    this.socketConnectTimeDR = setTimeout(() => {
      if (this.socketConnectionStatusDR != 'disconnected') {
        console.log('Time out');

        this.socketDR?.close(3001);
        this.connectionIdDR = '';
        this.messageService.openSnackBar('Request Time Out', 'warn');
        this.searchLoadingDR = false;
      }
    }, this.allinoneService.reportSocketTimeoutMins * 60 * 1000);
  }

  closeSocketWithTimeAR() {
    this.socketConnectTimeAR = setTimeout(() => {
      if (this.socketConnectionStatusAR != 'disconnected') {
        console.log('Time out');

        this.socketAR?.close(3001);
        this.connectionIdAR = '';
        this.messageService.openSnackBar('Request Time Out', 'warn');
        this.searchLoadingAR = false;
      }
    }, this.allinoneService.reportSocketTimeoutMins * 60 * 1000);
  }

  closeSocketWithTimeLOC() {
    this.socketConnectTimeLOC = setTimeout(() => {
      if (this.socketConnectionStatusLOC != 'disconnected') {
        console.log('Time out');
        this.socketLOC?.close(3001);
        this.connectionIdLOC = '';
        this.messageService.openSnackBar('Request Time Out', 'warn');
        this.searchLoadingLOC = false;
      }
    }, this.allinoneService.reportSocketTimeoutMins * 60 * 1000);
  }

  closeSocketWithTimeMember() {
    this.socketConnectTimeMember = setTimeout(() => {
      if (this.socketConnectionStatusMember != 'disconnected') {
        console.log('Time out');
        this.socketMember?.close(3001);
        this.connectionIdMember = '';
        this.messageService.openSnackBar('Request Time Out', 'warn');
        this.searchLoadingMember = false;
      }
    }, this.allinoneService.reportSocketTimeoutMins * 60 * 1000);
  }

  closeSocketWithTimeSummary() {
    this.socketConnectTimeDR = setTimeout(() => {
      if (this.socketConnectionStatusSummary != 'disconnected') {
        console.log('Time out');
        this.socketSummary?.close(3001);
        this.connectionIdSummary = '';
        this.messageService.openSnackBar('Request Time Out', 'warn');
        this.searchLoadingSummary = false;
        this.gettingSummary = false;
      }
    }, this.allinoneService.reportSocketTimeoutMins * 60 * 1000);
  }

  test() {
    this.callSummary();
  }

  test2() {
    this.connectionIdSummary = '';
    this.updateCIdSummary = false;

    clearTimeout(this.socketConnectTimeSummary);
    this.searchLoadingSummary = false;
    this.gettingSummary = false;
    this.socketSummary?.close(3001);
  }

  search() {
    if (this.displayMode == 'Daterange') {
      // this.searchWithDateRange(false);
      this.connectWebSocketDateRange();
      this.closeSocketWithTimeDR();
    } else if (this.displayMode == 'Absentreport') {
      // this.searchWithDateRange(true);
      this.connectWebSocketAbsentReport();
      this.closeSocketWithTimeAR();
    } else if (this.displayMode == 'Location') {
      // this.searchWithLoc();
      this.connectWebSocketLocation();
      this.closeSocketWithTimeLOC();
    } else if (this.displayMode == 'Project') {
      this.formSubmitted = true;
      if (
        this.getcheckinForm.projindex == '' ||
        this.getcheckinForm.projectid == ''
      ) {
        this.messageService.openSnackBar('Project cannot be blank.', 'warn');
        return;
      } else {
        this.searchByProj();
      }
    } else {
      this.atFormSubmitted = true;
      if (
        this.getcheckinForm.userid == '' ||
        this.getcheckinForm.userid == null
      ) {
        this.messageService.openSnackBar(
          this.getcheckinForm.membertype + ' cannot be blank.',
          'warn'
        );
        return;
      }
      // this.getCheckInList();
      this.connectWebSocketMember();
      this.closeSocketWithTimeMember();
    }
  }

  sortData(sort: Sort) {
    const data = this.daterangeData;
    if (!sort.active || sort.direction === '') {
      this.daterangeData = data;
      return;
    }

    this.daterangeData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'employeeid':
          return this.allinoneService.compare(
            a.employeeid,
            b.employeeid,
            isAsc
          );
        case 'days':
          return this.allinoneService.compare(a.days, b.days, isAsc);
        case 'hours':
          return this.allinoneService.compare(a.hours, b.hours, isAsc);
        case 'totalhours':
          return this.allinoneService.compare(
            a.totalhours,
            b.totalhours,
            isAsc
          );
        case 'iohours':
          return this.allinoneService.compare(a.iohours, b.iohours, isAsc);
        case 'absence':
          return this.allinoneService.compare(
            a.absencedays,
            b.absencedays,
            isAsc
          );
        case 'leave':
          return this.allinoneService.compare(
            a.leavecounts,
            b.leavecounts,
            isAsc
          );
        case 'rank':
          return this.allinoneService.compare(a.type, b.type, isAsc);
        case 'department':
          return this.allinoneService.compare(
            a.department,
            b.department,
            isAsc
          );
        case 'divisiion':
          return this.allinoneService.compare(a.divisiion, b.divisiion, isAsc);
        case 'division':
          return this.allinoneService.compare(a.division, b.division, isAsc);
        case 'date':
          return this.allinoneService.compare(a.date, b.date, isAsc);
        case 'location':
          return this.allinoneService.compare(a.location, b.location, isAsc);
        case 'absencedate':
          /* Returning undefined. */
          /* Returning undefined. */
          return this.allinoneService.compare(a.date, b.date, isAsc);
        default:
          return 0;
      }
    });
  }

  sortLocData(sort: Sort) {
    const data = this.locShowData;
    if (!sort.active || sort.direction === '') {
      this.locShowData = data;
      return;
    }

    this.locShowData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'employeeid':
          return this.allinoneService.compare(
            a.employeeid,
            b.employeeid,
            isAsc
          );
        case 'days':
          return this.allinoneService.compare(a.days, b.days, isAsc);
        case 'hours':
          return this.allinoneService.compare(a.hours, b.hours, isAsc);
        case 'totalhours':
          return this.allinoneService.compare(
            a.totalhours,
            b.totalhours,
            isAsc
          );
        case 'iohours':
          return this.allinoneService.compare(a.iohours, b.iohours, isAsc);
        case 'absence':
          return this.allinoneService.compare(
            a.absencedays,
            b.absencedays,
            isAsc
          );
        case 'leave':
          return this.allinoneService.compare(
            a.leavecounts,
            b.leavecounts,
            isAsc
          );
        case 'rank':
          return this.allinoneService.compare(a.type, b.type, isAsc);
        case 'department':
          return this.allinoneService.compare(
            a.department,
            b.department,
            isAsc
          );
        case 'divisiion':
          return this.allinoneService.compare(a.divisiion, b.divisiion, isAsc);
        case 'division':
          return this.allinoneService.compare(a.division, b.division, isAsc);
        case 'date':
          return this.allinoneService.compare(a.date, b.date, isAsc);
        case 'location':
          return this.allinoneService.compare(a.location, b.location, isAsc);
        case 'absencedate':
          /* Returning undefined. */
          /* Returning undefined. */
          return this.allinoneService.compare(a.date, b.date, isAsc);
        default:
          return 0;
      }
    });
  }

  sortAbsentData(sort: Sort) {
    const data = this.absentData;
    if (!sort.active || sort.direction === '') {
      this.absentData = data;
      return;
    }

    this.absentData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'employeeid':
          return this.allinoneService.compare(
            a.employeeid,
            b.employeeid,
            isAsc
          );
        case 'days':
          return this.allinoneService.compare(a.days, b.days, isAsc);
        case 'hours':
          return this.allinoneService.compare(a.hours, b.hours, isAsc);
        case 'totalhours':
          return this.allinoneService.compare(
            a.totalhours,
            b.totalhours,
            isAsc
          );
        case 'iohours':
          return this.allinoneService.compare(a.iohours, b.iohours, isAsc);
        case 'absence':
          return this.allinoneService.compare(
            a.absencedays,
            b.absencedays,
            isAsc
          );
        case 'leave':
          return this.allinoneService.compare(
            a.leavecounts,
            b.leavecounts,
            isAsc
          );
        case 'rank':
          return this.allinoneService.compare(a.type, b.type, isAsc);
        case 'department':
          return this.allinoneService.compare(
            a.department,
            b.department,
            isAsc
          );
        case 'divisiion':
          return this.allinoneService.compare(a.divisiion, b.divisiion, isAsc);
        case 'absencedate':
          /* Returning undefined. */
          /* Returning undefined. */
          return this.allinoneService.compare(a.date, b.date, isAsc);
        default:
          return 0;
      }
    });
  }

  sortProjData(sort: Sort) {
    const data = this.projData;
    if (!sort.active || sort.direction === '') {
      this.projData = data;
      return;
    }

    this.projData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'hours':
          return this.allinoneService.compare(a.hours, b.hours, isAsc);
        default:
          return 0;
      }
    });
  }

  typeChanged() {
    // this.getcheckinForm.subtype = event.target.value
    // this.getcheckinForm.subtype =

    if (this.getcheckinForm.subtype == 'Employee') {
      this.barChartData = [
        { data: [parseInt(this.summaryData.total)], label: 'Total' },
        { data: [parseInt(this.summaryData.usertimein)], label: 'Time In' },
        { data: [parseInt(this.summaryData.useractive)], label: 'Active' },
        { data: [parseInt(this.summaryData.usertimeout)], label: 'Time Out' },
        { data: [parseInt(this.summaryData.usercheckin)], label: 'Check In' },
        { data: [parseInt(this.summaryData.useractivity)], label: 'Activity' },
        { data: [parseInt(this.summaryData.userother)], label: 'Other' },
      ];
    } else {
      this.barChartData = [
        // { data: [parseInt(this.summaryData.total)], label: 'Total' },
        { data: [parseInt(this.summaryData.timein)], label: 'Time In' },
        { data: [parseInt(this.summaryData.timeout)], label: 'Time Out' },
        { data: [parseInt(this.summaryData.checkin)], label: 'Check In' },
        { data: [parseInt(this.summaryData.activity)], label: 'Activity' },
      ];
    }
  }

  downloadSampleFile(): any {
    this.http
      .get(this.allinoneService.bucketUrl + 'templates/SampleTemplate.xlsx', {
        responseType: 'blob',
      })
      .subscribe((response: any) => {
        saveAs(response, 'SampleTemplate.xlsx');
      }),
      (error: any) => {
        this.messageService.openSnackBar('Download failed.', 'warn');
      };
  }

  downloadLocSheet() {
    console.log('call');
    // console.log(this.locfileurl);
    // return this.http.get(this.locfileurl, { responseType: 'blob' });
    var startd = formatDate(
      new Date(this.getcheckinForm.startdate),
      this.formatToShow,
      'en-US'
    ).toString();
    var endd = formatDate(
      new Date(this.getcheckinForm.enddate),
      this.formatToShow,
      'en-US'
    ).toString();
    var name =
      this.getcheckinForm.orgname + '(' + startd + '-' + endd + ').xlsx';
    var exdata: any = [];
    const workBook = new Workbook();
    const workSheet = workBook.addWorksheet('Sheet 1');
    var filteredLocData = this.filter.transform(
      this.locShowData,
      this.searchTextLOC,
      15
    );
    filteredLocData.map((item: any) => {
      var temp = {
        EmployeeID: item.employeeid,
        UserID: item.userid,
        Name: item.username,
        Department: item.department,
        Division: item.division,
        TeamID: item.teamid,
        Type:
          item.iotype == 'in'
            ? 'Time In'
            : item.iotype == 'out'
              ? 'Time Out'
              : 'Check In',
        Date: this.allinoneService.formatDBToShow(item.date),
        Time: item.showtime,
        Location:
          item.location == '' && item.lat == '' && item.long == ''
            ? 'Unknown Location'
            : item.location,
        LatLong:
          item.lat != '' && item.long != '' ? item.lat + ', ' + item.long : '',
        Organization: item.orglocation,
        LocationType: item.locationtype,
      };
      exdata.push(temp);
    });
    workSheet.addRow([
      'Employee ID',
      'User ID',
      'Name',
      'Department',
      'Division',
      'TeamID',
      'Type',
      'Date',
      'Time',
      'Location',
      'LatLong',
      'Organization',
    ]);
    exdata.forEach((item: any) => {
      const row = workSheet.addRow([
        item.EmployeeID,
        item.UserID,
        item.Name,
        item.Department,
        item.Division,
        item.TeamID,
        item.Type,
        item.Date,
        item.Time,
        item.Location,
        item.LatLong,
        item.Organization,
      ]);
      // for (let i = 1; i < 10; i++) {
      //   const col = row.getCell(i);
      //   col.font = {
      //     color: {
      //       argb: item.LocationType == '3' ? '000000' : 'FF0000',
      //     },
      //   };
      // }
      const col = row.getCell(10);
      col.font = {
        color: {
          argb: item.LocationType == '3' ? '000000' : 'FF0000',
        },
      };
    });
    workBook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, name);
    });
  }

  downloadSheet() {
    var exdata: any = [];
    var startd = formatDate(
      new Date(this.getcheckinForm.startdate),
      this.formatToShow,
      'en-US'
    ).toString();
    var endd = formatDate(
      new Date(this.getcheckinForm.enddate),
      this.formatToShow,
      'en-US'
    ).toString();
    var name =
      this.getcheckinForm.orgname + '(' + startd + '-' + endd + ').xlsx';
    const workBook = new Workbook();
    const workSheet = workBook.addWorksheet('Sheet 1');
    if (this.displayMode == 'Daterange') {
      var filteredDaterangeData = this.filter.transform(
        this.daterangeShowData,
        this.searchTextDR,
        15
      );
      filteredDaterangeData.map((item: any) => {
        var absencedatelist: string = '';
        if (item.absencedatalist.length > 0) {
          item.absencedatalist.map((x: string, index: number) => {
            if (index == 0) {
              absencedatelist = this.allinoneService.formatDBToShow(x);
            } else {
              absencedatelist =
                absencedatelist + ', ' + this.allinoneService.formatDBToShow(x);
            }
          });
        }
        var temp = {
          'User ID': item.userid,
          Name: item.username,
          'Employee ID': item.employeeid,
          'Total Days': item.days,
          'Total Time In/Out Hours': item.iohours,
          'Actual Working Hours': item.totalhours,
          Leave: item.leavecounts,
          Absence: item.absencedays,
          'Absence Date': absencedatelist,
        };
        exdata.push(temp);
      });
    } else if (this.displayMode == 'Absentreport') {
      console.log('here -----> ');
      var filteredAbsentData = this.filter.transform(
        this.absentData,
        this.searchTextAS,
        15
      );
      filteredAbsentData.map((item: any) => {
        var temp = {
          'User ID': item.userid,
          Name: item.username,
          'Employee ID': item.employeeid,
          Post: item.type,
          Department: item.department,
          Divsion: item.division,
          'Absent Date': this.allinoneService.formatDBToShow(item.date),
          'Absent Type': item.halfdayabsent ? 'Half day absent' : '',
        };
        exdata.push(temp);
      });
    } else if (this.displayMode == 'Project') {
      var filteredProjData = this.filter.transform(
        this.projData,
        this.searchTextPJ,
        14
      );
      filteredProjData.map((item: any) => {
        var temp = {
          'User ID': item.userid,
          Name: item.username,
          'Employee ID': item.employeeid,
          'Total Hours': item.hours,
        };
        exdata.push(temp);
      });
    } else {
      this.sortedArray.map((user: any) => {
        user.datalist.map((date: any) => {
          date.data.map((cidata: any) => {
            exdata.push({
              'Date Time': date.showdate + ' ' + cidata.starttime,
              UserID: user.userid,
              UseName: user.username,
              Type:
                cidata.iotype == 'in'
                  ? 'Time In'
                  : cidata.iotype == 'out'
                    ? 'Time Out'
                    : cidata.iotype == 'checkin'
                      ? 'Check In'
                      : cidata.iotype == 'activity'
                        ? 'Activity'
                        : 'Event',
              'Location Name': cidata.location,
              'Location LatLong': cidata.lat + ',' + cidata.long,
              Hours:
                cidata.iotype == 'out' && cidata.timeoutduration != ''
                  ? this.calDuration(cidata.timeoutduration)
                  : cidata.child.length > 0
                    ? this.calEndTime(
                      cidata.date,
                      cidata.starttime,
                      cidata.child[0].date,
                      cidata.child[0].starttime
                    )
                    : '',
              Description: cidata.description,
              TaskID: cidata.autoid,
              'Task Descripton': cidata.taskdescription,
            });
            name = cidata.orgname + '(' + startd + ' - ' + endd + ').xlsx';
          });
        });
      });
    }
    this.allinoneService.exportEcecl(exdata, name);
  }

  clear() {
    if (this.displayMode == 'Daterange') {
      this.searchTextDR = '';
    } else if (this.displayMode == 'Project') {
      this.searchTextPJ = '';
    } else if (this.displayMode == 'Location') {
      this.searchTextLOC = '';
      // if (this.isSearch) {
      //   this.searchWithLoc(this.filename);
      // }
    } else {
      this.searchTextAS = '';
    }
  }

  async displayModeChanged() {
    if (this.displayMode == 'Summary') {
      this.dateRangeStartDate = this.getcheckinForm.startdate;
      this.getcheckinForm.startdate = this.summaryStartDate;
      if (this.selectedOrg != this.getcheckinForm.orgid) {
        // this.getSummary();
        this.callSummary();
      }
      this.selectedOrg = this.getcheckinForm.orgid;
    } else if (this.displayMode == 'Daterange') {
      this.summaryStartDate = this.getcheckinForm.startdate;
      this.getcheckinForm.startdate = this.dateRangeStartDate;
      if (this.selectedOrg1 != this.getcheckinForm.orgid) {
        this.searchWithDateRange(false);
      }
      this.selectedOrg1 = this.getcheckinForm.orgid;
    } else if (this.displayMode == 'Location') {
      this.summaryStartDate = this.getcheckinForm.startdate;
      this.getcheckinForm.startdate = this.dateRangeStartDate;
      if (this.selectedOrg1 != this.getcheckinForm.orgid) {
        this.searchWithDateRange(false);
      }
    } else if (this.displayMode == 'Absentreport') {
      this.summaryStartDate = this.getcheckinForm.startdate;
      this.getcheckinForm.startdate = this.dateRangeStartDate;
      if (this.selectedOrg1 != this.getcheckinForm.orgid) {
        this.searchWithDateRange(true);
      }
      this.selectedOrg1 = this.getcheckinForm.orgid;
    } else if (this.displayMode == 'Project') {
      if (this.selectedOrg2 != this.getcheckinForm.orgid) {
        const await_proj = await this.getProjects();
        if (this.projects.length > 0) {
          this.searchByProj();
        }
      }
      this.selectedOrg2 = this.getcheckinForm.orgid;
    } else {
      if (this.selectedOrg3 != this.getcheckinForm.orgid) {
        if (this.getcheckinForm.userid) {
          this.getCheckInList();
        } else {
          this.sortedArray = [];
        }
      }
      this.selectedOrg3 = this.getcheckinForm.orgid;
    }

    this.formSubmitted = false;
    this.atFormSubmitted = false;
  }

  // onSearch() {
  //   this.resetPagination();
  //   this.locShowData = [];
  //   this.locTotal = 0;
  //   this.gettingLocData = true;
  //   this.key = '';
  //   this.start = 0;
  //   this.end = Number(this.itemPerPageLoc);
  //   const data = {
  //     startdate: formatDate(
  //       new Date(this.getcheckinForm.startdate),
  //       this.dateFormat,
  //       'en-US'
  //     ).toString(),
  //     enddate: formatDate(
  //       new Date(this.getcheckinForm.enddate),
  //       this.dateFormat,
  //       'en-US'
  //     ).toString(),
  //     domain: this.allinoneService.getDomain().shortcode,
  //     domainid: this.allinoneService.getDomain().domainid,
  //     orgid: this.getcheckinForm.orgid,
  //     department: this.getcheckinForm.drdepartment == 'All' ? '' : this.getcheckinForm.drdepartment,
  //     division: this.getcheckinForm.drdivision == 'All' ? '' : this.getcheckinForm.drdivision,
  //     teamid: this.getcheckinForm.drteamid == 'All' ? '' : this.getcheckinForm.drteamid,
  //     locationid: this.getcheckinForm.locid,
  //     type: this.getcheckinForm.type,
  //     // key: this.key,
  //     // limit: Number(this.itemPerPageLoc),
  //     start: 0,
  //     end: Number(this.itemPerPageLoc),
  //     filename: this.isSearch ? this.locsearchfilename : this.filename,
  //     searchtext: this.searchTextLOC
  //   };

  //   console.log(data);

  //   this.isSearch = true;
  //   this.kunyekService.getSummaryWithLocation(data).subscribe(
  //     (res: any) => {
  //       console.log(res.datalist.length);

  //       if (res.returncode == '300') {
  //         this.locShowData = res.datalist;
  //         this.key = res.key;
  //         this.locTotal = res.totalcount;
  //         // this.locfileurl = res.fileurl;
  //         this.locsearchfileurl = res.fileurl;
  //         this.locsearchfilename = res.filename
  //         if (res.datalist.length < this.itemPerPageLoc) {
  //           this.nextDisabled = true;
  //           this.lastPage = this.currentPageLoc;
  //         }
  //         // if (res.key == '') {
  //         //   this.nextDisabled = true;
  //         //   this.lastPage = this.currentPageLoc;
  //         //   this.key = this.key;
  //         // }

  //       } else {
  //         console.log('hereeeeeeee');
  //         this.locShowData = [];
  //         this.messageService.openSnackBar(
  //           res.message || res.status || 'Server Error',
  //           'warn'
  //         );
  //       }
  //       this.searchLoading = false;
  //       this.gettingLocData = false;
  //     },
  //     (err) => {
  //       this.locShowData = [];
  //       this.gettingLocData = false;
  //       this.searchLoading = false;
  //     }
  //   );
  // }

  filterLoc() {
    // if (this.lastPage > 1) {
    //   this.nextDisabled = false;
    // }
    // if (this.searchTextLOC == '') {
    //   this.locShowData = [];
    // }
    // this.currentPage = 1;
  }

  blank() {
    // if (this.searchTextLOC == '') {
    //   this.locShowData = [];
    // }
  }

  getSummary() {
    var currentEndDate = formatDate(
      this.getcheckinForm.startdate,
      this.piedateFormat,
      'en-US'
    ).toString();
    // var addDays = 35;
    var addDays = 31;
    var date = new Date(this.getcheckinForm.startdate);
    date.setTime(date.getTime() + addDays * 24 * 60 * 60 * 1000);
    // this.minDate = formatDate(date, this.formatToShow, 'en-US').toString();
    this.minDate = date;
    if (
      this.getcheckinForm.enddate > this.minDate ||
      this.getcheckinForm.enddate < this.getcheckinForm.startdate
    ) {
      this.getcheckinForm.enddate = this.getcheckinForm.startdate;
    }
    // this.pieChartOptions.title = {
    //   display: true,
    //   position: 'top',
    //   fontFamily: 'Ubuntu',
    //   fontSize: 15,
    //   text: this.getcheckinForm.orgname + " - " + currentEndDate,
    //   fullWidth: false,
    // }
    this.barChartOptions.title = {
      display: true,
      position: 'top',
      fontFamily: 'Ubuntu',
      fontSize: 15,
      text: this.getcheckinForm.orgname + ' - ' + currentEndDate,
      fullWidth: false,
    };
    this.gettingSummary = true;
    const data = {
      // date: formatDate(
      //   new Date(this.getcheckinForm.startdate),
      //   this.dateFormat,
      //   'en-US'
      // ).toString(),
      date: this.allinoneService.parseDate(this.getcheckinForm.startdate),
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.getcheckinForm.orgid,
      department:
        this.getcheckinForm.department == 'All'
          ? ''
          : this.getcheckinForm.department,
      division:
        this.getcheckinForm.division == 'All'
          ? ''
          : this.getcheckinForm.division,
      teamid:
        this.getcheckinForm.teamid == 'All' ? '' : this.getcheckinForm.teamid,
      subdivision:
        this.getcheckinForm.subdivision == 'All' ? '' : this.getcheckinForm.subdivision,
      costcenter:
        this.getcheckinForm.costcenter == 'All'
          ? ''
          : this.getcheckinForm.costcenter,
      activestatus: this.getcheckinForm.status,
      connectionid: this.connectionIdSummary,
    };
    this.summarySubscription && this.summarySubscription.unsubscribe();
    this.summarySubscription = this.kunyekService
      .getCheckInSummary(data)
      .subscribe(
        (res: any) => {
          // if (res.returncode == 300) {
          //   this.summaryData = {
          //     total: res.orgusercounts,
          //     timein: res.timeincounts,
          //     timeout: res.timeoutcounts,
          //     usertimein: res.timeinusercounts,
          //     useractive: res.activeusercounts,
          //     usertimeout: res.timeoutusercounts,
          //     checkin: res.checkincounts,
          //     usercheckin: res.checkinusercounts,
          //     activity: res.activitycounts,
          //     useractivity: res.activityusercounts,
          //     other: res.othercounts,
          //     userother: res.otherusercounts,
          //     notimein: res.notimeinusercounts,
          //     noactivity: res.noactivityusercounts,
          //     leave: res.leavecounts,
          //   };
          //   // this.pieChartData = [parseInt(this.summaryData.total), parseInt(this.summaryData.timein), parseInt(this.summaryData.timeout)]
          //   if (this.getcheckinForm.subtype == 'Employee') {
          //     this.barChartData = [
          //       { data: [parseInt(this.summaryData.total)], label: 'Total' },
          //       {
          //         data: [parseInt(this.summaryData.usertimein)],
          //         label: 'Time In',
          //       },
          //       {
          //         data: [parseInt(this.summaryData.useractive)],
          //         label: 'Active',
          //       },
          //       {
          //         data: [parseInt(this.summaryData.usertimeout)],
          //         label: 'Time Out',
          //       },
          //       {
          //         data: [parseInt(this.summaryData.usercheckin)],
          //         label: 'Check In',
          //       },
          //       {
          //         data: [parseInt(this.summaryData.useractivity)],
          //         label: 'Activity',
          //       },
          //       {
          //         data: [parseInt(this.summaryData.leave)],
          //         label: 'Leave',
          //       },
          //       // {
          //       //   data: [parseInt(this.summaryData.notimein)],
          //       //   label: 'No Time In',
          //       // },
          //       // {
          //       //   data: [parseInt(this.summaryData.noactivity)],
          //       //   label: 'No Activity',
          //       // },
          //     ];
          //   } else {
          //     this.barChartData = [
          //       // { data: [parseInt(this.summaryData.total)], label: 'Total' },
          //       { data: [parseInt(this.summaryData.timein)], label: 'Time In' },
          //       {
          //         data: [parseInt(this.summaryData.timeout)],
          //         label: 'Time Out',
          //       },
          //       {
          //         data: [parseInt(this.summaryData.checkin)],
          //         label: 'Check In',
          //       },
          //       {
          //         data: [parseInt(this.summaryData.activity)],
          //         label: 'Activity',
          //       },
          //     ];
          //   }
          //   this.gettingSummary = false;
          // } else {
          //   this.summaryData = {
          //     total: '0',
          //     timein: '0',
          //     timeout: '0',
          //     usertimein: '0',
          //     useractive: '0',
          //     usertimeout: '0',
          //     checkin: '0',
          //     usercheckin: '0',
          //     activity: '0',
          //     useractivity: '0',
          //     other: '0',
          //     userother: '0',
          //     notimein: '0',
          //     noactivity: '0',
          //     leave: '0',
          //   };
          //   // this.pieChartData = [parseInt(this.summaryData.total), parseInt(this.summaryData.timein), parseInt(this.summaryData.timeout)]
          //   this.barChartData = [
          //     // { data: [parseInt(this.summaryData.total)], label: 'Total' },
          //     { data: [parseInt(this.summaryData.timein)], label: 'Time In' },
          //     { data: [parseInt(this.summaryData.timeout)], label: 'Time Out' },
          //     { data: [parseInt(this.summaryData.checkin)], label: 'Check In' },
          //     {
          //       data: [parseInt(this.summaryData.activity)],
          //       label: 'Activity',
          //     },
          //   ];
          //   this.gettingSummary = false;
          //   this.messageService.openSnackBar(
          //     res.message || res.status || 'Server Error',
          //     'warn'
          //   );
          // }
        },
        (err) => {
          this.summaryData = {
            total: '0',
            timein: '0',
            timeout: '0',
            usertimein: '0',
            useractive: '0',
            usertimeout: '0',
            checkin: '0',
            usercheckin: '0',
            activity: '0',
            useractivity: '0',
            other: '0',
            userother: '0',
            notimein: '0',
            noactivity: '0',
            leave: '0',
          };
          // this.pieChartData = [parseInt(this.summaryData.total), parseInt(this.summaryData.timein), parseInt(this.summaryData.timeout)]
          this.barChartData = [
            // { data: [parseInt(this.summaryData.total)], label: 'Total' },
            { data: [parseInt(this.summaryData.timein)], label: 'Time In' },
            { data: [parseInt(this.summaryData.timeout)], label: 'Time Out' },
            { data: [parseInt(this.summaryData.checkin)], label: 'Check In' },
            { data: [parseInt(this.summaryData.activity)], label: 'Activity' },
          ];
          this.gettingSummary = false;
        }
      );
  }

  // getSummaryOrg() {
  //   var currentEndDate = formatDate(
  //     this.getcheckinForm.startdate,
  //     this.piedateFormat,
  //     'en-US'
  //   ).toString();
  //   // var addDays = 35;
  //   var addDays = 31;
  //   var date = new Date(this.getcheckinForm.startdate);
  //   date.setTime(date.getTime() + addDays * 24 * 60 * 60 * 1000);
  //   this.minDate = formatDate(date, this.formatToShow, 'en-US').toString();
  //   if (
  //     this.getcheckinForm.enddate > this.minDate ||
  //     this.getcheckinForm.enddate < this.getcheckinForm.startdate
  //   ) {
  //     this.getcheckinForm.enddate = this.getcheckinForm.startdate;
  //   }
  //   // this.pieChartOptions.title = {
  //   //   display: true,
  //   //   position: 'top',
  //   //   fontFamily: 'Ubuntu',
  //   //   fontSize: 15,
  //   //   text: this.getcheckinForm.orgname + " - " + currentEndDate,
  //   //   fullWidth: false,
  //   // }
  //   this.barChartOptions.title = {
  //     display: true,
  //     position: 'top',
  //     fontFamily: 'Ubuntu',
  //     fontSize: 15,
  //     text: this.getcheckinForm.orgname + ' - ' + currentEndDate,
  //     fullWidth: false,
  //   };
  //   this.gettingSummary = true;
  //   const data = {
  //     date: formatDate(
  //       new Date(this.getcheckinForm.startdate),
  //       this.dateFormat,
  //       'en-US'
  //     ).toString(),
  //     domain: this.allinoneService.getDomain().shortcode,
  //     domainid: this.allinoneService.getDomain().domainid,
  //     orgid: this.getcheckinForm.orgid,
  //     department: this.getcheckinForm.department == 'All' ? '' : this.getcheckinForm.department,
  //     division: this.getcheckinForm.division == 'All' ? '' : this.getcheckinForm.division,
  //     teamid: this.getcheckinForm.teamid == 'All' ? '' : this.getcheckinForm.teamid,
  //     costcenter: this.getcheckinForm.costcenter == 'All' ? '' : this.getcheckinForm.costcenter,
  //     connectionid : this.connectionId
  //   };
  //   this.summarySubscription && this.summarySubscription.unsubscribe();
  //   this.summarySubscription = this.kunyekService
  //     .getCheckInSummary(data)
  //     .subscribe(
  //       (res: any) => {
  //         if (res.returncode == 300) {
  //           this.summaryData = {
  //             total: res.orgusercounts,
  //             timein: res.timeincounts,
  //             timeout: res.timeoutcounts,
  //             usertimein: res.timeinusercounts,
  //             useractive: res.activeusercounts,
  //             usertimeout: res.timeoutusercounts,
  //             checkin: res.checkincounts,
  //             usercheckin: res.checkinusercounts,
  //             activity: res.activitycounts,
  //             useractivity: res.activityusercounts,
  //             other: res.othercounts,
  //             userother: res.otherusercounts,
  //             notimein: res.notimeinusercounts,
  //             noactivity: res.noactivityusercounts,
  //             leave: res.leavecounts,
  //           };

  //           // this.pieChartData = [parseInt(this.summaryData.total), parseInt(this.summaryData.timein), parseInt(this.summaryData.timeout)]
  //           if (this.getcheckinForm.subtype == 'Employee') {
  //             this.barChartData = [
  //               { data: [parseInt(this.summaryData.total)], label: 'Total' },
  //               {
  //                 data: [parseInt(this.summaryData.usertimein)],
  //                 label: 'Time In',
  //               },
  //               {
  //                 data: [parseInt(this.summaryData.useractive)],
  //                 label: 'Active',
  //               },
  //               {
  //                 data: [parseInt(this.summaryData.usertimeout)],
  //                 label: 'Time Out',
  //               },
  //               {
  //                 data: [parseInt(this.summaryData.usercheckin)],
  //                 label: 'Check In',
  //               },
  //               {
  //                 data: [parseInt(this.summaryData.useractivity)],
  //                 label: 'Activity',
  //               },
  //               {
  //                 data: [parseInt(this.summaryData.leave)],
  //                 label: 'Leave',
  //               },
  //               // {
  //               //   data: [parseInt(this.summaryData.notimein)],
  //               //   label: 'No Time In',
  //               // },
  //               // {
  //               //   data: [parseInt(this.summaryData.noactivity)],
  //               //   label: 'No Activity',
  //               // },
  //             ];
  //           } else {
  //             this.barChartData = [
  //               // { data: [parseInt(this.summaryData.total)], label: 'Total' },
  //               { data: [parseInt(this.summaryData.timein)], label: 'Time In' },
  //               {
  //                 data: [parseInt(this.summaryData.timeout)],
  //                 label: 'Time Out',
  //               },
  //               {
  //                 data: [parseInt(this.summaryData.checkin)],
  //                 label: 'Check In',
  //               },
  //               {
  //                 data: [parseInt(this.summaryData.activity)],
  //                 label: 'Activity',
  //               },
  //             ];
  //           }

  //           this.gettingSummary = false;
  //         } else {
  //           this.summaryData = {
  //             total: '0',
  //             timein: '0',
  //             timeout: '0',
  //             usertimein: '0',
  //             useractive: '0',
  //             usertimeout: '0',
  //             checkin: '0',
  //             usercheckin: '0',
  //             activity: '0',
  //             useractivity: '0',
  //             other: '0',
  //             userother: '0',
  //             notimein: '0',
  //             noactivity: '0',
  //             leave: '0',
  //           };
  //           // this.pieChartData = [parseInt(this.summaryData.total), parseInt(this.summaryData.timein), parseInt(this.summaryData.timeout)]
  //           this.barChartData = [
  //             // { data: [parseInt(this.summaryData.total)], label: 'Total' },
  //             { data: [parseInt(this.summaryData.timein)], label: 'Time In' },
  //             { data: [parseInt(this.summaryData.timeout)], label: 'Time Out' },
  //             { data: [parseInt(this.summaryData.checkin)], label: 'Check In' },
  //             {
  //               data: [parseInt(this.summaryData.activity)],
  //               label: 'Activity',
  //             },
  //           ];
  //           this.gettingSummary = false;
  //           this.messageService.openSnackBar(
  //             res.message || res.status || 'Server Error',
  //             'warn'
  //           );
  //         }
  //       },
  //       (err) => {
  //         this.summaryData = {
  //           total: '0',
  //           timein: '0',
  //           timeout: '0',
  //           usertimein: '0',
  //           useractive: '0',
  //           usertimeout: '0',
  //           checkin: '0',
  //           usercheckin: '0',
  //           activity: '0',
  //           useractivity: '0',
  //           other: '0',
  //           userother: '0',
  //           notimein: '0',
  //           noactivity: '0',
  //           leave: '0',
  //         };
  //         // this.pieChartData = [parseInt(this.summaryData.total), parseInt(this.summaryData.timein), parseInt(this.summaryData.timeout)]
  //         this.barChartData = [
  //           // { data: [parseInt(this.summaryData.total)], label: 'Total' },
  //           { data: [parseInt(this.summaryData.timein)], label: 'Time In' },
  //           { data: [parseInt(this.summaryData.timeout)], label: 'Time Out' },
  //           { data: [parseInt(this.summaryData.checkin)], label: 'Check In' },
  //           { data: [parseInt(this.summaryData.activity)], label: 'Activity' },
  //         ];
  //         this.gettingSummary = false;
  //       }
  //     );
  // }

  searchWithDateRangeWithSocket(absentreportFilter: boolean) {
    const data = {
      startdate: formatDate(
        new Date(this.getcheckinForm.startdate),
        this.dateFormat,
        'en-US'
      ).toString(),
      enddate: formatDate(
        new Date(this.getcheckinForm.enddate),
        this.dateFormat,
        'en-US'
      ).toString(),
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.getcheckinForm.orgid,
      department:
        this.getcheckinForm.drdepartment == 'All'
          ? ''
          : this.getcheckinForm.drdepartment,
      division:
        this.getcheckinForm.drdivision == 'All'
          ? ''
          : this.getcheckinForm.drdivision,
      teamid:
        this.getcheckinForm.drteamid == 'All'
          ? ''
          : this.getcheckinForm.drteamid,
      subdivision:
        this.getcheckinForm.drsubdivision == 'All'
          ? ''
          : this.getcheckinForm.drsubdivision,
      costcenter:
        this.getcheckinForm.drcostcenter == 'All'
          ? ''
          : this.getcheckinForm.drcostcenter,
      activestatus: this.getcheckinForm.drstatus,
      absentreport: absentreportFilter,
      connectionid: absentreportFilter
        ? this.connectionIdAR
        : this.connectionIdDR,
    };

    this.kunyekService.getSummaryWithDateRange(data).subscribe(
      (res: any) => {
        if (res.returncode == 300) {
          console.log('api end');
        } else {
          this.searchLoadingDR = false;
          if (absentreportFilter) {
            this.absentData = [];
          } else {
            this.daterangeData = [];
          }
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
      },
      (err) => {
        this.searchLoadingDR = false;
      }
    );
    // this.daterangeData = [];
    // this.searchLoading = false;
  }

  searchWithDateRange(absentreportFilter: boolean) {
    console.log('ans');
    console.log(absentreportFilter);

    if (absentreportFilter) {
      this.searchLoadingAR = true;
    } else {
      this.searchLoadingDR = true;
    }

    const data = {
      startdate: formatDate(
        new Date(this.getcheckinForm.startdate),
        this.dateFormat,
        'en-US'
      ).toString(),
      enddate: formatDate(
        new Date(this.getcheckinForm.enddate),
        this.dateFormat,
        'en-US'
      ).toString(),
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.getcheckinForm.orgid,
      department:
        this.getcheckinForm.drdepartment == 'All'
          ? ''
          : this.getcheckinForm.drdepartment,
      division:
        this.getcheckinForm.drdivision == 'All'
          ? ''
          : this.getcheckinForm.drdivision,
      teamid:
        this.getcheckinForm.drteamid == 'All'
          ? ''
          : this.getcheckinForm.drteamid,
      subdivision:
        this.getcheckinForm.drsubdivision == 'All'
          ? ''
          : this.getcheckinForm.drsubdivision,
      absentreport: absentreportFilter,
      connectionid: absentreportFilter
        ? this.connectionIdAR
        : this.connectionIdDR,
    };
    // var addDays=35
    // var date=new Date(this.getcheckinForm.startdate)
    // console.log(date)
    // date.setTime(date.getTime() + (addDays * 24 * 60 * 60 * 1000));
    // console.log(date)
    // this.minDate=formatDate(
    //   date,
    //   this.formatToShow,
    //   'en-US'
    // ).toString();

    this.kunyekService.getSummaryWithDateRange(data).subscribe(
      (res: any) => {
        if (res.returncode == 300) {
          if (absentreportFilter) {
            this.absentData = res.datalist;
          } else {
            this.daterangeData = res.datalist;
            this.daterangeShowData = this.daterangeData;
          }
          this.daterangePG[3].count = res.datalist.length;
          // this.searchLoading = false;
          if (absentreportFilter) {
            this.searchLoadingAR = false;
          } else {
            this.searchLoadingDR = false;
          }
        } else {
          // this.searchLoading = false;
          if (absentreportFilter) {
            this.searchLoadingAR = false;
          } else {
            this.searchLoadingDR = false;
          }
          if (absentreportFilter) {
            this.absentData = [];
          } else {
            this.daterangeData = [];
          }
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
      },
      (err) => {
        // this.searchLoading = false;
        if (absentreportFilter) {
          this.searchLoadingAR = false;
        } else {
          this.searchLoadingDR = false;
        }
      }
    );
    // this.daterangeData = [];
    // this.searchLoading = false;
  }

  // searchLocWithPagination() {
  //   this.start = this.start + Number(this.itemPerPageLoc);
  //   this.end = this.end + Number(this.itemPerPageLoc);
  //   this.gettingLocData = true;
  //   const data = {
  //     startdate: formatDate(
  //       new Date(this.getcheckinForm.startdate),
  //       this.dateFormat,
  //       'en-US'
  //     ).toString(),
  //     enddate: formatDate(
  //       new Date(this.getcheckinForm.enddate),
  //       this.dateFormat,
  //       'en-US'
  //     ).toString(),
  //     domain: this.allinoneService.getDomain().shortcode,
  //     domainid: this.allinoneService.getDomain().domainid,
  //     orgid: this.getcheckinForm.orgid,
  //     department: this.getcheckinForm.drdepartment == 'All' ? '' : this.getcheckinForm.drdepartment,
  //     division: this.getcheckinForm.drdivision == 'All' ? '' : this.getcheckinForm.drdivision,
  //     teamid: this.getcheckinForm.drteamid == 'All' ? '' : this.getcheckinForm.drteamid,
  //     locationid: this.getcheckinForm.locid,
  //     type: this.getcheckinForm.type,
  //     // key: this.key,
  //     // limit: Number(this.itemPerPageLoc),
  //     start: this.start,
  //     end: this.end,
  //     filename: this.isSearch ? this.locsearchfilename : this.filename
  //   };

  //   this.kunyekService.getSummaryWithLocation(data).subscribe(
  //     (res: any) => {
  //       console.log(res.datalist.length);
  //       if (res.returncode == '300') {
  //         // this.locShowData = res.datalist;
  //         // this.filename = res.filename
  //         for (var i = 0; i < res.datalist.length; i++) {
  //           this.locShowData.push(res.datalist[i]);
  //         }
  //         if (res.datalist.length < this.itemPerPageLoc) {
  //           this.nextDisabled = true;
  //           this.lastPage = this.currentPageLoc;
  //         }
  //         // if (res.key == '') {
  //         //   this.nextDisabled = true;
  //         //   this.lastPage = this.currentPageLoc;
  //         //   this.key = this.key;
  //         // } else {
  //         //   this.key = res.key;
  //         // }
  //         // console.log(this.locShowData.length);
  //       } else {
  //         this.locShowData = [];
  //         this.messageService.openSnackBar(
  //           res.message || res.status || 'Server Error',
  //           'warn'
  //         );
  //       }
  //       this.gettingLocData = false;
  //       this.searchLoading = false;
  //     },
  //     (err) => {
  //       this.locShowData = [];
  //       this.gettingLocData = false;
  //       this.searchLoading = false;
  //     }
  //   );
  // }

  searchWithLoc(filename: string = '') {
    this.searchLoadingLOC = true;
    // this.resetPagination();
    this.locShowData = [];
    this.locTotal = 0;
    // this.key = '';
    // this.start = 0;
    // this.isSearch = false;
    // this.end = Number(this.itemPerPageLoc);
    const data = {
      startdate: formatDate(
        new Date(this.getcheckinForm.startdate),
        this.dateFormat,
        'en-US'
      ).toString(),
      enddate: formatDate(
        new Date(this.getcheckinForm.enddate),
        this.dateFormat,
        'en-US'
      ).toString(),
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.getcheckinForm.orgid,
      // department: this.getcheckinForm.drdepartment,
      // division: this.getcheckinForm.drdivision,
      // teamid: this.getcheckinForm.drteamid,
      department:
        this.getcheckinForm.drdepartment == 'All'
          ? ''
          : this.getcheckinForm.drdepartment,
      division:
        this.getcheckinForm.drdivision == 'All'
          ? ''
          : this.getcheckinForm.drdivision,
      teamid:
        this.getcheckinForm.drteamid == 'All'
          ? ''
          : this.getcheckinForm.drteamid,
      subdivision:
        this.getcheckinForm.drsubdivision == 'All'
          ? ''
          : this.getcheckinForm.drsubdivision,
      costcenter:
        this.getcheckinForm.drcostcenter == 'All'
          ? ''
          : this.getcheckinForm.drcostcenter,
      activestatus: this.getcheckinForm.drstatus,
      locationid: this.getcheckinForm.locid,
      type: this.getcheckinForm.type,
      connectionid: this.connectionIdLOC,
      // key: this.key,
      // limit: Number(this.itemPerPageLoc),
      // start: 0,
      // end: Number(this.itemPerPageLoc),
      // filename: filename,
      // searchtext: ''
    };

    this.kunyekService.getSummaryWithLocation(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.locShowData = res.datalist;
          // this.key = res.key;
          // this.filename = res.filename
          // this.locTotal = res.totalcount;
          // this.locfileurl = res.fileurl;
          // if (res.datalist.length < this.itemPerPageLoc) {
          //   this.nextDisabled = true;
          //   this.lastPage = this.currentPageLoc;
          // }
          // if (res.key == '') {
          //   this.nextDisabled = true;
          //   this.lastPage = this.currentPageLoc;
          //   this.key = this.key;
          // }
        } else {
          console.log('hereeeeeeee');
          this.locShowData = [];
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        // this.searchLoading = false;
        // this.gettingLocData = false;
      },
      (err) => {
        this.locShowData = [];
        this.gettingLocData = false;
        this.searchLoadingLOC = false;
      }
    );
  }

  searchByProj() {
    this.formSubmitted = true;
    this.searchLoadingProject = true;
    const data = {
      projectid:
        this.projects[parseInt(this.getcheckinForm.projindex)].projectid,
      jobid:
        this.getcheckinForm.jobindex == ''
          ? ''
          : this.jobs[parseInt(this.getcheckinForm.jobindex)].jobid,
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.getcheckinForm.orgid,
      startdate: this.allinoneService.formatDate(this.projStartDate),
      enddate: this.allinoneService.formatDate(this.projEndDate),
      role: '300',
    };
    this.kunyekService.getProjectAndJobSummary(data).subscribe(
      (res: any) => {
        if (res.returncode == 300) {
          this.projData = res.datalist;
          this.searchLoadingProject = false;
          this.formSubmitted = false;
          this.tasktype =
            this.getcheckinForm.jobindex == '' ? 'project' : 'job';
          this.projectPG[3].count = this.projData.length;
        } else {
          this.searchLoadingProject = false;
          this.formSubmitted = false;
          this.projData = [];
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
      },
      (err) => {
        this.projData = [];
        this.searchLoadingProject = false;
        this.formSubmitted = false;
      }
    );
  }

  filterByDate() {
    const dialog = this.dialog.open(CheckInProjectDaterangeComponent, {
      height: 'auto',
      maxWidth: '400px',
      minWidth: '350px',
      closeOnNavigation: true,
      panelClass: ['mat-typography'],
      data: {
        startdate: this.projStartDate,
        enddate: this.projEndDate,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.projStartDate = dialogResult.startdate;
        this.projEndDate = dialogResult.enddate;
        this.searchByProj();
      }
    });
  }

  changeFormat(timeStr: any) {
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes, second] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}:${second}`;
  }

  calEndTime(date: any, starttime: any, childdate: any, childstarttime: any) {
    var format = 'yyyy/MM/dd hh:mm:ss a';
    var datetime = date;
    var yy = datetime.slice(0, 4);
    var mm = datetime.slice(4, 6);
    var dd = datetime.slice(6, 8);
    var checkinD =
      yy +
      '/' +
      mm +
      '/' +
      dd +
      ' ' +
      this.allinoneService.formatTimeToShow(starttime);

    var cdatetime = childdate;
    var cyy = cdatetime.slice(0, 4);
    var cmm = cdatetime.slice(4, 6);
    var cdd = cdatetime.slice(6, 8);
    var childD =
      cyy +
      '/' +
      cmm +
      '/' +
      cdd +
      ' ' +
      this.allinoneService.formatTimeToShow(childstarttime);
    let date1 = new Date(checkinD);
    let date2 = new Date(childD);
    var diffMin = this.diff_minutes(date1, date2);
    return this.transformMinute(diffMin);
  }

  calDuration(datetime: any) {
    var durationArray = datetime.split('-');
    var day = parseInt(durationArray[0]);
    var hr = parseInt(durationArray[1]);
    var min = parseInt(durationArray[2]);
    if (day == 0) {
      if (hr == 0) {
        if (min == 0) {
          return '1 mins ';
        }
        return min + ' mins ';
      }
      if (hr == 1) {
        if (min == 1) {
          return durationArray[1] + ' hr ' + durationArray[2] + ' min ';
        }
        return durationArray[1] + ' hr ' + durationArray[2] + ' mins ';
      }
      return durationArray[1] + ' hrs ' + durationArray[2] + ' mins ';
    } else {
      if (hr == 0) {
        if (day == 1) {
          if (min == 1) {
            return durationArray[0] + ' day ' + durationArray[2] + ' min ';
          }
          return durationArray[0] + ' day ' + durationArray[2] + ' mins ';
        }
        return durationArray[0] + ' days ' + durationArray[2] + ' mins ';
      } else if (hr == 1) {
        if (day == 1) {
          if (min == 1) {
            return (
              durationArray[0] +
              ' day ' +
              durationArray[1] +
              ' hr ' +
              durationArray[2] +
              ' min'
            );
          }
          return (
            durationArray[0] +
            ' day ' +
            durationArray[1] +
            ' hr ' +
            durationArray[2] +
            ' mins '
          );
        }
        return (
          durationArray[0] +
          ' days ' +
          durationArray[1] +
          ' hr ' +
          durationArray[2] +
          ' mins '
        );
      }
      return (
        durationArray[0] +
        ' day ' +
        durationArray[1] +
        ' hr ' +
        durationArray[2] +
        ' mins '
      );
    }
  }

  diff_minutes(dt2: any, dt1: any) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  transformMinute(value: number): string {
    var hours = Math.floor(value / 60);
    let minutes = Math.floor(value % 60);
    var day = 0;
    if (hours == 0) {
      return minutes + ' mins';
    } else if (hours == 1) {
      return hours + ' hr ' + minutes + ' mins ';
    } else if (hours == 24) {
      day = Math.floor(hours / 24);
      hours = Math.floor(hours % 24);
      if (hours == 0) {
        return day + ' days ' + minutes + ' mins ';
      }
      return day + ' day ' + hours + ' hr ' + minutes + ' mins ';
    } else if (hours > 24) {
      day = Math.floor(hours / 24);
      hours = Math.floor(hours % 24);
      if (hours == 0) {
        return day + ' days ' + minutes + ' mins ';
      }
      return day + ' days ' + hours + ' hr ' + minutes + ' mins ';
    } else {
      return hours + ' hrs ' + minutes + ' mins ';
    }
  }

  hide(j: any, x: any) {
    var index = x;
    this.sortedArray[j].datalist[index].show = this.sortedArray[j].datalist[
      index
    ].show
      ? false
      : true;
  }

  hideMemberCheckIn(j: any) {
    this.sortedArray[j].show = !this.sortedArray[j].show;
    if (this.sortedArray[j].show == false) {
      this.sortedArray[j].datalist.map((item: any) => {
        item.show = false;
      });
    }
  }

  getCheckInList() {
    console.log('true');

    // if(this.getcheckinForm.userid == '' || this.getcheckinForm.userid == null ) {
    //   this.messageService.openSnackBar('User ID cannot be blank.', 'warn');
    //   return;
    // }
    this.searchLoadingMember = true;

    var domain = this.allinoneService.getDomain();

    var startd = formatDate(
      new Date(this.getcheckinForm.startdate),
      this.dateFormat,
      'en-US'
    ).toString();
    var endd = formatDate(
      new Date(this.getcheckinForm.enddate),
      this.dateFormat,
      'en-US'
    ).toString();
    if (
      new Date(this.getcheckinForm.startdate) >
      new Date(this.getcheckinForm.enddate)
    ) {
      this.messageService.openSnackBar(
        'Please select the valid end date!',
        'warn'
      );
      this.searchLoadingMember = false;
      return;
    }
    let userID = '';
    if (this.getcheckinForm.userid != '' && this.getcheckinForm.membertype == 'User ID') {
      userID = this.allinoneService.checkUserId(this.getcheckinForm.userid);
    } else {
      userID = this.getcheckinForm.userid.toString().trim();
    }
    var data = {
      startdate: startd,
      enddate: endd,
      domain: domain.shortcode,
      domainid: domain.domainid,
      searchuser: userID,
      orgid: this.getcheckinForm.orgid,
      searchtype:
        this.getcheckinForm.membertype == 'User Name'
          ? '2222'
          : this.getcheckinForm.membertype == 'User ID'
            ? '0000'
            : '1111',
      connectionid: this.connectionIdMember,
    };
    this.kunyekService.getAdminCheckInListBySocket(data).subscribe(
      (res) => {
        // this.sortedArray = [];

        // var d: any = res;
        // console.log(d);
        if (res.returncode == '300') {
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
          this.searchLoadingMember = false;
          this.atFormSubmitted = false;

          this.connectionIdMember = '';
          this.socketMember?.close(3001);
          clearTimeout(this.socketConnectTimeMember);
        }
      },
      (err) => {
        this.searchLoadingMember = false;
        this.atFormSubmitted = false;
        this.connectionIdMember = '';
        this.socketMember?.close(3001);
        clearTimeout(this.socketConnectTimeMember);
      }
    );
  }

  removeDate() {
    this.projStartDate = '';
    this.projEndDate = '';
    this.projData = [];
  }

  chooseIDtype(type: string) {
    // this.isuserID = !this.isuserID;
    // if (this.isuserID == true) {
    //   this.IDText = 'User ID';
    //   this.IDPlaceholder = 'Enter User ID';
    //   this.AuthPlaceholder = 'User ID';
    //   this.getcheckinForm.userid = '';
    // } else {
    //   this.IDText = 'Employee ID';
    //   this.IDPlaceholder = 'Enter Employee ID';
    //   this.AuthPlaceholder = 'Employee ID';
    //   this.getcheckinForm.userid = '';
    // }
    this.getcheckinForm.membertype = type;
    this.IDPlaceholder = 'Enter ' + type;
    this.getcheckinForm.userid = '';
  }

  // getMemberPosition() {
  //   this.gettingMemberPosition = true;
  //   const data = {
  //     orgid: this.getcheckinForm.orgid,
  //   };
  //   this.subscriptions.sink = this.kunyekService
  //     .getMemberPosition(data)
  //     .subscribe(
  //       (res: any) => {
  //         if (res.returncode == '300') {
  //           this.departmentList = res.departmentlist;
  //           this.divisionList = res.divisionlist;
  //           this.teamidList = res.teamidlist;
  //         } else {
  //           this.messageService.openSnackBar(
  //             res.message || res.status || 'Server Error',
  //             'warn'
  //           );
  //         }
  //         this.gettingMemberPosition = false;
  //       },
  //       (err) => {
  //         this.gettingMemberPosition = false;
  //       }
  //     );
  // }

  getMemberPosition() {
    this.gettingMemberPosition = true;
    const data = {
      orgid: this.getcheckinForm.orgid,
    };
    this.subscriptions.sink = this.kunyekService
      .getMemberPosition(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            // this.departmentList = res.departmentlist;
            // this.divisionList = res.divisionlist;
            // this.teamidList = res.teamidlist;
            for (var i = 0; i < res.departmentlist.length; i++) {
              this.departmentList.push(res.departmentlist[i]);
            }
            for (var i = 0; i < res.divisionlist.length; i++) {
              this.divisionList.push(res.divisionlist[i]);
            }
            for (var i = 0; i < res.teamidlist.length; i++) {
              this.teamidList.push(res.teamidlist[i]);
            }
            for (var i = 0; i < res.costcenterlist.length; i++) {
              this.costCenterList.push(res.costcenterlist[i]);
            }
            for (var i = 0; i < res.subdivision.length; i++) {
              this.subDivisionList.push(res.subdivision[i]);
            }
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingMemberPosition = false;
        },
        (err) => {
          this.gettingMemberPosition = false;
        }
      );
  }

  absenceLeaveDetails(type: String, data: any) {
    // ApprovalExportComponent
    const dialog = this.dialog.open(LeaveAbsenceDetailViewComponent, {
      maxWidth: '95vw',
      width: '600px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        type: type,
        list: data,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => { });
  }

  dateRangeStatusChange(status: boolean) {
    this.p = 1;
    this.viewAllDRData = status;
    if (status) {
      this.daterangeShowData = this.daterangeData;
    } else {
      this.daterangeShowData = this.daterangeData.filter(
        (x: any) => x.notimein == true
      );
    }
  }

  importSheet(isError: boolean = false) {
    clearInterval(this.statusInterval);
    const dialog = this.dialog.open(FileUploadComponent, {
      maxHeight: '95vh',
      maxWidth: '95vw',
      // height: '400px',
      width: '600px',
      closeOnNavigation: true,
      panelClass: ['mat-typography'],
      data: {
        isError: isError,
        orgid: this.getcheckinForm.orgid,
        domainid: this.domainid,
        errorData: this.importData,
        type: '003',
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      clearInterval(this.statusInterval);
      this.getImportStatus();
      this.statusInterval = setInterval(() => {
        if (
          this.importStatus != '001' &&
          this.importStatus != '005' &&
          this.importStatus != '000'
        ) {
          this.getImportStatus();
        }
      }, 5000);
    });
  }

  getImportStatus() {
    this.refreshingImportStatus = true;
    var data = {
      type: '007',
      orgid: this.getcheckinForm.orgid,
    };
    this.kunyekService.getHierarchyImportStatus(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (
            (this.importStatus == '002' || this.importStatus == '003') &&
            res.status != this.importStatus
          ) {
            // this.getSummary();
            this.callSummary();
          }
          this.importStatus = res.status;
          this.importData = res;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.refreshingImportStatus = false;
      },
      (err) => {
        this.refreshingImportStatus = false;
      }
    );
  }

  openTicketDetails(ticket: any) {
    const dialog = this.dialog.open(CheckinTicketDetailsComponent, {
      width: '700px',
      height: '600px',
      maxHeight: '95vw',
      maxWidth: '95vw',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      data: {
        orgid: this.getcheckinForm.orgid,
        ticketno: ticket,
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
      }
    });
  }
} 
