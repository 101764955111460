<!-- <div infinite-scroll> -->
<div *ngIf="post == undefined">
  <div class="title">
    Wrong post url...
  </div>
</div>
<div *ngIf="post != undefined" class="post-header">
  <div class="title-container">
    <button mat-icon-button class="back-btn" (click)="back()">
      <mat-icon>arrow_back</mat-icon>
      <!-- <i class="fas fa-arrow-left" style="font-size: 20px;" mat-dialog-close></i> -->
    </button>
    <div *ngIf="post.type == '2' || post.type == '4'" class="title">
      {{post.admin}}'s Post
    </div>
    <div *ngIf="post.type == '1'" class="title">
      {{domaindesc}}'s Post
    </div>
    <div *ngIf="post.type == '3'" class="title">
      {{post.orgname}}'s Post
    </div>
  </div>
</div>

<div *ngIf="post != undefined" class="main row">

  <!-- <div class="left-column col-md-6"> -->
  <perfect-scrollbar style="height: auto !important;" class="col-md-6" [config]="config" [scrollIndicators]="true"
    [ngClass]="{'left-column' : !isMobileView}">
    <div class="profile-container">
      <span *ngIf="post.type == '1'">
        <img class="profile-image" [defaultImage]="'../../../assets/images/bg.jpg'"
          [lazyLoad]="post.domainurl ? post.domainurl : '../../../assets/images/post_admin.png'">
      </span>
      <span *ngIf="post.type == '2' || post.type == '4'">
        <img class="profile-image" (click)="viewProfile(post.adminid)" [defaultImage]="'../../../assets/images/bg.jpg'"
          [lazyLoad]="post.adminimage ? post.adminimage : '../../../assets/images/profile.png'">
      </span>
      <span *ngIf="post.type == '3'">
        <img class="profile-image" [defaultImage]="'../../../assets/images/bg.jpg'"
          [lazyLoad]="post.orgimageurl ? post.orgimageurl :'../../../assets/images/post_admin.png'">
      </span>

      <div class="profile-item-container">
        <div class="profile-item">
          <div class="username" *ngIf="post.type == '1'">
            <b>{{domaindesc}}</b> <img class="bluemark" src="../../../assets/images/bluemark.png">

          </div>
          <!-- <div class="username" *ngIf="post.type == '4'">
                        <b class="user-id" (click)="viewProfile(post.adminid)">{{post.admin ?
                            post.admin : post.adminid}}</b>
                        <span *ngIf = "post.orgname">
                            &nbsp; <i class="fas fas fa-at"></i> &nbsp;
                            <b>{{post.orgname}}</b>
                        </span>
                    </div> -->
          <div class="username" *ngIf="post.type == '2' || post.type == '4'">
            <p class="username-text">
              <span *ngIf="post.orgtag.length > 0">
                <b class="user-id" (click)="viewProfile(post.adminid)">{{post.admin ? post.admin : post.adminid}}</b>

                &nbsp;is&nbsp;with&nbsp;
                <b>{{post.orgtag[0].orgname}}</b>
              </span>
              <span
                *ngIf="post.orgtag.length == 0 && (post.tag.length == 0 || post.tag == undefined || post.tag == null)">
                <b class="user-id" (click)="viewProfile(post.adminid)">{{post.admin ? post.admin : post.adminid}}</b>

              </span>
              <span *ngIf="post.orgtag.length == 0 && (post.tag.length == 1)">
                <b class="user-id" (click)="viewProfile(post.adminid)">{{post.admin ? post.admin : post.adminid}}</b>

                &nbsp;is&nbsp;with&nbsp;
                <b class="user-id" (click)="viewProfile(post.tag[0].userid)">{{post.tag[0].username ?
                  post.tag[0].username : post.tag[0].userid}}</b>


              </span>
              <span *ngIf="post.orgtag.length == 0 && (post.tag.length == 2)">
                <b class="user-id" (click)="viewProfile(post.adminid)">{{post.admin ? post.admin : post.adminid}}</b>

                &nbsp;is&nbsp;with&nbsp;
                <b class="user-id" (click)="viewProfile(post.tag[0].userid)">{{post.tag[0].username ?
                  post.tag[0].username : post.tag[0].userid}}</b>

                &nbsp;and&nbsp;
                <b class="user-id" (click)="viewProfile(post.tag[1].userid)">{{post.tag[1].username ?
                  post.tag[1].username : post.tag[1].userid}}</b>

              </span>
              <span *ngIf="post.tag.length > 2">
                <b class="user-id" (click)="viewProfile(post.adminid)">{{post.admin ? post.admin : post.adminid}}</b>

                &nbsp;is&nbsp;with&nbsp;
                <b class="user-id" (click)="viewProfile(post.tag[0].userid)">{{post.tag[0].username ?
                  post.tag[0].username : post.tag[0].userid}}</b>

                &nbsp;and&nbsp;
                <b class="viewOtherTag" (click)="LikeAndTagForm(post.syskey, 'tags', post.tag)">
                  {{post.tag.length - 1}} others
                </b>
              </span>
              <span *ngIf="post.orgname && post.type == '4'">
                &nbsp;<i class="fas fa-at"></i>&nbsp;
                <b>{{post.orgname}}</b>
              </span>
              <span *ngIf="post.channelid && post.type == '2'">
                &nbsp;<i class="fas fa-at"></i>&nbsp;
                <b>{{post.channelname}}</b>
              </span>
            </p>
          </div>
          <div class="username" *ngIf="post.type == '3'">
            <p class="username-text">
              <span *ngIf="post.orgtag.length > 0">
                <b>{{post.orgname}}</b> <img class="bluemark" src="../../../assets/images/bluemark.png">

                &nbsp;is&nbsp;with&nbsp;
                <b>{{post.orgtag[0].orgname}}</b>
              </span>
              <span
                *ngIf="post.orgtag.length == 0 && (post.tag.length == 0 || post.tag == undefined || post.tag == null)">
                <b>{{post.orgname}}</b> <img class="bluemark" src="../../../assets/images/bluemark.png">

              </span>
              <span *ngIf="post.orgtag.length == 0 && (post.tag.length == 1)">
                <b>{{post.orgname}}</b> <img class="bluemark" src="../../../assets/images/bluemark.png">

                &nbsp;is&nbsp;with&nbsp;
                <b class="user-id" (click)="viewProfile(post.tag[0].userid)">{{post.tag[0].username ?
                  post.tag[0].username : post.tag[0].userid}}</b>


              </span>
              <span *ngIf="post.orgtag.length == 0 && (post.tag.length == 2)">
                <b>{{post.orgname}}</b> <img class="bluemark" src="../../../assets/images/bluemark.png">

                &nbsp;is&nbsp;with&nbsp;
                <b class="user-id" (click)="viewProfile(post.tag[0].userid)">{{post.tag[0].username ?
                  post.tag[0].username : post.tag[0].userid}}</b>

                &nbsp;and&nbsp;
                <b class="user-id" (click)="viewProfile(post.tag[1].userid)">{{post.tag[1].username ?
                  post.tag[1].username : post.tag[1].userid}}</b>

              </span>
              <span *ngIf="post.tag.length > 2">
                <b>{{post.orgname}}</b> <img class="bluemark" src="../../../assets/images/bluemark.png">

                &nbsp;is&nbsp;with&nbsp;
                <b class="user-id" (click)="viewProfile(post.tag[0].userid)">{{post.tag[0].username ?
                  post.tag[0].username : post.tag[0].userid}}</b>

                &nbsp;and&nbsp;
                <b class="viewOtherTag" (click)="LikeAndTagForm(post.syskey, 'tags', post.tag)">
                  {{post.tag.length - 1}} others
                </b>
              </span>

            </p>

          </div>
          <div class="time d-flex">
            <!-- <div>
              {{allinoneService.formatDBToShow(post.date)}}, {{allinoneService.formatTimeToShow(post.time)}}
            </div> -->
            <div>
              {{(allinoneService.formatDBToShowInEdit(post.date,
                '/')
                +' '+ post.time) | datetimeago}}
            </div>
            <div class="d-flex align-items-center">
              <i class="fa fa-circle" style="margin-left: 5px; font-size: 6px;"></i>
            </div>
            <div style="margin-left: 5px;">
              <i class="fas fa-globe-americas"
                *ngIf="post.postsetting == '001' && (post.type != '3' && post.type !='4')"></i>
              <i class="fas fa-globe-americas"
                *ngIf="post.postsetting == '001' && (post.type == '3' || post.type == '4')"></i>
              <i class="fas fa-user-friends"
                *ngIf="(post.postsetting == '002' || post.postsetting == '') && (post.type != '3' && post.type !='4')"></i>
              <i class="fal fa-user-friends" style="color: #333;"
                *ngIf="(post.postsetting == '002' || post.postsetting == '') && (post.type == '3' || post.type =='4')"></i>
              <i class="fa fa-tags" *ngIf="post.postsetting == '003'"></i>
              <i class="fas fa-lock" *ngIf="post.postsetting == '004'"></i>
              <i class="fas fa-comment-alt" *ngIf="post.postsetting == '005'"></i>
            </div>
          </div>

          <!-- <div>End Left Side</div> -->
        </div>
        <div class="post-menu">
          <button mat-icon-button [matMenuTriggerFor]="menu"
            *ngIf="(post.type == 1 && postDomainAdmin) || ((post.type == 3 || post.type == '4') &&(orgAdmin || postAdmin)) || post.type == '2' || post.type == '4'">
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="menu" xPosition="before">
            <button mat-menu-item class="menu-container" (click)="duplicatePost()">
              <div class="menu-icon-holder">
                <div class="menu-icon">
                  <mat-icon class="ic">content_copy</mat-icon>
                </div>
              </div>
              <div class="menu-text">
                Duplicate Post
              </div>
            </button>
            <!-- <span
              *ngIf="((post.type == 2 || post.type == 4) && post.adminid != userid) || ((post.type == 3 || post.type == 4) && !allinoneService.checkOrgIsInAdminOrgList(post.orgid)) || (post.type == 1 && !postDomainAdmin)"> -->
              <span *ngIf="(post.type == 2 && post.adminid != userid)">
              <button mat-menu-item class="menu-container" (click)="reportForm(post.syskey)">
                <div class="menu-icon-holder">
                  <div class="menu-icon">
                    <mat-icon class="ic">report</mat-icon>
                  </div>
                </div>
                <div class="menu-text">
                  Report
                  <span>I'm concerned about this post.</span>
                </div>
              </button>
              <button mat-menu-item class="menu-container" (click)="blockUser()">
                <div class="menu-icon-holder">
                  <div class="menu-icon">
                    <mat-icon class="ic">block</mat-icon>
                  </div>
                </div>
                <div class="menu-text">
                  Block
                  <span>Stop seeing posts and remove from friends list.</span>
                </div>
              </button>
            </span>
            <span
              *ngIf="((post.type == 2 || post.type == 4 ) && post.adminid == userid) || ((post.type == 3 || post.type == '4') &&(orgAdmin || postAdmin)) || (post.type == 1 && postDomainAdmin)">
              <button mat-menu-item class="menu-container" (click)="editPost()">
                <div class="menu-icon-holder">
                  <div class="menu-icon">
                    <mat-icon class="ic">edit</mat-icon>
                  </div>
                </div>
                <div class="menu-text">
                  Edit Post
                </div>
              </button>
            </span>
            <span
              *ngIf="((post.type == 2 || post.type == 4 ) && post.adminid == userid) || ((post.type == 2 || post.type == 4 || post.type == 3) &&(orgAdmin || postAdmin)) || (post.type == 1 && postDomainAdmin) || domainAdmin">
              <button mat-menu-item class="menu-container" (click)="deletePost()">
                <div class="menu-icon-holder">
                  <div class="menu-icon">
                    <mat-icon class="ic">delete</mat-icon>
                  </div>
                </div>
                <div class="menu-text">
                  Delete Post
                </div>
              </button>
            </span>
          </mat-menu>
        </div>
      </div>

    </div>
    <div *ngIf="!post.showMore" style="line-height: 1.7;margin-bottom: 10px;">
      <span [innerHTML]="allinoneService.replaceEnter(trimString(post.note,150))"
        style="line-height: 1.7;margin-bottom: 10px;"></span>
      <span *ngIf="post.note.length> 150" (click)="post.showMore = !post.showMore" class="showMore">{{post.showMore ? ''
        : 'See More'}}</span>
    </div>
    <span *ngIf="post.showMore" style="line-height: 1.7;margin-bottom: 10px;"
      [innerHTML]="allinoneService.replaceEnter(post.note)"></span>

    <div
      *ngIf="post.meetingtype == '1'||post.meetingtype == '2'||post.meetingtype == '3'||post.meetingtype == '5' ||post.meetingtype == '6' || ( post.meetinglink!='' && post.meetingtype == '')"
      class="meeting-container">
      <div>
        <!-- <i class="fas fa-calendar-alt"></i> -->
        <span class="event-title" *ngIf="post.meetingtype == '1' || ( post.meetinglink!='' && post.meetingtype == '')"
          style="font-weight: bold; margin-top: 5px; margin-bottom: 5px; ">
          Meeting
        </span>
        <span class="event-title" *ngIf="post.meetingtype == '6' || ( post.meetinglink!='' && post.meetingtype == '')"
          style="font-weight: bold; margin-top: 5px; margin-bottom: 5px; ">
          Class
        </span>
        <span class="event-title" *ngIf="post.meetingtype == '2'"
          style="font-weight: bold; margin-top: 5px; margin-bottom: 5px; ">
          Seminar
        </span>
        <span class="event-title" *ngIf="post.meetingtype == '3'"
          style="font-weight: bold; margin-top: 5px; margin-bottom: 5px;">
          Conference
        </span>

        <span class="event-title" *ngIf="post.meetingtype == '5'"
          style="font-weight: bold; margin-top: 5px; margin-bottom: 5px; ">
          Other
        </span>
      </div>
      <div style="margin-top: 10px ;">
        <span>{{post.venue}}</span>

      </div>

      <div style="margin-top: 10px ;" *ngIf="post.meetingtype != '3'">
        <span>{{allinoneService.formatDBToShow(post.startdate)}} </span>
        <span>{{allinoneService.formatTimeToShow(post.starttime)}} to
          {{allinoneService.formatTimeToShow(post.endtime)}}</span>
        <span *ngIf="post.meetingappear">
          <span *ngIf="post.meetingappear == 'Mon'">&nbsp;on Monday</span>
          <span *ngIf="post.meetingappear == 'Tue'">&nbsp;on Tuesday</span>
          <span *ngIf="post.meetingappear == 'Wed'">&nbsp;on Wednesday</span>
          <span *ngIf="post.meetingappear == 'Thu'">&nbsp;on Thursday</span>
          <span *ngIf="post.meetingappear == 'Fri'">&nbsp;on Friday</span>
          <span *ngIf="post.meetingappear == 'Sat'">&nbsp;on Saturday</span>
          <span *ngIf="post.meetingappear == 'Sun'">&nbsp;on Sunday</span>
          (Weekly)
        </span>
      </div>
      <div style="margin-top: 10px ;" *ngIf="post.meetingtype == '3'">
        <span>{{allinoneService.formatDBToShow(post.startdate)}} -
          {{allinoneService.formatDBToShow(post.enddate)}} </span> <br>
        <span>{{allinoneService.formatTimeToShow(post.starttime)}} to
          {{allinoneService.formatTimeToShow(post.endtime)}}</span>
      </div>
      <div style="margin-top: 10px ;">
        <span style="white-space: pre-line;">{{post.meetingdesc}}</span>

      </div>
      <div *ngIf="post.meetinglink != ''">
        <div *ngIf="post.ics == 'false'">
          <div class="preview-container" (click)="clickMetting(post.meetinglink)">
            <div style="padding: 10px 10px 5px 10px;">
              <span class="join-url">{{post.meetinglink}}</span>
            </div>
            <!-- Link Preview... -->
            <div *ngIf="post.preview" class="preview-footer">
              <div *ngIf="post.previewImage != ''">
                <img class="preivew-image" [defaultImage]="'../../../assets/images/bg.jpg'"
                  [lazyLoad]="post.previewImage">
              </div>
              <div *ngIf="post.previewImage != ''" style="color: black;font-weight: bold;">
                {{post.previewTitle}}</div>
              <div *ngIf="post.previewImage == ''" style="color: black;padding-left: 5px;font-weight: bold;">
                {{post.previewTitle}}
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="post.ics == 'true'">
          <div style="padding: 10px 0px 5px 0px;">
            <span class="join-url" (click)="clickMetting(post.meetinglink)">{{post.meetinglink}}</span>
          </div>
          <div class="show-ics" (click)="allinoneService.addtoCalendar(post)">
            <div style="padding: 10px 10px 10px 10px;display: flex;align-items: center;">
              <mat-icon>date_range</mat-icon>
              <span style="margin-left: 10px;">Add to Calendar</span>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div *ngIf="post.meetingtype == '4'" class="meeting-container">
      <div class="d-flex justify-content-between mb-3">
        <div class="d-flex">
          <span class="voting-title me-2">
            Voting

          </span>

        </div>
        <div class="max-vote" *ngIf="post.maxoption>1">
          Vote Limit: {{post.maxoption}}
        </div>

      </div>
      <div class="voting-note d-flex" style="white-space: pre-line;">
        {{post.meetingdesc}}
      </div>
      <div *ngFor="let poll of post.answer;let i=index;trackBy: trackByItems">

        <div class="poll-container d-flex justify-content-between"
          [style.background]="getVotePercentage(post.showresult,post.answer,post.totalvote,poll.code)">
          <label for="{{post.syskey}}{{poll.code}}" class="col-10" style="cursor: pointer;">
            <div class="d-flex justify-content-start flex-wrap">
              <div class="poll-selected me-2">
                <!-- <mat-icon>check</mat-icon>-->
                <input class="form-check-input" type="checkbox" id="{{post.syskey}}{{poll.code}}"
                  [checked]="checkVoted(post.answer,poll.code)"
                  (click)="VoteOnChange($event,poll.name,poll.code,post.syskey)"
                  *ngIf="checkVotingEndtime(post)!='Final result'"
                  [disabled]="(!checkVoted(post.answer,poll.code)&&checkVoteLimit(post.answer)>=post.maxoption )|| !checkVotingStartTime(post.startdate,post.starttime)">
                <div *ngIf="checkVotingEndtime(post)=='Final result'">
                  <div class="p-2" *ngIf="!checkVoted(post.answer,poll.code)">
                  </div>
                  <mat-icon *ngIf="checkVoted(post.answer,poll.code)" class="check-icon" style="width: 15px;">check
                  </mat-icon>
                </div>
              </div>

              <div class="poll-name">
                {{poll.name}}
              </div>
            </div>

          </label>

          <!-- <span *ngFor="let result of ;"> -->
          <div
            *ngIf="poll.count >0 && post.showcount=='true' || (post.showresult=='true' && checkVoteResult(post.answer,poll.code)) || post.shownamelist=='true'"
            class="vote-count">
            <div *ngIf="poll.count==1">
              <span *ngIf="post.shownamelist=='false'">
                {{poll.count}} vote
              </span>

              <span *ngIf="post.shownamelist=='true'" class="showname-list link-btn"
                (click)="VoteListForm(post.syskey,poll.code)">
                {{poll.count}} vote
              </span>
            </div>
            <div *ngIf="poll.count>1">
              <!-- <span [innerHTML]=""> -->

              <!-- </span> -->
              <span *ngIf="post.shownamelist=='false'">
                {{poll.count}} votes
              </span>

              <span *ngIf="post.shownamelist=='true'" class="showname-list link-btn"
                (click)="VoteListForm(post.syskey,poll.code)">
                {{poll.count}} votes
              </span>
            </div>

          </div>

          <!-- </span> -->



          <!-- -->


        </div>




      </div>

    </div>

    <div *ngIf="post.filename.length > 0 && post.fileurl.length > 0" class="pdf-card-outline">
      <div *ngFor="let file of post.filename; let i = index" class="pdf-card">
        <div style="width: 100%;">
          <i class="far fa-file-pdf"></i>
          <span style="margin-left: 5px;">{{file}}</span>
        </div>
        <div style="margin-top: 3px;">
          <mat-icon class="pdf-download-icon" (click)="clickDownloadPDF(post, file, i)">file_download
          </mat-icon>
        </div>

      </div>
    </div>

    <div *ngIf="post.videopost && !post.hidevideo" class="mt-2">
      <vm-player #player>
        <vm-youtube cookies="true" [attr.video-id]="post.videourl | getVideoId"></vm-youtube>
        <vm-default-ui>
        </vm-default-ui>
      </vm-player>
    </div>
    <div class="body-image" style="margin-bottom: 30px !important;">
      <!-- Here is image -->
      <div *ngFor="let img of post.imagelist;let i = index;">
        <!-- <img src='{{img}}'/> -->
        <img class="pos-img" [defaultImage]="'../../../assets/images/bg.jpg'" [lazyLoad]="img.imageurl"
            [alt]="appname" (click)="imageDetailView(post,i)">
        <div *ngIf="post.imagelist.length > 1">
          <div>
            <div class="like-comment" *ngIf="img.comment>0 || img.totallike > 0">
              <div>
                <div *ngIf="post.meetingtype=='' && img.totallike > 0">
                  <span class="link-btn" (click)="LikeAndTagForm(post.syskey, 'reacts', post.tag, img.imagename)">
                    <div class="d-flex align-items-center">
                      <div>
                        <div *ngIf="img.totalreact[0].id == '4'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/like.svg" width="15">
                        </div>
                        <div *ngIf="img.totalreact[0].id == '002'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/love.svg" width="15">
                        </div>
                        <div *ngIf="img.totalreact[0].id == '003'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg" width="15">
                        </div>
                        <div *ngIf="img.totalreact[0].id == '004'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="15">
                        </div>
                        <div *ngIf="img.totalreact[0].id == '005'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg" width="15">
                        </div>
                      </div>
                      <div *ngIf="img.totalreact.length > 1">
                        <div *ngIf="img.totalreact[1].id == '4'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/like.svg" width="15">
                        </div>
                        <div *ngIf="img.totalreact[1].id == '002'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/love.svg" width="15">
                        </div>
                        <div *ngIf="img.totalreact[1].id == '003'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg" width="15">
                        </div>
                        <div *ngIf="img.totalreact[1].id == '004'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="15">
                        </div>
                        <div *ngIf="img.totalreact[1].id == '005'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg" width="15">
                        </div>
                      </div>
                      <div *ngIf="img.totalreact.length > 2">
                        <div *ngIf="img.totalreact[2].id == '4'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/like.svg" width="15">
                        </div>
                        <div *ngIf="img.totalreact[2].id == '002'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/love.svg" width="15">
                        </div>
                        <div *ngIf="img.totalreact[2].id == '003'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg" width="15">
                        </div>
                        <div *ngIf="img.totalreact[2].id == '004'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="15">
                        </div>
                        <div *ngIf="img.totalreact[2].id == '005'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg" width="15">
                        </div>
                      </div>

                      <div class="ms-1">
                        {{img.totallike}}
                      </div>
                    </div>
                  </span>
                </div>

              </div>
              <div>
                <span (click)="imageDetailView(post,i)" class="link-btn">
                  <span *ngIf="img.comment > 1">
                    {{img.comment}} Comments
                  </span>
                  <span *ngIf="img.comment == 1">
                    {{img.comment}} Comment
                  </span>
                </span>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div class="like-comment-buttons">
              <button mat-button class="like-button">
                <div class="d-flex justify-content-center" *ngIf="!img.like" (click)="likeSingleImage('4',i)">
                  <div><i class="far fa-thumbs-up me-2"></i></div>
                  <div class="ms-2">Like</div>
                </div>
                <div *ngIf="img.like">
                  <div class="d-flex justify-content-center" *ngIf="img.react == '4'" (click)="likeSingleImage( '' ,i)">
                    <div>
                      <img style="margin-bottom:2px;" src="../../../assets/images/like.svg" width="15">
                    </div>
                    <div class="ms-2" style="color: var(--main-color);">Like</div>
                  </div>
                  <div class="d-flex justify-content-center" *ngIf="img.react == '002'"
                    (click)="likeSingleImage( '' ,i)">
                    <div>
                      <img style="margin-bottom:2px;" src="../../../assets/images/love.svg" width="15">
                    </div>
                    <div class="ms-2" style="color: #ef3453;">Love</div>
                  </div>
                  <div class="d-flex justify-content-center" *ngIf="img.react == '003'"
                    (click)="likeSingleImage( '' ,i)">
                    <div>
                      <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg" width="15">
                    </div>
                    <div class="ms-2" style="color: orange;">Haha</div>
                  </div>
                  <div class="d-flex justify-content-center" *ngIf="img.react == '004'"
                    (click)="likeSingleImage( '' ,i)">
                    <div>
                      <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="15">
                    </div>
                    <div class="ms-2" style="color: orange;">Sad</div>
                  </div>
                  <div class="d-flex justify-content-center" *ngIf="img.react == '005'"
                    (click)="likeSingleImage( '' ,i)">
                    <div>
                      <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg" width="15">
                    </div>
                    <div class="ms-2" style="color: #eb640b;">Angry</div>
                  </div>
                </div>
                <div class="react-list">
                  <div class="react" (click)="likeSingleImage( '4' ,i)">
                    <img style="margin-bottom:2px;" src="../../../assets/images/like.svg" width="25">
                  </div>
                  <div class="react" (click)="likeSingleImage( '002',i)">
                    <img style="margin-bottom:2px;" src="../../../assets/images/love.svg" width="25">
                  </div>
                  <div class="react" (click)="likeSingleImage( '003',i)">
                    <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg" width="25">
                  </div>
                  <div class="react" (click)="likeSingleImage( '004',i)">
                    <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="25">
                  </div>
                  <div class="react" (click)="likeSingleImage( '005',i)">
                    <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg" width="25">
                  </div>
                </div>
              </button>

              <!-- <button mat-button class="answer-button" id="dropdownMenuClickableInside"
        *ngIf="post.meetingtype!=''&& post.meetingtype!='4'" [ngClass]="{'liked' : post.like == 'true'}"
        data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false"
        [disabled]="checkVotingEndtime(post)=='Final result'">
        <i class="fas fa-comments"></i> Response
      </button> -->

              <button mat-button *ngIf="post.commentsetting=='true'" (click)="imageDetailView(post,i)">
                <i class="far fa-comment-alt me-2"></i>Comment
              </button>

              <!-- <button mat-button (click)="share()" *ngIf="post.type == '1'">
        <i class="fal fa-share me-2"></i>Share
      </button> -->
            </div>

            <mat-divider></mat-divider>
          </div>
        </div>

      </div>
      <!-- <app-social-posts-images [images]="post.image"></app-social-posts-images> -->
    </div>
  </perfect-scrollbar>
  <!-- </div> -->
  <!-- <div class="right-column col-md-6"> -->
  <perfect-scrollbar class="col-md-6" [config]="config" [scrollIndicators]="true"
    [ngClass]="{'right-column' : !isMobileView}" #rightSideScroll>
    <!-- <div>RightSide...</div> -->
    <div class="like-comment mt-2 mb-2">
      <div>
        <div *ngIf="post.meetingtype=='' && post.totallike > 0">
          <span class="link-btn" (click)="LikeAndTagForm(post.syskey, 'reacts', post.tag)">
            <div class="d-flex align-items-center">
              <div>
                <div *ngIf="post.totalreact[0].id == '4'">
                  <img style="margin-bottom:2px;" src="../../../assets/images/like.svg" width="15">
                </div>
                <div *ngIf="post.totalreact[0].id == '002'">
                  <img style="margin-bottom:2px;" src="../../../assets/images/love.svg" width="15">
                </div>
                <div *ngIf="post.totalreact[0].id == '003'">
                  <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg" width="15">
                </div>
                <div *ngIf="post.totalreact[0].id == '004'">
                  <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="15">
                </div>
                <div *ngIf="post.totalreact[0].id == '005'">
                  <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg" width="15">
                </div>
              </div>
              <div *ngIf="post.totalreact.length > 1">
                <div *ngIf="post.totalreact[1].id == '4'">
                  <img style="margin-bottom:2px;" src="../../../assets/images/like.svg" width="15">
                </div>
                <div *ngIf="post.totalreact[1].id == '002'">
                  <img style="margin-bottom:2px;" src="../../../assets/images/love.svg" width="15">
                </div>
                <div *ngIf="post.totalreact[1].id == '003'">
                  <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg" width="15">
                </div>
                <div *ngIf="post.totalreact[1].id == '004'">
                  <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="15">
                </div>
                <div *ngIf="post.totalreact[1].id == '005'">
                  <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg" width="15">
                </div>
              </div>
              <div *ngIf="post.totalreact.length > 2">
                <div *ngIf="post.totalreact[2].id == '4'">
                  <img style="margin-bottom:2px;" src="../../../assets/images/like.svg" width="15">
                </div>
                <div *ngIf="post.totalreact[2].id == '002'">
                  <img style="margin-bottom:2px;" src="../../../assets/images/love.svg" width="15">
                </div>
                <div *ngIf="post.totalreact[2].id == '003'">
                  <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg" width="15">
                </div>
                <div *ngIf="post.totalreact[2].id == '004'">
                  <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="15">
                </div>
                <div *ngIf="post.totalreact[2].id == '005'">
                  <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg" width="15">
                </div>
              </div>

              <div class="ms-1">
                {{post.totallike}}
              </div>
            </div>
          </span>
        </div>
        <div *ngIf="post.meetingtype!='' && post.meetingtype=='4' ">
          <span class="">
            <span *ngIf="post.totalvote >1">
              {{post.totalvote}} votes
            </span>
            <span *ngIf="post.totalvote == 1">
              {{post.totalvote}} vote
            </span>
            <span [ngClass]="{'showStarttime' : !checkVotingStartTime(post.startdate,post.starttime)}">
              <span *ngIf="checkVotingEndtime(post)!=''">
                •
              </span> {{checkVotingEndtime(post)}}
            </span>
            <span *ngIf="post.meetingtype!='' && post.meetingtype=='4' && checkVotingColseOrOpenTime(post) =='Open' "
              class="">
              (<span style="color: #63c20a;">Open</span>)

            </span>
            <span *ngIf="post.meetingtype!='' && post.meetingtype=='4' && checkVotingColseOrOpenTime(post)=='Closed' "
              class="">
              (<span style="color: red;">Closed</span>)

            </span>

          </span>
        </div>
        <div *ngIf="post.meetingtype!='' && post.meetingtype!='4'" class="d-flex">
          <div class="pe-1">
            <span *ngFor="let result of post.answer;let i=index;">
              <!-- {{result.count}} {{result.name}} -->
              <span
                *ngIf="result.count>0 && (post.showresult=='true' || post.showcount =='true') && post.shownamelist=='false' ">
                {{result.count}} {{result.name}}
              </span>
              <span *ngIf="result.count>0 && (post.shownamelist=='true')" class="link-btn"
                (click)="ResponseListForm(result,post.syskey)">
                {{result.count}} {{result.name}}
              </span>
              <!-- <span
                *ngIf=" result.count>0 && post.showresult=='true' && checkVoteResult(post.answer,result.code)">
                <span class="link-btn" (click)="ResponseListForm(result)">
                  {{result.count}} {{result.name}}
                </span>
              </span> -->
              <span *ngIf="post.answer.length>1 && i!=post.answer.length-1">
              </span>
            </span>
          </div>
          <div class="pe-1"
            *ngIf=" post.showcount == 'false'  && post.shownamelist == 'false'  && post.showresult == 'false'">
            {{post.totalvote}} Responses
          </div>
        </div>
      </div>
      <div>
        <span class="">
          <span *ngIf="post.comment > 1">
            {{post.comment}} Comments
          </span>
          <span *ngIf="post.comment == 1">
            {{post.comment}} Comment
          </span>
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="like-comment-buttons">
      <button mat-button class="like-button" *ngIf="post.meetingtype==''">
        <div class="d-flex justify-content-center" *ngIf="post.like == 'false'" (click)="like('4')">
          <div><i class="far fa-thumbs-up me-2"></i></div>
          <div class="ms-2">Like</div>
        </div>
        <div *ngIf="post.like == 'true'">
          <div class="d-flex justify-content-center" *ngIf="post.react == '4'" (click)="like( '')">
            <div>
              <img style="margin-bottom:2px;" src="../../../assets/images/like.svg" width="15">
            </div>
            <div class="ms-2" style="color: var(--main-color);">Like</div>
          </div>
          <div class="d-flex justify-content-center" *ngIf="post.react == '002'" (click)="like( '')">
            <div>
              <img style="margin-bottom:2px;" src="../../../assets/images/love.svg" width="15">
            </div>
            <div class="ms-2" style="color: #ef3453;">Love</div>
          </div>
          <div class="d-flex justify-content-center" *ngIf="post.react == '003'" (click)="like( '')">
            <div>
              <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg" width="15">
            </div>
            <div class="ms-2" style="color: orange;">Haha</div>
          </div>
          <div class="d-flex justify-content-center" *ngIf="post.react == '004'" (click)="like( '')">
            <div>
              <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="15">
            </div>
            <div class="ms-2" style="color: orange;">Sad</div>
          </div>
          <div class="d-flex justify-content-center" *ngIf="post.react == '005'" (click)="like( '')">
            <div>
              <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg" width="15">
            </div>
            <div class="ms-2" style="color: #eb640b;">Angry</div>
          </div>
        </div>
        <div class="react-list">
          <div class="react" (click)="like( '4')">
            <img style="margin-bottom:2px;" src="../../../assets/images/like.svg" width="25">
          </div>
          <div class="react" (click)="like( '002')">
            <img style="margin-bottom:2px;" src="../../../assets/images/love.svg" width="25">
          </div>
          <div class="react" (click)="like( '003')">
            <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg" width="25">
          </div>
          <div class="react" (click)="like( '004')">
            <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="25">
          </div>
          <div class="react" (click)="like( '005')">
            <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg" width="25">
          </div>
        </div>
      </button>
      <button mat-button class="vote-button" disabled *ngIf="post.meetingtype=='4'"
        [ngClass]="{'liked' : post.like == 'true'}">
        <i class="fas fa-poll mb-1"></i> Vote
      </button>
      <button mat-button class="answer-button" id="dropdownMenuClickableInside"
        *ngIf="post.meetingtype!=''&& post.meetingtype!='4'" [ngClass]="{'liked' : post.like == 'true'}"
        data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false"
        [disabled]="checkVotingEndtime(post)=='Final result'">
        <i class="fas fa-comments"></i> Response
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuClickableInside">
        <li class="dropdown-item" *ngFor="let poll of post.answer;trackBy: trackByItems">
          <div class="d-flex ">
            <!-- <label for="answer{{post.syskey}}{{poll.code}}"> -->
            <div class="me-2 ">
              <input class="form-check-input" type="checkbox" id="answer{{post.syskey}}{{poll.code}}"
                [checked]="checkVoted(post.answer,poll.code)"
                (click)="VoteOnChange($event,poll.name,poll.code,post.syskey)"
                *ngIf="checkVotingEndtime(post)!='Final result'"
                [disabled]="(!checkVoted(post.answer,poll.code) && checkVoteLimit(post.answer)>=post.maxoption) ">
              <div *ngIf="checkVotingEndtime(post)=='Final result'">
                <div class="p-2" *ngIf="!checkVoted(post.answer,poll.code)">
                </div>
                <mat-icon *ngIf="checkVoted(post.answer,poll.code)" class="check-icon"
                  style="width: 15px;padding-bottom: 10px;">check</mat-icon>
              </div>
            </div>
            <div class="menu-text d-flex justify-content-between flex-row" style="min-width: 150px;">
              <div class=" mb-1">
                <label for="answer{{post.syskey}}{{poll.code}}" style="min-width: 120px;cursor: pointer;">

                  {{poll.name}}
                </label>
              </div>


              <div
                *ngIf="poll.count>0 && post.showcount=='true' || (post.showresult=='true' && checkVoteResult(post.answer,poll.code)) || post.shownamelist=='true'">
                <div *ngIf="poll.count==1">
                  <span *ngIf="post.shownamelist=='false'">
                    1 vote
                  </span>

                  <span *ngIf="post.shownamelist=='true'" class="showname-list link-btn"
                    (click)="VoteListForm(post.syskey,poll.code)">
                    1 vote
                  </span>
                </div>
                <div *ngIf="poll.count>1">

                  <span *ngIf="post.shownamelist=='false'">
                    {{poll.count}} votes
                  </span>

                  <span *ngIf="post.shownamelist=='true'" class="showname-list link-btn"
                    (click)="VoteListForm(post.syskey,poll.code)">
                    {{poll.count}} votes
                  </span>
                </div>
              </div>
            </div>
            <!-- </label> -->


          </div>
        </li>
      </ul>
      <button mat-button *ngIf="post.commentsetting=='true'">
        <i class="far fa-comment-alt me-2"></i>Comment
      </button>

      <button mat-button (click)="share()" *ngIf="post.type == '1'">
        <i class="fal fa-share me-2"></i>Share
      </button>
    </div>

    <mat-divider></mat-divider>
    <div class="my-2 comment-section" *ngIf="post.commentsetting=='true'">
      <img class="profile-image me-3" [defaultImage]="'../../../assets/images/bg.jpg'"
        [lazyLoad]="profileimg ? profileimg : '../../../assets/images/profile.png'">
      <div class="input-box">
        <!-- <textarea class="textarea" autosize [minRows]="1" [(ngModel)]="post.commentText"
          placeholder="Write a comment ..." (keydown.enter)="submitComment($event)">
                </textarea> -->
        <quill-editor class="textarea" [modules]="modules" style="width: 100%;" [formats]="formats" format="object"
          [(ngModel)]="post.commentText" [trimOnValidation]="true">
        </quill-editor>
        <div [ngClass]="{'image-box': post.selectedImage }" *ngIf="post.selectedImage">
          <div class="close" (click)="closeImage()">
            <mat-icon>close</mat-icon>
          </div>
          <img [src]="post.selectedImage" alt="">
        </div>
        <div class="btns" *ngIf="!post.saveCommentLoading">
          <input style="display: none" type="file" accept="image/*" (change)="onFileSelect($event)" #hiddenfileinput>
          <button mat-icon-button class="image" (click)="hiddenfileinput.click()">
            <mat-icon>photo_library</mat-icon>
          </button>
          <button mat-icon-button class="send" (click)="submitComment($event)">
            <mat-icon>send</mat-icon>
          </button>
        </div>
        <div class="btns" *ngIf="post.saveCommentLoading">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!gettingComments && comments.length == 0" style="padding: 20px;
        color: grey;
        text-align: center;">
      <p>No Comment</p>
    </div>
    <div *ngIf="comments.length > 0">
      <div *ngFor="let comment of comments; let i = index ">
        <div class="message-container">
          <div class="parent-reply-line"
            *ngIf="(comment.childlist.length > 0 || comment.children.length > 0) || comment.replyboxopen">
          </div>
          <span>
            <img class="comment-profile-image" (click)="viewProfile(comment.userid)"
              [defaultImage]="'../../../assets/images/profile.png'"
              [lazyLoad]="comment.userimage ? comment.userimage : '../../../assets/images/profile.png'">
          </span>
          <div class="comment-box" [attr.data-is-edit]="comment.edit ? '1': '0'">
            <div class="message-box">
              <div class="delete-loading" *ngIf="commentSyskey==comment.syskey">
                <div class="spinner-border mb-3 " role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>


              <div class="comment-option-box">
                <button mat-icon-button class="back-btn" id="dropdownMenuButton1" aria-expanded="false"
                  data-bs-toggle="dropdown">
                  <mat-icon>more_horiz</mat-icon>

                  <!-- <i class="fas fa-arrow-left" style="font-size: 20px;" mat-dialog-close></i> -->
                </button>
                <div class="dropdown-menu msg-menu dropdown-menu-start" aria-labelledby="dropdownMenuButton1">

                  <ng-container
                    *ngIf="(domainAdmin || comment.userid==userid || post.adminid==userid || (post.type == 3 &&(orgAdmin || postAdmin)))  && commentSyskey!=comment.syskey; else elseTemp">
                    <button mat-menu-item [cdkCopyToClipboard]="comment.comment" class="dropdown-item">
                      <mat-icon>content_copy</mat-icon>Copy Comment
                    </button>
                    <button *ngIf="comment.userid == userid" mat-menu-item class="dropdown-item"
                      (click)="editCommetConfirm(comment, i)">
                      <mat-icon>edit</mat-icon>Edit Comment
                    </button>

                    <button mat-menu-item class="dropdown-item" (click)="deleteCommetConfirm(comment.syskey, i)">
                      <mat-icon>delete</mat-icon>Delete Comment
                    </button>
                  </ng-container>
                  <ng-template #elseTemp>
                    <button mat-menu-item [cdkCopyToClipboard]="comment.comment" class="dropdown-item">
                      <mat-icon>content_copy</mat-icon>Copy Comment
                    </button>
                  </ng-template>
                </div>
              </div>

              <div class="message-header">
                {{comment.username}}
              </div>

              <div *ngIf="comment.edit">
                <div class="edit-input-box mb-1">
                  <!-- <textarea class="edit-textarea" autosize [minRows]="1" [(ngModel)]="editText">
                                  </textarea> -->

                  <div [id]="'editor-container'" class="edit-textarea">

                  </div>
                </div>
                <div style="display: flex; justify-content: flex-end;">
                  <button mat-icon-button (click)="closeEditComment(i)" [disabled]="editLoading">
                    <mat-icon>close</mat-icon>
                  </button>
                  <button *ngIf="!editLoading" mat-icon-button (click)="submitEditComment(comment)">
                    <mat-icon>check</mat-icon>
                  </button>
                  <div style="display: flex; align-items: center;">
                    <span *ngIf="editLoading" class="spinner-border spinner-border-sm button-spinner" role="status"
                      aria-hidden="true" style="color: var(--main-color) !important;"></span>
                  </div>
                </div>
              </div>

              <span *ngIf="!comment.edit" class="message-note"
                [innerHTML]="comment.comment | replaceEnterForChat:(comment.userid == userid):i"
                (click)="clickcmt($event)"></span>

              <ng-container *ngIf="comment.imagename != '' && !comment.edit">
                <img class="comment-image" [defaultImage]="'../../../assets/images/bg.jpg'"
                  [lazyLoad]="comment.imagename" [alt]="appname" (click)="imageViewer(comment.imagename  ,i)">
              </ng-container>
              <div class="react-box" [attr.data-is-edit]="comment.edit ? '1': '0'"
                *ngIf="comment.totalreact.length > 0">
                <div class="reacts" (click)="CommentReactForm(post.syskey,comment.syskey, 'commentreacts', '')">
                  <!-- Avatar item -->
                  <div *ngFor="let react of comment.totalreact;let index=index;">
                    <div *ngIf="react.id == '4' && 2>=index" class="react-image">
                      <img style="margin-bottom:2px;" src="../../../assets/images/like.svg" width="15">
                    </div>
                    <div *ngIf="react.id == '002' && 2>=index" class="react-image">
                      <img style="margin-bottom:2px;" src="../../../assets/images/love.svg" width="15">
                    </div>
                    <div *ngIf="react.id == '003' && 2>=index" class="react-image">
                      <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg" width="15">
                    </div>
                    <div *ngIf="react.id == '004' && 2>=index" class="react-image">
                      <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="15">
                    </div>
                    <div *ngIf="react.id == '005' && 2>=index" class="react-image">
                      <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg" width="15">
                    </div>

                  </div>
                  <div class="px-2 d-flex reactcount" style="word-break: initial;">
                    {{getTotalReactCount(comment)}}
                  </div>
                </div>

              </div>
            </div>
            <div class="comment-time">
              <span class="like-cmt me-2">
                <span *ngIf="!comment.userreact" (click)="reactComment('4', comment.syskey, i)">Like</span>
                <span *ngIf="comment.userreact" style="color: var(--main-color);"
                  (click)="reactComment(comment.userreact, comment.syskey, i)">Like</span>
                <div class="cmt-react-list" [id]="'reactbox'+i">
                  <div class="react d-flex flex-column" (click)="reactComment('4', comment.syskey, i)">
                    <div>
                      <img style="margin-bottom:2px;" src="../../../assets/images/like.svg" width="25">
                    </div>
                    <div *ngIf="comment.userreact == '4'" class="active">
                      <i class="fas fa-circle"></i>
                    </div>
                  </div>
                  <div class="react" (click)="reactComment('002', comment.syskey, i)">
                    <div>
                      <img style="margin-bottom:2px;" src="../../../assets/images/love.svg" width="25">
                    </div>
                    <div *ngIf="comment.userreact == '002'" class="active">
                      <i class="fas fa-circle"></i>
                    </div>
                  </div>
                  <div class="react" (click)="reactComment('003', comment.syskey, i)">
                    <div>
                      <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg" width="25">
                    </div>
                    <div *ngIf="comment.userreact == '003'" class="active">
                      <i class="fas fa-circle"></i>
                    </div>
                  </div>
                  <div class="react" (click)="reactComment('004', comment.syskey, i)">
                    <div>
                      <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="25">
                    </div>
                    <div *ngIf="comment.userreact == '004'" class="active">
                      <i class="fas fa-circle"></i>
                    </div>
                  </div>
                  <div class="react" (click)="reactComment('005', comment.syskey, i)">
                    <div>
                      <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg" width="25">
                    </div>
                    <div *ngIf="comment.userreact == '005'" class="active">
                      <i class="fas fa-circle"></i>
                    </div>
                  </div>
                </div>
              </span>
              <span class="like-cmt me-2" (click)="comment.replyboxopen = !comment.replyboxopen"
                *ngIf="post.commentsetting == 'true'">Reply</span>
              <span
                [title]="allinoneService.formatDBToShow(comment.date) +' '+ comment.time">{{(allinoneService.formatDBToShowInEdit(comment.date,
                '/')
                +' '+ comment.time) | timeAgo}}</span>
            </div>
          </div>
        </div>
        <ng-container *ngIf="comment.replyboxopen">
          <div class="cmt-reply-box d-flex w-100">
            <div class="reply-line me-2" style="margin-top: 2px !important;">
            </div>
            <div class="child-reply-line" *ngIf="comment.childlist.length > 0">
            </div>
            <div class="mb-3 comment-section d-flex flex-fill" *ngIf="post.commentsetting=='true'">
              <img class="comment-reply-profile-image me-3 mt-2" [defaultImage]="'../../../assets/images/bg.jpg'"
                [lazyLoad]="profileimg ? profileimg : '../../../assets/images/profile.png'">
              <div class="reply-input-box mt-1 flex-fill">
                <!-- <textarea class="textarea" autosize [minRows]="1" [(ngModel)]="comment.commentText"
                  placeholder="Write a comment ..." (keydown.enter)="submitReplyComment($event, i)">
                        </textarea> -->
                <quill-editor class="textarea mt-1" [modules]="modules" style="width: 100%;" [formats]="formats"
                  format="object" [(ngModel)]="comment.commentText" [trimOnValidation]="true">
                </quill-editor>
                <div [ngClass]="{'image-box': comment.selectedImage }" *ngIf="comment.selectedImage">
                  <div class="close" (click)="closeImage(comment)">
                    <mat-icon>close</mat-icon>
                  </div>
                  <img [src]="comment.selectedImage" alt="">
                </div>
                <div class="btns" *ngIf="!comment.saveCommentLoading">
                  <input style="display: none" type="file" accept="image/*" (change)="onReplyFileSelect($event, i)"
                    #hiddenreplyfileinput>
                  <button mat-icon-button class="image" (click)="hiddenreplyfileinput.click()">
                    <mat-icon>photo_library</mat-icon>
                  </button>
                  <button mat-icon-button class="send" (click)="submitReplyComment($event, i)">
                    <mat-icon>send</mat-icon>
                  </button>
                </div>
                <div class="btns" *ngIf="comment.saveCommentLoading" style="bottom: 0 !important;">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="(comment.childlist.length > 0 || comment.children.length > 0) && !comment.gettingreply">
          <div class="cmt-reply-box align-items-center d-flex" *ngIf="comment.children.length == 0">
            <div class="reply-line me-2" style="margin-top: -16px !important;">

            </div>
            <div>
              <img class="comment-reply-profile-image" (click)="viewProfile(comment.userid)"
                [defaultImage]="'../../../assets/images/profile.png'"
                [lazyLoad]="comment.childlist[0].userimage ? comment.childlist[0].userimage : '../../../assets/images/profile.png'">
            </div>
            <div (click)="getReply(comment.syskey, i)" style="cursor: pointer;">
              {{comment.childlist[0].username}} replied.&nbsp;<span
                class="comment-time">{{comment.childlist[0].count}}&nbsp;{{comment.childlist[0].count > 1 ? 'Replies':
                'Reply'}}</span>
            </div>
          </div>
          <ng-container *ngIf="comment.children">
            <ng-container *ngFor="let reply of comment.children; let rindex = index">
              <div class="cmt-reply-box d-flex">
                <div class="child-reply-line"
                  *ngIf="(rindex != (comment.children.length - 1)) || comment.viewmorecomments || comment.gettingmorereply">
                </div>
                <div class="reply-line me-2">
                </div>
                <div>
                  <img class="comment-reply-profile-image" (click)="viewProfile(reply.userid)"
                    [defaultImage]="'../../../assets/images/profile.png'"
                    [lazyLoad]="reply.userimage ? reply.userimage : '../../../assets/images/profile.png'">
                </div>
                <div>
                  <div class="comment-box" [attr.data-is-edit]="reply.edit ? '1': '0'">
                    <div class="message-box">
                      <div class="delete-loading" *ngIf="commentSyskey==reply.syskey">
                        <div class="spinner-border mb-3 " role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>


                      <div class="comment-option-box">
                        <button mat-icon-button class="back-btn" id="dropdownMenuButton1" aria-expanded="false"
                          data-bs-toggle="dropdown">
                          <mat-icon>more_horiz</mat-icon>

                          <!-- <i class="fas fa-arrow-left" style="font-size: 20px;" mat-dialog-close></i> -->
                        </button>
                        <div class="dropdown-menu msg-menu dropdown-menu-start" aria-labelledby="dropdownMenuButton1">

                          <ng-container
                            *ngIf="(domainAdmin || reply.userid==userid || post.adminid==userid || (post.type == 3 &&(orgAdmin || postAdmin)))  && commentSyskey!=comment.syskey; else elseTemp">
                            <button mat-menu-item [cdkCopyToClipboard]="reply.comment" class="dropdown-item">
                              <mat-icon>content_copy</mat-icon>Copy Comment
                            </button>
                            <button *ngIf="reply.userid == userid" mat-menu-item class="dropdown-item"
                              (click)="editCommetConfirm(reply, i)">
                              <mat-icon>edit</mat-icon>Edit Comment
                            </button>

                            <button mat-menu-item class="dropdown-item"
                              (click)="deleteCommetConfirm(reply.syskey, i, rindex)">
                              <mat-icon>delete</mat-icon>Delete Comment
                            </button>
                          </ng-container>
                          <ng-template #elseTemp>
                            <button mat-menu-item [cdkCopyToClipboard]="reply.comment" class="dropdown-item">
                              <mat-icon>content_copy</mat-icon>Copy Comment
                            </button>
                          </ng-template>
                        </div>
                      </div>

                      <div class="message-header">
                        {{reply.username}}
                      </div>

                      <div *ngIf="reply.edit">
                        <div class="edit-input-box mb-1">
                          <!-- <textarea class="edit-textarea" autosize [minRows]="1" [(ngModel)]="editText">
                                          </textarea> -->
                          <div [id]="'editor-container'" class="edit-textarea">

                          </div>
                        </div>
                        <div style="display: flex; justify-content: flex-end;">
                          <button mat-icon-button (click)="closeEditComment(i, rindex)" [disabled]="editLoading">
                            <mat-icon>close</mat-icon>
                          </button>
                          <button *ngIf="!editLoading" mat-icon-button (click)="submitEditComment(reply)">
                            <mat-icon>check</mat-icon>
                          </button>
                          <div style="display: flex; align-items: center;">
                            <span *ngIf="editLoading" class="spinner-border spinner-border-sm button-spinner"
                              role="status" aria-hidden="true" style="color: var(--main-color) !important;"></span>
                          </div>
                        </div>
                      </div>

                      <span *ngIf="!reply.edit" class="message-note"
                        [innerHTML]="reply.comment | replaceEnterForChat:(reply.userid == userid):i"
                        (click)="clickcmt($event)"></span>

                      <div *ngIf="reply.imagename != '' && !reply.edit">
                        <img class="comment-image" [defaultImage]="'../../../assets/images/bg.jpg'"
                          [lazyLoad]="reply.imagename" [alt]="appname" (click)="imageViewer(reply.imagename  ,i)">
                      </div>
                      <div class="react-box" [attr.data-is-edit]="reply.edit ? '1': '0'"
                        *ngIf="reply.totalreact.length > 0">
                        <div class="reacts" (click)="CommentReactForm(post.syskey,reply.syskey, 'commentreacts', '')">
                          <!-- Avatar item -->
                          <div *ngFor="let react of reply.totalreact;let index=index;">
                            <div *ngIf="react.id == '4' && 2>=index" class="react-image">
                              <img style="margin-bottom:2px;" src="../../../assets/images/like.svg" width="15">
                            </div>
                            <div *ngIf="react.id == '002' && 2>=index" class="react-image">
                              <img style="margin-bottom:2px;" src="../../../assets/images/love.svg" width="15">
                            </div>
                            <div *ngIf="react.id == '003' && 2>=index" class="react-image">
                              <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg" width="15">
                            </div>
                            <div *ngIf="react.id == '004' && 2>=index" class="react-image">
                              <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="15">
                            </div>
                            <div *ngIf="react.id == '005' && 2>=index" class="react-image">
                              <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg" width="15">
                            </div>

                          </div>
                          <div class="px-2 d-flex reactcount" style="word-break: initial;">
                            {{getTotalReactCount(reply)}}
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="comment-time">
                      <span class="like-cmt me-2">
                        <span *ngIf="!reply.userreact"
                          (click)="reactReplyComment('4', reply.syskey, i, rindex)">Like</span>
                        <span *ngIf="reply.userreact" style="color: var(--main-color);"
                          (click)="reactReplyComment(reply.userreact, reply.syskey, i, rindex)">Like</span>
                        <div class="cmt-react-list reply-react-list" [id]="'reactbox'+i">
                          <div class="react d-flex flex-column"
                            (click)="reactReplyComment('4', reply.syskey, i, rindex)">
                            <div>
                              <img style="margin-bottom:2px;" src="../../../assets/images/like.svg" width="25">
                            </div>
                            <div *ngIf="reply.userreact == '4'" class="active">
                              <i class="fas fa-circle"></i>
                            </div>
                          </div>
                          <div class="react" (click)="reactReplyComment('002', reply.syskey, i, rindex)">
                            <div>
                              <img style="margin-bottom:2px;" src="../../../assets/images/love.svg" width="25">
                            </div>
                            <div *ngIf="reply.userreact == '002'" class="active">
                              <i class="fas fa-circle"></i>
                            </div>
                          </div>
                          <div class="react" (click)="reactReplyComment('003', reply.syskey, i, rindex)">
                            <div>
                              <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg" width="25">
                            </div>
                            <div *ngIf="reply.userreact == '003'" class="active">
                              <i class="fas fa-circle"></i>
                            </div>
                          </div>
                          <div class="react" (click)="reactReplyComment('004', reply.syskey, i, rindex)">
                            <div>
                              <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="25">
                            </div>
                            <div *ngIf="reply.userreact == '004'" class="active">
                              <i class="fas fa-circle"></i>
                            </div>
                          </div>
                          <div class="react" (click)="reactReplyComment('005', reply.syskey, i, rindex)">
                            <div>
                              <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg" width="25">
                            </div>
                            <div *ngIf="reply.userreact == '005'" class="active">
                              <i class="fas fa-circle"></i>
                            </div>
                          </div>
                        </div>
                      </span>
                      <!-- <span class="like-cmt me-2" (click)="reply.replyboxopen = !reply.replyboxopen">Reply</span> -->
                      <span
                        [title]="allinoneService.formatDBToShow(reply.date) +' '+ reply.time">{{(allinoneService.formatDBToShowInEdit(reply.date,
                        '/')
                        +' '+ reply.time) | timeAgo}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="comment.viewmorecomments && !comment.gettingmorereply">
              <div class="cmt-reply-box align-items-center d-flex">
                <div class="reply-line me-2" style="margin-top: -16px !important;">
                </div>
                <div class="viewmore" (click)="getMoreReply(comment.syskey, i)">
                  View more replies
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="comment.gettingreply || comment.gettingmorereply">
          <div class="cmt-reply-box d-flex align-items-center">
            <div class="reply-line me-2" style="margin-top: -14px !important;">

            </div>
            <span>
              <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ 
                            'height.px': 30,
                            'width.px' : 30,
                            'margin-left' : 0
                        }"></ngx-skeleton-loader>
            </span>
            <div style="display: flex;
                    align-items: center;
                    margin-left: 10px;">
              <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                            'height.px': 30,
                            'width.px' : 250,
                            'margin-bottom' : 0
                        }"></ngx-skeleton-loader>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="!gettingMoreComments && post.viewmorecomments" (click)="getMoreComments()" class="my-3 ms-2 viewmore">
        View more comments
      </div>
    </div>
    <div *ngIf="gettingMoreComments || gettingComments">
      <div *ngFor="let i of [1,2,3]" class="message-container">
        <span>
          <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ 
                        'height.px': 40,
                        'width.px' : 40,
                        'margin-left' : 0
                    }"></ngx-skeleton-loader>
        </span>
        <div style="display: flex;
                align-items: center;
                margin-left: 10px;">
          <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                        'height.px': 40,
                        'width.px' : 250,
                        'margin-bottom' : 0
                    }"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
  <!-- </div> -->
</div>

<div class="modal" id="post-image-viewer" aria-hidden="true" aria-labelledby="imageviewerLabel" tabindex="-1">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-more-container">
          <button type="button" class="btn-icon" data-bs-dismiss="modal" aria-label="Close" (click)="downloadImage()">
            <mat-icon>download</mat-icon>
          </button>
          <button type="button" class="btn-icon" data-bs-dismiss="modal" aria-label="Close">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <!-- <button *ngIf="!bothDisable" type="button" class="btn-icon" (click)="Previous()" [disabled]="previousBtn">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button> -->

        <div>
          <img [src]="imagename" alt="" class="image-view">
        </div>

        <!-- <button *ngIf="!bothDisable" type="button" class="btn-icon" [disabled]="nextBtn" (click)="Next()">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button> -->
      </div>
    </div>
  </div>
</div>