import { DatePipe, formatDate } from '@angular/common';

import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { CrmLeadConvertComponent } from '../crm-lead-convert/crm-lead-convert.component';

@Component({
  selector: 'app-manhour-detail-report',
  templateUrl: './manhour-detail-report.component.html',
  styleUrls: ['./manhour-detail-report.component.scss']
})
export class ManhourDetailReportComponent implements OnInit {
  gettingData: boolean = false;
  manhourlist: any = [];
  orgid: any = "";
  organizations: any = [];
  customers:any = [];
  selectcustomername:any = "customer";
  text1:any = "";
  text2:any = "";
  customerid:any = "";
  customersearch:any = "";
  getttingCustomer:boolean = false;
  loadProject:boolean = false;
  projects:any = [];
  projectsearch:any = "";
  isAdmin: boolean = false;
  projectname: any = 'project';
  projectsID:any = "";
  picname:any = "pic";
  picid:any = "";


  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private http: HttpClient,
    private dialog: MatDialog,
    public datepipe: DatePipe,
  ) {
    this.organizations = this.allinoneService.orgsCRm;
    this.orgid = this.allinoneService.selectedOrgCRM
      ? this.allinoneService.selectedOrgCRM
      : this.organizations[0].orgid;
  }

  ngOnInit(): void {
    this.getCustomers();
    this.getProjects();
    this.getPIC();
    this.getmanhourlist();
  }


  refreshgetData() {
    this.getmanhourlist();

  }

  downloadSheet1() {

    var data: any = [];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = 'Manhour_Detail_Report' + time + '.xlsx';

    this.manhourlist.map((man: any) => {
      data.push({
        // "Date": man.date,
        "Consultant": man.assignedto,
        "Manhour": man.manhour,
        // "Actual Month": man.actualmonth,
        "Ticket ID": man.ticketno,
        "Description": man.description,
        "Activity": man.activity,
      });
    });

    this.allinoneService.exportEcecl(data, name);
  }
  getmanhourlist() {

    this.gettingData = true;
    this.manhourlist = [];
    var data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "customerid":this.customerid,
      "projectid":this.projectsID,
      "picid":this.picid,
      // "date":this.allinoneService.formatDBToShow(this.startdate)
    };
    this.kunyekService.getmanhourlist(data).subscribe(
      (res: any) => {

        if (res.returncode == '300') {
          this.manhourlist = res.list;
          this.gettingData = false;
        } else {

          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
        this.gettingData = false;
      }
    );
  }

  
  getCustomers() {
    this.getttingCustomer = true;
    const data = {
      domainid: this.allinoneService.getDomain().domainid,

      orgid: this.allinoneService.selectedOrgCRM
        ? this.allinoneService.selectedOrgCRM
        : this.organizations[0].orgid,
    };
    this.kunyekService.getCrmCustomers(data).subscribe(
      (res: any) => {
        console.log(res);
        if (res.returncode == '300') {

          this.customers = res.list;
          // var customersmid = this.route.snapshot.queryParamMap.get('customersmid') || "";
          // if (customersmid) {
          //   var type = this.customers.map((x: any) => x.customerid).indexOf(customersmid);
          //   console.log(type)
          //   console.log(this.customers)
          //   console.log(">>>>>>>>>>>>>>>>>>. customer")
          //   this.customerid = this.customers[type].customerid;
          //   this.selectcustomername = this.customers[type].customername;
          //   this.branchlist = this.customers[type].branchlist;
          // }
          // console.log("branchlist>>>>>>>");
          // console.log(this.branchlist);
          // var branchmid = this.route.snapshot.queryParamMap.get('branchid') || "";
          // if (branchmid) {
          //   var type = this.branchlist.map((x: any) => x.branchid).indexOf(branchmid);
          //   this.branchid = this.branchlist[type].branchid;
          //   this.selectbranchname = this.branchlist[type].branchname;
          // }
          this.getttingCustomer = false;

        } else {
          this.getttingCustomer = false;
        }
      },
      (err) => {
        this.getttingCustomer = false;
      }
    );
  }

  getProjects() {
    this.loadProject = true;


    return new Promise((resolve: any) => {
      this.projects = [];
      const data = {
        orgid: this.allinoneService.selectedOrgCRM
          ? this.allinoneService.selectedOrgCRM
          : this.organizations[0].orgid,
        domainid: this.allinoneService.getDomain().domainid,
        customerview:  false,
        customerid: this.customerid,
      };
      this.kunyekService.getAllCRMproject(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {

            // check by id
            var userid = this.allinoneService.getKunyekUserId();
            if (this.isAdmin == false) {
              for (let i = 0; i < res.data.length; i++) {
                if (res.data[i]['memberstatus'] == '') {
                  this.projects.push(res.data[i]);
                  this.loadProject = false;
                }
                if (res.data[i]['memberstatus'] == '1') {
                  for (let j = 0; j < res.data[i]['memberlist'].length; j++) {
                    console.log(res.data[i]['memberlist'][j]['userid'] == userid);
                    if (userid == res.data[i]['memberlist'][j]['userid']) {
                      this.projects.push(res.data[i]);
                    }
                  }
                }
              }
            }

            //   // if(this.projectsmid){
            //   //   var type = this.projects.map((x: any) => x.projectid).indexOf(this.projectsmid);

            //   //   console.log(this.productListdata)
            //   //   this.projectsID = this.projects[type].projectid;
            //   //   this.projectname = this.projects[type].name;
            //   // }

            //   this.projects = this.projects
            // } else {

            this.projects = res.data;
            this.loadProject = false;

            // }
            // this.getProjectId();
          

            this.loadProject = false;
          } else {
            this.loadProject = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          this.loadProject = false;
          resolve(false);
        }
      );
    });
  }

  picList:any = [];
  picsearch:any = "";
  getPIC() {
    return new Promise((resolve) => {

      const data = {
        "domainid": this.allinoneService.getDomain().domainid,
        "orgid": this.orgid,
      }
      this.kunyekService.getCrmPIC(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.picList = [];
            var picname = "";
            res.list.map((pic: any) => {

              if (pic.picname == "") {
                picname = pic.userid
              } else {
                if (pic.userid == "") {
                  picname = pic.picname
                } else {
                  picname = pic.picname + " (" + pic.userid + ")"
                }
              }
              this.picList.push({
                "userid": pic.userid,
                "name": picname,
                "picid": pic.picid,
              })
            });
            resolve('true')
          } else {
            resolve('false')
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          resolve('false')
        }
      );

    });

  }

  removeCust() {
    this.customersearch = '';
  }

  removeProJect() {
    this.projectsearch = '';

  }

  removePic(){
    this.picsearch = '';
  }

  changecustomer(data: any) {
    if (data == 'customer') {
      this.customerid = '';
      this.selectcustomername = 'customer';
      
      this.getmanhourlist();
    } else {
      console.log(data);
      this.customerid = data.customerid;
      this.selectcustomername = data.customername;

      this.getmanhourlist();
    }
  }
  
  
  changeproject(data: any) {
    console.log(data);
    if (data == 'project') {
      this.projectsID = '';
      this.projectname = 'project';
      // this.router.navigate([], {
      //   relativeTo: this.route,
      //   // { queryParams: { projectid: this.projectsID } }
      //   { queryParams: { projectid: this.projectsID } }

      // );
      this.getmanhourlist();
    } else {
      this.projectsID = data.projectid;
      this.projectname = data.name;

      this.getmanhourlist();
    }



  }
  
  changepic(data: any) {
    console.log(data);
    if (data == 'pic') {
      this.picid = '';
      this.picname = 'pic';
      // this.router.navigate([], {
      //   relativeTo: this.route,
      //   // { queryParams: { projectid: this.projectsID } }
      //   { queryParams: { projectid: this.projectsID } }

      // );
      this.getmanhourlist();
    } else {
      this.picid = data.picid;
      this.picname = data.name;

      this.getmanhourlist();
    }



  }

  

}
