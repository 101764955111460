import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';
declare var $: any;

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, AfterViewInit {
  user: any;

  isBlocking: boolean = false;
  imagename:any;
  currentCount: number = 10;
  type: number = 2;
  currentStart: number = 0;
  currentEnd: number = 10;
  loading: boolean = false;

  error: boolean = false;

  uid: any;

  constructor(
    private kunyekService: KunyekService,
    public messageService: MessageService,
    public allinoneService: AllInOneService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UserProfileComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    public applicationRef: ApplicationRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (
      (data.user == undefined || data.user == '') &&
      (data.userid != '' || data.userid != undefined)
    ) {
      this.uid = data.userid;
      this.getContactList(data.userid);
    } else {
      this.user = data.user;
      console.log('U>>');
      console.log(this.user);
    }
  }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  getContactList(uid: any) {
    console.log('GC>>');

    this.loading = true;
    const data = {
      contact: [],
      count: this.currentCount,
      start: this.currentStart,
      end: this.currentEnd,
      search: '',
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
      contactid: uid,
    };
    this.kunyekService.getContactList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.loading = false;
          this.user = res;
          console.log(res);
        } else {
          // this.gettingContactList = false
          // this.gettingMoreContact = false;
          this.loading = false;
          this.error = true;

          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.loading = false;
        this.error = true;
        // this.gettingContactList = false
        // this.gettingMoreContact = false;
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }

  blockUser() {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.isBlocking = true;
        this.dialogRef.disableClose = true;
        const data = {
          domain: this.allinoneService.getDomain().shortcode,
          domainid: this.allinoneService.getDomain().domainid,
          date: this.allinoneService.getCurrentDateToDB(),
          time: this.allinoneService.getCurrentTimeToDB(),
          contact: this.user.userid,
        };
        
        this.kunyekService.blockUser(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              // this.dialogRef.disableClose = false
              this.isBlocking = false;
              this.dialogRef.close(true);
            } else {
              this.messageService.openSnackBar(res.message, 'warn');
              this.isBlocking = false;
            }
            
          },
          (err) => {
            this.isBlocking = false;
            
          }
        );
      }
    });
  }

  addContact() {
    this.allinoneService.isLoading = true;
    const data = {
      domain: this.allinoneService.getDomain().shortcode,
      recordstatus: '1',
      contact: [this.user.userid],
      count: 0,
    };

    this.kunyekService.addContact(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.getMemberList()
          this.user.friend = 'true';
          this.allinoneService.isLoading = false;
        } else {
          this.allinoneService.isLoading = true;
          this.messageService.openSnackBar(res.message, 'warn');
        }
        
      },
      (err) => {
        this.allinoneService.isLoading = true;
        
      }
    );
  }

  close() {
    console.log('closing');
    this.dialogRef.close();
    setTimeout(() => {
      this.applicationRef.tick();
    }, 50);
    
  }
  imageViewer() {
    // console.log(document.getElementById('profile-image-viewer'))
    // if(document.getElementById('profile-image-viewer') != null)
    // {
    //   console.log("close")
    // }
    if(this.user.photo!=""){  
      this.imagename=this.user.photo
      $('#profile-image-viewer').modal('show');
    }
  }
  CloseImageViewer(){
    this.imagename=""
    $("#profile-image-viewer").modal('hide');


  }
  downloadImage() {
    window.open(this.imagename, '_blank')
  }
  getImgSrc(htmlString: any) {
    var element = <HTMLImageElement>htmlString;
    return element.src;
  }
}
