import { MessageService } from './../../shared/services/message.service';
import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { SubSink } from 'subsink';


@Component({
  selector: 'app-crm-dashboard-service',
  templateUrl: './crm-dashboard-service.component.html',
  styleUrls: ['./crm-dashboard-service.component.scss']
})
export class CrmDashboardServiceComponent implements OnInit {
  mobileViewWidth: number = 426;
  isMobileView: boolean = false
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {

    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else if (window.innerWidth > this.mobileViewWidth) {
      this.isMobileView = false;
    } else {
      this.isMobileView = false;
    }

  }


  CrmList = [

    {
      "title": "Service",
      "card": [
        { "appid": "4", "appname": "Service Tickets", "icon": "assets/images/service.png", "routname": "service/service-tickets", "value": "001" },
        { "appid": "5", "appname": "Service Summary", "icon": "assets/images/servicesummary.png", "routname": "service/summary", "value": "002" },
        { "appid": "5", "appname": "Service Monthly Report", "icon": "assets/images/summaryreport.png", "routname": "service/servicereport", "value": "009" },
        // { "appid": "5", "appname": "Monthly Earlier Report", "icon": "assets/images/summaryreport.png", "routname": "service/earlierreport", "value": "009" },
        { "appid": "10", "appname": "Service Type", "icon": "assets/images/servicetype.png", "routname": "service/servicetype", "value": "003" },
        { "appid": "11", "appname": "Service Status", "icon": "assets/images/servicestatus.png", "routname": "service/status", "value": "004" },
        { "appid": "12", "appname": "Service Overage", "icon": "assets/images/overagesetup.png", "routname": "service/serviceoverage", "value": "005" },
        { "appid": "12", "appname": "Service Overage Report", "icon": "assets/images/overage.png", "routname": "service/serviceoveragereport", "value": "005" },
        { "appid": "8", "appname": "Customers", "icon": "assets/images/customers.png", "routname": "service/customers", "value": "008" },
        { "appid": "13", "appname": "Service Manhour Detail", "icon": "assets/images/overagesetup.png", "routname": "service/servicemanhourdetailreport", "value": "005" },

      ]
    },

  ]

  appList1: any =
    [
      {
        "title": "Service",
        "card":
          [
            { "appid": "4", "appname": "Service Tickets", "icon": "assets/images/service.png", "routname": "service/service-tickets", "value": "001" },
            { "appid": "5", "appname": "Service Summary", "icon": "assets/images/servicesummary.png", "routname": "service/summary", "value": "002" },
          ]
      }
    ]

    serviceOverage = { "appid": "12", "appname": "Service Overage Report", "icon": "assets/images/overage.png", "routname": "service/serviceoveragereport", "value": "002" };
  


  // customercard:any = [
  //   { "appid": "10", "appname": "Service Type", "icon": "assets/images/servicetype.png", "routname": "service/servicetype", "value": "003" },
  //   { "appid": "11", "appname": "Service Status", "icon": "assets/images/servicestatus.png", "routname": "service/status", "value": "004" },
  //   { "appid": "8", "appname": "Customers", "icon": "assets/images/customers.png", "routname": "service/customers", "value": "008" },

  // ]
  customercare: boolean = false;
  customers: any = [];
  loadCustomer: boolean = false;
  customerAry: string = "";
  selectCustomerID: any = [];
  arrays: any = [];
  permission: any = [];

  private subs = new SubSink();

  // customercarecheck: boolean = false;
  constructor(
    public router: Router,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private ref: ChangeDetectorRef,
    private kunyekService: KunyekService,
    private route: ActivatedRoute,

  ) {
    this.allinoneService.isCRMServiceView = true;
    this.allinoneService.isCustomerCare = false;
    console.log("okokko" + this.allinoneService.getWebView())

    if (this.allinoneService.getWebView() == 'true') {
      console.log("okokko")
      this.allinoneService.navbar = false
    }

  }
  organizations: any = [];
  orgid: any = '';
  orgName: any = '';
  admin: boolean = false;
  orgList: any = []
  checkorg: boolean = false;
  customerdata: any = [];
  customerid: any = "";
  servicetype: boolean = false;
  servicestatus: boolean = false;
  async ngOnInit(): Promise<void> {
    this.allinoneService.activeroute = "/sales";


    // if(window.location.reload()){

    // }
    this.organizations = this.allinoneService.orgsCRm;


    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else if (window.innerWidth > this.mobileViewWidth) {
      this.isMobileView = false;
    }

    this.customerdata = this.allinoneService.getCustomer();
    this.customerid = this.route.snapshot.queryParamMap.get('customerid') || "";
    if (this.customerid != '' && this.customerid) {
      console.log("11111111111222222222222222");
      this.customercare = true;
      var temp = this.organizations.find((x: any) => x.orgid == this.allinoneService.getSelectedCRMOrg());

      console.log(temp);
      if (temp != undefined) {
        console.log("1");
        this.allinoneService.selected_orgcrm = [];
        this.allinoneService.selected_orgcrm = temp;
        this.orgName = temp.name;
        this.checkorg = false;


        console.log();
        for (let i = 0; i < this.organizations.length; i++) {
          if (this.orgid == this.organizations[i].orgid) {
            if (this.organizations[i].admin == 'true') {
              this.allinoneService.crmOrgAdmin = 'true'
            } else {
              this.allinoneService.crmOrgAdmin = 'false'
            }
          }
        }
      } else {
        this.checkorg = true;
      }
      await this.getC();
      console.log("customerid" + this.customerid)
      console.log(this.customers)
      var type = this.customers.map((x: any) => x.customerid).indexOf(this.customerid);
      console.log(type)
      if (type != -1) {
        console.log("check true");

        if (this.customers.length > 0) {

          console.log(">>")
          console.log(this.customerdata)

          this.customerAry = this.customers[type].customerid;
          this.selectCustomerID = this.customers[type];
          console.log("...selectcustoemr");
          console.log(this.selectCustomerID)
          this.allinoneService.setSelectedCRM(this.customers[type].orgid);
          this.allinoneService.setCookie(
            'customercrm',
            JSON.stringify(this.customers[type]) || 'undefined'
          );
          this.allinoneService.selectedOrgCRM = this.customers[type].orgid;
          // 5868957369
          var temp = this.organizations.find((x: any) => x.orgid == this.customers[type].orgid);
          if (this.customers[0].contacts.length > 0) {
            this.checkselfservice = this.customers[type].contacts[type].selfservice == null || this.customers[type].contacts[0].selfservice == 'true' ? true : false;
          } else {
            this.checkselfservice = false;
          }
          if (temp != undefined) {
            this.allinoneService.crmOrgAdmin = temp.admin;

          }


        }
      } else {
        console.log("check false");
        this.customerAry = ''

      }
    } else {
      console.log("33333333333333333333333333");
      console.log(this.organizations.length);
      // console.log(">>")
      // console.log(this.customerdata)
      if (this.customerdata && this.customerdata != '') {
        this.customercare = true;

        console.log(this.allinoneService.selected_orgcrm)
        if (this.allinoneService.selected_orgcrm) {
          this.orgName = this.allinoneService.selected_orgcrm.name;
          this.checkorg = false;
        }
        await this.getCustomers()

        this.customerAry = this.customerdata.customerid.toString();
        // this.customercarecheck = true;
        let i = this.allinoneService.getindex()
        console.log("index")
        if (this.customerdata.contacts.length > 0) {
          this.checkselfservice = this.customerdata.contacts[0].selfservice == null || this.customerdata.contacts[0].selfservice == 'true' ? true : false;
        } else {
          this.checkselfservice = false;
        }
        console.log(this.checkselfservice)
        if (i && i != "") {
          if (parseInt(i) > -1) {
            this.allinoneService.selected_orgcrm = [];
            this.allinoneService.selected_orgcrm = this.organizations[i];
            this.allinoneService.setSelectedCRM(this.organizations[i].orgid);
            this.orgName = this.organizations[i].name;
            this.checkorg = false;
            this.orgid = this.organizations[i].orgid
          }
        }
        this.checkorg = false;
      } else {
        if (this.organizations.length > 0) {
          var index = this.organizations.map((x: any) => x.orgid).indexOf(this.allinoneService.getSelectedCRMOrg());
          console.log(this.organizations)
          console.log(this.allinoneService.getSelectedCRMOrg())
          console.log("setinde")
          this.allinoneService.setIndex(index)
        }
        this.customercare = false;
        this.checkorg = true;
      }
      //




      if (this.organizations.length > 0) {
        // var index = this.organizations.map((x: any) => x.orgid).indexOf(this.allinoneService.getSelectedCRMOrg());
        // this.allinoneService.orgIndex
        var temp = this.organizations.find((x: any) => x.orgid == this.allinoneService.getSelectedCRMOrg());
        console.log(temp);
        if (temp != undefined) {
          console.log("1");
          this.allinoneService.selected_orgcrm = [];
          this.allinoneService.selected_orgcrm = temp;
          this.allinoneService.setSelectedCRM(temp.orgid);
          this.orgName = temp.name;
          this.checkorg = false;
          this.orgid = this.allinoneService.selectedOrgCRM
            ? this.allinoneService.selectedOrgCRM
            : this.organizations[0].orgid
          console.log();
          for (let i = 0; i < this.organizations.length; i++) {
            if (this.orgid == this.organizations[i].orgid) {
              if (this.organizations[i].admin == 'true') {
                this.allinoneService.crmOrgAdmin = 'true'
              } else {
                this.allinoneService.crmOrgAdmin = 'false'
              }
            }
          }
        } else {
          console.log("2");
          var is = this.allinoneService.getindex()
          if (is && is != "") {
            if (parseInt(is) > -1) {

              this.allinoneService.selected_orgcrm = [];
              this.allinoneService.selected_orgcrm = this.organizations[is];
              this.allinoneService.setSelectedCRM(this.organizations[is].orgid);
              this.orgName = this.organizations[is].name;
              this.checkorg = false;
              this.orgid = this.organizations[is].orgid
              this.allinoneService.crmOrgAdmin = this.organizations[is].admin
            }
          }

          console.log(this.organizations, "org");
        }

      } else {
        console.log("3");
        this.customercare = true;
        this.checkorg = true;
        await this.getCustomers();
        // this.router.navigateByUrl('/');
        // this.allinoneService.isCRMServiceView = false;
      }
      // if (this.organizations.length > 0) {

      // } else if (this.organizations.length == 0) {
      //   this.checkorg = true;
      // }
      this.permission = this.allinoneService.getCRMpermission()
      console.log(this.permission)
      console.log("iejifj")
      if (this.permission.length > 0) {
        var type = this.permission.map((x: any) => x).indexOf('003');
        if (type > -1) {
          this.servicetype = true;
          this.appList1[0].card.push({
            "appid": "10",
            "appname": "Service Type",
            "icon": "assets/images/servicetype.png",
            "routname": "service/servicetype",
            "value": "003",
          });
        }
      }
      if (this.permission.length > 0) {
        var type1 = this.permission.map((x: any) => x).indexOf('004');
        if (type1 > -1) {
          this.servicestatus = true;
          this.appList1[0].card.push({
            "appid": "11",
            "appname": "Service Status",
            "icon": "assets/images/servicestatus.png",
            "routname": "service/status",
            "value": "004"
          })
        }

      }
      if (this.permission.length > 0) {
        var type2 = this.permission.map((x: any) => x).indexOf('008');
        if (type2 > -1) {
          this.servicestatus = true;
          this.appList1[0].card.push({
            "appid": "8",
            "appname": "Customers",
            "icon": "assets/images/customers.png",
            "routname": "sales/customers", "value": "008"
          })
        }

      }
    }



  }
  CustomerChange1(event: any) {
    console.log()
    if (event.target.checked == true) {
      this.customercare = false;
      // this.customercarecheck = false;
      this.allinoneService.setCookie('customercrm', JSON.stringify(''));
      this.customerAry = "";

      var i = this.allinoneService.getindex()
      console.log(i)
      if (i && i != "") {
        if (parseInt(i) > -1) {
          this.allinoneService.selected_orgcrm = [];
          this.allinoneService.selected_orgcrm = this.organizations[i];
          this.allinoneService.setSelectedCRM(this.organizations[i].orgid);
          this.allinoneService.selectedOrgCRM = this.organizations[i].orgid;
          this.allinoneService.crmOrgAdmin = this.organizations[i].admin
          console.log("lll");
          console.log(this.organizations[i])
          console.log(this.allinoneService.getSelectedCRMOrg())
          this.orgName = this.organizations[i].name;
          this.checkorg = false;
          this.orgid = this.organizations[i].orgid
          console.log(this.orgid)
        }
      }
      this.subs.unsubscribe();


    }
  }
  async CustomerChange2(event: any) {
    this.customers = []
    if (event.target.checked == true) {
      this.customercare = true;
      console.log(this.customers)
      if (this.customers.length == 0) {
        await this.getCustomers();
      }

    }
  }

  getC() {
    return new Promise((resolve => {
      this.loadCustomer = true;
      const data = {
        domainid: this.allinoneService.getDomain().domainid,
        // orgid: this.allinoneService.selectedOrgCRM
        //   ? this.allinoneService.selectedOrgCRM
        //   : this.organizations[0].orgid,
      };
      this.subs.sink = this.kunyekService.getCustomercare(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {

            this.customers = res.list;
            console.log(this.customers)
            this.loadCustomer = false;

            resolve('true')
            // var type = this.customers.map((x: any) => x.customerid).indexOf(data.target.value);
            // if (this.customerid == '') {
            //   if (this.customers.length > 0) {
            //     this.customerdata = this.allinoneService.getCustomer();
            //     console.log(">>")
            //     console.log(this.customerdata)
            //     if (!this.customerdata && this.customerdata == '') {

            //       this.customerAry = this.customers[0].customerid;
            //       this.allinoneService.setSelectedCRM(this.customers[0]);
            //       this.allinoneService.setCookie(
            //         'customercrm',
            //         JSON.stringify(this.customers[0]) || 'undefined'
            //       );
            //       this.allinoneService.selectedOrgCRM = this.customers[0].orgid;

            //       var temp = this.organizations.find((x: any) => x.orgid == this.customers[0].orgid);
            //       if (this.customers[0].contacts.length > 0) {
            //         this.checkselfservice = this.customers[0].contacts[0].selfservice == null || this.customers[0].contacts[0].selfservice == 'true' ? true : false;
            //       } else {
            //         this.checkselfservice = false;
            //       }
            //       if (temp != undefined) {
            //         this.allinoneService.crmOrgAdmin = temp.admin;

            //       }
            //     }

            //   }
            // }
            // this.customerAry = this.customerdata.customerid.toString();
          } else {
            this.loadCustomer = false;
            resolve('false')
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          this.loadCustomer = false;
          resolve('false')
          this.messageService.openSnackBar(err, 'warn');
        }
      );


    }));
  }
  getCustomers() {
    return new Promise((resolve) => {
      this.loadCustomer = true;
      const data = {
        domainid: this.allinoneService.getDomain().domainid,
        // orgid: this.allinoneService.selectedOrgCRM
        //   ? this.allinoneService.selectedOrgCRM
        //   : this.organizations[0].orgid,
      };
      this.subs.sink = this.kunyekService.getCustomercare(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {

            this.customers = res.list;
            console.log(this.customers)
            if (this.customerid == '') {
              if (this.customers.length > 0) {
                this.customerdata = this.allinoneService.getCustomer();
                console.log(">>")
                console.log(this.customerdata)
                if (!this.customerdata && this.customerdata == '') {

                  this.customerAry = this.customers[0].customerid;
                  this.selectCustomerID = this.customers[0];
                  this.allinoneService.setSelectedCRM(this.customers[0].orgid);
                  this.allinoneService.setCookie(
                    'customercrm',
                    JSON.stringify(this.customers[0]) || 'undefined'
                  );
                  this.allinoneService.selectedOrgCRM = this.customers[0].orgid;

                  var temp = this.organizations.find((x: any) => x.orgid == this.customers[0].orgid);
                  if (this.customers[0].contacts.length > 0) {
                    this.checkselfservice = this.customers[0].contacts[0].selfservice == null || this.customers[0].contacts[0].selfservice == 'true' ? true : false;
                  } else {
                    this.checkselfservice = false;
                  }
                  if (temp != undefined) {
                    this.allinoneService.crmOrgAdmin = temp.admin;

                  }
                } else {
                  console.log(this.customers)
                  var type = this.customers.map((x: any) => x.customerid).indexOf(this.customerdata.customerid);

                  this.customerAry = this.customers[type].customerid;
                  this.allinoneService.setSelectedCRM(this.customers[type]);
                  this.selectCustomerID = this.customers[type]

                  this.allinoneService.setCookie(
                    'customercrm',
                    JSON.stringify(this.customers[type]) || 'undefined'
                  );
                  this.allinoneService.selectedOrgCRM = this.customers[type].orgid;

                  var temp = this.organizations.find((x: any) => x.orgid == this.customers[type].orgid);
                  console.log("......>>>>>>")
                  console.log(this.customers[type].contacts);
                  console.log(this.customers[type].contacts[0]);
                  if (this.customers[type].contacts.length > 0) {
                    this.checkselfservice = this.customers[type].contacts[0].selfservice == null || this.customers[type].contacts[0].selfservice == 'true' ? true : false;
                  } else {
                    this.checkselfservice = false;
                  }
                  if (temp != undefined) {
                    this.allinoneService.crmOrgAdmin = temp.admin;

                  }
                }

              }
            }
            // this.customerAry = this.customerdata.customerid.toString();
            this.loadCustomer = false;
            resolve('true')
          } else {
            resolve('false')
            this.loadCustomer = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          resolve('false')
          this.loadCustomer = false;
          this.messageService.openSnackBar(err, 'warn');
        }
      );
    });
  }
  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.allinoneService.activeroute = "/adminconsole";

  }



  checkselfservice: boolean = true;
  selectCustomer(data: any) {
    console.log(data);
    if (data != undefined) {

      this.customerAry = data.customerid

      if (this.customerAry) {
        var index = this.organizations.map((x: any) => x.orgid).indexOf(this.allinoneService.getSelectedCRMOrg());
        console.log(this.allinoneService.getSelectedCRMOrg());
        console.log(this.organizations)
        console.log("setinde1" + index)
        // console.log("setinde1"+ this.allinoneService.getSelectedCRMOrg())
        if (this.customerid != '') {
          this.allinoneService.setIndex(index)

        }
        console.log(index)
        var type = this.customers.map((x: any) => x.customerid).indexOf(data.customerid);
        this.arrays = this.customers[type]
        console.log(this.arrays)
        console.log(this.arrays.contacts)

        // this.customercarecheck = true;
        this.allinoneService.setSelectedCRM(this.arrays.orgid);
        this.allinoneService.setCookie(
          'customercrm',
          JSON.stringify(this.arrays) || 'undefined'
        );
        this.allinoneService.selectedOrgCRM = this.arrays.orgid;
        console.log(this.organizations)
        var temp = this.organizations.find((x: any) => x.orgid == this.arrays.orgid);

        if (this.arrays.contacts.length > 0) {
          this.checkselfservice = this.arrays.contacts[0].selfservice == null || this.arrays.contacts[0].selfservice == 'true' ? true : false;
        } else {
          this.checkselfservice = false;
        }
        if (temp != undefined) {
          this.allinoneService.crmOrgAdmin = temp.admin;

        }
      } else if (this.customerAry == '') {
        console.log("remove")
        // this.customercarecheck = false;
        if (this.organizations.length > 0) {
          this.checkorg = false;

          this.allinoneService.selected_orgcrm = [];
          this.allinoneService.selected_orgcrm = this.organizations[0];
          this.allinoneService.setSelectedCRM(this.organizations[0].orgid);
          this.orgName = this.organizations[0].name;
          this.checkorg = false;
          this.orgid = this.organizations[0].orgid

          // for (let i = 0; i < this.organizations.length; i++) {
          //   if (this.orgid == this.organizations[i].orgid) {
          //     if (this.organizations[i].admin == 'true') {
          //       this.allinoneService.crmOrgAdmin = 'true'
          //     } else {
          this.allinoneService.crmOrgAdmin = this.organizations[0].admin
        }
        this.customerAry = '';
        this.allinoneService.setCookie(
          'customercrm',
          JSON.stringify('')
        );

      } else {
        this.selectCustomerID = [];

      }

    }


  }

  goService(data: any) {
    console.log(this.selectCustomerID)
    if (this.selectCustomerID && !Array.isArray(this.selectCustomerID)) {
      this.router.navigateByUrl(`${data}?v=selfservice`);
      this.allinoneService.isCustomerCare = true;
    } else {
      this.messageService.openSnackBar('Please select customer.', 'warn');
    }
  }

  goCustomerInfo(data: any) {
    console.log(this.selectCustomerID)
    if (this.selectCustomerID && !Array.isArray(this.selectCustomerID)) {
      this.router.navigateByUrl(`${data}`);
      this.allinoneService.isCustomerCare = true;
    } else {
      this.messageService.openSnackBar('Please select customer.', 'warn');
    }

  }

  goRout(app: any, routname: any) {
    console.log(routname)
    // this.router.navigateByUrl(`${routname}`);


    if (this.allinoneService.crmOrgAdmin == 'true') {
      this.router.navigateByUrl(`${routname}`);

    } else {
      this.permission = this.allinoneService.getCRMpermission()
      console.log(this.permission);
      if (this.permission.length > 0) {

        var type = this.permission.map((x: any) => x).indexOf(app.value);
        
        if (type > -1 && routname == 'service/service-tickets') {
          this.router.navigateByUrl(`service/service-tickets`);
        }
        else if (type > -1 && routname == 'service/summary') {
          this.router.navigateByUrl(`service/summary`);
        }
        else if (type > -1 && routname == 'service/servicetype') {
          this.router.navigateByUrl(`service/servicetype`);
        }
        else if (type > -1 && routname == 'service/status') {
          this.router.navigateByUrl(`service/status`);
        }

        else if ((type > -1 && routname == 'service/customers')) {
          this.router.navigateByUrl(`service/customers`);
        }
        else if ((type > -1 && routname == 'service/serviceoveragereport' )) {
          this.router.navigateByUrl("service/serviceoveragereport");
        }
        else {
          // this.messageService.openSnackBar('You do not have the permission required to perform this operation.', 'warn');
          this.messageService.openSnackBar(this.allinoneService.unauthorizedAccessMessage, 'warn');

        }

      } else {
        this.messageService.openSnackBar(this.allinoneService.unauthorizedAccessMessage, 'warn');
      }

    }

  }
}
