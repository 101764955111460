import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { CrmLeadConvertComponent } from '../crm-lead-convert/crm-lead-convert.component';
import { ProductsComponent } from '../products/products.component';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-crm-lead-add',
  templateUrl: './crm-lead-add.component.html',
  styleUrls: ['./crm-lead-add.component.css'],
})
export class CrmLeadAddComponent implements OnInit {
  @ViewChild('hiddenpdffileinput') hiddenpdffileinput: any;

  @Input() isExcel: any;
  @Input() orgid: any;
  @Input() businessType: any[] = [];
  @Input() currencyType: any = [];
  @Input() statusList: any = [];
  @Input() editData: any;
  @Input() industrytypelist: any[] = [];
  @Input() leadSourceList: any[] = [];

  @Output('save')
  save: EventEmitter<any> = new EventEmitter<any>();

  @Output('crmcancel')
  cancel: EventEmitter<any> = new EventEmitter<any>();

  attachmentList: any[] = [];
  deleteFileList: any[] = [];
  desiableStatus: boolean = false;
  // industrytypelist:any = [
  //   {
  //     "name":"Nyi Ye Lwin"
  //   },
  //   {
  //     "name":"Wai Yan Soe"
  //   },
  // ];
  industryvalue: any = '';
  text1: any = '';
  text2: any = '';
  leadForm = {
    id: '',
    orgid: '',
    name: '',
    businesstype: '001',
    post: '',
    org: '',
    phone: '',
    email: '',
    address1: '',
    address2: '',
    currencycode: '001',
    amount: 0,
    products: [],
    status: '002',
    remark: '',
    industrytype: '',
    leadsource: '',
  };

  leadFormSubmitted: boolean = false;
  saveLoading: boolean = false;
  isEditing: boolean = false;

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private http: HttpClient,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.leadForm.orgid = this.orgid;
    if (this.editData) {
      this.isEditing = true;
      this.leadForm = {
        id: this.editData.leadid,
        orgid: this.orgid,
        name: this.editData.name,
        businesstype: this.editData.leadtype,
        post: this.editData.post,
        org: this.editData.organization,
        phone: this.editData.mobile,
        email: this.editData.email,
        address1: this.editData.address1,
        address2: this.editData.address2,
        currencycode: this.editData.currency,
        amount: parseInt(this.editData.amount),
        products: this.editData.product,
        status: this.editData.status,
        remark: this.editData.note,
        industrytype: this.editData.industrytype,
        leadsource: this.editData.leadsource,
      };
      console.log(this.editData)
      if (this.editData.status == '001') {
        this.desiableStatus = true;
      }
      this.attachmentList = this.editData.filelist;
    }
  }

  onFileSelect(event: any) {
    let files = event.target.files;
    console.log('file')
    console.log(files)
    if (files) {
      for (let file of files) {
        // if (file.type == 'application/pdf') {
        var alreadySelect = false;
        this.attachmentList.map((item: any) => {
          if (item.filename == file.name) {
            alreadySelect = true;
          }
        });
        if (!alreadySelect) {
          var temp = {
            file: file,
            filename: file.name,
          };
          this.attachmentList.push(temp);
        }
   
      }
      this.hiddenpdffileinput.nativeElement.value = '';
    }
  }

  removeAttachment(index: number) {
    if (this.attachmentList[index].fileurl) {
      this.deleteFileList.push(this.attachmentList[index].filename)
    }
    this.attachmentList.splice(index, 1);
  }

  cancelFunction() {
    this.cancel.emit();
  }

  submitLead() {
    this.leadFormSubmitted = true;
    // if (this.leadForm.phone != '') {
    //   if ((/([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/g).test(this.allinoneService.checkUserId(this.leadForm.phone.toString().trim())) == false) {
    //     //  if(this.leadForm.phone == '' || this.leadForm.phone.substring(0, 2) != '09' || this.leadForm.phone.substring(0, 4) != '+959' || this.leadForm.phone.toString().length > 12){
    //     this.messageService.openSnackBar("Please enter valid phone number.", 'warn');
    //     return;
    //   }
    // }
    if (this.leadForm.name && this.leadForm.org) {
      this.saveLead();
    }
  }

  async saveLead() {

    if (this.leadForm.name.length > 255
      || this.leadForm.post.length > 255
      || this.leadForm.phone.length > 255
      || this.leadForm.email.length > 255
      || this.leadForm.address1.length > 255
      || this.leadForm.address2.length > 255) {
      return;
    }
    this.saveLoading = true;

    const tempFileName = formatDate(
      new Date(),
      'yyyyMMddHH:mm:ss',
      'en-US'
    ).toString();

    var tempFileList = this.attachmentList
    this.attachmentList = []
    tempFileList.map((item: any) => {
      if (!item.fileurl) {
        this.attachmentList.push(item)
      }
    })

    var filepath = 'crm/' + (this.isEditing ? this.leadForm.id : tempFileName).toString()
    console.log(filepath)
    if (this.attachmentList.length > 0) {
      const upload_aws = await this.allinoneService.multipleUploadToAWS(
        this.attachmentList,
        filepath
      );
      if (!upload_aws) {
        this.messageService.openSnackBar(
          'Unknown error occured while uploading your files.',
          'warn'
        );
        return;
      }
    }

    const filenamelist: any = [];
    tempFileList.map((item: any) => {
      filenamelist.push(item.filename);
    });
    console.log(">.....");
    console.log(this.leadForm.products)
    this.leadForm.products.map((item: any) => {
      item.qty = item.qty.toString()
      item.price = item.price.toString()
      item.amount = item.amount.toString()
    })
    var data = {
      name: this.leadForm.name,
      leadtype: this.leadForm.businesstype,
      mobile: this.leadForm.phone == '' ? '' : this.allinoneService.checkUserId(this.leadForm.phone.toString().trim()),
      email: this.leadForm.email,
      address1: this.leadForm.address1,
      address2: this.leadForm.address2,
      product: this.leadForm.products,
      post: this.leadForm.post,
      currency: this.leadForm.currencycode,
      amount: this.leadForm.amount ? this.leadForm.amount.toString() : '',
      note: this.leadForm.remark,
      date: this.allinoneService.formatDBToShowInEdit(this.allinoneService.getCurrentDateToDB()),
      organization: this.leadForm.org,
      status: this.leadForm.status,
      orgid: this.leadForm.orgid,
      filename: filenamelist,
      tmpfilename: tempFileName,
      domainid: this.allinoneService.getDomain().domainid,
      industrytype: this.leadForm.industrytype,
      leadsource: this.leadForm.leadsource,

    };
    if (this.isEditing) {
      data = Object.assign(data,
        {
          leadid: this.leadForm.id,
          deletefilename: this.deleteFileList
        }
      );
    }
    var fun = this.isEditing
      ? this.kunyekService.editCrmLead(data)
      : this.kunyekService.createCrmLead(data);

    console.log(data);
    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.saveLoading = false;
          this.save.emit();
        } else {
          this.saveLoading = false;
          this.messageService.openSnackBar(res.status, 'warn');
        }
      },
      (err) => {
        this.saveLoading = false;
      }
    );
  }

  downloadFile(fileurl: any, filename: any) {
    window.open(fileurl,filename);
    this.http
      .get(fileurl, { responseType: 'blob' })
      .subscribe((response: any) => {
        saveAs(response, filename);
      }),
      (error: any) => {
        this.messageService.openSnackBar('Download failed.', 'warn');
      };
  }

  openProductForm() {
    const dialog = this.dialog.open(ProductsComponent, {
      width: '700px',
      height: "550px",
      closeOnNavigation: true,
      panelClass: ['event-modal', 'event-detail', 'mat-typography'],
      data: {
        "form": 'lead',
        'products': this.leadForm.products,
      }
    })

    dialog.afterClosed().subscribe((dialogResult: any) => {
      this.leadForm.products = dialogResult.datalist
      this.leadForm.amount = 0
      this.leadForm.products.map((item: any) => {
        this.leadForm.amount += parseInt(item.amount)
      })
    });
  }

  convert() {
    const data = {
      "orgid": this.leadForm.orgid,
      "leadid": this.leadForm.id,
      "leadtype": this.leadForm.businesstype,
    }
    const dialog = this.dialog.open(CrmLeadConvertComponent, {
      maxWidth: '750px',
      maxHeight: '400px',
      closeOnNavigation: true,
      panelClass: ['mat-typography'],
      data: {
        "orgid": data.orgid,
        "leadid": data.leadid,
        "leadtype": data.leadtype,
        "organization": this.leadForm.org,
        "businessType": this.businessType,
        "name": this.leadForm.name,
        "email": this.leadForm.email,
        "phone": this.leadForm.phone,
        "post": this.leadForm.post,
        "amount": this.leadForm.amount,
        "campany": this.leadForm.org
      }
    })

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.save.emit();
      }
    });
  }

  industrySelect(data: any) {
    this.leadForm.industrytype = data.name
  }
  leadSelect(data: any) {
    this.leadForm.leadsource = data.name
  }


  errorList: any = []
  // excel:boolean = false;
  checkingExcelFile: boolean = false;
  allok: boolean = false;
  duplicateList: any = [];
  warningList: any = [];
  excelerror: boolean = false;
  addlead: boolean = false;
  finalMemberList: any = [];
  useridList: any = [];
  newcclist: any = [];
  uidList: any = [];
  excel: any = '';
  rawMemberList: any[] = [];
  @ViewChild('excelFile') excelFile: any;
  Start: any = 0;
  End: any = 30;
  mobileList: any = [];
  emailList: any = [];
  nameList: any = [];
  downloadSampleFile(): any {
    this.http
      .get(
        this.allinoneService.bucketUrl + 'templates/CRMCustomerSampleTemplate.xlsx',
        { responseType: 'blob' }
      )
      .subscribe((response: any) => {
        saveAs(response, 'CRMCustomerSampleTemplate.xlsx');
      }),
      (error: any) => {
        this.messageService.openSnackBar('Download failed.', 'warn');
      };
  }


  removeExcel() {
    this.excel = '';
    this.excelFile.nativeElement.value = '';
    this.errorList = [];
    this.warningList = [];
    this.excelerror = false;
    this.duplicateList = [];
    this.finalMemberList = [];
    this.useridList = [];
    this.newcclist = [];
    this.uidList = [];
    this.allok = false;
    this.errorList = [];
    this.mobileList = [];
    this.nameList = [];
    this.emailList = [];

  }

  onExcelFileSelect(event: any) {
    this.excelerror = false;
    if (
      event.target.files[0].type ==
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      this.excel = event.target.files[0];
      const target: DataTransfer = <DataTransfer>event.target;
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        const wsname: string = wb.SheetNames[0];

        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        this.rawMemberList = XLSX.utils.sheet_to_json(ws, { header: 1 });
        console.log(this.rawMemberList);
        this.checkExcelFile();
      };

      reader.readAsBinaryString(target.files[0]);
    } else {
      this.removeExcel();
      this.messageService.openSnackBar('File type is not supported!', 'warn');
    }
  }


  checkExcelFile() {
    this.checkingExcelFile = true;

    this.duplicateList = [];
    this.finalMemberList = [];
    this.useridList = [];
    this.uidList = [];

    const tempCcList: any = [];
    for (let i = 1; i < this.rawMemberList.length; i++) {
      const raw = this.rawMemberList[i];
      if (
        (raw[0] == null || raw[0] == undefined || raw[0] == '') &&
        (raw[1] == null || raw[1] == undefined || raw[1] == '') &&
        (raw[2] == null || raw[2] == undefined || raw[2] == '') &&
        (raw[3] == null || raw[3] == undefined || raw[3] == '') &&
        (raw[4] == null || raw[4] == undefined || raw[4] == '') &&
        (raw[5] == null || raw[5] == undefined || raw[5] == '') &&
        (raw[6] == null || raw[6] == undefined || raw[6] == '') &&
        (raw[7] == null || raw[7] == undefined || raw[7] == '') &&
        (raw[8] == null || raw[8] == undefined || raw[8] == '') &&
        (raw[9] == null || raw[9] == undefined || raw[9] == '') &&
        (raw[10] == null || raw[10] == undefined || raw[10] == '')
      ) {
      } else {
        if (raw[0] == null || raw[0] == undefined || raw[0] == '') {
          const tempError = {
            line: (i + 1).toString(),
            error: "'Name' is empty.",
          };
          this.errorList.push(tempError);
        } else {
          if (raw[3] == null || raw[3] == undefined || raw[3] == '') {
            const tempError = {
              line: (i + 1).toString(),
              error: "'Campany' is empty.",
            };
            this.errorList.push(tempError);
          } else {
         


            // const eindex = this.mobileList.findIndex((x: any) =>
            //   x.mobile.trim() == raw[1] ? raw[1] : ''.toString().trim()
            // );
            // console.log(">>>>mobile")
            // console.log(this.mobileList)
            // console.log(eindex)
            // if (eindex > -1) {
            //   const tempError = {
            //     line: (i + 1).toString(),
            //     error:
            //       "Mobile '" +
            //       raw[1].toString().trim() +
            //       "' is duplicated.",
            //   };
            //   this.errorList.push(tempError);
            // } else {
            //   if (raw[1] ? raw[1] : ''.toString().trim() != '') {
            //     var tempMobile = {
            //       line: (i + 1).toString(),
            //       mobile: raw[1].toString().trim(),
            //     };
            //     this.mobileList.push(tempMobile);
            //   }

            //   const eindex1 = this.emailList.findIndex((x: any) =>
            //     x.email.trim() == raw[2] ? raw[2] : ''.toString().trim()
            //   );
            //   console.log(">>>>")
            //   console.log(eindex)
            //   if (eindex1 > -1) {
            //     const tempError = {
            //       line: (i + 1).toString(),
            //       error:
            //         "Email '" +
            //         raw[2].toString().trim() +
            //         "' is duplicated.",
            //     };
            //     this.errorList.push(tempError);
            //   } else {
            //     if (raw[2] ? raw[2] : ''.toString().trim() != '') {
            //       var tempEmail = {
            //         line: (i + 1).toString(),
            //         email: raw[2].toString().trim(),
            //       };
            //       this.emailList.push(tempEmail);
            //     }



            const name = raw[0] ? raw[0].toString() : '';
            const businessType = raw[1] ? raw[1].toString() : '';
            const post = raw[2] ? raw[2].toString() : '';
            const campany = raw[3] ? raw[3].toString() : '';
            const mobile = raw[4] ? raw[4].toString() : '';
            const email = raw[5] ? raw[5].toString() : '';
            const address = raw[6] ? raw[6].toString() : '';
            const industrytype = raw[7] ? raw[7].toString() : '';
            const leadsourse = raw[8] ? raw[8].toString() : '';
            const currency = raw[9] ? raw[9].toString() : '';
            const status = raw[10] ? raw[10].toString() : '';
            const remark = raw[11] ? raw[11].toString() : '';

            const tempMember = {
              name: name,
              leadtype: businessType == 'Organization' ? '001' : '002',
              post: post,
              organization: campany,
              mobile: this.allinoneService.checkUserId(mobile),
              email:email,
              address1: address,
              industrytype: industrytype,
              leadsource: leadsourse,
              currency: currency,
              status: status == '1' ? '002' : '001',
              note:remark,
              // contacts: contactss
            };
   
            this.finalMemberList.push(tempMember);
            this.checkingExcelFile = false;
            this.allok = true;
          }

          // }
          // }
        }

      }
    }
    console.log('err ====> ');
    console.log(this.errorList);
    console.log('duplicate ====> ');
    console.log(this.duplicateList);
    console.log('final ====> ');
    console.log(this.finalMemberList);
    if (
      this.errorList.length == 0 &&
      this.newcclist.length == 0 &&
      this.finalMemberList.length > 0
    ) {
      // this.checkEidList()
    } else {
      this.checkingExcelFile = false;
    }
  }

  submitExcelLead() {
    console.log("11")

    if (this.finalMemberList.length > 0) {
      if (this.End > this.finalMemberList.length) {
        this.End = this.finalMemberList.length;
      }
      this.AddExeclLead();
    }
    else {
      this.messageService.openSnackBar('Your excel file is empty', 'warn')
    }
  }

  AddExeclLead() {

    console.log(22)


    var templead: any[] = [];
    for (let i = this.Start; i < this.End; i++) {
      templead.push(this.finalMemberList[i]);
    }
    this.addlead = true;
    this.excelerror = false;
    const data = {
      leadlist: templead,
      createtype: 'array',
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.leadForm.orgid,
      name: '',
      leadtype: '',
      mobile: '',
      email: '',
      address1: '',
      address2: '',
      product: '',
      post: '',
      currency: '',
      amount: '',
      note: '',
      date: this.allinoneService.formatDBToShowInEdit(this.allinoneService.getCurrentDateToDB()),
      organization: '',
      status: '',
      filename: '',
      tmpfilename: '',
      industrytype: '',
      leadsource: '',
    };
    console.log(data);

    this.kunyekService.createCrmLead(data).subscribe((res: any) => {
      if (res.returncode == '300') {
        if (this.End == this.finalMemberList.length) {
          this.addlead = false;


          this.isExcel = false;
          this.excel = false;
          this.allok = false;
          // this.removeExcel();
          this.Start = 0;
          this.End = 30;
          this.save.emit();

        } else {
          this.Start = this.End;
          this.End = this.End + 30;
          if (this.End > this.finalMemberList.length) {
            this.End = this.finalMemberList.length;
          }
          this.AddExeclLead();
        }
      } else {
        this.addlead = false;
        this.messageService.openSnackBar(res.status, 'warn');
      }
    });

  }

}
