import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { resolve } from 'dns';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { ApprovalExportComponent } from '../approval-export/approval-export.component';
import { ApprovalFilterComponent } from '../approval-filter/approval-filter.component';
import { OfficeLeaveSummaryComponent } from '../office-leave-summary/office-leave-summary.component';
import { FileUploadComponent } from 'src/app/shared/components/file-upload/file-upload.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-office-approval',
  templateUrl: './office-approval.component.html',
  styleUrls: ['./office-approval.component.scss'],
})
export class OfficeApprovalComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  mobileViewWidth: any = 490;
  itemPerPage: any = 100;
  tranItemPerPage: number = 100;
  currentPage: number = 1;
  tranCurrentPage: number = 1;
  count = 0;
  selectedRequests = 0;
  approveCount = 0;
  rejectCount = 0;
  paidCount = 0;
  checkCount = 0;

  viewingRequest: boolean = false;
  isMobileView: boolean = false;
  isFocus: boolean = false;
  nextDisabled: boolean = false;
  approvalListLoading: boolean = false;
  gettingRequestType: boolean = false;
  isAdmin: boolean = false;
  addingRequest: boolean = false;
  currentPageAllSelected: boolean = false;
  // multipleLoading: boolean = false;
  approveLoading: boolean = false;
  rejectLoading: boolean = false;
  paidLoading: boolean = false;
  isDeleting: boolean = false;

  searchText: string = '';
  transearchText: string = '';
  lastPage: any = '';
  end: string = '';
  tranend: string = '';

  organizations: any[] = [];
  approvalList: any[] = [];
  selectedRequest: any = [];
  multipleRequestList: any = [];
  memberList: any = [];
  requestSubTypeList: any = [];

  showSubType: boolean = false;
  showType: boolean = false;
  showPayment: boolean = false;

  requestTypeList: any[] = [];
  filterReqTypeList: any[] = [];
  teamMemberList = [
    {
      name: 'All',
      value: false,
    },
    {
      name: 'Members',
      value: true,
    },
  ];

  // Transportaion View
  transportationView: boolean = false;
  transportationDetail: boolean = false;
  transubtypename: string = '';
  transubtype: string = '';
  tranparentid: string = '';
  tranrefno: string = '';

  pickupList: any[] = [];
  gettingPickUp: boolean = false;
  tabletrantype: any = '';

  gettingtran: boolean = false;
  tran_sub!: Subscription;
  transportaionList: any[] = [];

  weekDay = [
    {
      name: 'Monday',
      value: 'Monday',
    },
    {
      name: 'Tuesday',
      value: 'Tuesday',
    },
    {
      name: 'Wednesday',
      value: 'Wednesday',
    },
    {
      name: 'Thursday',
      value: 'Thursday',
    },
    {
      name: 'Friday',
      value: 'Friday',
    },
    {
      name: 'Saturday',
      value: 'Saturday',
    },
    {
      name: 'Sunday',
      value: 'Sunday',
    },
  ];

  statusList = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'Submitted',
      value: '001',
    },
    {
      name: 'Approved',
      value: '002',
    },
    {
      name: 'Rejected',
      value: '003',
    },
    {
      name: 'Completed',
      value: '004',
    },
  ];

  modeList = [
    {
      id: '001',
      value: 'By Air',
    },
    {
      id: '002',
      value: 'By Road',
    },
    {
      id: '003',
      value: 'By Rail',
    },
    {
      id: '004',
      value: 'By Ship',
    },
  ];
  vehicleuseList = [
    {
      id: '001',
      value: "Office's Vehicle",
    },
    {
      id: '002',
      value: 'Public Transportation',
    },
    {
      id: '003',
      value: 'By Own Vehicle(state reason)',
    },
  ];

  claimRoleView: boolean = false;

  searchForm = {
    startdate: '',
    enddate: '',
    requesttype: '',
    requesttypename: 'All',
    requestsubtype: '',
    requestsubtypename: 'All',
    requeststatus: '001',
    requeststatusname: 'Submitted',
    teammember: false,
    orgid: '',
    domainid: '',
    approvedstartdate: '',
    approvedenddate: '',
    paymentstatus: '',
    // transportation
    trequestid: '',
    tenddate: '',
    tstartdate: '',
    trequesttype: '',
    trequesttypename: 'All',
    trequestsubtype: '',
    trequestsubtypename: 'All',
    trequeststatus: '',
    trequeststatusname: 'All',
    tteammember: false,
    torgid: '',
    tdomainid: '',
    tapprovedstartdate: '',
    tapprovedenddate: '',
    tpaymentstatus: '',
  };

  approvalForm = {
    domainid: '',
    orgid: '',
  };

  subscriptions = new SubSink();

  isApproving: boolean = false;
  isRejecting: boolean = false;
  byjson: boolean = false;

  statusInterval: any;
  importStatus: string = '000'; //000- Server Error, 001-Success, 002-Progress, 003-Upload, 004-Error, 005-Cancel
  refreshingImportStatus: boolean = false;
  importData: any;
  domainid: string = this.allinoneService.getDomain().domainid;

  reservationid: string = 'AVASDAFNMVHSJESAM';
  transportationid: string = 'ANVAHSEFAMVAJDAJ';
  claimid: string = 'WOEIEPPLMXNW';
  lateid: string = 'KDIEVNEIADEOHDWJ';
  earlyoutid: string = 'IUEBCHDUWKNSGP';
  workfromhomeid: string = 'EUOMXJSOQNCUWI';
  offinlieuid: string = 'OMCHDFYUEJBAWCQ';
  travelrequestid: string = 'BCNDUWO85SCGQW';
  cashadvancedid: string = 'QINDJSUWOMXSA';

  cashAdvncedView: boolean = false;

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private filterPipe: FilterPipe,
    private http: HttpClient
  ) {
    this.route.data.subscribe((data) => {
      if (data.role == 'admin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        this.allinoneService.isOfficeView = true;
      }
      if (data.requesttypeid == this.cashadvancedid) {
        this.cashAdvncedView = true;
        this.searchForm.requesttype = this.cashadvancedid;
      }
    });
  }
  ngOnDestroy(): void {
    this.allinoneService.isOfficeView = false;
    clearInterval(this.statusInterval);
  }

  async ngOnInit(): Promise<void> {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    }

    this.checkClaimRoleView();

    this.approvalForm.domainid = this.allinoneService.getDomain().domainid;
    if (this.isAdmin) {
      this.byjson = true;
      // this.organizations = this.allinoneService.orgs;
      // this.approvalForm.orgid = this.allinoneService.selectedOrg
      //   ? this.allinoneService.selectedOrg
      //   : this.organizations[0].orgid;
      this.organizations = this.allinoneService.orgs;
      this.approvalForm.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;
    } else {
      this.organizations = this.allinoneService.orgsCRm;
      this.approvalForm.orgid = this.allinoneService.selected_orgcrm
        ? this.allinoneService.selected_orgcrm.orgid
        : this.organizations[0].orgid;
    }
    this.approvalListLoading = true;
    if (this.isAdmin) {
      this.getMemberList();
    }
    const reqtypes = await this.getRequestType();
    this.getApprovalList();
    this.getImportStatus();
    this.statusInterval = setInterval(() => {
      if (
        this.importStatus != '001' &&
        this.importStatus != '005' &&
        this.importStatus != '000'
      ) {
        this.getImportStatus();
      }
    }, 5000);
    // this.getPickupPoint();

    

  }

  importSheet(isError: boolean = false) {
    clearInterval(this.statusInterval);
    const dialog = this.dialog.open(FileUploadComponent, {
      maxHeight: '95vh',
      maxWidth: '95vw',
      width: '600px',
      closeOnNavigation: true,
      panelClass: ['mat-typography'],
      data: {
        isError: isError,
        orgid: this.approvalForm.orgid,
        domainid: this.domainid,
        isHierarchy: false,
        errorData: this.importData,
        type: '004',
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      this.getImportStatus();
      this.statusInterval = setInterval(() => {
        if (
          this.importStatus != '001' &&
          this.importStatus != '005' &&
          this.importStatus != '000'
        ) {
          this.getImportStatus();
        }
      }, 5000);
    });
  }

  getImportStatus() {
    this.refreshingImportStatus = true;
    var data = {
      type: '009',
      orgid: this.approvalForm.orgid,
    };
    this.kunyekService.getHierarchyImportStatus(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (
            (this.importStatus == '002' || this.importStatus == '003') &&
            res.status != this.importStatus
          ) {
            this.refresh();
          }
          this.importStatus = res.status;
          this.importData = res;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.refreshingImportStatus = false;
      },
      (err) => {
        this.refreshingImportStatus = false;
      }
    );
  }

  getRequestType() {
    return new Promise((resolve) => {
      this.gettingRequestType = true;
      var data = {
        domainid: this.approvalForm.domainid,
        orgid: this.approvalForm.orgid,
        roleid: this.allinoneService.getSelectedRole(),
      };
      this.kunyekService.getRequestType(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.requestTypeList = res.requesttypelist;
            this.filterReqTypeList = [
              { requesttypeid: '', requesttypename: 'All' },
              ...this.requestTypeList,
            ];
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingRequestType = false;
          resolve(true);
        },
        (err) => {
          this.gettingRequestType = false;
          resolve(false);
        }
      );
    });
  }

  getMemberList() {
    const data = {
      startcount: 0,
      endcount: 10,
      search: '',
      orgid: this.approvalForm.orgid,
    };
    this.subscriptions.sink = this.kunyekService
      .getOfficeMember(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.memberList = res.list;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
        },
        (err) => { }
      );
  }

  getApprovalList() {
    this.approvalListLoading = true;

    let data = {
      byjson: this.byjson,
      orgid: this.approvalForm.orgid,
      domainid: this.approvalForm.domainid,
      startdate: this.allinoneService.parseDate(this.searchForm.startdate),
      enddate: this.allinoneService.parseDate(this.searchForm.enddate),
      // requesttype: this.searchForm.requesttype,
      requesttype: this.claimRoleView ? this.claimid : this.searchForm.requesttype,
      // requestsubtype:
      //   this.searchForm.requesttype == 'NCMLEIWHRNVIE' ||
      //   this.searchForm.requesttype == this.claimid
      //     ? this.searchForm.requestsubtype
      //     : '',
      requestsubtype: this.searchForm.requestsubtype,
      requeststatus: this.searchForm.requeststatus,
      end: '',
      teammember: this.searchForm.teammember,
      roleid: this.allinoneService.getSelectedRole(),
      toexport: true,
      paymentstatus:
        this.searchForm.requesttype == this.claimid &&
          (this.searchForm.requeststatus == '002' ||
            this.searchForm.requeststatus == '004')
          ? this.searchForm.paymentstatus == '1'
            ? true
            : this.searchForm.paymentstatus == '2'
              ? false
              : ''
          : '',
      approvedstartdate:
        this.searchForm.requeststatus == '002'
          ? this.allinoneService.parseDate(this.searchForm.approvedstartdate)
          : '',
      approvedenddate:
        this.searchForm.requeststatus == '002'
          ? this.allinoneService.parseDate(this.searchForm.approvedenddate)
          : '',
      byadmin: this.isAdmin,
    };
    console.log(`Apporve Request Body: ${JSON.stringify(data)}`);
    this.subscriptions.unsubscribe();
    this.subscriptions.sink = this.kunyekService
      .getApprovalList(data)
      .subscribe(
        async (res) => {
          if (res.returncode == 300) {
            if (this.byjson && res.path != '') {
              this.http.get(res.path).subscribe((res: any) => {
                this.approvalList = res.map((item: any) => ({
                  ...item,
                  isChecked: false,
                  // ***
                  requesttypename: this.requestTypeList.find(
                    (x: any) => x.requesttypeid == item.requesttype
                  )?.requesttypename,
                  requestsubtypename: this.getRequestSubtypeName(
                    item.requesttype,
                    item.requestsubtype
                  ),
                  requeststatusname: this.statusList.find(
                    (x: any) => x.value == item.requeststatus
                  )?.name,

                  approvalremark:
                    item.requesttype == 'NCMLEIWHRNVIE'
                      ? this.returnLeave(item)
                      : item.requesttype == this.claimid
                        ? this.returnClaim(item)
                        : item.requesttype == 'UEYVNDLOAPQWXQW'
                          ? this.returnOvertime(item)
                          : item.requesttype == 'BCNDUWO85SCGQW'
                            ? this.returnTravel(item)
                            : '',
                }));
                this.approvalListLoading = false;
              });
            } else {
              this.approvalList = res.approvallist.map((item: any) => ({
                ...item,
                isChecked: false,
                requesttypename: this.requestTypeList.find(
                  (x: any) => x.requesttypeid == item.requesttype
                )?.requesttypename,
                requeststatusname: this.statusList.find(
                  (x: any) => x.value == item.requeststatus
                )?.name,
                approvalremark:
                  item.requesttype == 'NCMLEIWHRNVIE'
                    ? this.returnLeave(item)
                    : item.requesttype == this.claimid
                      ? this.returnClaim(item)
                      : item.requesttype == 'UEYVNDLOAPQWXQW'
                        ? this.returnOvertime(item)
                        : item.requesttype == 'BCNDUWO85SCGQW'
                          ? this.returnTravel(item)
                          : '',
              }));
              this.approvalListLoading = false;
            }

            // var getCount = await this.getApprovalCount();

            // if (res.approvallist.length > 0) {

            // this.nextDisabled = false;
            // this.approvalList.push.apply(this.approvalList, res.approvallist);
            // this.end = res.end;

            // if (res.approvallist.length < 10) {
            //   this.nextDisabled = true;
            //   this.lastPage = this.currentPage;
            // }
            // } else {
            //   this.currentPage = this.currentPage - 1;
            //   this.nextDisabled = true;
            //   this.lastPage = this.currentPage;
            // }
            // if (!getCount) {
            //   this.count = this.approvalList.length;
            // }
          } else {
            this.messageService.openSnackBar(
              res.message || res.stutus || 'Server Error',
              'warn'
            );
            this.approvalListLoading = false;
          }
        },
        (err) => {
          this.approvalListLoading = false;
        }
      );
  }

  getApprovalCount() {
    return new Promise((resolve) => {
      var data = {
        orgid: this.approvalForm.orgid,
        domainid: this.approvalForm.domainid,
        startdate: this.allinoneService.formatDate(this.searchForm.startdate),
        enddate: this.allinoneService.formatDate(this.searchForm.enddate),
        requesttype: this.searchForm.requesttype,
        // requestsubtype:
        //   this.searchForm.requesttype == 'NCMLEIWHRNVIE' ||
        //   this.searchForm.requesttype == this.claimid
        //     ? this.searchForm.requestsubtype
        //     : '',
        requestsubtype: this.searchForm.requestsubtype,
        requeststatus: this.searchForm.requeststatus,
        end: this.end,
        teammember: this.searchForm.teammember,
        roleid: this.allinoneService.getSelectedRole(),
        allrequest: this.searchForm.requeststatus ? false : true,
      };
      this.subscriptions.sink = this.kunyekService
        .getApprovalCount(data)
        .subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.count = res.approval;
              resolve(true);
            } else {
              resolve(false);
            }
          },
          (err) => {
            resolve(false);
          }
        );
    });
  }

  gotoOffice() {
    this.router.navigateByUrl(`adminconsole/office`);
  }

  gotoTransportationView() {
    console.log('your fun');
    this.transportationView = true;
    this.transportationDetail = false;
    this.viewingRequest = false;
    this.addingRequest = false;
  }

  cancel(transportationCancel: boolean = false) {
    if (transportationCancel) {
      this.transportationView = true;
      this.transportationDetail = false;
    }
    this.addingRequest = false;
    this.viewingRequest = false;
    this.searchForm.trequeststatus = '';
    this.searchForm.trequeststatusname = 'All';
  }

  clear() {
    this.searchText = '';
  }

  tranclear() {
    this.transearchText = '';
  }

  refresh() {
    this.currentPageAllSelected = false;
    this.selectedRequests = 0;
    this.approveCount = 0;
    this.rejectCount = 0;
    this.paidCount = 0;
    this.checkCount = 0;
    this.count = 0;
    this.end = '';
    this.currentPage = 1;
    this.lastPage = '';
    this.approvalList = [];
    this.getApprovalList();
  }

  tranrefresh() {
    this.currentPageAllSelected = false;
    this.selectedRequests = 0;
    this.approveCount = 0;
    this.rejectCount = 0;
    this.paidCount = 0;
    this.checkCount = 0;
    this.count = 0;
    this.end = '';
    this.currentPage = 1;
    this.lastPage = '';
    this.transportaionList = [];
    this.getTransportaionsList();
  }

  pageChange(event: any) {
    if (this.nextDisabled == true) {
      if (event < this.lastPage) {
        this.currentPage = event;
        this.checkCurrentPageAllSelected();
        if (event < 1) {
        } else {
          this.nextDisabled = false;
        }
      }
    } else {
      if (event == this.lastPage && this.lastPage != '') {
        this.currentPage = event;
        this.nextDisabled = true;
        this.checkCurrentPageAllSelected();
      } else {
        this.currentPage = event;
        if (event > this.approvalList.length / this.itemPerPage) {
          this.getApprovalList();
          this.currentPageAllSelected = false;
        } else {
          this.checkCurrentPageAllSelected();
        }
      }
    }
  }

  sortData(sort: Sort) {
    const data = this.approvalList;
    if (!sort.active || sort.direction === '') {
      this.approvalList = data;
      return;
    }
    this.approvalList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'employeeid':
          return this.allinoneService.compare(
            a.employeeid,
            b.employeeid,
            isAsc
          );

        case 'refno':
          return this.allinoneService.compare(
            parseInt(a.refno),
            parseInt(b.refno),
            isAsc
          );
        case 'date':
          return this.allinoneService.compare(
            a.claimdate ? a.claimdate : a.startdate + a.enddate,
            a.claimdate ? a.claimdate : a.startdate + a.enddate,
            isAsc
          );
        case 'type':
          return this.allinoneService.compare(
            a.requesttypename,
            b.requesttypename,
            isAsc
          );
        case 'status':
          return this.allinoneService.compare(
            a.requeststatusname,
            b.requeststatusname,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  tranSortData(sort: Sort) {
    const data = this.transportaionList;
    if (!sort.active || sort.direction === '') {
      this.transportaionList = data;
      return;
    }
    this.transportaionList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date':
          return this.allinoneService.compare(a.date, b.date, isAsc);
        case 'status':
          return this.allinoneService.compare(
            a.requeststatusname,
            b.requeststatusname,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  test() {
    console.log(this.searchForm.trequeststatus);
    console.log(this.searchForm.trequeststatusname);
  }

  cancelToRequest() {
    this.transportationDetail = false;
    this.transportationView = false;
    this.viewingRequest = false;
    this.addingRequest = false;
    this.tranend = '';
    this.count = 0;
    this.selectedRequests = 0;
    this.approveCount = 0;
    this.rejectCount = 0;
    this.paidCount = 0;
    this.checkCount = 0;
    this.tranCurrentPage = 1;
    this.currentPageAllSelected = false;
    this.searchForm.trequeststatus = '';
    this.searchForm.trequeststatusname = 'All';
  }

  transportationcancel() {
    this.transportationView = false;
    this.tranend = '';
    this.count = 0;
    this.selectedRequests = 0;
    this.approveCount = 0;
    this.rejectCount = 0;
    this.paidCount = 0;
    this.checkCount = 0;
    this.tranCurrentPage = 1;
    this.currentPageAllSelected = false;
    this.searchForm.trequeststatus = '';
    this.searchForm.trequeststatusname = 'All';
  }

  transportationcancel2() {
    this.transportationDetail = false;
    this.tranend = '';
    this.viewingRequest = false;
    this.addingRequest = false;
    this.transportationView = true;
    this.searchForm.trequeststatus = '';
    this.searchForm.trequeststatusname = 'All';
  }

  getTransportaionsList() {
    this.gettingtran = true;
    const data = {
      orgid: this.approvalForm.orgid,
      requesttype: this.searchForm.trequesttype,
      requeststatus: this.searchForm.trequeststatus,
      startdate: this.searchForm.tstartdate
        ? this.allinoneService.formatDate(this.searchForm.tstartdate)
        : '',
      enddate: this.searchForm.tenddate
        ? this.allinoneService.formatDate(this.searchForm.tenddate)
        : '',
      end: this.tranend,
      requestid: this.searchForm.trequestid,
      byadmin: true,
    };
    this.tran_sub && this.tran_sub.unsubscribe();
    this.tran_sub = this.kunyekService.getApprovalList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.transportaionList = [];
          if (res.approvallist.length > 0) {
            this.tranparentid = res.approvallist[0].olduserid
              ? res.approvallist[0].olduserid
              : res.approvallist[0].userid;
            var tempList = res.approvallist[0]['child'];
            if (tempList.length > 0) {
              tempList = tempList.map((item: any) => ({
                ...item,
                isChecked: false,
                requeststatusname: this.statusList.find(
                  (x: any) => x.value == item.requeststatus
                )?.name,
              }));

              this.transportaionList.push.apply(
                this.transportaionList,
                tempList
              );
            }
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingtran = false;
      },
      (err) => {
        this.gettingtran = false;
        this.messageService.openSnackBar('Server Error', 'warn');
      }
    );
  }

  clickapprovalList(
    approval: any,
    transportationView: boolean = false,
    transportationDetail: boolean = false
  ) {
    console.log('s');
    console.log(transportationView);
    console.log(transportationDetail);
    console.log(this.viewingRequest);
    console.log('e');

    if (transportationDetail) {
      this.transportationDetail = true;
    }

    if (transportationView) {
      this.count = 0;
      this.selectedRequests = 0;
      this.approveCount = 0;
      this.rejectCount = 0;
      this.paidCount = 0;
      this.checkCount = 0;
      this.currentPageAllSelected = false;
      this.transportationView = true;
      this.viewingRequest = false;
      this.addingRequest = false;
      this.selectedRequest = approval;
      this.transubtypename = approval.requestsubtypename;
      this.transubtype = approval.requestsubtype;
      this.tranrefno = approval.requestrefno;
      this.searchForm.trequestid = approval.requestid;
      this.tabletrantype = approval.trantype;
      // this.requestForm.refno = request.requestrefno;
      this.getTransportaionsList();
    } else {
      console.log('else con');

      this.viewingRequest = true;
      this.transportationView = false;
      this.selectedRequest = approval;
      // console.log(this.selectedRequest);
      this.selectedRequest.modeoftravelname = [];
      this.selectedRequest.vehicleusename = [];
      for (var i = 0; i < this.requestTypeList.length; i++) {
        if (
          this.requestTypeList[i]['requesttypeid'] ==
          this.selectedRequest.requesttype
        ) {
          this.requestSubTypeList = this.requestTypeList[i].child;
        }
      }
      if (this.selectedRequest.requesttype == this.claimid) {
        var temp = this.requestSubTypeList.find(
          (x: any) => x.requesttypeid == this.selectedRequest.requestsubtype
        );
        if (temp) {
          this.selectedRequest.taxifare = temp.taxifare;
        }
      }
      if (this.selectedRequest.requesttype == 'BCNDUWO85SCGQW') {
        for (var i = 0; i < this.selectedRequest.modeoftravel.length; i++) {
          var modeObj = this.modeList.find(
            (x: any) => x.id == this.selectedRequest.modeoftravel[i]
          );
          if (modeObj) {
            this.selectedRequest.modeoftravelname.push(modeObj.value);
          }
        }
        for (var i = 0; i < this.selectedRequest.vehicleuse.length; i++) {
          var modeObj = this.vehicleuseList.find(
            (x: any) => x.id == this.selectedRequest.vehicleuse[i]
          );
          if (modeObj) {
            this.selectedRequest.vehicleusename.push(modeObj.value);
          }
        }
      }

      if (!transportationDetail) {
        if (
          this.selectedRequest.image.length == 0 ||
          this.selectedRequest.image.length == 1
        ) {
          this.bothDisable = true;
        }
      }
    }
    console.log('s2');
    console.log(this.transportationView);
    console.log(this.transportationDetail);
    console.log(this.addingRequest);
    console.log(this.viewingRequest);
    console.log(this.selectedRequest);

    console.log('e2');
  }

  imageIndex: any = 0;
  imagename: string = '';
  nextBtn: boolean = false;
  previousBtn: boolean = false;
  bothDisable: boolean = false;
  imageViewer(msg: any, i: any) {
    this.imageIndex = i;
    this.imagename = msg;
    $('#approval-image-viewer').modal('show');
  }
  Next() {
    if (this.imageIndex == this.selectedRequest.image.imgurl.length - 1) {
      this.imageIndex = 0;
    } else {
      this.imageIndex++;
    }
    this.imagename = this.selectedRequest.image.imgurl[this.imageIndex];
    // console.log(this.imageIndex);
    // console.log(this.imagename);
  }
  Previous() {
    if (this.imageIndex == 0) {
      this.imageIndex = this.selectedRequest.image.imgurl.length - 1;
    } else {
      this.imageIndex--;
    }
    this.imagename = this.selectedRequest.image.imgurl[this.imageIndex];
    // console.log(this.imageIndex);
    // console.log(this.imagename);
  }
  downloadImage() {
    window.open(this.imagename, '_blank');
  }
  getImgSrc(htmlString: any) {
    var element = <HTMLImageElement>htmlString;
    return element.src;
  }

  filter() {
    const dialog = this.dialog.open(ApprovalExportComponent, {
      maxWidth: '95vw',
      width: '600px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        claimRoleView : this.claimRoleView,
        requeststatus: this.searchForm.requeststatus,
        requestList: this.requestTypeList,
        teamMemberList: this.teamMemberList,
        startdate: this.searchForm.startdate,
        enddate: this.searchForm.enddate,
        requesttype: this.claimRoleView ? this.claimid : this.searchForm.requesttype,
        requestsubtype: this.searchForm.requestsubtype,
        teammember: this.searchForm.teammember,
        approvedstartdate: this.searchForm.approvedstartdate,
        approvedenddate: this.searchForm.approvedenddate,
        paymentstatus: this.searchForm.paymentstatus,
        hideRequest: this.searchForm.requesttype === this.cashadvancedid
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        // console.log('dia result');

        // console.log(dialogResult);

        this.searchForm.requeststatus = dialogResult.requeststatus;
        this.searchForm.requeststatusname =
          this.searchForm.requeststatus == ''
            ? 'All'
            : this.statusList.find(
              (x: any) => x.value == this.searchForm.requeststatus
            )!.name;
        this.searchForm.startdate =
          dialogResult.startdate == undefined ? '' : dialogResult.startdate;
        this.searchForm.enddate =
          dialogResult.enddate == undefined ? '' : dialogResult.enddate;
        this.searchForm.requesttype = dialogResult.requesttype;
        this.searchForm.teammember =
          dialogResult.teammember == 'true' || dialogResult.teamMember == true
            ? true
            : false;
        this.searchForm.requesttypename =
          this.searchForm.requesttype == ''
            ? 'All'
            : this.requestTypeList.find(
              (x: any) => x.requesttypeid == this.searchForm.requesttype
            ).requesttypename;
        this.searchForm.requestsubtype = dialogResult.requestsubtype;
        this.searchForm.requestsubtypename =
          this.searchForm.requestsubtype == ''
            ? 'All'
            : this.requestTypeList
              .find(
                (x: any) => x.requesttypeid == this.searchForm.requesttype
              )
              .child.find(
                (y: any) => y.requesttypeid == this.searchForm.requestsubtype
              ).requesttypename;
        this.searchForm.paymentstatus = dialogResult.paymentstatus;
        this.searchForm.approvedstartdate =
          dialogResult.approvedstartdate == undefined
            ? ''
            : dialogResult.approvedstartdate;
        this.searchForm.approvedenddate =
          dialogResult.approvedenddate == undefined
            ? ''
            : dialogResult.approvedenddate;
        this.showSubType = true;
        this.showType = true;
        this.showPayment = true;
        this.refresh();
      }
    });
  }

  clearDate() {
    this.searchForm.startdate = '';
    this.searchForm.enddate = '';
    this.refresh();
  }

  clearApproveDate() {
    this.searchForm.approvedstartdate = '';
    this.searchForm.approvedenddate = '';
    this.refresh();
  }

  // clearSubType() {
  //   this.searchForm.requestsubtype = '';
  //   this.searchForm.requestsub
  // }

  clearFilter() {
    this.searchForm = {
      startdate: '',
      enddate: '',
      requesttype: this.searchForm.requesttype,
      // requesttypename: 'All',
      requesttypename: this.searchForm.requesttypename,
      requestsubtype: '',
      requestsubtypename: '',
      requeststatus: this.searchForm.requeststatus,
      requeststatusname: this.searchForm.requeststatusname,
      teammember: false,
      orgid: this.searchForm.orgid,
      domainid: this.searchForm.domainid,
      approvedstartdate: '',
      approvedenddate: '',
      paymentstatus: '',
      // transportation
      trequestid: '',
      tenddate: '',
      tstartdate: '',
      trequesttype: '',
      trequesttypename: 'All',
      trequestsubtype: '',
      trequestsubtypename: 'All',
      trequeststatus: '001',
      trequeststatusname: 'Submitted',
      tteammember: false,
      torgid: '',
      tdomainid: '',
      tapprovedstartdate: '',
      tapprovedenddate: '',
      tpaymentstatus: '',
    };
    this.showSubType = false;
    this.showType = false;
    this.showPayment = false;
    this.refresh();
  }

  statusChange(status: any) {
    console.log(`Status ${JSON.stringify(status)}`);
    if (this.searchForm.requeststatus != status.value) {
      this.searchForm.requeststatus = status.value;
      this.searchForm.requeststatusname = status.name;
      this.refresh();
    }
  }

  transtatusChange(status: any) {
    if (this.searchForm.trequeststatus != status.value) {
      this.searchForm.trequeststatus = status.value;
      this.searchForm.trequeststatusname = status.name;
      this.tranrefresh();
    }
  }

  requestTypeChange(type: any) {
    console.log(type);

    if (this.searchForm.requesttype != type.requesttypeid) {
      this.searchForm.requesttype = type.requesttypeid;
      this.searchForm.requesttypename = type.requesttypename;
      this.searchForm.requestsubtypename = 'All';
      this.searchForm.requestsubtype = '';
      this.refresh();
    }
  }

  returnLeave(data: any) {
    var value = '';
    value =
      this.splicDuration(data.duration) +
      ' days of ' +
      data.requestsubtypename.toLowerCase() +
      ' leave';
    // if (data.requeststatus == '001') {
    //   value =
    //     this.splicDuration(data.duration) +
    //     ' days of ' +
    //     data.requestsubtypename.toLowerCase() +
    //     ' leave submitted';
    // } else if (data.requeststatus == '002') {
    //   value =
    //     this.splicDuration(data.duration) +
    //     ' days of ' +
    //     data.requestsubtypename.toLowerCase() +
    //     ' leave approved by ' +
    //     data.personname +
    //     ' (' +
    //     this.allinoneService.formatDBToShow(data.startdate) +
    //     ')';
    // } else if (data.requeststatus == '003') {
    //   value =
    //     this.splicDuration(data.duration) +
    //     ' days of ' +
    //     data.requestsubtypename.toLowerCase() +
    //     ' leave rejected by ' +
    //     data.personname +
    //     ' (' +
    //     this.allinoneService.formatDBToShow(data.startdate) +
    //     ')';
    // } else if (data.requeststatus == '004') {
    //   value =
    //     this.splicDuration(data.duration) +
    //     ' days of ' +
    //     data.requestsubtypename.toLowerCase() +
    //     ' leave completed';
    // }
    return value;
  }

  returnClaim(data: any) {
    var value = '';
    value =
      data.requestsubtypename +
      ' ( ' +
      data.amount.toString() +
      ' )' +
      data.currencyname +
      '';
    // if (data.requeststatus == '001') {
    //   value =
    //     data.requestsubtypename +
    //     ' ( ' +
    //     data.amount.toString() +
    //     ' )' +
    //     data.currencyname +
    //     ' submitted';
    // } else if (data.requeststatus == '002') {
    //   value =
    //     data.requestsubtypename +
    //     ' ( ' +
    //     data.amount.toString() +
    //     ' )' +
    //     data.currencyname +
    //     ' approved by ' +
    //     data.personname +
    //     ' (' +
    //     this.allinoneService.formatDBToShow(data.claimdate) +
    //     ')';
    // } else if (data.requeststatus == '003') {
    //   value =
    //     data.requestsubtypename +
    //     ' ( ' +
    //     data.amount.toString() +
    //     ' )' +
    //     data.currencyname +
    //     ' rejected by ' +
    //     data.personname +
    //     ' (' +
    //     this.allinoneService.formatDBToShow(data.claimdate) +
    //     ')';
    // } else if (data.requeststatus == '004') {
    //   value =
    //     data.requestsubtypename +
    //     ' ( ' +
    //     data.amount.toString() +
    //     ' )' +
    //     data.currencyname +
    //     ' completed ' +
    //     '(' +
    //     this.allinoneService.formatDBToShow(data.claimdate) +
    //     ')';
    // }
    return value;
  }

  returnOvertime(data: any) {
    var value = '';
    value = data.duration.toString();
    // if (data.requeststatus == '001') {
    //   value = data.duration.toString() + ' submitted';
    // } else if (data.requeststatus == '002') {
    //   value =
    //     data['duration'].toString() +
    //     ' approved by ' +
    //     data['personname'] +
    //     ' (' +
    //     this.allinoneService.formatDBToShow(data['startdate']) +
    //     ')';
    // } else if (data['requeststatus'] == '003') {
    //   value =
    //     data['duration'].toString() +
    //     ' rejected by ' +
    //     data['personname'] +
    //     ' (' +
    //     this.allinoneService.formatDBToShow(data['startdate']) +
    //     ')';
    // } else if (data['requeststatus'] == '004') {
    //   value = data['duration'].toString() + ' completed';
    // }
    return value;
  }

  returnTravel(data: any) {
    var value = '';
    // if (data['requeststatus'] == '001') {
    //   value =
    //     data['duration'].toString() +
    //     ' days of ' +
    //     data['remark'] +
    //     ' travel submitted.';
    // } else if (data['requeststatus'] == '002') {
    //   value =
    //     data['duration'].toString() +
    //     ' days of ' +
    //     data['remark'] +
    //     ' travel approved by ' +
    //     data['personname'] +
    //     ' (' +
    //     this.allinoneService.formatDBToShow(data['startdate']) +
    //     ')';
    // } else if (data['requeststatus'] == '003') {
    //   value =
    //     data['duration'].toString() +
    //     ' days of ' +
    //     data['remark'] +
    //     ' travel rejected by ' +
    //     data['personname'] +
    //     ' (' +
    //     this.allinoneService.formatDBToShow(data['startdate']) +
    //     ')';
    // } else if (data['requeststatus'] == '004') {
    //   value =
    //     data['duration'].toString() +
    //     ' days of ' +
    //     data['remark'] +
    //     ' travel completed';
    // }
    value =
      data['duration'].toString() + ' days of ' + data['remark'] + ' travel';
    return value;
  }

  splicDuration(data: any) {
    var check = data.indexOf('.');
    var point = data.substring(check + 1, data.length);
    // if (point == "0") {
    //   return data.substring(0, check);
    // } else {
    //   return data;
    // }
    return data;
  }

  /**
   *
   * @param type 002 - approve | 003 - reject
   */
  submitApproval(
    type: string,
    paymentstatus: boolean = false,
    updatingpaymentstatus: boolean = false
  ) {
    if (type == '002') {
      this.isApproving = true;
    } else {
      this.isRejecting = true;
    }
    var data;
    if (this.transportationDetail) {
      data = {
        orgid: this.approvalForm.orgid,
        requeststatus: type,
        roleid: this.allinoneService.getSelectedRole(),
        byadmin: this.isAdmin,
        transportation: true,
        startdate: this.selectedRequest.date,
        enddate: this.selectedRequest.date,
        requestlist: [
          {
            parentid: this.tranparentid,
            date: this.selectedRequest.date,
            memberid: this.selectedRequest.olduserid
              ? this.selectedRequest.olduserid
              : this.selectedRequest.userid,
            requestid: this.selectedRequest.transportationid,
            requestsubtype: this.transubtype,
            approver: [],
          },
        ],
      };
      this.kunyekService.updateMultipleRequest(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            if (updatingpaymentstatus) {
              this.selectedRequest.paymentstatus = true;
            } else {
              this.selectedRequest.requeststatus = type;
              this.selectedRequest.requeststatusname = this.statusList.find(
                (x: any) => x.value == type
              )?.name;
              this.selectedRequest.rejectperson =
                this.allinoneService.getKunyekUserId();
              this.selectedRequest.personname =
                this.allinoneService.getKunyekUserName();
              // this.transportationcancel();
              // this.tranrefresh();
              this.getTransportaionsList();
              this.gotoTransportationView();
              this.messageService.openSnackBar('Successful.', 'success');
            }
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.isApproving = false;
          this.isRejecting = false;
        },
        (err) => {
          this.isApproving = false;
          this.isRejecting = false;
        }
      );
    } else {
      data = {
        domainid: this.approvalForm.domainid,
        orgid: this.approvalForm.orgid,
        requestid: this.selectedRequest.requestid,
        requeststatus: type,
        roleid: this.allinoneService.getSelectedRole(),
        paymentstatus: paymentstatus,
        byadmin: this.isAdmin,
      };
      this.kunyekService.addApprovalList(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            if (updatingpaymentstatus) {
              this.selectedRequest.paymentstatus = true;
            } else {
              this.selectedRequest.requeststatus = type;
              this.selectedRequest.requeststatusname = this.statusList.find(
                (x: any) => x.value == type
              )?.name;
              this.selectedRequest.rejectperson =
                this.allinoneService.getKunyekUserId();
              this.selectedRequest.personname =
                this.allinoneService.getKunyekUserName();
              this.cancel();
              this.refresh();
            }
            this.messageService.openSnackBar('Successful.', 'success');
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.isApproving = false;
          this.isRejecting = false;
        },
        (err) => {
          this.isApproving = false;
          this.isRejecting = false;
        }
      );
    }
  }

  exportApproval() {
    const dialog = this.dialog.open(ApprovalExportComponent, {
      maxWidth: '95vw',
      width: '600px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        requestList: this.requestTypeList,
        teamMemberList: this.teamMemberList,
        startdate: this.searchForm.startdate,
        enddate: this.searchForm.enddate,
        requesttype: this.searchForm.requesttype,
        requestsubtype: this.searchForm.requestsubtype,
        teammember: this.searchForm.teammember,
        orgid: this.approvalForm.orgid,
        domainid: this.approvalForm.domainid,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => { });
  }

  checkSubType(id: any) {
    console.log(id);

    if (id != '') {
      if (id != undefined) {
        var sub = this.requestTypeList.find(
          (x: any) => x.requesttypeid == id
        ).subtype;

        return sub;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  checkClaimRoleView() {
    var PList = this.allinoneService.officePermissionList;
    PList.map((x: any) => {
      // if(x.permission[0] == '011') {
      //   this.claimRoleView = true;
      // }
      var claim = x.permission.find((y: any) => y == '011');
      if (claim) {
        this.claimRoleView = true;
      }
    })
  }

  t() {
    // console.log(this.selectedRequest);
    // console.log(this.searchForm.requesttype);
    // console.log(this.searchForm.requesttypename);

    console.log(this.allinoneService.officePermissionList);






  }

  export() {
    console.log('this fun');

    var exdata: any = [];
    // var startd = formatDate(new Date("this".allinoneService.formatDBToShowInEdit(this.detailForm.date, '/')), "dd-MM-yyyy", 'en-US').toString()
    // var temp = this.detailForm.type == 'in' ? 'Time In' :  'Time Out';
    var selectList: any = [];
    for (var i = 0; i < this.approvalList.length; i++) {
      if (this.approvalList[i].isChecked) {
        selectList.push(this.approvalList[i]);
      }
    }
    console.log('1');

    var name =
      (this.searchForm.requestsubtypename
        ? this.searchForm.requestsubtypename + ' '
        : '') +
      (this.searchForm.requesttypename ? this.searchForm.requesttypename : '') +
      ' Report' +
      '.xlsx';



    console.log(name, this.searchForm.requesttype);


    var filteredData = this.filterPipe.transform(
      this.checkCount == 0 ? this.approvalList : selectList,
      this.searchText,
      'approvalList'
    );
    if (this.searchForm.requesttype == 'NCMLEIWHRNVIE') {
      filteredData.map((item: any) => {
        console.log(item);
        var temp = {
          'Ref No.': item.requestrefno,
          ID: item.employeeid,
          Name: item.username || item.userid,
          Department: item.department,
          Division: item.division,
          'Cost Center': item.costcenter,
          'Leave Type': this.getSubTypeName(item.requestsubtype),
          'Applied Date':
            item.date != ''
              ? this.allinoneService.formatDBToShow(item.date)
              : '',
          'Start Date':
            item.startdate != ''
              ? this.allinoneService.formatDBToShow(item.startdate)
              : '',
          'Start AM/PM': item.starttime,
          'End Date':
            item.enddate != ''
              ? this.allinoneService.formatDBToShow(item.enddate)
              : '',
          'End AM/PM': item.endtime,
          Duration: item.duration,
          'Approved Date': item.approvedtime
            ? this.allinoneService.formatDBToShow(item.approvedtime.slice(0, 8))
            : '',
          'Approved By': item.approverusername,
          'Handover Person': '',
          Remark: item.remark,
          Status: this.getStatusName(item.requeststatus),
        };
        exdata.push(temp);
      });
    } else if (this.searchForm.requesttype == this.claimid) {
      this.requestSubTypeList = [];
      for (var i = 0; i < this.requestTypeList.length; i++) {
        if (
          this.requestTypeList[i]['requesttypeid'] ==
          this.searchForm.requesttype
        ) {
          this.requestSubTypeList = this.requestTypeList[i].child;
        }
      }
      var taxifare: boolean = false;
      var temp = this.requestSubTypeList.find(
        (x: any) => x.requesttypeid == this.searchForm.requestsubtype
      );
      if (temp) {
        taxifare = temp.taxifare;
      }

      // Taxi Fare
      if (taxifare) {
        filteredData.map((item: any) => {
          var temp = {
            'Ref No.': item.requestrefno,
            ID: item.employeeid,
            Name: item.username || item.userid,
            Department: item.department,
            Division: item.division,
            'Cost Center': item.costcenter,

            'Applied Date': item.date
              ? this.allinoneService.formatDBToShow(item.date)
              : '',
            'Claim Date':
              item.claimdate != ''
                ? this.allinoneService.formatDBToShow(item.claimdate)
                : '',
            'Claims Type': this.getSubTypeName(item.requestsubtype),
            Type: 'Claim',
            'From Date':
              item.date != ''
                ? this.allinoneService.formatDBToShow(item.date)
                : '',
            'To Date': '',
            Time: '',
            'From Place': item.fromplace,
            'To Place': item.toplace,
            Amount: item.amount,
            Currency: item.currencyname,
            Payment:
              item.paymentstatus == '' || !item.paymentstatus
                ? 'Unpaid'
                : 'Paid',
            Remark: item.remark,
            'Approved Date':
              item.approvedtime != ''
                ? this.allinoneService.formatDBToShow(
                  item.approvedtime.slice(0, 8)
                )
                : '',
            'Approved By ID': item.approveremployeeid, // Employee ID ထည့်ရန်
            'Approved By Name': item.approverusername,
            Status: this.getStatusName(item.requeststatus),
          };
          exdata.push(temp);
        });

        // Phone Charges
      } else if (this.searchForm.requestsubtypename == 'Phone Charges') {
        filteredData.map((item: any) => {
          var temp = {
            'Ref No.': item.requestrefno,
            ID: item.employeeid,
            Name: item.username || item.userid,
            Department: item.department,
            Division: item.division,
            'Cost Center': item.costcenter,

            'Applied Date':
              item.date != ''
                ? this.allinoneService.formatDBToShow(item.date)
                : '', // မေးရန်
            'Claim Date':
              item.claimdate != ''
                ? this.allinoneService.formatDBToShow(item.claimdate)
                : '',
            'Claims Type': this.searchForm.requestsubtypename,
            Type: 'Claim',
            'From Date': '',
            'To Date': '',
            Amount: item.amount,
            Currency: item.currencyname,
            Payment:
              item.paymentstatus == '' || !item.paymentstatus
                ? 'Unpaid'
                : 'Paid',
            Remark: item.remark,
            'Approved Date':
              item.approvedtime != ''
                ? this.allinoneService.formatDBToShow(
                  item.approvedtime.slice(0, 8)
                )
                : '',
            'Approved By ID': item.approveremployeeid,
            'Approved By Name': item.approverusername,
            Status: this.getStatusName(item.requeststatus),
          };

          exdata.push(temp);
        });
      } else {
        filteredData.map((item: any) => {
          var temp = {
            'Ref No.': item.requestrefno,
            ID: item.employeeid,
            Name: item.username || item.userid,
            Department: item.department,
            Division: item.division,
            'Cost Center': item.costcenter,

            'Applied Date':
              item.date != ''
                ? this.allinoneService.formatDBToShow(item.date)
                : '',
            'Claim Date': item.claimdate
              ? this.allinoneService.formatDBToShow(item.claimdate)
              : '',
            'Claims Type': this.getSubTypeName(item.requestsubtype),
            Type: 'Claim',
            Amount: item.amount,
            Currency: item.currencyname,
            'From Place': item.fromplace,
            'To Place': item.toplace,
            Payment:
              item.paymentstatus == '' || !item.paymentstatus
                ? 'Unpaid'
                : 'Paid',
            Remark: item.remark,
            'Approved Date': item.approvedtime
              ? this.allinoneService.formatDBToShow(
                item.approvedtime.slice(0, 8)
              )
              : '',
            'Approved By ID': item.approveremployeeid, // Employee ID ထည့်ရန်
            'Approved By Name': item.approverusername,
            Status: this.getStatusName(item.requeststatus),
          };
          exdata.push(temp);
        });
      }
    } else if (this.searchForm.requesttype == this.workfromhomeid) {
      filteredData.map((item: any) => {
        var temp = {
          'Ref No.': item.requestrefno,
          ID: item.employeeid,
          Name: item.username || item.userid,
          Rank: item.rank,
          Department: item.department,
          Division: item.division,
          'Cost Center': item.costcenter,
          'Applied Date': item.date
            ? this.allinoneService.formatDBToShow(item.date)
            : '',
          'Claim Date': item.claimdate
            ? this.allinoneService.formatDBToShow(item.claimdate)
            : '',
          'Sub Type': item.requestsubtypename,
          Type: this.getTypeName(item.requesttype),
          'Designated Location Name': item.location,
          'Designated Lat': item.lat,
          'Designated Long': item.long,
          'Time In Location Name': item.actual_location,
          'Time In Lat': item.actual_lat,
          'Time In Long': item.actual_long,
          'From Date': item.startdate
            ? this.allinoneService.formatDBToShow(item.startdate)
            : '',
          'To Date': item.enddate
            ? this.allinoneService.formatDBToShow(item.enddate)
            : '',
          'Start Time': item.starttime
            ? this.allinoneService.formatTimeAmpm(item.starttime)
            : '',
          'End Time': item.endtime
            ? this.allinoneService.formatTimeAmpm(item.endtime)
            : '',
          'From Place': item.fromplace,
          'To Place': item.toplace,
          Amount: item.amount,
          Currency: item.currencyname,
          Payment:
            item.requesttype != this.claimid
              ? ''
              : item.paymentstatus == '' || !item.paymentstatus
                ? 'Unpaid'
                : 'Paid',
          Remark: item.remark,
          'Approved Date': item.approvedtime
            ? this.allinoneService.formatDBToShow(item.approvedtime.slice(0, 8))
            : '',
          'Approved By ID': item.approveremployeeid,
          'Approved By Name': item.approverusername,
          Status: this.getStatusName(item.requeststatus)
        };
        exdata.push(temp);
      });
    }
    else {
      console.log('EUOMXJSOQNCUWI')
      filteredData.map((item: any) => {
        var temp = {
          'Ref No.': item.requestrefno,
          ID: item.employeeid,
          Name: item.username || item.userid,
          Department: item.department,
          Division: item.division,
          'Cost Center': item.costcenter,
          'Applied Date': item.date
            ? this.allinoneService.formatDBToShow(item.date)
            : '',
          'Claim Date': item.claimdate
            ? this.allinoneService.formatDBToShow(item.claimdate)
            : '',
          'Sub Type': item.requestsubtypename,
          Type: this.getTypeName(item.requesttype),
          'From Date': item.startdate
            ? this.allinoneService.formatDBToShow(item.startdate)
            : '',
          'To Date': item.enddate
            ? this.allinoneService.formatDBToShow(item.enddate)
            : '',
          'Start Time': item.starttime
            ? this.allinoneService.formatTimeAmpm(item.starttime)
            : '',
          'End Time': item.endtime
            ? this.allinoneService.formatTimeAmpm(item.endtime)
            : '',
          'From Place': item.fromplace,
          'To Place': item.toplace,
          Amount: item.amount,
          Currency: item.currencyname,
          Payment:
            item.requesttype != this.claimid
              ? ''
              : item.paymentstatus == '' || !item.paymentstatus
                ? 'Unpaid'
                : 'Paid',
          Remark: item.remark,
          'Approved Date': item.approvedtime
            ? this.allinoneService.formatDBToShow(item.approvedtime.slice(0, 8))
            : '',
          'Approved By ID': item.approveremployeeid,
          'Approved By Name': item.approverusername,
          Status: this.getStatusName(item.requeststatus),
        };
        exdata.push(temp);
      });
    }
    this.allinoneService.exportEcecl(exdata, name);
  }

  getStatusName(id: string) {
    if (id == '001') {
      return 'Submitted';
    } else if (id == '002') {
      return 'Approved';
    } else if (id == '003') {
      return 'Rejected';
    } else {
      return 'Completed';
    }
  }

  getSubTypeName(subtype: any) {
    var temp = this.requestTypeList
      .find((x: any) => x.requesttypeid == this.searchForm.requesttype)
      .child.find((y: any) => y.requesttypeid == subtype);
    if (temp) {
      return temp.requesttypename;
    } else {
      return '';
    }
  }

  getTypeName(type: any) {
    var temp = this.requestTypeList.find((x: any) => x.requesttypeid == type);

    if (temp) {
      return temp.requesttypename
    } else {
      return ''
    }

    // return this.requestTypeList.find((x: any) => x.requesttypeid == type)
    //   .requesttypename;


  }

  openLeaveSummaryForm() {
    const dialog = this.dialog.open(OfficeLeaveSummaryComponent, {
      maxWidth: '95vw',
      width: '550px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        memberid: this.selectedRequest.userid,
        domainid: this.approvalForm.domainid,
        orgid: this.approvalForm.orgid,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => { });
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  newRequest() {
    this.addingRequest = true;
  }

  // selectRequest(request: any) {
  //   console.log(request);

  //   if (request.isChecked) {
  //     this.selectedRequests += 1;
  //   } else {
  //     this.selectedRequests -= 1;
  //   }
  // }

  selectRequest(request: any) {
    if (request.isChecked) {
      if (request.requeststatus == '001' || request.requeststatus == '003') {
        this.approveCount += 1;
      }
      if (request.requeststatus == '001' || request.requeststatus == '002') {
        this.rejectCount += 1;
      }
      if (
        request.requesttype == this.claimid &&
        request.requeststatus == '002' &&
        !request.paymentstatus
      ) {
        this.paidCount += 1;
      }
    } else {
      if (request.requeststatus == '001' || request.requeststatus == '003') {
        this.approveCount -= 1;
      }
      if (request.requeststatus == '001' || request.requeststatus == '002') {
        this.rejectCount -= 1;
      }
      if (
        request.requesttype == this.claimid &&
        request.requeststatus == '002' &&
        !request.paymentstatus
      ) {
        this.paidCount -= 1;
      }
    }

    console.log('approve count -> ' + this.approveCount);
    console.log('reject count -> ' + this.rejectCount);
    console.log('paid count -> ' + this.paidCount);
    this.checkExcelSelectCount();
  }

  // currentPageSelectAll() {
  //   // console.log(this.currentPageAllSelected);
  //   // // return;
  //   // const y = this.currentPage * 10;
  //   // const x = y - 10;
  //   // if (y > this.approvalList.length) {
  //   //   y == this.approvalList.length;
  //   // }
  //   if (!this.currentPageAllSelected) {
  //     for (var i = 0; i < this.approvalList.length; i++) {
  //       if (
  //         this.approvalList[i].requesttype == this.claimid &&
  //         this.approvalList[i].requeststatus == '002' &&
  //         this.approvalList[i].paymentstatus == false &&
  //         this.approvalList[i].isChecked == true
  //       ) {
  //         this.approvalList[i].isChecked = false;
  //         this.selectedRequests += -1;
  //       }
  //     }
  //     this.currentPageAllSelected = false;
  //   } else {
  //     for (var i = 0; i < this.approvalList.length; i++) {
  //       if (
  //         this.approvalList[i].requesttype == this.claimid &&
  //         this.approvalList[i].requeststatus == '002' &&
  //         this.approvalList[i].paymentstatus == false &&
  //         this.approvalList[i].isChecked == false
  //       ) {
  //         this.approvalList[i].isChecked = true;
  //         this.selectedRequests = this.selectedRequests + 1;
  //       }
  //     }
  //     this.currentPageAllSelected = true;
  //   }
  // }

  transportationPageSelectAll() {
    console.log('here');
    if (this.currentPageAllSelected) {
      this.approveCount = 0;
      this.rejectCount = 0;
      this.paidCount = 0;
      for (var i = 0; i < this.transportaionList.length; i++) {
        this.transportaionList[i].isChecked = true;
        if (
          this.transportaionList[i].requeststatus == '001' ||
          this.transportaionList[i].requeststatus == '003'
        ) {
          this.approveCount += 1;
        }
        if (
          this.transportaionList[i].requeststatus == '001' ||
          this.transportaionList[i].requeststatus == '002'
        ) {
          this.rejectCount += 1;
        }
        if (
          this.transportaionList[i].requesttype == this.claimid &&
          this.transportaionList[i].requeststatus == '002' &&
          !this.transportaionList[i].paymentstatus
        ) {
          this.paidCount += 1;
        }
      }
    } else {
      for (var i = 0; i < this.transportaionList.length; i++) {
        if (
          this.transportaionList[i].isChecked &&
          (this.transportaionList[i].requeststatus == '001' ||
            this.transportaionList[i].requeststatus == '003')
        ) {
          this.approveCount -= 1;
        }
        if (
          this.transportaionList[i].isChecked &&
          (this.transportaionList[i].requeststatus == '001' ||
            this.transportaionList[i].requeststatus == '002')
        ) {
          this.rejectCount -= 1;
        }
        if (
          this.transportaionList[i].isChecked &&
          this.transportaionList[i].requesttype == this.claimid &&
          this.transportaionList[i].requeststatus == '002' &&
          !this.transportaionList[i].paymentstatus
        ) {
          this.paidCount -= 1;
        }
        this.transportaionList[i].isChecked = false;
      }
    }
    this.checkExcelSelectCount();
  }

  currentPageSelectAll() {
    if (this.currentPageAllSelected) {
      this.approveCount = 0;
      this.rejectCount = 0;
      this.paidCount = 0;
      for (var i = 0; i < this.approvalList.length; i++) {
        if (this.approvalList[i].requesttype == this.transportationid) {
          continue;
        }
        this.approvalList[i].isChecked = true;
        if (
          this.approvalList[i].requeststatus == '001' ||
          this.approvalList[i].requeststatus == '003'
        ) {
          this.approveCount += 1;
        }
        if (
          this.approvalList[i].requeststatus == '001' ||
          this.approvalList[i].requeststatus == '002'
        ) {
          this.rejectCount += 1;
        }
        if (
          this.approvalList[i].requesttype == this.claimid &&
          this.approvalList[i].requeststatus == '002' &&
          !this.approvalList[i].paymentstatus
        ) {
          this.paidCount += 1;
        }
      }
    } else {
      for (var i = 0; i < this.approvalList.length; i++) {
        if (this.approvalList[i].requesttype == this.transportationid) {
          continue;
        }
        if (
          this.approvalList[i].isChecked &&
          (this.approvalList[i].requeststatus == '001' ||
            this.approvalList[i].requeststatus == '003')
        ) {
          this.approveCount -= 1;
        }
        if (
          this.approvalList[i].isChecked &&
          (this.approvalList[i].requeststatus == '001' ||
            this.approvalList[i].requeststatus == '002')
        ) {
          this.rejectCount -= 1;
        }
        if (
          this.approvalList[i].isChecked &&
          this.approvalList[i].requesttype == this.claimid &&
          this.approvalList[i].requeststatus == '002' &&
          !this.approvalList[i].paymentstatus
        ) {
          this.paidCount -= 1;
        }
        this.approvalList[i].isChecked = false;
      }
    }
    this.checkExcelSelectCount();
  }

  checkExcelSelectCount() {
    var count = 0;
    for (var i = 0; i < this.approvalList.length; i++) {
      if (this.approvalList[i].isChecked) {
        count += 1;
      }
    }
    this.checkCount = count;
  }

  checkCurrentPageAllSelected() {
    const y = this.currentPage * 10;
    const x = y - 10;
    if (y > this.approvalList.length) {
      y == this.approvalList.length;
    }
    this.currentPageAllSelected = true;
    for (var i = x; i < y; i++) {
      if (
        this.approvalList[i].requesttype == this.claimid &&
        this.approvalList[i].requeststatus == '002' &&
        this.approvalList[i].paymentstatus == false &&
        this.approvalList[i].isChecked == false
      ) {
        this.currentPageAllSelected = false;
        break;
      }
    }
  }

  // submitMutipleUpload() {
  //   this.multipleLoading = true;
  //   var finalList: any = [];
  //   this.approvalList.map((x: any) => {
  //     if (
  //       x.isChecked &&
  //       x.requesttype == this.claimid &&
  //       x.requeststatus == '002' &&
  //       x.paymentstatus == false
  //     ) {
  //       finalList.push({
  //         memberid: x.userid,
  //         requestid: x.requestid,
  //         requestsubtype: x.requestsubtype,
  //         approver: x.approver ? x.approver : [],
  //       });
  //     }
  //   });
  //   var start = 0;
  //   var end = 30;
  //   if (end > finalList.length) {
  //     end = finalList.length;
  //   }
  //   this.updatePayment(finalList, start, end);
  // }

  // paymenttype = 001 -> approve , 002 -> reject , 003 -> paid
  submitApproved() {
    console.log('app fun');

    if (!this.rejectLoading || !this.paidLoading) {
      var message =
        'Are you sure to approve selected ' + this.approveCount + ' records?';
      const dialog = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        // width: '3256x',
        panelClass: ['mat-typography'],
        closeOnNavigation: true,
        data: {
          cmessage: message,
        },
      });
      dialog.afterClosed().subscribe((dialogResult: any) => {
        if (dialogResult) {
          this.approveLoading = true;
          var tempApproveList = [];
          var datelist: any = [];
          var datalist = this.transportationView
            ? this.transportaionList
            : this.approvalList;
          console.log(datalist);
          for (var i = 0; i < datalist.length; i++) {
            if (
              datalist[i].isChecked &&
              (datalist[i].requeststatus == '001' ||
                datalist[i].requeststatus == '003')
            ) {
              if (datalist[i].date && datalist[i].date != '') {
                datelist.push(datalist[i].date);
              }
              if (datalist[i].startdate != '') {
                datelist.push(datalist[i].startdate);
              }
              if (datalist[i].enddate != '') {
                datelist.push(datalist[i].enddate);
              }
              // tempApproveList.push(this.approvalList[i]);
              tempApproveList.push({
                parentid: this.transportationView ? this.tranparentid : '',
                date: datalist[i].date,
                memberid: this.transportationView
                  ? datalist[i].olduserid
                  : datalist[i].userid,
                requestid: this.transportationView
                  ? datalist[i].transportationid
                  : datalist[i].requestid,
                requestsubtype: this.transportationView
                  ? this.transubtype
                  : datalist[i].requestsubtype,
                approver: datalist[i].approver ? datalist[i].approver : [],
              });
            }
          }
          // console.log(tempApproveList.length);
          var start = 0;
          var end = 100;
          if (end > tempApproveList.length) {
            end = tempApproveList.length;
          }

          tempApproveList = tempApproveList.sort((a: any, b: any) => {
            return this.allinoneService.compare(a.date, b.date, false);
          });

          this.updatePayment(
            tempApproveList,
            start,
            end,
            this.transportationView ? '002' : '001',
            datelist
          );
        }
      });
    }
  }

  submitRejected() {
    if (!this.approveLoading && !this.paidLoading) {
      var message =
        'Are you sure to reject selected ' + this.rejectCount + ' records?';
      const dialog = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        // width: '285px',
        panelClass: ['mat-typography'],
        closeOnNavigation: true,
        data: {
          cmessage: message,
        },
      });
      dialog.afterClosed().subscribe((dialogResult: any) => {
        if (dialogResult) {
          this.rejectLoading = true;
          var tempRejectList = [];
          var datelist: any = [];
          var datalist = this.transportationView
            ? this.transportaionList
            : this.approvalList;
          for (var i = 0; i < datalist.length; i++) {
            if (
              datalist[i].isChecked &&
              (datalist[i].requeststatus == '001' ||
                datalist[i].requeststatus == '002')
            ) {
              if (datalist[i].date && datalist[i].date != '') {
                datelist.push(datalist[i].date);
              }
              if (datalist[i].startdate != '') {
                datelist.push(datalist[i].startdate);
              }
              if (datalist[i].enddate != '') {
                datelist.push(datalist[i].enddate);
              }
              // tempRejectList.push(this.approvalList[i]);
              tempRejectList.push({
                parentid: this.transportationView ? this.tranparentid : '',
                date: datalist[i].date,
                memberid: this.transportationView
                  ? datalist[i].olduserid
                  : datalist[i].userid,
                requestid: this.transportationView
                  ? datalist[i].transportationid
                  : datalist[i].requestid,
                requestsubtype: this.transportationView
                  ? this.transubtype
                  : datalist[i].requestsubtype,
                approver: datalist[i].approver ? datalist[i].approver : [],
              });
            }
          }
          // console.log(tempRejectList.length);
          var start = 0;
          var end = 100;
          if (end > tempRejectList.length) {
            end = tempRejectList.length;
          }

          tempRejectList = tempRejectList.sort((a: any, b: any) => {
            return this.allinoneService.compare(a.date, b.date, false);
          });

          this.updatePayment(
            tempRejectList,
            start,
            end,
            this.transportationView ? '003' : '002',
            datelist
          );
        }
      });
    }
  }

  returnTransportation(data: any) {
    var value = '';
    if (data.requeststatus == '001') {
      value =
        data.requestsubtypename.toLowerCase() + ' transportation submitted';
    } else if (data.requeststatus == '002') {
      value =
        data.requestsubtypename.toLowerCase() +
        ' transportation approved by ' +
        data.personname +
        ' (' +
        this.allinoneService.formatDBToShow(data.startdate) +
        ')';
    } else if (data.requeststatus == '003') {
      value =
        data.requestsubtypename.toLowerCase() +
        ' transportation rejected by ' +
        data.personname +
        ' (' +
        this.allinoneService.formatDBToShow(data.startdate) +
        ')';
    } else if (data.requeststatus == '004') {
      value =
        data.requestsubtypename.toLowerCase() + ' transportation completed';
    }
    return value;
  }

  getPickupPoint() {
    this.gettingPickUp = true;
    this.pickupList = [];

    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.approvalForm.orgid,
      type: '001',
    };
    this.kunyekService.getPickup(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          res.datalist.map((item: any) => {
            this.pickupList.push({
              name: item.name,
              user: '',
              userid: item.userid,
              requestdata: item.requestdata,
              usercounts: item.usercounts,
              pickuppointid: item.pickuppointid,
            });
          });

          this.gettingPickUp = false;
        } else {
          this.gettingPickUp = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
        this.gettingPickUp = false;
      }
    );
  }

  submitUnpaid() {
    if (!this.approveLoading && !this.rejectLoading) {
      var message =
        'Are you sure to update paid status for selected ' +
        this.paidCount +
        ' records?';
      const dialog = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '500px',
        // width: '415px',
        panelClass: ['mat-typography'],
        closeOnNavigation: true,
        data: {
          cmessage: message,
        },
      });
      dialog.afterClosed().subscribe((dialogResult: any) => {
        if (dialogResult) {
          this.paidLoading = true;
          var tempPaidList: any[] = [];
          var datelist: any[] = [];
          for (var i = 0; i < this.approvalList.length; i++) {
            if (
              this.approvalList[i].isChecked &&
              this.approvalList[i].requesttype == this.claimid &&
              this.approvalList[i].requeststatus == '002' &&
              !this.approvalList[i].paymentstatus
            ) {
              if (this.approvalList[i].date && this.approvalList[i].date != '') {
                datelist.push(this.approvalList[i].date);
              }
              if (this.approvalList[i].startdate != '') {
                datelist.push(this.approvalList[i].startdate);
              }
              if (this.approvalList[i].enddate != '') {
                datelist.push(this.approvalList[i].enddate);
              }
              tempPaidList.push({
                date: this.approvalList[i].date,
                memberid: this.approvalList[i].olduserid
                  ? this.approvalList[i].olduserid
                  : this.approvalList[i].userid,
                requestid: this.approvalList[i].requestid,
                requestsubtype: this.approvalList[i].requestsubtype,
                approver: this.approvalList[i].approver
                  ? this.approvalList[i].approver
                  : [],
              });
            }
          }
          // console.log(tempPaidList.length);
          var start = 0;
          var end = 100;
          if (end > tempPaidList.length) {
            end = tempPaidList.length;
          }
          tempPaidList = tempPaidList.sort((a: any, b: any) => {
            return this.allinoneService.compare(a.date, b.date, false);
          });
          this.updatePayment(tempPaidList, start, end, '003', datelist);
        }
      });
    }
  }

  returnFacilites(data: any) {
    var value = '';
    if (data.requeststatus == '001') {
      value = data.requestsubtypename.toLowerCase() + ' reservation submitted';
    } else if (data.requeststatus == '002') {
      value =
        data.requestsubtypename.toLowerCase() +
        ' reservation approved by ' +
        data.personname +
        ' (' +
        this.allinoneService.formatDBToShow(data.startdate) +
        ')';
    } else if (data.requeststatus == '003') {
      value =
        data.requestsubtypename.toLowerCase() +
        ' reservation rejected by ' +
        data.personname +
        ' (' +
        this.allinoneService.formatDBToShow(data.startdate) +
        ')';
    } else if (data.requeststatus == '004') {
      value = data.requestsubtypename.toLowerCase() + ' reservation completed';
    }
    return value;
  }

  updatePayment(
    list: any,
    start: number,
    end: number,
    type: string,
    datelist: any
  ) {
    var flist: any = [];
    for (var i = start; i < end; i++) {
      flist.push(list[i]);
    }

    datelist = datelist.sort((a: any, b: any) => {
      return this.allinoneService.compare(a, b, false);
    });

    var startdate = datelist[datelist.length - 1];
    var enddate = datelist[0];
    var data;
    var fun;
    if (this.transportationView) {
      data = {
        orgid: this.approvalForm.orgid,
        requestlist: flist,
        transportation: true,
        byadmin: this.isAdmin,
        startdate: startdate,
        enddate: enddate,
        requeststatus: type,
      };

      fun = this.kunyekService.updateMultipleRequest(data);
    } else {
      data = {
        orgid: this.approvalForm.orgid,
        requestlist: flist,
        byadmin: this.isAdmin,
        paymenttype: type,
        startdate: startdate,
        enddate: enddate,
      };

      fun = this.kunyekService.updateMultipleClaimPayment(data);
    }
    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (end == list.length) {
            if (this.transportationView) {
              this.tranrefresh();
            } else {
              this.refresh();
            }

            this.approveLoading = false;
            this.rejectLoading = false;
            this.paidLoading = false;
          } else {
            start = end;
            end = end + 100;
            if (end > list.length) {
              end = list.length;
            }
            this.updatePayment(list, start, end, type, datelist);
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
          this.approveLoading = false;
          this.rejectLoading = false;
          this.paidLoading = false;
        }
      },
      (err) => {
        this.approveLoading = false;
        this.rejectLoading = false;
        this.paidLoading = false;
      }
    );
  }

  cancelReqeust() {
    console.log('cr');

    this.addingRequest = false;
  }

  deleteRequest(requestid: string) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.approvalListLoading = true;
        this.isDeleting = true;
        const data = {
          orgid: this.approvalForm.orgid,
          requestid: requestid,
          byadmin: true,
        };
        this.kunyekService.deleteOfficeRequest(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              // this.refresh();
              const index = this.approvalList.findIndex(
                (x: any) => x.requestid == requestid
              );
              if (index > -1) {
                this.approvalList.splice(index, 1);
              }
              this.messageService.openSnackBar('Successful.', 'success');

              this.cancel();
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.approvalListLoading = false;
            this.isDeleting = false;
          },
          (err) => {
            this.approvalListLoading = false;
            this.isDeleting = false;
          }
        );
      }
    });
  }

  getRequestSubtypeName(typeid: string, subtypeid: string) {
    if (subtypeid) {
      var temp = this.requestTypeList
        .find((x: any) => x.requesttypeid == typeid)
        .child.find((y: any) => y.requesttypeid == subtypeid);
      if (temp) {
        return temp.requesttypename;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  goToMap(lat: string, long: string) {
    const url = `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;
    window.open(url, "_blank");
  }
}
