<div class = "main">
    <div class = "title">
        <div class = "subtitle">
            <img *ngIf="allinoneService.appView=='001'" src = "../../../assets/images/kunyek.png"  class = "me-3"><img *ngIf="allinoneService.appView=='002'" src = "../../../assets/images/neoteamsLogo.jpg"  class = "me-3"><span style="color: rgb(175, 37, 37);font-size: 2rem;"> Permission required !</span>
        </div>
        <span>
            You do not have the permission that required to perform this operation. Ask your administrator to add HR admin permissions!
        </span> 
    </div>
    <div class = "image">
        <img src="../../../assets/images/permissionrequire.png">
    </div>
</div>