<div class="container-fluid">
    <div class="content">
        <app-crm-breadcrumb *ngIf="!addpriority && !isEditing" s_breadcrumb="Service Overage" (emit)="cancelFunction()">
        </app-crm-breadcrumb>
        <app-crm-breadcrumb *ngIf="addpriority || isEditing" s_breadcrumb="Service Overage"
            [t_breadcrumb]="isEditing ? 'Edit' : 'New'" (emit)="cancelFunction()">
        </app-crm-breadcrumb>
        <div *ngIf="!addpriority && !isEditing ">
            <div class="d-flex flex-wrap">
                <div class="showbtn me-auto  mb-3">
                    <button class="btn btn-custom" (click)="new()">
                        <i class="fa fa-plus me-1"></i> New
                    </button>
                </div>
            </div>


            <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                <table class="table table-responsive table-borderless">
                    <thead class="table-header">
                        <tr>
                            <td scope="col" class="col-lg-5 col-md-5" mat-sort-header="description">Description</td>
                            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="priorityname">Priority</td>
                            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="typename">Type</td>
                            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="days">Days</td>
                            <td scope="col" class="d-flex justify-content-center">Action</td>
                        </tr>
                    </thead>
                    <tbody *ngIf="gettingData">
                        <tr>
                            <td colspan="5" class="nodata">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="gettingData== false">
                        <tr *ngIf="data.length == 0">
                            <td colspan="5" class="nodata">Empty</td>

                        </tr>
                        <!-- | crmFilter :searchText:searchStatusLead:serviceFilter:1 | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage }; let index = index -->
                        <tr *ngFor="let item of data ">

                            <td class="td-data td-edit" (click)="editpriority(item)">
                                <span [title]="item.description">{{item.description}}</span>
                            </td>
                            <td class="td-data td-edit" (click)="editpriority(item)">
                                <span [title]="item.type">{{item.priorityname}}</span>
                            </td>
                            <td class="td-data td-edit" (click)="editpriority(item)">
                                <span [title]="item.type">{{item.typename}}</span>
                            </td>
                            <td class="td-data" (click)="editpriority(item)">
                                <span [title]="item.day">{{item.days}}</span>
                            </td>

                            <td class="d-flex justify-content-center">
                                <button title="Edit" class="btn btn-outline-primary" (click)="editpriority(item)">
                                    <i class='fa fa-edit'></i>
                                </button>
                                <button title="Delete" class="ms-2 btn btn-outline-danger"
                                    (click)="delete(item.overageid)">
                                    <i class='fa fa-trash-o'></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


        <div *ngIf="addpriority ||  isEditing">
            <div class="close-save-button mb-3">
                <button type="button" class="btn btn-custom me-2" (click)="cancelFunction()" *ngIf="!saveLoading">
                    Cancel
                </button>
                <app-button-loader (click)="submitpriority()" [button_text]="'Save'" [load_button_text]="'Saving'"
                    [isLoading]="saveLoading">
                </app-button-loader>
            </div>

            <div class="row">
                <div class="row">
                    <div class="mb-3 col-md-12">
                        <!-- <label for="ref" class="form-label" (click)="Priority()">Priority</label> -->
                        <div class="d-flex align-items-center">
                            <div class="form-check  me-5" *ngFor="let prio of priority">
                                <label class="radio-inline">
                                    <input class="form-check-input" type="radio" name="priority" [id]="prio.code"
                                        #priority="ngModel" [(ngModel)]="priorityForm.priority" [value]="prio.code"
                                        [checked]="prio.code == priorityForm.priority">
                                    {{prio.name}}
                                </label>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row">

                    <div class="mb-3 col-md-6">
                        <label for="type" class="form-label">Type</label>
                        <ng-select class="role-select" [items]="typelist" bindLabel="name" placeholder="-"
                            appendTo="body" [multiple]="true" [(ngModel)]="servicetypelist"
                            (change)="serviceTypechange($event)" required #type="ngModel"
                            [ngClass]="{ 'service-select-invalid': servicetypelist.length == 0  && priorityFormSave }">

                        </ng-select>

                        <!-- [disabled]="getServiceType" -->
                        <!-- <select class="form-select" aria-label="Select type" id="type" name="type"
                    [(ngModel)]="serviceForm.servicetype" required #type="ngModel"
                    [ngClass]="{ 'is-invalid': serviceFormSubmitted && type.invalid }"
                    [disabled]="getServiceType">
                    <option value=''>Select Service Type</option>
                    <option *ngFor="let type of servielist;trackBy: trackByServicetype" value="{{type.code}}">
                        {{type.name}}</option>
                </select> -->
                        <mat-progress-bar mode="indeterminate" *ngIf="getServiceType"></mat-progress-bar>

                        <div class="invalid-feedback"
                            *ngIf="type.invalid || (type.dirty || type.touched) && priorityFormSave">
                            <div *ngIf="type.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>


                    </div>

                    <div class="mb-3 col-md-6">
                        <label for="day" class="form-label">Days</label>
                        <input type="text" class="form-control" id="day" name="day" [(ngModel)]="priorityForm.day"
                            #day="ngModel" [ngClass]="{'is-invalid': priorityFormSave && day.invalid}" required>

                        <div class="invalid-feedback"
                            *ngIf="day.invalid || (day.dirty || day.touched) && priorityFormSave">
                            <div *ngIf="day.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>

                    </div>

                </div>
                <div class="row">
                    <div class="mb-3 col-md-12">
                        <label for="type" class="form-label">Name</label>
                        <input type="text" class="form-control" id="day" name="day" [(ngModel)]="priorityForm.name"
                            #name="ngModel" [ngClass]="{'is-invalid': priorityFormSave && name.invalid}" required>

                        <div class="invalid-feedback"
                            *ngIf="name.invalid || (name.dirty || name.touched) && priorityFormSave">
                            <div *ngIf="name.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                        

                    </div>
                    <small class="font-weight-light" style="margin-left: 5px; color: grey;">* {{priorityForm.name}} must not be more than
                        {{priorityForm.day}} days old.</small>

                </div>

            </div>


        </div>

    </div>
</div>