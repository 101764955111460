<!-- <div infinite-scroll> -->
<div *ngIf="post == undefined">
    <div class="title">
        Wrong post url...
    </div>
</div>
<div *ngIf="post != undefined" class="row row-custom">
    <button mat-icon-button class="back-btn" (click)="back()">
        <mat-icon>close</mat-icon>
    </button>
    <div class="col-lg-8 col-md-6 d-flex align-items-center justify-content-center image-container position-relative"
        [ngClass]="{'left-column' : !isMobileView}">
        <ng-container *ngIf="post.imagelist.length > 1">
            <button mat-icon-button class="left-btn" (click)="slideImage('left')">
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button mat-icon-button class="right-btn" (click)="slideImage('right')">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
        </ng-container>
        <img class="pos-img" [defaultImage]="'../../../assets/images/bg.jpg'" [lazyLoad]="post.imagelist[currentIndex].imageurl"
            [alt]="appname">
    </div>
    <div class="col-lg-4 col-md-6" [ngClass]="{'right-column' : !isMobileView , 'p-3' : isMobileView}">
        <perfect-scrollbar [config]="config" [scrollIndicators]="true">
            <div class="profile-container">
                <span *ngIf="post.type == '1'">
                    <img class="profile-image" [defaultImage]="'../../../assets/images/bg.jpg'"
                        [lazyLoad]="post.domainurl ? post.domainurl : '../../../assets/images/post_admin.png'">
                </span>
                <span *ngIf="post.type == '2' || post.type == '4'">
                    <img class="profile-image" (click)="viewProfile(post.adminid)"
                        [defaultImage]="'../../../assets/images/bg.jpg'"
                        [lazyLoad]="post.adminimage ? post.adminimage : '../../../assets/images/profile.png'">
                </span>
                <span *ngIf="post.type == '3'">
                    <img class="profile-image" [defaultImage]="'../../../assets/images/bg.jpg'"
                        [lazyLoad]="post.orgimageurl ? post.orgimageurl :'../../../assets/images/post_admin.png'">
                </span>

                <div class="profile-item-container">
                    <div class="profile-item">
                        <div class="username" *ngIf="post.type == '1'">
                            <b>{{domaindesc}}</b> <img class="bluemark" src="../../../assets/images/bluemark.png">

                        </div>

                        <div class="username" *ngIf="post.type == '2' || post.type == '4'">
                            <p class="username-text">
                                <span *ngIf="post.orgtag.length > 0">
                                    <b class="user-id" (click)="viewProfile(post.adminid)">{{post.admin ? post.admin :
                                        post.adminid}}</b>

                                    &nbsp;is&nbsp;with&nbsp;
                                    <b>{{post.orgtag[0].orgname}}</b>
                                </span>
                                <span
                                    *ngIf="post.orgtag.length == 0 && (post.tag.length == 0 || post.tag == undefined || post.tag == null)">
                                    <b class="user-id" (click)="viewProfile(post.adminid)">{{post.admin ? post.admin :
                                        post.adminid}}</b>

                                </span>
                                <span *ngIf="post.orgtag.length == 0 && (post.tag.length == 1)">
                                    <b class="user-id" (click)="viewProfile(post.adminid)">{{post.admin ? post.admin :
                                        post.adminid}}</b>

                                    &nbsp;is&nbsp;with&nbsp;
                                    <b class="user-id" (click)="viewProfile(post.tag[0].userid)">{{post.tag[0].username
                                        ?
                                        post.tag[0].username : post.tag[0].userid}}</b>


                                </span>
                                <span *ngIf="post.orgtag.length == 0 && (post.tag.length == 2)">
                                    <b class="user-id" (click)="viewProfile(post.adminid)">{{post.admin ? post.admin :
                                        post.adminid}}</b>

                                    &nbsp;is&nbsp;with&nbsp;
                                    <b class="user-id" (click)="viewProfile(post.tag[0].userid)">{{post.tag[0].username
                                        ?
                                        post.tag[0].username : post.tag[0].userid}}</b>

                                    &nbsp;and&nbsp;
                                    <b class="user-id" (click)="viewProfile(post.tag[1].userid)">{{post.tag[1].username
                                        ?
                                        post.tag[1].username : post.tag[1].userid}}</b>

                                </span>
                                <span *ngIf="post.tag.length > 2">
                                    <b class="user-id" (click)="viewProfile(post.adminid)">{{post.admin ? post.admin :
                                        post.adminid}}</b>

                                    &nbsp;is&nbsp;with&nbsp;
                                    <b class="user-id" (click)="viewProfile(post.tag[0].userid)">{{post.tag[0].username
                                        ?
                                        post.tag[0].username : post.tag[0].userid}}</b>

                                    &nbsp;and&nbsp;
                                    <b class="viewOtherTag" (click)="LikeAndTagForm(post.syskey, 'tags', post.tag)">
                                        {{post.tag.length - 1}} others
                                    </b>
                                </span>
                                <span *ngIf="post.orgname && post.type == '4'">
                                    &nbsp;<i class="fas fa-at"></i>&nbsp;
                                    <b>{{post.orgname}}</b>
                                </span>
                                <span *ngIf="post.channelid && post.type == '2'">
                                    &nbsp;<i class="fas fa-at"></i>&nbsp;
                                    <b>{{post.channelname}}</b>
                                </span>
                            </p>
                        </div>
                        <div class="username" *ngIf="post.type == '3'">
                            <p class="username-text">
                                <span *ngIf="post.orgtag.length > 0">
                                    <b>{{post.orgname}}</b> <img class="bluemark"
                                        src="../../../assets/images/bluemark.png">

                                    &nbsp;is&nbsp;with&nbsp;
                                    <b>{{post.orgtag[0].orgname}}</b>
                                </span>
                                <span
                                    *ngIf="post.orgtag.length == 0 && (post.tag.length == 0 || post.tag == undefined || post.tag == null)">
                                    <b>{{post.orgname}}</b> <img class="bluemark"
                                        src="../../../assets/images/bluemark.png">

                                </span>
                                <span *ngIf="post.orgtag.length == 0 && (post.tag.length == 1)">
                                    <b>{{post.orgname}}</b> <img class="bluemark"
                                        src="../../../assets/images/bluemark.png">

                                    &nbsp;is&nbsp;with&nbsp;
                                    <b class="user-id" (click)="viewProfile(post.tag[0].userid)">{{post.tag[0].username
                                        ?
                                        post.tag[0].username : post.tag[0].userid}}</b>


                                </span>
                                <span *ngIf="post.orgtag.length == 0 && (post.tag.length == 2)">
                                    <b>{{post.orgname}}</b> <img class="bluemark"
                                        src="../../../assets/images/bluemark.png">

                                    &nbsp;is&nbsp;with&nbsp;
                                    <b class="user-id" (click)="viewProfile(post.tag[0].userid)">{{post.tag[0].username
                                        ?
                                        post.tag[0].username : post.tag[0].userid}}</b>

                                    &nbsp;and&nbsp;
                                    <b class="user-id" (click)="viewProfile(post.tag[1].userid)">{{post.tag[1].username
                                        ?
                                        post.tag[1].username : post.tag[1].userid}}</b>

                                </span>
                                <span *ngIf="post.tag.length > 2">
                                    <b>{{post.orgname}}</b> <img class="bluemark"
                                        src="../../../assets/images/bluemark.png">

                                    &nbsp;is&nbsp;with&nbsp;
                                    <b class="user-id" (click)="viewProfile(post.tag[0].userid)">{{post.tag[0].username
                                        ?
                                        post.tag[0].username : post.tag[0].userid}}</b>

                                    &nbsp;and&nbsp;
                                    <b class="viewOtherTag" (click)="LikeAndTagForm(post.syskey, 'tags', post.tag)">
                                        {{post.tag.length - 1}} others
                                    </b>
                                </span>

                            </p>

                        </div>
                        <div class="time d-flex">
                            <!-- <div>
                                {{allinoneService.formatDBToShow(post.date)}},
                                {{allinoneService.formatTimeToShow(post.time)}}
                            </div> -->
                            <div>
                                {{(allinoneService.formatDBToShowInEdit(post.date,
                                  '/')
                                  +' '+ post.time) | datetimeago}}
                              </div>
                            <div class="d-flex align-items-center">
                                <i class="fa fa-circle" style="margin-left: 5px; font-size: 6px;"></i>
                            </div>
                            <div style="margin-left: 5px;">
                                <i class="fas fa-globe-americas"
                                    *ngIf="post.postsetting == '001' && (post.type != '3' && post.type !='4')"></i>
                                <i class="fas fa-globe-americas"
                                    *ngIf="post.postsetting == '001' && (post.type == '3' || post.type == '4')"></i>
                                <i class="fas fa-user-friends"
                                    *ngIf="(post.postsetting == '002' || post.postsetting == '') && (post.type != '3' && post.type !='4')"></i>
                                <i class="fal fa-user-friends" style="color: #333;"
                                    *ngIf="(post.postsetting == '002' || post.postsetting == '') && (post.type == '3' || post.type =='4')"></i>
                                <i class="fa fa-tags" *ngIf="post.postsetting == '003'"></i>
                                <i class="fas fa-lock" *ngIf="post.postsetting == '004'"></i>
                                <i class="fas fa-comment-alt" *ngIf="post.postsetting == '005'"></i>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <!-- <div>RightSide...</div> -->
            <div class="like-comment mt-2 mb-2">
                <div>
                    <div *ngIf="post.imagelist[currentIndex].totallike > 0">
                        <span class="link-btn" (click)="LikeAndTagForm(post.syskey, 'reacts', post.tag)">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div *ngIf="post.imagelist[currentIndex].totalreact[0].id == '4'">
                                        <img style="margin-bottom:2px;" src="../../../assets/images/like.svg"
                                            width="15">
                                    </div>
                                    <div *ngIf="post.imagelist[currentIndex].totalreact[0].id == '002'">
                                        <img style="margin-bottom:2px;" src="../../../assets/images/love.svg"
                                            width="15">
                                    </div>
                                    <div *ngIf="post.imagelist[currentIndex].totalreact[0].id == '003'">
                                        <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg"
                                            width="15">
                                    </div>
                                    <div *ngIf="post.imagelist[currentIndex].totalreact[0].id == '004'">
                                        <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="15">
                                    </div>
                                    <div *ngIf="post.imagelist[currentIndex].totalreact[0].id == '005'">
                                        <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg"
                                            width="15">
                                    </div>
                                </div>
                                <div *ngIf="post.imagelist[currentIndex].totalreact.length > 1">
                                    <div *ngIf="post.imagelist[currentIndex].totalreact[1].id == '4'">
                                        <img style="margin-bottom:2px;" src="../../../assets/images/like.svg"
                                            width="15">
                                    </div>
                                    <div *ngIf="post.imagelist[currentIndex].totalreact[1].id == '002'">
                                        <img style="margin-bottom:2px;" src="../../../assets/images/love.svg"
                                            width="15">
                                    </div>
                                    <div *ngIf="post.imagelist[currentIndex].totalreact[1].id == '003'">
                                        <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg"
                                            width="15">
                                    </div>
                                    <div *ngIf="post.imagelist[currentIndex].totalreact[1].id == '004'">
                                        <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="15">
                                    </div>
                                    <div *ngIf="post.imagelist[currentIndex].totalreact[1].id == '005'">
                                        <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg"
                                            width="15">
                                    </div>
                                </div>
                                <div *ngIf="post.imagelist[currentIndex].totalreact.length > 2">
                                    <div *ngIf="post.imagelist[currentIndex].totalreact[2].id == '4'">
                                        <img style="margin-bottom:2px;" src="../../../assets/images/like.svg"
                                            width="15">
                                    </div>
                                    <div *ngIf="post.imagelist[currentIndex].totalreact[2].id == '002'">
                                        <img style="margin-bottom:2px;" src="../../../assets/images/love.svg"
                                            width="15">
                                    </div>
                                    <div *ngIf="post.imagelist[currentIndex].totalreact[2].id == '003'">
                                        <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg"
                                            width="15">
                                    </div>
                                    <div *ngIf="post.imagelist[currentIndex].totalreact[2].id == '004'">
                                        <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="15">
                                    </div>
                                    <div *ngIf="post.imagelist[currentIndex].totalreact[2].id == '005'">
                                        <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg"
                                            width="15">
                                    </div>
                                </div>

                                <div class="ms-1">
                                    {{post.imagelist[currentIndex].totallike}}
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div>
                    <span class="">
                        <span *ngIf="post.imagelist[currentIndex].comment > 1">
                            {{post.imagelist[currentIndex].comment}} Comments
                        </span>
                        <span *ngIf="post.imagelist[currentIndex].comment == 1">
                            {{post.imagelist[currentIndex].comment}} Comment
                        </span>
                    </span>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="like-comment-buttons">
                <button mat-button class="like-button">
                    <div class="d-flex justify-content-center" *ngIf="!post.imagelist[currentIndex].like" (click)="like('4')">
                        <div><i class="far fa-thumbs-up me-2"></i></div>
                        <div class="ms-2">Like</div>
                    </div>
                    <div *ngIf="post.imagelist[currentIndex].like">
                        <div class="d-flex justify-content-center" *ngIf="post.imagelist[currentIndex].react == '4'" (click)="like( '')">
                            <div>
                                <img style="margin-bottom:2px;" src="../../../assets/images/like.svg" width="15">
                            </div>
                            <div class="ms-2" style="color: var(--main-color);">Like</div>
                        </div>
                        <div class="d-flex justify-content-center" *ngIf="post.imagelist[currentIndex].react == '002'" (click)="like( '')">
                            <div>
                                <img style="margin-bottom:2px;" src="../../../assets/images/love.svg" width="15">
                            </div>
                            <div class="ms-2" style="color: #ef3453;">Love</div>
                        </div>
                        <div class="d-flex justify-content-center" *ngIf="post.imagelist[currentIndex].react == '003'" (click)="like( '')">
                            <div>
                                <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg" width="15">
                            </div>
                            <div class="ms-2" style="color: orange;">Haha</div>
                        </div>
                        <div class="d-flex justify-content-center" *ngIf="post.imagelist[currentIndex].react == '004'" (click)="like( '')">
                            <div>
                                <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="15">
                            </div>
                            <div class="ms-2" style="color: orange;">Sad</div>
                        </div>
                        <div class="d-flex justify-content-center" *ngIf="post.imagelist[currentIndex].react == '005'" (click)="like( '')">
                            <div>
                                <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg" width="15">
                            </div>
                            <div class="ms-2" style="color: #eb640b;">Angry</div>
                        </div>
                    </div>
                    <div class="react-list">
                        <div class="react" (click)="like('4')">
                            <img style="margin-bottom:2px;" src="../../../assets/images/like.svg" width="25">
                        </div>
                        <div class="react" (click)="like('002')">
                            <img style="margin-bottom:2px;" src="../../../assets/images/love.svg" width="25">
                        </div>
                        <div class="react" (click)="like('003')">
                            <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg" width="25">
                        </div>
                        <div class="react" (click)="like('004')">
                            <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="25">
                        </div>
                        <div class="react" (click)="like('005')">
                            <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg" width="25">
                        </div>
                    </div>
                </button>
                <button mat-button *ngIf="post.commentsetting=='true'">
                    <i class="far fa-comment-alt me-2"></i>Comment
                </button>
            </div>
            <mat-divider></mat-divider>
            <div class="my-2 comment-section" *ngIf="post.commentsetting=='true'">
                <img class="profile-image me-3" [defaultImage]="'../../../assets/images/bg.jpg'"
                    [lazyLoad]="profileimg ? profileimg : '../../../assets/images/profile.png'">
                <div class="input-box">
                    <quill-editor class="textarea" [modules]="modules" style="width: 100%;" [formats]="formats"
                        format="object" [(ngModel)]="this.post.imagelist[this.currentIndex].commentText" [trimOnValidation]="true">
                    </quill-editor>
                    <div [ngClass]="{'image-box': this.post.imagelist[this.currentIndex].selectedImage }" *ngIf="this.post.imagelist[this.currentIndex].selectedImage">
                        <div class="close" (click)="closeImage()">
                            <mat-icon>close</mat-icon>
                        </div>
                        <img [src]="this.post.imagelist[this.currentIndex].selectedImage" alt="">
                    </div>
                    <div class="btns" *ngIf="!this.post.imagelist[this.currentIndex].saveCommentLoading">
                        <input style="display: none" type="file" accept="image/*" (change)="onFileSelect($event)"
                            #hiddenfileinput>
                        <button mat-icon-button class="image" (click)="hiddenfileinput.click()">
                            <mat-icon>photo_library</mat-icon>
                        </button>
                        <button mat-icon-button class="send" (click)="submitComment($event)">
                            <mat-icon>send</mat-icon>
                        </button>
                    </div>
                    <div class="btns" *ngIf="this.post.imagelist[this.currentIndex].saveCommentLoading">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!post.imagelist[currentIndex].gettingComments && post.imagelist[currentIndex].comments.length == 0" style="padding: 20px;
            color: grey;
            text-align: center;">
                <p>No Comment</p>
            </div>
            <div *ngIf="post.imagelist[currentIndex].comments.length > 0">
                <div *ngFor="let comment of post.imagelist[currentIndex].comments; let i = index ">
                    <div class="message-container">
                        <div class="parent-reply-line"
                            *ngIf="(comment.childlist.length > 0 || comment.children.length > 0) || comment.replyboxopen">
                        </div>
                        <span>
                            <img class="comment-profile-image" (click)="viewProfile(comment.userid)"
                                [defaultImage]="'../../../assets/images/profile.png'"
                                [lazyLoad]="comment.userimage ? comment.userimage : '../../../assets/images/profile.png'">
                        </span>
                        <div class="comment-box" [attr.data-is-edit]="comment.edit ? '1': '0'">
                            <div class="message-box">
                                <div class="delete-loading" *ngIf="commentSyskey==comment.syskey">
                                    <div class="spinner-border mb-3 " role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>


                                <div class="comment-option-box">
                                    <button mat-icon-button id="dropdownMenuButton1"
                                        aria-expanded="false" data-bs-toggle="dropdown">
                                        <mat-icon>more_horiz</mat-icon>
                                    </button>
                                    <div class="dropdown-menu msg-menu dropdown-menu-start"
                                        aria-labelledby="dropdownMenuButton1">

                                        <ng-container
                                            *ngIf="(domainAdmin || comment.userid==userid || post.adminid==userid || (post.type == 3 &&orgAdmin))  && commentSyskey!=comment.syskey; else elseTemp">
                                            <button mat-menu-item [cdkCopyToClipboard]="comment.comment"
                                                class="dropdown-item">
                                                <mat-icon>content_copy</mat-icon>Copy Comment
                                            </button>
                                            <button *ngIf="comment.userid == userid" mat-menu-item class="dropdown-item"
                                                (click)="editCommetConfirm(comment, i)">
                                                <mat-icon>edit</mat-icon>Edit Comment
                                            </button>

                                            <button mat-menu-item class="dropdown-item"
                                                (click)="deleteCommetConfirm(comment.syskey, i)">
                                                <mat-icon>delete</mat-icon>Delete Comment
                                            </button>
                                        </ng-container>
                                        <ng-template #elseTemp>
                                            <button mat-menu-item [cdkCopyToClipboard]="comment.comment"
                                                class="dropdown-item">
                                                <mat-icon>content_copy</mat-icon>Copy Comment
                                            </button>
                                        </ng-template>
                                    </div>
                                </div>

                                <div class="message-header">
                                    {{comment.username}}
                                </div>

                                <div *ngIf="comment.edit">
                                    <div class="edit-input-box mb-1">
                                        

                                        <div [id]="'editor-container'" class="edit-textarea">

                                        </div>
                                    </div>
                                    <div style="display: flex; justify-content: flex-end;">
                                        <button mat-icon-button (click)="closeEditComment(i)" [disabled]="editLoading">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                        <button *ngIf="!editLoading" mat-icon-button
                                            (click)="submitEditComment(comment)">
                                            <mat-icon>check</mat-icon>
                                        </button>
                                        <div style="display: flex; align-items: center;">
                                            <span *ngIf="editLoading"
                                                class="spinner-border spinner-border-sm button-spinner" role="status"
                                                aria-hidden="true" style="color: var(--main-color) !important;"></span>
                                        </div>
                                    </div>
                                </div>

                                <span *ngIf="!comment.edit" class="message-note"
                                    [innerHTML]="comment.comment | replaceEnterForChat:(comment.userid == userid):i"
                                    (click)="clickcmt($event)"></span>

                                <ng-container *ngIf="comment.imagename != '' && !comment.edit">
                                    <img class="comment-image" [defaultImage]="'../../../assets/images/bg.jpg'"
                                        [lazyLoad]="comment.imagename" [alt]="appname"
                                        (click)="imageViewer(comment.imagename  ,i)">
                                </ng-container>
                                <div class="react-box" [attr.data-is-edit]="comment.edit ? '1': '0'"
                                    *ngIf="comment.totalreact.length > 0">
                                    <div class="reacts"
                                        (click)="CommentReactForm(post.syskey,comment.syskey, 'commentreacts', '')">
                                        
                                        <div *ngFor="let react of comment.totalreact;let index=index;">
                                            <div *ngIf="react.id == '4' && 2>=index" class="react-image">
                                                <img style="margin-bottom:2px;" src="../../../assets/images/like.svg"
                                                    width="15">
                                            </div>
                                            <div *ngIf="react.id == '002' && 2>=index" class="react-image">
                                                <img style="margin-bottom:2px;" src="../../../assets/images/love.svg"
                                                    width="15">
                                            </div>
                                            <div *ngIf="react.id == '003' && 2>=index" class="react-image">
                                                <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg"
                                                    width="15">
                                            </div>
                                            <div *ngIf="react.id == '004' && 2>=index" class="react-image">
                                                <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg"
                                                    width="15">
                                            </div>
                                            <div *ngIf="react.id == '005' && 2>=index" class="react-image">
                                                <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg"
                                                    width="15">
                                            </div>

                                        </div>
                                        <div class="px-2 d-flex reactcount" style="word-break: initial;">
                                            {{getTotalReactCount(comment)}}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="comment-time">
                                <span class="like-cmt me-2">
                                    <span *ngIf="!comment.userreact"
                                        (click)="reactComment('4', comment.syskey, i)">Like</span>
                                    <span *ngIf="comment.userreact" style="color: var(--main-color);"
                                        (click)="reactComment(comment.userreact, comment.syskey, i)">Like</span>
                                    <div class="cmt-react-list" [id]="'reactbox'+i">
                                        <div class="react d-flex flex-column"
                                            (click)="reactComment('4', comment.syskey, i)">
                                            <div>
                                                <img style="margin-bottom:2px;" src="../../../assets/images/like.svg"
                                                    width="25">
                                            </div>
                                            <div *ngIf="comment.userreact == '4'" class="active">
                                                <i class="fas fa-circle"></i>
                                            </div>
                                        </div>
                                        <div class="react" (click)="reactComment('002', comment.syskey, i)">
                                            <div>
                                                <img style="margin-bottom:2px;" src="../../../assets/images/love.svg"
                                                    width="25">
                                            </div>
                                            <div *ngIf="comment.userreact == '002'" class="active">
                                                <i class="fas fa-circle"></i>
                                            </div>
                                        </div>
                                        <div class="react" (click)="reactComment('003', comment.syskey, i)">
                                            <div>
                                                <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg"
                                                    width="25">
                                            </div>
                                            <div *ngIf="comment.userreact == '003'" class="active">
                                                <i class="fas fa-circle"></i>
                                            </div>
                                        </div>
                                        <div class="react" (click)="reactComment('004', comment.syskey, i)">
                                            <div>
                                                <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg"
                                                    width="25">
                                            </div>
                                            <div *ngIf="comment.userreact == '004'" class="active">
                                                <i class="fas fa-circle"></i>
                                            </div>
                                        </div>
                                        <div class="react" (click)="reactComment('005', comment.syskey, i)">
                                            <div>
                                                <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg"
                                                    width="25">
                                            </div>
                                            <div *ngIf="comment.userreact == '005'" class="active">
                                                <i class="fas fa-circle"></i>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                                <span class="like-cmt me-2" (click)="comment.replyboxopen = !comment.replyboxopen"
                                    *ngIf="post.commentsetting == 'true'">Reply</span>
                                <span
                                    [title]="allinoneService.formatDBToShow(comment.date) +' '+ comment.time">{{(allinoneService.formatDBToShowInEdit(comment.date,
                                    '/')
                                    +' '+ comment.time) | timeAgo}}</span>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="comment.replyboxopen">
                        <div class="cmt-reply-box d-flex w-100">
                            <div class="reply-line me-2" style="margin-top: 2px !important;">
                            </div>
                            <div class="child-reply-line" *ngIf="comment.childlist.length > 0">
                            </div>
                            <div class="mb-3 comment-section d-flex flex-fill" *ngIf="post.commentsetting=='true'">
                                <img class="comment-reply-profile-image me-3 mt-2"
                                    [defaultImage]="'../../../assets/images/bg.jpg'"
                                    [lazyLoad]="profileimg ? profileimg : '../../../assets/images/profile.png'">
                                <div class="reply-input-box mt-1 flex-fill">
                                    
                                    <quill-editor class="textarea mt-1" [modules]="modules" style="width: 100%;"
                                        [formats]="formats" format="object" [(ngModel)]="comment.commentText"
                                        [trimOnValidation]="true">
                                    </quill-editor>
                                    <div [ngClass]="{'image-box': comment.selectedImage }"
                                        *ngIf="comment.selectedImage">
                                        <div class="close" (click)="closeImage(comment)">
                                            <mat-icon>close</mat-icon>
                                        </div>
                                        <img [src]="comment.selectedImage" alt="">
                                    </div>
                                    <div class="btns" *ngIf="!comment.saveCommentLoading">
                                        <input style="display: none" type="file" accept="image/*"
                                            (change)="onReplyFileSelect($event, i)" #hiddenreplyfileinput>
                                        <button mat-icon-button class="image" (click)="hiddenreplyfileinput.click()">
                                            <mat-icon>photo_library</mat-icon>
                                        </button>
                                        <button mat-icon-button class="send" (click)="submitReplyComment($event, i)">
                                            <mat-icon>send</mat-icon>
                                        </button>
                                    </div>
                                    <div class="btns" *ngIf="comment.saveCommentLoading" style="bottom: 0 !important;">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container
                        *ngIf="(comment.childlist.length > 0 || comment.children.length > 0) && !comment.gettingreply">
                        <div class="cmt-reply-box align-items-center d-flex" *ngIf="comment.children.length == 0">
                            <div class="reply-line me-2" style="margin-top: -16px !important;">

                            </div>
                            <div>
                                <img class="comment-reply-profile-image" (click)="viewProfile(comment.userid)"
                                    [defaultImage]="'../../../assets/images/profile.png'"
                                    [lazyLoad]="comment.childlist[0].userimage ? comment.childlist[0].userimage : '../../../assets/images/profile.png'">
                            </div>
                            <div (click)="getReply(comment.syskey, i)" style="cursor: pointer;">
                                {{comment.childlist[0].username}} replied.&nbsp;<span
                                    class="comment-time">{{comment.childlist[0].count}}&nbsp;{{comment.childlist[0].count
                                    > 1 ? 'Replies':
                                    'Reply'}}</span>
                            </div>
                        </div>
                        <ng-container *ngIf="comment.children">
                            <ng-container *ngFor="let reply of comment.children; let rindex = index">
                                <div class="cmt-reply-box d-flex">
                                    <div class="child-reply-line"
                                        *ngIf="(rindex != (comment.children.length - 1)) || comment.viewmorecomments || comment.gettingmorereply">
                                    </div>
                                    <div class="reply-line me-2">
                                    </div>
                                    <div>
                                        <img class="comment-reply-profile-image" (click)="viewProfile(reply.userid)"
                                            [defaultImage]="'../../../assets/images/profile.png'"
                                            [lazyLoad]="reply.userimage ? reply.userimage : '../../../assets/images/profile.png'">
                                    </div>
                                    <div>
                                        <div class="comment-box" [attr.data-is-edit]="reply.edit ? '1': '0'">
                                            <div class="message-box">
                                                <div class="delete-loading" *ngIf="commentSyskey==reply.syskey">
                                                    <div class="spinner-border mb-3 " role="status">
                                                        <span class="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>


                                                <div class="comment-option-box">
                                                    <button mat-icon-button id="dropdownMenuButton1"
                                                        aria-expanded="false" data-bs-toggle="dropdown">
                                                        <mat-icon>more_horiz</mat-icon>

                                                    </button>
                                                    <div class="dropdown-menu msg-menu dropdown-menu-start"
                                                        aria-labelledby="dropdownMenuButton1">

                                                        <ng-container
                                                            *ngIf="(domainAdmin || reply.userid==userid || post.adminid==userid || (post.type == 3 &&orgAdmin))  && commentSyskey!=comment.syskey; else elseTemp">
                                                            <button mat-menu-item [cdkCopyToClipboard]="reply.comment"
                                                                class="dropdown-item">
                                                                <mat-icon>content_copy</mat-icon>Copy Comment
                                                            </button>
                                                            <button *ngIf="reply.userid == userid" mat-menu-item
                                                                class="dropdown-item"
                                                                (click)="editCommetConfirm(reply, i)">
                                                                <mat-icon>edit</mat-icon>Edit Comment
                                                            </button>

                                                            <button mat-menu-item class="dropdown-item"
                                                                (click)="deleteCommetConfirm(reply.syskey, i, rindex)">
                                                                <mat-icon>delete</mat-icon>Delete Comment
                                                            </button>
                                                        </ng-container>
                                                        <ng-template #elseTemp>
                                                            <button mat-menu-item [cdkCopyToClipboard]="reply.comment"
                                                                class="dropdown-item">
                                                                <mat-icon>content_copy</mat-icon>Copy Comment
                                                            </button>
                                                        </ng-template>
                                                    </div>
                                                </div>

                                                <div class="message-header">
                                                    {{reply.username}}
                                                </div>

                                                <div *ngIf="reply.edit">
                                                    <div class="edit-input-box mb-1">

                                                        <div [id]="'editor-container'" class="edit-textarea">

                                                        </div>
                                                    </div>
                                                    <div style="display: flex; justify-content: flex-end;">
                                                        <button mat-icon-button (click)="closeEditComment(i, rindex)"
                                                            [disabled]="editLoading">
                                                            <mat-icon>close</mat-icon>
                                                        </button>
                                                        <button *ngIf="!editLoading" mat-icon-button
                                                            (click)="submitEditComment(reply)">
                                                            <mat-icon>check</mat-icon>
                                                        </button>
                                                        <div style="display: flex; align-items: center;">
                                                            <span *ngIf="editLoading"
                                                                class="spinner-border spinner-border-sm button-spinner"
                                                                role="status" aria-hidden="true"
                                                                style="color: var(--main-color) !important;"></span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <span *ngIf="!reply.edit" class="message-note"
                                                    [innerHTML]="reply.comment | replaceEnterForChat:(reply.userid == userid):i"
                                                    (click)="clickcmt($event)"></span>

                                                <div *ngIf="reply.imagename != '' && !reply.edit">
                                                    <img class="comment-image"
                                                        [defaultImage]="'../../../assets/images/bg.jpg'"
                                                        [lazyLoad]="reply.imagename" [alt]="appname"
                                                        (click)="imageViewer(reply.imagename  ,i)">
                                                </div>
                                                <div class="react-box" [attr.data-is-edit]="reply.edit ? '1': '0'"
                                                    *ngIf="reply.totalreact.length > 0">
                                                    <div class="reacts"
                                                        (click)="CommentReactForm(post.syskey,reply.syskey, 'commentreacts', '')">
                                                        
                                                        <div *ngFor="let react of reply.totalreact;let index=index;">
                                                            <div *ngIf="react.id == '4' && 2>=index"
                                                                class="react-image">
                                                                <img style="margin-bottom:2px;"
                                                                    src="../../../assets/images/like.svg" width="15">
                                                            </div>
                                                            <div *ngIf="react.id == '002' && 2>=index"
                                                                class="react-image">
                                                                <img style="margin-bottom:2px;"
                                                                    src="../../../assets/images/love.svg" width="15">
                                                            </div>
                                                            <div *ngIf="react.id == '003' && 2>=index"
                                                                class="react-image">
                                                                <img style="margin-bottom:2px;"
                                                                    src="../../../assets/images/haha.svg" width="15">
                                                            </div>
                                                            <div *ngIf="react.id == '004' && 2>=index"
                                                                class="react-image">
                                                                <img style="margin-bottom:2px;"
                                                                    src="../../../assets/images/sad.svg" width="15">
                                                            </div>
                                                            <div *ngIf="react.id == '005' && 2>=index"
                                                                class="react-image">
                                                                <img style="margin-bottom:2px;"
                                                                    src="../../../assets/images/angry.svg" width="15">
                                                            </div>

                                                        </div>
                                                        <div class="px-2 d-flex reactcount"
                                                            style="word-break: initial;">
                                                            {{getTotalReactCount(reply)}}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="comment-time">
                                                <span class="like-cmt me-2">
                                                    <span *ngIf="!reply.userreact"
                                                        (click)="reactReplyComment('4', reply.syskey, i, rindex)">Like</span>
                                                    <span *ngIf="reply.userreact" style="color: var(--main-color);"
                                                        (click)="reactReplyComment(reply.userreact, reply.syskey, i, rindex)">Like</span>
                                                    <div class="cmt-react-list reply-react-list" [id]="'reactbox'+i">
                                                        <div class="react d-flex flex-column"
                                                            (click)="reactReplyComment('4', reply.syskey, i, rindex)">
                                                            <div>
                                                                <img style="margin-bottom:2px;"
                                                                    src="../../../assets/images/like.svg" width="25">
                                                            </div>
                                                            <div *ngIf="reply.userreact == '4'" class="active">
                                                                <i class="fas fa-circle"></i>
                                                            </div>
                                                        </div>
                                                        <div class="react"
                                                            (click)="reactReplyComment('002', reply.syskey, i, rindex)">
                                                            <div>
                                                                <img style="margin-bottom:2px;"
                                                                    src="../../../assets/images/love.svg" width="25">
                                                            </div>
                                                            <div *ngIf="reply.userreact == '002'" class="active">
                                                                <i class="fas fa-circle"></i>
                                                            </div>
                                                        </div>
                                                        <div class="react"
                                                            (click)="reactReplyComment('003', reply.syskey, i, rindex)">
                                                            <div>
                                                                <img style="margin-bottom:2px;"
                                                                    src="../../../assets/images/haha.svg" width="25">
                                                            </div>
                                                            <div *ngIf="reply.userreact == '003'" class="active">
                                                                <i class="fas fa-circle"></i>
                                                            </div>
                                                        </div>
                                                        <div class="react"
                                                            (click)="reactReplyComment('004', reply.syskey, i, rindex)">
                                                            <div>
                                                                <img style="margin-bottom:2px;"
                                                                    src="../../../assets/images/sad.svg" width="25">
                                                            </div>
                                                            <div *ngIf="reply.userreact == '004'" class="active">
                                                                <i class="fas fa-circle"></i>
                                                            </div>
                                                        </div>
                                                        <div class="react"
                                                            (click)="reactReplyComment('005', reply.syskey, i, rindex)">
                                                            <div>
                                                                <img style="margin-bottom:2px;"
                                                                    src="../../../assets/images/angry.svg" width="25">
                                                            </div>
                                                            <div *ngIf="reply.userreact == '005'" class="active">
                                                                <i class="fas fa-circle"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                                
                                                <span
                                                    [title]="allinoneService.formatDBToShow(reply.date) +' '+ reply.time">{{(allinoneService.formatDBToShowInEdit(reply.date,
                                                    '/')
                                                    +' '+ reply.time) | timeAgo}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="comment.viewmorecomments && !comment.gettingmorereply">
                                <div class="cmt-reply-box align-items-center d-flex">
                                    <div class="reply-line me-2" style="margin-top: -16px !important;">
                                    </div>
                                    <div class="viewmore" (click)="getMoreReply(comment.syskey, i)">
                                        View more replies
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="comment.gettingreply || comment.gettingmorereply">
                        <div class="cmt-reply-box d-flex align-items-center">
                            <div class="reply-line me-2" style="margin-top: -14px !important;">

                            </div>
                            <span>
                                <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ 
                                'height.px': 30,
                                'width.px' : 30,
                                'margin-left' : 0
                            }"></ngx-skeleton-loader>
                            </span>
                            <div style="display: flex;
                        align-items: center;
                        margin-left: 10px;">
                                <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                                'height.px': 30,
                                'width.px' : 250,
                                'margin-bottom' : 0
                            }"></ngx-skeleton-loader>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="!this.post.imagelist[currentIndex].gettingMoreComments && this.post.imagelist[currentIndex].viewmorecomments" (click)="getMoreComments()"
                    class="my-3 ms-2 viewmore">
                    View more comments
                </div>
            </div>
            <div *ngIf="this.post.imagelist[currentIndex].gettingMoreComments || this.post.imagelist[currentIndex].gettingComments">
                <div *ngFor="let i of [1,2,3]" class="message-container">
                    <span>
                        <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ 
                            'height.px': 40,
                            'width.px' : 40,
                            'margin-left' : 0
                        }"></ngx-skeleton-loader>
                    </span>
                    <div style="display: flex;
                    align-items: center;
                    margin-left: 10px;">
                        <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                            'height.px': 40,
                            'width.px' : 250,
                            'margin-bottom' : 0
                        }"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>

        </perfect-scrollbar>
    </div>
</div>
<div class="modal" id="comment-image-viewer" aria-hidden="true" aria-labelledby="imageviewerLabel" tabindex="-1">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-more-container">
                    <button type="button" class="btn-icon" data-bs-dismiss="modal" aria-label="Close"
                        (click)="downloadImage()">
                        <mat-icon>download</mat-icon>
                    </button>
                    <button type="button" class="btn-icon" data-bs-dismiss="modal" aria-label="Close">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
            <div class="modal-body">
                <div>
                    <img [src]="imagename" alt="" class="image-view">
                </div>
            </div>
        </div>
    </div>
</div>