<!-- <perfect-scrollbar [ngClass]="{'event-container':  checkModeal}" [config]="config" [scrollIndicators]="true"
    fxFlex="auto" #perfectScroll> -->

<div class="container-fluid">
    <div class="content">


        <div>
            <app-crm-breadcrumb s_breadcrumb="Service Overage Report">
            </app-crm-breadcrumb>



            <div class="d-flex flex-wrap mb-3">
                <!-- <div class="mb-3 me-2 " [matMenuTriggerFor]="productmenu">
                        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" aria-expanded="false"
                            mdbDropdownToggle
                            style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                            (click)="removeProduct()">
                            {{productname != 'product' ? productname : 'Product' }}
                        </button>
                    </div>
    
                    <mat-menu #productmenu="matMenu" xPosition="before">
                        <div class="input-group cl-project-new me-2 mb-3" (click)="$event.stopPropagation();">
                            <input class="clas-project" type="text" placeholder="Search" [(ngModel)]="productsearch"
                                style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;"
                                autocomplete="off">
                        </div>
                        <button class="mat-item" (click)="changeproduct('product')">-</button>
                        <button class="mat-item"
                            *ngFor="let proj of productListdata | crmFilter : productsearch:text1:text2:15;"
                            (click)="changeproduct(proj)">{{proj.name}}</button>
                    </mat-menu> -->

                <!-- <div class="date me-2">
                        <input type="date" class="form-control" id="startdate" name="startdate" [(ngModel)]="startdate">
                    </div>
                    <div class="date me-2">
                        <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="enddate">
                    </div> -->
                <div class="me-2">
                    <button class="btn btn-custom" (click)="refreshgetData()" matTooltip="Search">
                        Search
                    </button>
                </div>
                <div class="">
                    <button type="button" class="btn btn-custom" (click)="downloadSheet()"
                        matTooltip="Export Service Overage Report">
                        <img class="excel-icon" src="../../../assets/images/excel.png">Export
                    </button>

                </div>
            </div>




            <div>
                <div class="card card-body my-card" *ngIf="gettingData" style="flex-direction: column;">
                    <div class="d-flex justify-content-center align-items-center" style="height: 300px;">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
                <div class="card card-body my-card">

                    <div class="table-responsive" *ngIf="!gettingData">
                        <table class="table table-responsive table-borderless">
                            <thead class="table-header">
                                <tr>
                                    <td scope="col"  *ngFor="let item of columnlist">
                                        <div style=" 
                                        text-align: center;">{{item.name}}</div>
                                        <div *ngIf="item.name != ''" style="    font-size: 10px;
                                        text-align: center;">(Over {{item.overage}} ages)</div>
                                    </td>

                                </tr>
                            </thead>
                            <tbody *ngIf="gettingData">
                                <!-- <tr>
                                    <td colspan="9" class="nodata">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </td>
                                </tr> -->
                            </tbody>
                            <tbody *ngIf="gettingData== false">
                                <tr *ngIf="rowlist.length == 0">
                                    <td class="nodata">Empty</td>
                                </tr>
                                <tr *ngFor="let item of rowlist">
                                    <td  [ngClass]="{'p-name' : item.productname == 'Total' }">
                                        <span>{{item.productname}}</span>
                                    </td>
                                    <td id="td-data" *ngFor="let month of item.countlist " style="text-align:center;"
                                    [ngClass]="{'p-name' : item.productname == 'Total' }"
                                        (click)="Open(month,item.productid)">
                                        <span style="color:var(--main-color)">{{month.count}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>



        </div>






        <!-- <div *ngIf="summaryCheck"> -->
        <!-- (summary)="summaryBack($event)" -->
        <!-- (summary)="summaryBack($event)" -->
        <!-- <app-crm-summary [checkOrg]="checkOrgB" [orgid]="crmForm.orgid" (crmcancel)="cancel()" (statussummary)="summaryBackStatus($event)">
                        </app-crm-summary> -->
        <!-- app-crm-summary -->
        <!-- </div> -->
    </div>


</div>
<!-- </perfect-scrollbar> -->