import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import {
  HttpErrorResponse,
  HttpHeaders,
  HttpClient,
} from '@angular/common/http';
import { catchError, map, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MessageService } from './message.service';
import { AllInOneService } from './all-in-one.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class KunyekService {
  domain = environment.domain + 'auth/';
  samDomain = environment.domain + 'auth/';
  samDomainv2 = environment.domain + 'auth2/';
  kunyekDomain = environment.kunyekDomain;
  crmDomain = environment.crmsamdomain + 'crmv2/';
  crmnodeDomain = environment.crmsamdomain + 'crmnodev2/';

  constructor(
    private cookieService: CookieService,
    private messageService: MessageService,
    private allinoneService: AllInOneService,
    private http: HttpClient,
  ) { }

  private handleError = (error: HttpErrorResponse) => {
    console.error(error);
    if (error.status == 0) {
      if (this.allinoneService.isonline) {
        this.messageService.openSnackBar('Request Time Out', 'warn');
      } else {
        this.messageService.openSnackBar('Network Error', 'warn');
      }
    } else {
      if (error.status.toString().startsWith('5')) {
        this.messageService.openSnackBar('Server Error', 'warn');
      } else if (error.status.toString().startsWith('4')) {
        if (
          error.status.toString() == '401' ||
          error.status.toString() == '403'
        ) {
          this.messageService.openSnackBar('Unauthorized Access.', 'warn');
          let promise = new Promise(async (resolve) => {
            const awaitCheckToken = await this.checkTokenFailedHttpRequest();
            resolve('');
          });
        } else {
          this.messageService.openSnackBar('Client Error', 'warn');
        }
      }
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`,
      );
    }

    return throwError('Something bad happened; please try again later.');
  };

  httpRequestForLogin(url: string, param: any) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html,application/json',
        'Content-Type': 'application/json',
      }),
    };
    const appid = this.allinoneService.getAppId();
    const body = Object.assign(param, {
      appid: appid,
    });

    return this.http
      .post(url, body, options)
      .pipe(catchError(this.handleError));
  }

  httpRequestAttendanceQR(url: string, param: any) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html,application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${param.atoken}`,
      }),
    };
    const appid = this.allinoneService.getAppId();
    const body = Object.assign(param, {
      appid: appid,
    });

    return this.http
      .post(url, body, options)
      .pipe(catchError(this.handleError));
  }

  httpRequest(url: string, param: any) {
    const appid = this.allinoneService.getAppId();
    var userid = this.allinoneService.getKunyekUserId();
    const atoken = this.allinoneService.getAtoken();
    const domainid = this.allinoneService.getDomain().domainid;
    const domain = this.allinoneService.getDomain().shortcode;

    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html,application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${atoken}`,
      }),
    };
    const body = Object.assign(param, {
      appid: appid,
      userid: userid,
      atoken: atoken,
      domainid: domainid,
      domain: domain,
    });

    return this.http.post(url, body, options).pipe(
      retry(2),
      map((res: any) => {
        if (res.returncode != '300') {
          let promise = new Promise(async (resolve) => {
            const awaitCheckToken = await this.checkTokenFailedHttpRequest();
            resolve('');
          });
        }
        return res;
      }),
      catchError(this.handleError),
    );
  }

  httpRequestForAbsentReport(url: string, param: any) {
    const appid = this.allinoneService.getAppId();
    var userid = this.allinoneService.getKunyekUserId();
    const atoken = this.allinoneService.getAtoken();
    const domainid = this.allinoneService.getDomain().domainid;
    // const domain = this.allinoneService.getDomain().shortcode;

    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html,application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${atoken}`,
      }),
    };
    const body = Object.assign(param, {
      appid: appid,
      userid: userid,
      atoken: atoken,
      domainid: domainid,
    });

    return this.http.post(url, body, options).pipe(
      retry(2),
      map((res: any) => {
        if (res.returncode != '300') {
          let promise = new Promise(async (resolve) => {
            const awaitCheckToken = await this.checkTokenFailedHttpRequest();
            resolve('');
          });
        }
        return res;
      }),
      catchError(this.handleError),
    );
  }

  httpRequestForVoting(url: string, param: any) {
    var userid = this.allinoneService.getVotingUserid();
    const atoken = this.allinoneService.getVotingToken();

    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html,application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${atoken}`,
      }),
    };

    const body = Object.assign(param, {
      userid: userid,
      atoken: atoken,
    });

    return this.http
      .post(url, body, options)
      .pipe(catchError(this.handleError));
  }

  httpRequestWithInterval(url: string, param: any) {
    const appid = this.allinoneService.getAppId();
    var userid = this.allinoneService.getKunyekUserId();
    const atoken = this.allinoneService.getAtoken();

    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html,application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${atoken}`,
      }),
    };

    const body = Object.assign(param, {
      appid: appid,
      userid: userid,
      atoken: atoken,
    });

    return this.http.post(url, body, options).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  exchangeToken(data: any) {
    const url = this.samDomain + 'exchangetoken';
    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html,application/json',
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(url, data, options).pipe(
      retry(2),
      map((res: any) => {
        if (res.returncode == '200') {
          let promise = new Promise(async (resolve) => {
            const awaitCheckToken = await this.checkTokenFailedHttpRequest();
            resolve('');
          });
        }
        return res;
      }),
      catchError(this.handleError),
    );
  }

  renewToken() {
    const url = this.samDomain + 'renewtoken';
    const param = {};
    return this.httpRequest(url, param);
  }

  checkTokenFailedHttpRequest() {
    return new Promise((resolve) => {
      const atoken = this.allinoneService.getAtoken() || '';
      const appid = this.allinoneService.getAppId() || '';
      const kunyek_userid = this.allinoneService.getKunyekUserId() || '';
      if (atoken == '' || appid == '' || kunyek_userid == '') {
        this.allinoneService.logout();
        resolve('error');
      } else {
        this.checkToken().subscribe(
          (res: any) => {
            if (res.returncode == '1' || res.returncode == '3') {
              if (atoken == '' || appid == '' || kunyek_userid == '') {
                resolve('error');
              }
              // this.allinoneService.logout();
              this.renewToken().subscribe((res: any) => {
                if (res.returncode == '300') {
                  this.allinoneService.setCookie('atoken', res.atoken);
                }
              });
            } else {
            }
            resolve('checkedToken');
          },
          (err) => {
            resolve('error');
          },
        );
      }
    });
  }

  httpRequestWithoutUserid(url: string, param: any) {
    const appid = this.allinoneService.getAppId();
    const atoken = this.allinoneService.getAtoken();

    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html,application/json',
        'Content-Type': 'application/json',
        // 'Authorization' : `Bearer ${atoken}`
      }),
    };

    const body = Object.assign(param, {
      appid: appid,
      atoken: atoken,
    });

    return this.http.post(url, body, options).pipe(
      map((res: any) => {
        if (res.returncode == '200') {
          let promise = new Promise(async (resolve) => {
            const awaitCheckToken = await this.checkTokenFailedHttpRequest();
            resolve('');
          });
        }
        return res;
      }),
      catchError(this.handleError),
    );
  }

  /** User */
  signin(userid: string) {
    const appid = this.allinoneService.getAppId();

    const url = this.samDomain + 'signin';
    const param = {
      userid: userid,
      password: '',
      uuid: '',
      recaptcha: '',
      appid: appid,
      fcm: '',
    };

    return this.httpRequestForLogin(url, param);
  }

  verify(otp: string) {
    var userid = this.allinoneService.getUserId();
    const session = this.allinoneService.getSession();
    const appid = this.allinoneService.getAppId();
    const kunyekusername = sessionStorage.getItem('kunyekusername') || '';
    const rememberme = sessionStorage.getItem('rememberme') || 'FALSE';

    const url = this.samDomain + 'verifyuser';
    const param = {
      userid: userid,
      username: kunyekusername,
      uuid: '',
      otp: otp,
      session: session,
      appid: appid,
      rememberme: rememberme,
    };

    return this.httpRequestForLogin(url, param);
  }
  checkToken() {
    const url = this.samDomain + 'checktoken';
    const atoken = this.allinoneService.getAtoken();
    const appid = this.allinoneService.getAppId();
    const kunyek_userid = this.allinoneService.getKunyekUserId();

    const param = {
      userid: kunyek_userid,
      appid: appid,
      atoken: atoken,
    };

    // {
    //   "returncode": "3" // 0 = valid, 1 = invalid, 3 = error
    //  }

    return this.httpRequestForLogin(url, param);
  }

  getUser() {
    const url = this.kunyekDomain + 'user/signin';
    const param = {
      username: '',
      imagename: '',
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
      domaintype: 1,
      uuid: '',
      fcmtoken: '',
    };

    return this.httpRequest(url, param);
  }

  getPolicy(domain?: string, type?: string) {
    // const url = this.samDomain + 'getpolicy';
    const url = this.domain + 'test';
    // const url = this.domain + 'test2';
    const param = {
      orgid: this.allinoneService.selectedOrg,
      domain: domain ? domain : '',
      type: 0,
      attendance: this.allinoneService.isAttendanceView,
      config: this.allinoneService.appView,
    };

    return this.httpRequest(url, param);
  }

  //counts
  getDomainCounts(data: any) {
    const url = this.samDomain + 'getdomaincounts';
    return this.httpRequest(url, data);
  }

  getOrgCounts(data: any) {
    const url = this.samDomain + 'getorganizationcounts';
    return this.httpRequest(url, data);
  }

  //LOGIN
  requestQR(requesturl: any) {
    const url = this.samDomain + 'requestqr';
    const param = {
      // "requesturl": requesturl,
      appid: 'kunyek',
      type: '0',
    };
    return this.httpRequestForLogin(url, param);
  }

  //New API
  checkQR(requestqr: any) {
    const url = this.samDomain + 'checkqr';
    const param = {
      qr: requestqr,
      // "appid": "kunyek"
    };
    return this.httpRequestForLogin(url, param);
  }

  // Domains
  getDomains() {
    const url = this.samDomain + 'getalldomain';
    const param = {};
    return this.httpRequest(url, param);
  }

  getDomain() {
    const url = this.samDomain + 'getdomain';
    return this.httpRequest(url, {});
  }

  checkDomain(domainid: string) {
    const url = this.samDomain + 'checkdomain';
    // const url = this.samDomain + 'test3';

    const param = {
      domainid: domainid,
      appview: this.allinoneService.appView,
      // "type": this.allinoneService.getRole(),
    };

    return this.httpRequest(url, param);
  }

  // Organizations
  getOrgByDomainId(domainid: any) {
    const url = this.samDomain + 'getorganization';
    const param = {
      domainid: domainid,
    };
    return this.httpRequest(url, param);
  }

  addOrganization(data: any) {
    const url = this.samDomain + 'addorganization';
    return this.httpRequest(url, data);
  }

  updateOrganization(data: any) {
    const url = this.samDomain + 'updateorganization';
    return this.httpRequest(url, data);
  }

  /** Locations */
  getLocations(data: any) {
    const url = this.samDomain + 'getlocation';
    return this.httpRequest(url, data);
  }

  getPersonalLocations(data: any) {
    const url = this.samDomainv2 + 'getpersonallocation';
    return this.httpRequest(url, data);
  }

  getLocationQR(data: any) {
    const url = this.kunyekDomain + 'adminlocationqr/get ';
    return this.httpRequest(url, data);
  }

  //Check Get Location
  checkLocationQR(requestqr: any) {
    const url = this.kunyekDomain + 'adminlocationqr/validate';
    const param = {
      qrdata: requestqr,
    };
    return this.httpRequestForLogin(url, param);
  }

  addLocation(data: any) {
    const url = this.samDomain + 'addlocation';
    return this.httpRequest(url, data);
  }

  updateLocation(data: any) {
    const url = this.samDomain + 'updatelocation';

    return this.httpRequest(url, data);
  }

  deleteLocation(data: any) {
    const url = this.samDomain + 'deletelocation';
    return this.httpRequest(url, data);
  }

  // Members

  syncMembers(data: any) {
    const url = this.samDomain + 'tagemail';
    return this.httpRequest(url, data);
  }

  getMembers(data: any) {
    const url = this.samDomain + 'getmember';
    return this.httpRequest(url, data);
  }

  getMemberProfileImage(data: any) {
    const url = this.samDomainv2 + 'getmemberprofile';
    return this.httpRequest(url, data);
  }

  getHierarchyMembers(data: any) {
    const url = this.samDomain + 'gethierarchymember';
    return this.httpRequest(url, data);
  }

  addMember(data: any) {
    const url = this.samDomain + 'addmember';
    return this.httpRequest(url, data);
  }

  updateMember(data: any) {
    const url = this.samDomain + 'updatemember';
    return this.httpRequest(url, data);
  }

  checkMember(data: any) {
    const url = this.samDomain + 'checkmember';
    return this.httpRequest(url, data);
  }

  removeMember(data: any) {
    const url = this.samDomain + 'removemember';
    return this.httpRequest(url, data);
  }

  checkEmployeeidList(data: any) {
    const url = this.samDomain + 'checkemployeeidlist';
    return this.httpRequest(url, data);
  }

  //Classcode
  createClasscode(data: any) {
    const url = this.samDomain + 'addclasscode';
    return this.httpRequest(url, data);
  }

  updateClasscode(data: any) {
    const url = this.samDomain + 'updateclasscode';
    return this.httpRequest(url, data);
  }

  getClassCodeList(data: any) {
    const url = this.samDomain + 'getclasscode';
    return this.httpRequest(url, data);
  }

  getMemberPosition(data: any) {
    const url = this.samDomain + 'getmemberposition';
    return this.httpRequest(url, data);
  }

  getMemberTypes(data: any) {
    const url = this.samDomain + 'getmembertype';
    return this.httpRequest(url, data);
  }

  getNrcCode(data: any) {
    const url = this.samDomainv2 + 'getnrccode';
    return this.httpRequest(url, data);
  }

  createMemberType(data: any) {
    const url = this.samDomain + 'addmembertype';
    return this.httpRequest(url, data);
  }

  updateMemberTypes(data: any) {
    const url = this.samDomain + 'updatemembertype';
    return this.httpRequest(url, data);
  }

  getMemberHistory(data: any) {
    const url = this.samDomain + 'getmemberhistory';
    return this.httpRequest(url, data);
  }

  addMemberHistory(data: any) {
    const url = this.samDomain + 'addmemberhistory';
    return this.httpRequest(url, data);
  }

  updateMemberHistory(data: any) {
    const url = this.samDomain + 'updatememberhistory';
    return this.httpRequest(url, data);
  }

  deleteMemberHistory(data: any) {
    const url = this.samDomain + 'deletememberhistory';
    return this.httpRequest(url, data);
  }

  pcSyncData(data: any) {
    const url = this.samDomain + 'updatehcmpositioncontrol';
    return this.httpRequest(url, data);
  }

  memberInfoSyncData(data: any) {
    const url = this.samDomainv2 + 'updatehcmemployeeinfo';
    return this.httpRequest(url, data);
  }

  // Events
  createEvent(data: any) {
    const url = this.samDomain + 'createevent';
    return this.httpRequest(url, data);
  }

  getEvents(data: any) {
    const url = this.samDomain + 'getevent';
    return this.httpRequest(url, data);
  }

  updateEvent(data: any) {
    const url = this.samDomain + 'updateevent';
    return this.httpRequest(url, data);
  }

  // QR
  getQrString(data: any) {
    const url = this.samDomain + 'getshowqr';
    return this.httpRequest(url, data);
  }

  getAttendance(data: any) {
    const url = this.samDomain + 'getattendance';
    return this.httpRequest(url, data);
  }

  // attendanceqr
  getAttendanceQr(data: any) {
    const url = this.samDomain + 'getattendanceqr';
    return this.httpRequest(url, data);
  }

  getAttendanceQrDetails(data: any) {
    const url = this.samDomain + 'getattendanceqrdetails';
    return this.httpRequestAttendanceQR(url, data);
  }

  // get scaned user in checkin attendance
  getScanedUser(data: any) {
    const url = 'https://api1.ky.omnicloudapi.com/socket/attendance/get';
    return this.httpRequest(url, data);
  }

  createAttendanceQr(data: any) {
    const url = this.samDomain + 'addattendanceqr';
    return this.httpRequest(url, data);
  }

  updateAttendanceQr(data: any) {
    const url = this.samDomain + 'updateattendanceqr';
    return this.httpRequest(url, data);
  }

  deleteAttendanceQr(data: any) {
    const url = this.samDomain + 'deleteattendanceqr';
    return this.httpRequest(url, data);
  }

  //Contacts tracing
  getContactstracing(data: any) {
    const url = this.samDomain + 'getcontactstracing';

    return this.httpRequest(url, data);
  }

  // Channel
  getChannels(data: any) {
    const url = this.kunyekDomain + 'channel/getbyorg';
    return this.httpRequest(url, data);
  }

  getChannelMembers(data: any) {
    const url = this.kunyekDomain + 'channel/getuser';
    return this.httpRequest(url, data);
  }

  //Messages

  connectChatSocket(data: any) {
    const url = this.kunyekDomain + 'socket/chat/connect';
    return this.httpRequest(url, data);
  }

  getChatList(data: any, isWithInterval?: boolean) {
    const url = this.kunyekDomain + 'message/get';
    if (isWithInterval) {
      return this.httpRequestWithInterval(url, data);
    }
    return this.httpRequest(url, data);
  }

  getChat(data: any, isWithInterval?: boolean) {
    const url = this.kunyekDomain + 'message/get';
    if (isWithInterval) {
      return this.httpRequestWithInterval(url, data);
    }
    return this.httpRequest(url, data);
  }

  getContactList(data: any) {
    const url = this.kunyekDomain + 'contact/get';
    return this.httpRequest(url, data);
  }

  addContact(data: any) {
    const url = this.kunyekDomain + 'contact/add';
    return this.httpRequest(url, data);
  }

  likeMessage(data: any) {
    const url = this.kunyekDomain + 'message/like';
    return this.httpRequest(url, data);
  }

  editMessage(data: any) {
    const url = this.kunyekDomain + 'message/edit';
    return this.httpRequest(url, data);
  }

  deleteMessage(data: any) {
    const url = this.kunyekDomain + 'message/delete';
    return this.httpRequest(url, data);
  }

  //Group
  createGroup(data: any) {
    const url = this.kunyekDomain + 'channel/create';
    return this.httpRequest(url, data);
  }

  addGroupMembers(data: any) {
    const url = this.kunyekDomain + 'channel/update';
    return this.httpRequest(url, data);
  }

  getGroupInfo(data: any) {
    const url = this.kunyekDomain + 'channel/getinfo';
    return this.httpRequest(url, data);
  }

  updateGroupInfo(data: any) {
    const url = this.kunyekDomain + 'channel/update';
    return this.httpRequest(url, data);
  }

  leaveGroup(data: any) {
    const url = this.kunyekDomain + 'channel/leave';
    return this.httpRequest(url, data);
  }

  deleteChat(data: any) {
    const url = this.kunyekDomain + 'channel/delete';
    return this.httpRequest(url, data);
  }

  makeAdmin(data: any) {
    const url = this.kunyekDomain + 'channel/update';
    return this.httpRequest(url, data);
  }

  //send
  sendMessage(data: any) {
    const url = this.kunyekDomain + 'message/sent';
    return this.httpRequest(url, data);
  }

  ///Check In
  shareCheckin(data: any) {
    const url = this.kunyekDomain + 'message/sent';
    return this.httpRequest(url, data);
  }

  getAdminCheckInList(data: any) {
    const url = this.samDomain + 'gettimeinout';
    return this.httpRequest(url, data);
  }

  getAdminCheckInListBySocket(data: any) {
    const url = this.samDomainv2 + 'gettimeinoutbyuser';
    return this.httpRequest(url, data);
  }

  getTicketForActivity(data: any) {
    const url = this.crmnodeDomain + 'service/getticketsforactivity';
    return this.httpRequest(url, data);
  }

  getticketdetails(data: any) {
    const url = this.crmnodeDomain + 'service/getticketdetailforactivity';
    return this.httpRequest(url, data);
  }

  addcrmProject(data: any) {
    const url = this.crmnodeDomain + 'service/customerproject';
    return this.httpRequest(url, data);
  }

  getCheckIn(data: any) {
    const url = this.kunyekDomain + 'timeinout/get';
    return this.httpRequest(url, data);
  }

  getCheckInList(data: any) {
    const url = this.kunyekDomain + 'timeinout/getlist';
    return this.httpRequest(url, data);
  }

  deleteCheckin(data: any) {
    const url = this.kunyekDomain + 'timeinout/delete';
    return this.httpRequest(url, data);
  }

  getType(data: any) {
    const url = this.kunyekDomain + 'type';
    return this.httpRequest(url, data);
  }
  addCheckIn(data: any) {
    const url = this.kunyekDomain + 'timeinout/addlist';
    return this.httpRequest(url, data);
  }
  updateCheckIn(data: any) {
    const url = this.kunyekDomain + 'timeinout/update';
    return this.httpRequest(url, data);
  }

  //Check in Attendances

  addCheckinImport(data: any) {
    const url = this.samDomain + 'checkcheckinimport';
    return this.httpRequest(url, data);
  }

  updateCheckinImport(data: any) {
    const url = this.samDomain + 'updateimportstatus';
    return this.httpRequest(url, data);
  }

  getCheckInSummary(data: any) {
    const url = this.samDomain + 'getcheckinsummary';
    return this.httpRequest(url, data);
  }

  getSummaryDetails(data: any) {
    const url = this.samDomain + 'getsummarydetails';
    return this.httpRequest(url, data);
  }

  getSummaryWithDateRange(data: any) {
    // ***
    const url = this.samDomain + 'getsummarywithdaterange';
    return this.httpRequest(url, data);
  }

  getProjectAndJobSummary(data: any) {
    const url = this.samDomain + 'getprojectandjobsummary';
    return this.httpRequest(url, data);
  }

  getSummaryWithLocation(data: any) {
    const url = this.samDomain + 'getlocationreport';
    return this.httpRequest(url, data);
  }

  //Domains
  checkUser(data: any) {
    const url = this.samDomain + 'checkuser';
    return this.httpRequest(url, data);
  }

  createDomain(data: any) {
    const url = this.samDomain + 'createdomain';
    return this.httpRequest(url, data);
  }

  updateDomain(data: any) {
    const url = this.samDomain + 'updatedomain';
    return this.httpRequest(url, data);
  }

  //Root Admins
  getAllRootAdmins() {
    const url = this.samDomain + 'getallrootadmins';
    const data = {};
    return this.httpRequest(url, data);
  }

  addRootAdmin(id: any, userid: string) {
    const url = this.samDomain + 'addrootadmin';
    const data = {
      id: id,
      adduser: userid,
    };
    return this.httpRequest(url, data);
  }

  removeRootAdmin(id: string, userid: string) {
    const url = this.samDomain + 'removerootadmin';
    const data = {
      id: id,
      adduser: userid,
    };
    return this.httpRequest(url, data);
  }

  //admins
  getalldomainmembers(domainid: string) {
    const url = this.samDomain + 'getalldomainmembers';
    const data = {
      domainid: domainid,
    };

    return this.httpRequest(url, data);
  }

  addDomainAdmin(data: any) {
    const url = this.samDomain + 'adddomainadmin';
    return this.httpRequest(url, data);
  }

  removeDomainAdmin(id: string, userid: string) {
    const url = this.samDomain + 'removedomainadmin';
    const data = {
      id: id,
      adduser: userid,
    };
    return this.httpRequest(url, data);
  }

  // App Restrictions
  getAppRestrictions() {
    const url = this.samDomain + 'getapprestrict';
    return this.httpRequest(url, {});
  }

  addAppRestriction(data: any) {
    const url = this.samDomain + 'addapprestrict';
    return this.httpRequest(url, data);
  }

  updateAppRestriction(data: any) {
    const url = this.samDomain + 'updateapprestrict';
    return this.httpRequest(url, data);
  }

  deleteAppRestriction(data: any) {
    const url = this.samDomain + 'deleteapprestrict';
    return this.httpRequest(url, data);
  }

  //Projects
  getAllProjects(data: any) {
    const url = this.samDomain + 'getallprojects';
    return this.httpRequest(url, data);
  }

  //Projects
  getContributorProjects(data: any) {
    const url = this.samDomain + 'getprojectbycontributor';
    return this.httpRequest(url, data);
  }

  addProject(data: any) {
    const url = this.samDomain + 'addproject';
    return this.httpRequest(url, data);
  }

  updateProject(data: any) {
    const url = this.samDomain + 'updateproject';
    return this.httpRequest(url, data);
  }

  getProjectStatus(data: any) {
    const url = this.samDomainv2 + 'getprojectstatus';
    return this.httpRequest(url, data);
  }

  addProjectStatus(data: any) {
    const url = this.samDomainv2 + 'addprojectstatus';
    return this.httpRequest(url, data);
  }

  updateProjectStatus(data: any) {
    const url = this.samDomainv2 + 'updateprojectstatus';
    return this.httpRequest(url, data);
  }

  getAllJobs(data: any) {
    const url = this.samDomain + 'getalljobs';
    return this.httpRequest(url, data);
  }

  addJob(data: any) {
    const url = this.samDomain + 'addjob';
    return this.httpRequest(url, data);
  }
  updateJob(data: any) {
    const url = this.samDomain + 'updatejob';
    return this.httpRequest(url, data);
  }

  //Posts
  createPost(data: any) {
    const url = this.kunyekDomain + 'post/create';
    return this.httpRequest(url, data);
  }

  editPost(data: any) {
    const url = this.kunyekDomain + 'post/edit';
    return this.httpRequest(url, data);
  }

  deletePost(data: any) {
    const url = this.kunyekDomain + 'post/delete';
    return this.httpRequest(url, data);
  }

  Report(data: any) {
    const url = this.kunyekDomain + 'post/report';
    return this.httpRequest(url, data);
  }

  getPosts(data: any) {
    const url = this.kunyekDomain + 'post/get';
    return this.httpRequest(url, data);
  }

  likePost(data: any) {
    const url = this.kunyekDomain + 'post/react';
    return this.httpRequest(url, data);
  }

  reactComment(data: any) {
    const url = this.kunyekDomain + 'post/react';
    return this.httpRequest(url, data);
  }

  commentPost(data: any) {
    const url = this.kunyekDomain + 'post/addcomment';
    return this.httpRequest(url, data);
  }

  votePost(data: any) {
    const url = this.kunyekDomain + 'post/react';
    return this.httpRequest(url, data);
  }

  getComments(data: any) {
    const url = this.kunyekDomain + 'post/getcomment';
    return this.httpRequest(url, data);
  }

  deleteComments(data: any) {
    const url = this.kunyekDomain + 'post/deletecomment';
    return this.httpRequest(url, data);
  }

  editComment(data: any) {
    const url = this.kunyekDomain + 'post/updatecomment';
    return this.httpRequest(url, data);
  }

  //User Block
  blockUser(data: any) {
    const url = this.kunyekDomain + 'user/block';
    return this.httpRequest(url, data);
  }

  unblockUser(data: any) {
    const url = this.kunyekDomain + 'user/unblock';
    return this.httpRequest(url, data);
  }

  getOrgs(data: any) {
    const url = this.kunyekDomain + 'user/getorg';
    return this.httpRequest(url, data);
  }

  getReacts(data: any) {
    const url = this.kunyekDomain + 'post/getreact';
    return this.httpRequest(url, data);
  }

  getClassCode(data: any) {
    const url = this.kunyekDomain + 'post/getclass';
    return this.httpRequest(url, data);
  }

  //Default Answers for voting
  getDefaultAnswers(data: any) {
    const url = this.kunyekDomain + 'answer/get';
    return this.httpRequest(url, data);
  }
  getTaskType(data: any) {
    const url = this.kunyekDomain + 'gettaskstype';
    return this.httpRequest(url, data);
  }
  getTaskList(data: any) {
    const url = this.kunyekDomain + 'task/get';
    return this.httpRequest(url, data);
  }

  //Hierarchy
  checkEmployeeId(data: any) {
    const url = this.samDomain + 'checkemployeeid';
    return this.httpRequest(url, data);
  }

  getHierarchy(data: any) {
    const url = this.samDomain + 'gethierarchy';
    return this.httpRequest(url, data);
  }

  getHierarchyByUser(data: any) {
    const url = this.samDomain + 'gethierarchybyuser';
    return this.httpRequest(url, data);
  }

  addHierarchy(data: any) {
    const url = this.samDomain + 'addhierarchy';
    return this.httpRequest(url, data);
  }

  changeHierarchyType(data: any) {
    const url = this.samDomain + 'changehierarchytype';
    return this.httpRequest(url, data);
  }

  editHierarchy(data: any) {
    const url = this.samDomain + 'edithierarchy';
    return this.httpRequest(url, data);
  }

  getHierarchyImportStatus(data: any) {
    const url = this.samDomain + 'getimportstatus';
    return this.httpRequest(url, data);
  }

  syncHierarchy(data: any) {
    const url = this.samDomain + 'updatehierarchyparents';
    return this.httpRequest(url, data);
  }

  addHierarchImport(data: any) {
    const url = this.samDomain + 'checkhierarchyimport';
    return this.httpRequest(url, data);
  }

  updateHierarchyImport(data: any) {
    const url = this.samDomain + 'updateimportstatus';
    return this.httpRequest(url, data);
  }

  deleteHierarchy(data: any) {
    const url = this.samDomain + 'deletehierarchy';
    return this.httpRequest(url, data);
  }

  getHierarchyType(data: any) {
    const url = this.samDomain + 'gethierarchytype';
    return this.httpRequest(url, data);
  }

  updateOrderType(data: any) {
    const url = this.samDomain + 'updateorgordertype';
    return this.httpRequest(url, data);
  }

  //Positions
  createPosition(data: any) {
    const url = this.samDomain + 'addposition';
    return this.httpRequest(url, data);
  }
  updatePosition(data: any) {
    const url = this.samDomain + 'updateposition';
    return this.httpRequest(url, data);
  }
  getPositionList(data: any) {
    const url = this.samDomain + 'getpositions';
    return this.httpRequest(url, data);
  }

  //CRM

  getCRMOrgs(data: any) {
    const url = this.crmDomain + 'getorg';
    return this.httpRequest(url, data);
  }

  getCrmLeads(data: any) {
    const url = this.crmDomain + 'getcrmlead';
    return this.httpRequest(url, data);
  }

  getCrmCustomers(data: any) {
    const url = this.crmDomain + 'getcrmcustomer';
    return this.httpRequest(url, data);
  }

  getCrmDeals(data: any) {
    const url = this.crmDomain + 'getcrmdeal';
    return this.httpRequest(url, data);
  }

  getPipelines(data: any) {
    const url = this.crmDomain + 'getcrmpipeline';
    return this.httpRequest(url, data);
  }

  getPipelineStage(data: any) {
    const url = this.crmDomain + 'deal/getstage';
    return this.httpRequest(url, data);
  }
  createCrmLead(data: any) {
    const url = this.crmDomain + 'lead/create';
    return this.httpRequest(url, data);
  }
  createCrmDeal(data: any) {
    const url = this.crmDomain + 'deal/create';
    return this.httpRequest(url, data);
  }
  editCrmDeal(data: any) {
    const url = this.crmDomain + 'deal/edit';
    return this.httpRequest(url, data);
  }
  editCrmLead(data: any) {
    const url = this.crmDomain + 'lead/edit';
    return this.httpRequest(url, data);
  }
  deleteCrmLead(data: any) {
    const url = this.crmDomain + 'lead/delete';
    return this.httpRequest(url, data);
  }
  deleteCrmCustomer(data: any) {
    const url = this.crmDomain + 'customer/delete';
    return this.httpRequest(url, data);
  }
  deleteCrmDeal(data: any) {
    const url = this.crmDomain + 'deal/delete';
    return this.httpRequest(url, data);
  }
  convertLead(data: any) {
    const url = this.crmDomain + 'lead/convert';
    return this.httpRequest(url, data);
  }
  createCrmCustomer(data: any) {
    const url = this.crmDomain + 'customer/create';
    return this.httpRequest(url, data);
  }
  editCrmCustomer(data: any) {
    const url = this.crmDomain + 'customer/edit';
    return this.httpRequest(url, data);
  }
  // CRM products
  getProducts(data: any) {
    const url = this.crmDomain + 'product/get';
    return this.httpRequest(url, data);
  }
  // CRM customer
  getCustomers(data: any) {
    const url = this.crmDomain + 'customer/get';
    return this.httpRequest(url, data);
  }
  createService(data: any) {
    const url = this.crmnodeDomain + 'service/create';
    return this.httpRequest(url, data);
  }
  editService(data: any) {
    const url = this.crmnodeDomain + 'service/edit';
    return this.httpRequest(url, data);
  }
  getService(data: any) {
    const url = this.crmnodeDomain + 'service/get';
    return this.httpRequest(url, data);
  }

  addoveragesetup(data: any) {
    const url =
      'https://ut809vr7a7.execute-api.ap-southeast-1.amazonaws.com/kunyek/crm/createserviceoverage';
    return this.httpRequest(url, data);
  }

  getoveragesetup(data: any) {
    const url = this.crmnodeDomain + 'service/get';
    return this.httpRequest(url, data);
  }

  getServiceOveragereport(data: any) {
    const url =
      'https://ut809vr7a7.execute-api.ap-southeast-1.amazonaws.com/kunyek/crm/getoveragesummary';
    return this.httpRequest(url, data);
  }

  getServiceMonth(data: any) {
    // const url = this.crmnodeDomain + 'service/getmonthlysummary';
    const url =
      'https://ut809vr7a7.execute-api.ap-southeast-1.amazonaws.com/kunyek/crm/test3';
    return this.httpRequest(url, data);
  }
  // editService(data: any) {
  //   const url = this.kunyekDomain + 'service/edit';
  //   return this.httpRequest(url, data);
  // }
  deleteService(data: any) {
    const url = this.crmnodeDomain + 'service/delete';
    return this.httpRequest(url, data);
  }
  updateMoveService(data: any) {
    const url = this.crmnodeDomain + 'service/move';
    return this.httpRequest(url, data);
  }
  getHistory(data: any) {
    const url = this.crmnodeDomain + 'service/gethistory';
    return this.httpRequest(url, data);
  }
  getSummery(data: any) {
    const url = this.crmnodeDomain + 'service/summary';
    return this.httpRequest(url, data);
  }
  createServiceType(data: any) {
    // const url = "https://ut809vr7a7.execute-api.ap-southeast-1.amazonaws.com/kunyek/service/createtype"
    const url = this.crmnodeDomain + 'service/createtype';
    return this.httpRequest(url, data);
  }
  getServiceType(data: any) {
    const url = this.crmnodeDomain + 'service/gettype';
    return this.httpRequest(url, data);
  }
  createStatusType(data: any) {
    const url = this.crmnodeDomain + 'service/createstatus';
    return this.httpRequest(url, data);
  }
  getStatusType(data: any) {
    const url = this.crmnodeDomain + 'service/getstatus';
    return this.httpRequest(url, data);
  }
  getPriority(data: any) {
    const url = this.crmnodeDomain + 'service/getpriority';
    return this.httpRequest(url, data);
  }
  createPriorityType(data: any) {
    const url = this.crmnodeDomain + 'service/createpriority';
    return this.httpRequest(url, data);
  }
  statusOnOff(data: any) {
    const url = this.crmnodeDomain + 'service/onoffstatus';
    return this.httpRequest(url, data);
  }
  serviceTypeOnOff(data: any) {
    const url = this.crmnodeDomain + 'service/onofftype';
    return this.httpRequest(url, data);
  }
  getAllCRMproject(data: any) {
    const url = this.crmnodeDomain + 'service/getallprojects';
    return this.httpRequest(url, data);
  }
  getAllCRMProducts(data: any) {
    const url = this.crmnodeDomain + 'service/getproducts';
    return this.httpRequest(url, data);
  }
  addProductCrm(data: any) {
    const url = this.crmnodeDomain + 'service/createproduct';
    return this.httpRequest(url, data);
  }
  getCrmPIC(data: any) {
    const url = this.crmnodeDomain + 'pic/get';
    return this.httpRequest(url, data);
  }
  addAdminProduct(data: any) {
    const url = this.crmnodeDomain + 'service/addproductadmin';
    return this.httpRequest(url, data);
  }
  getProductAdmin(data: any) {
    const url = this.crmnodeDomain + 'service/getproductadmins';
    return this.httpRequest(url, data);
  }
  deleteProductAdmin(data: any) {
    const url = this.crmnodeDomain + 'service/removeproductadmin';
    return this.httpRequest(url, data);
  }
  deletePic(data: any) {
    const url = this.crmnodeDomain + 'service/deletepic';
    return this.httpRequest(url, data);
  }
  deleteProduct(data: any) {
    const url = this.crmnodeDomain + 'service/deleteproduct';
    return this.httpRequest(url, data);
  }
  getServiceRecord(data: any) {
    const url = this.crmnodeDomain + 'service/getrecord';
    return this.httpRequest(url, data);
  }

  getServiceManhour(data: any) {
    const url = this.crmnodeDomain + 'service/getrecord';
    return this.httpRequest(url, data);
  }
  editmanhour(data: any) {
    // const url = this.crmnodeDomain + 'service/edit';
    const url =
      'https://ut809vr7a7.execute-api.ap-southeast-1.amazonaws.com/kunyek/crm/test2';
    return this.httpRequest(url, data);
  }
  addPerson(data: any) {
    const url = this.crmnodeDomain + 'pic/create';
    return this.httpRequest(url, data);
  }
  getCustomercare(data: any) {
    const url = this.crmnodeDomain + 'customer/getbycontact';
    return this.httpRequest(url, data);
  }
  getCrmCustomerDetail(data: any) {
    const url = this.crmnodeDomain + 'customer/getbycontact';
    return this.httpRequest(url, data);
  }

  addcrmProduct(data: any) {
    const url = this.crmnodeDomain + 'service/customerproduct';
    return this.httpRequest(url, data);
  }

  getMembersCRM(data: any) {
    const url = this.crmnodeDomain + 'getmember';
    return this.httpRequest(url, data);
  }

  exportcrmservice(data: any) {
    const url = this.crmnodeDomain + 'service/getexportstatus';
    return this.httpRequest(url, data);
  }

  getCustomerName(data: any) {
    const url = this.crmDomain + 'customer/check';
    return this.httpRequest(url, data);
  }

  getAssinProduct(data: any) {
    const url = this.crmnodeDomain + 'pic/getbyproduct';
    return this.httpRequest(url, data);
  }
  getAssinProject(data: any) {
    const url = this.crmnodeDomain + 'pic/getbyproject';
    return this.httpRequest(url, data);
  }

  addcustomergroup(data: any) {
    const url = this.crmnodeDomain + 'customer/createcustomergroup';
    return this.httpRequest(url, data);
  }
  editcustomergroup(data: any) {
    const url = this.crmnodeDomain + 'customer/updatecustomergroup';
    return this.httpRequest(url, data);
  }
  getcustomergroup(data: any) {
    const url = this.crmnodeDomain + 'customer/getcustomergroup';
    return this.httpRequest(url, data);
  }
  deleteCustomergroup(data: any) {
    const url =
      'https://ut809vr7a7.execute-api.ap-southeast-1.amazonaws.com/kunyek/crm/test2';
    return this.httpRequest(url, data);
  }
  getmanhourlist(data: any) {
    const url =
      'https://ut809vr7a7.execute-api.ap-southeast-1.amazonaws.com/kunyek/crm/getmanhour';
    return this.httpRequest(url, data);
  }

  //Voting System for Myanmar Rice Federation
  getVotingRoundByCampaignId(data: any) {
    const url = this.samDomain + 'checkcampaign';
    return this.httpRequest(url, data);
  }

  getVotingRound(data: any) {
    const url = this.samDomain + 'vote/getvotinground';
    return this.httpRequestForLogin(url, data);
  }

  voterSignin(data: any) {
    const url = this.samDomain + 'vote/votersignin';
    return this.httpRequestForLogin(url, data);
  }

  checkVoter(data: any) {
    const url = this.samDomain + 'vote/checkvoter';
    return this.httpRequestForLogin(url, data);
  }

  saveVotes(data: any) {
    const url = this.samDomain + 'vote/savevotes';
    return this.httpRequestForLogin(url, data);
  }

  getVotingRoundList(data: any) {
    const url = this.samDomain + 'vote/admin/getvotingrounds';
    return this.httpRequest(url, data);
  }

  getVotingRoundSummary(data: any) {
    const url = this.samDomain + 'vote/admin/getvotingsummary';
    return this.httpRequest(url, data);
  }

  getVotersDetails(data: any) {
    const url = this.samDomain + 'vote/admin/getvoterdetails';
    return this.httpRequest(url, data);
  }

  addVotingRound(data: any) {
    const url = this.samDomain + 'vote/admin/addvotinground';
    return this.httpRequest(url, data);
  }

  updateVotingRound(data: any) {
    const url = this.samDomain + 'vote/admin/editvotinground';
    return this.httpRequest(url, data);
  }

  getVoterList(data: any) {
    const url = this.samDomain + 'vote/admin/getvoter';
    return this.httpRequest(url, data);
  }

  setupvoterimmport(data: any) {
    const url = this.samDomain + 'vote/admin/setupvoterimport';
    return this.httpRequest(url, data);
  }

  getVoter(data: any) {
    const url = this.samDomain + 'vote/admin/getvoter';
    return this.httpRequest(url, data);
  }

  updateVoter(data: any) {
    const url = this.samDomain + 'vote/admin/updatevoter';
    return this.httpRequest(url, data);
  }

  deleteVoter(data: any) {
    const url = this.samDomain + 'vote/admin/deletevoter';
    return this.httpRequest(url, data);
  }

  voterAdminSignin(data: any) {
    const url = this.samDomain + 'vote/admin/votingadminsignin';
    return this.httpRequest(url, data);
  }

  //Campaign

  getCampaigns(data: any) {
    const url = this.samDomain + 'getcampaigns';
    return this.httpRequest(url, data);
  }

  addCampaign(data: any) {
    const url = this.samDomain + 'addcampaign';
    return this.httpRequest(url, data);
  }

  updateCampaign(data: any) {
    const url = this.samDomain + 'updatecampaign';
    return this.httpRequest(url, data);
  }

  // Notification Module
  sendNotifications(data: any) {
    const url = this.samDomain + 'sendnotifications';
    return this.httpRequest(url, data);
  }

  getNotifications(data: any) {
    const url = this.samDomain + 'getnotifications';
    return this.httpRequest(url, data);
  }

  //Link Preview
  getLinkPreviewData(data: string) {
    // const url = "http://api.linkpreview.net/?key=71de2bc169e9d6b544501358df8a19f0&q=" + data;
    const url =
      'https://graph.facebook.com/v12.0/?kunyek=true&id=' +
      data +
      '&access_token=271421224639992%7Chpulk7nPNipxXeTISTi2mHAXSk8';
    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html,application/json',
        'Content-Type': 'application/json',
      }),
    };

    return this.http
      .post(url, options)
      .pipe
      // catchError(this.handleError)
      ();
  }

  getGroupList(data: any) {
    const url = this.kunyekDomain + 'message/get';
    return this.httpRequest(url, data);
  }

  sharePost(data: any) {
    const url = this.kunyekDomain + 'message/sent';
    return this.httpRequest(url, data);
  }
  //Coupon

  updateProfile(data: any) {
    const url = this.kunyekDomain + 'user/update';
    return this.httpRequest(url, data);
  }

  //Logistics
  getLogisticsOrders(data: any) {
    const url = this.kunyekDomain + 'logistics/order/get';
    return this.httpRequest(url, data);
  }

  createLogisticsOrder(data: any) {
    const url = this.kunyekDomain + 'logistics/order/add';
    return this.httpRequest(url, data);
  }

  editLogisticsOrder(data: any) {
    const url = this.kunyekDomain + 'logistics/order/update';
    return this.httpRequest(url, data);
  }

  deleteLogisticsOrder(data: any) {
    const url = this.kunyekDomain + 'logistics/order/delete';
    return this.httpRequest(url, data);
  }

  getAllBatchs(data: any) {
    const url = this.kunyekDomain + 'logistics/batch/get';
    return this.httpRequest(url, data);
  }

  createLogisticsBatch(data: any) {
    const url = this.kunyekDomain + 'logistics/batch/add';
    return this.httpRequest(url, data);
  }

  editLogisticsBatch(data: any) {
    const url = this.kunyekDomain + 'logistics/batch/update';
    return this.httpRequest(url, data);
  }

  deleteLogisticsBatch(data: any) {
    const url = this.kunyekDomain + 'logistics/batch/delete';
    return this.httpRequest(url, data);
  }

  createLogisticsAssigment(data: any) {
    const url = this.kunyekDomain + 'logistics/assignment/add';
    return this.httpRequest(url, data);
  }

  editLogisticsAssigment(data: any) {
    const url = this.kunyekDomain + 'logistics/assignment/update';
    return this.httpRequest(url, data);
  }

  getLogisticsAssigment(data: any) {
    const url = this.kunyekDomain + 'logistics/assignment/get';
    return this.httpRequest(url, data);
  }

  deleteLogisticsAssigment(data: any) {
    const url = this.kunyekDomain + 'logistics/assignment/delete';
    return this.httpRequest(url, data);
  }

  getRoleList(data: any) {
    const url = this.samDomain + 'getrole';
    return this.httpRequest(url, data);
  }

  updateRole(data: any) {
    const url = this.samDomain + 'updaterole';
    return this.httpRequest(url, data);
  }

  addRole(data: any) {
    const url = this.samDomain + 'addrole';
    return this.httpRequest(url, data);
  }

  // Shift
  getShift(data: any) {
    const url = this.samDomain + 'getshift';
    return this.httpRequest(url, data);
  }
  createShift(data: any) {
    const url = this.samDomain + 'addshift';
    return this.httpRequest(url, data);
  }

  updateShift(data: any) {
    const url = this.samDomain + 'updateshift';
    return this.httpRequest(url, data);
  }

  deleteShift(data: any) {
    const url = this.samDomain + 'deleteshift';
    return this.httpRequest(url, data);
  }
  // Shift End

  // Roster
  getRoster(data: any) {
    const url = this.samDomain + 'getroster';
    return this.httpRequest(url, data);
  }
  createRoster(data: any) {
    const url = this.samDomain + 'addroster';
    return this.httpRequest(url, data);
  }
  updateRoster(data: any) {
    const url = this.samDomain + 'updateroster';
    return this.httpRequest(url, data);
  }
  deleteRoster(data: any) {
    const url = this.samDomain + 'deleteroster';
    return this.httpRequest(url, data);
  }
  // Roster End

  // Work Policy Start
  getWorkPolicy(data: any) {
    const url = this.samDomain + 'getuserwp';
    return this.httpRequest(url, data);
  }
  createWorkPolicy(data: any) {
    const url = this.samDomain + 'adduserwp';
    return this.httpRequest(url, data);
  }
  updateWorkPolicy(data: any) {
    const url = this.samDomain + 'updateuserwp';
    return this.httpRequest(url, data);
  }
  deleteWorkPolicy(data: any) {
    const url = this.samDomain + 'deleteuserwp';
    return this.httpRequest(url, data);
  }

  addWpImport(data: any) {
    const url = this.samDomain + 'checkuserwpimport';
    return this.httpRequest(url, data);
  }

  updateWpImport(data: any) {
    const url = this.samDomain + 'updateimportstatus';
    return this.httpRequest(url, data);
  }

  getWpImportStatus(data: any) {
    const url = this.samDomain + 'getimportstatus';
    return this.httpRequest(url, data);
  }
  // Work Policy End

  addReqType(data: any) {
    const url = this.kunyekDomain + 'office/request/type/add';
    return this.httpRequest(url, data);
  }

  updateReqType(data: any) {
    const url = this.kunyekDomain + 'office/request/type/update';
    return this.httpRequest(url, data);
  }

  getRequestType(data: any) {
    const url = this.kunyekDomain + 'office/request/type/get';
    if ('leavetype' in data) {
    } else {
      data = Object.assign(data, {
        leavetype: this.allinoneService.isAttendanceView,
      });
    }
    return this.httpRequest(url, data);
  }

  getLeavePolicy(data: any) {
    const url = this.kunyekDomain + 'office/leavepolicy/get';
    return this.httpRequest(url, data);
  }

  editLeavePolicy(data: any) {
    const url = this.kunyekDomain + 'office/leavepolicy/update';
    return this.httpRequest(url, data);
  }

  deleteRequestType(data: any) {
    const url = this.kunyekDomain + 'office/request/type/delete';
    return this.httpRequest(url, data);
  }

  getProratedMethods(data: any) {
    const url = this.kunyekDomain + 'office/proratedmethod/get';
    return this.httpRequest(url, data);
  }

  getRequestList(data: any) {
    const url = this.kunyekDomain + 'office/request/get';
    return this.httpRequest(url, data);
  }

  getRequestAll(data: any) {
    const url = this.kunyekDomain + 'office/approval/get';
    return this.httpRequest(url, data);
  }

  getCalendar(data: any) {
    const url = this.kunyekDomain + 'office/calendar/get';
    return this.httpRequest(url, data);
  }

  getTemplate(data: any) {
    const url = this.kunyekDomain + 'office/template/get';
    return this.httpRequest(url, data);
  }

  getOfficeMember(data: any) {
    const url = this.kunyekDomain + 'office/member/get';
    return this.httpRequest(url, data);
  }

  getAvailableTemplateByUser(data: any) {
    const url = this.kunyekDomain + 'office/request/type/getbyuser';
    return this.httpRequest(url, data);
  }

  getCurrency() {
    const url = this.kunyekDomain + 'office/currency/get';
    return this.httpRequest(url, {});
  }

  addLeaveEntitlement(data: any) {
    const url = this.kunyekDomain + 'office/entitlement/add';
    return this.httpRequest(url, data);
  }
  updateLeaveEntitlement(data: any) {
    const url = this.kunyekDomain + 'office/entitlement/update';
    return this.httpRequest(url, data);
  }
  getLeaveEntitlement(data: any) {
    const url = this.kunyekDomain + 'office/entitlement/get';
    return this.httpRequest(url, data);
  }

  deleteLeaveEntitlement(data: any) {
    const url = this.kunyekDomain + 'office/entitlement/delete';
    return this.httpRequest(url, data);
  }

  calculateBroughtForward(data: any) {
    const url = this.kunyekDomain + 'office/carryleave/calculate';
    return this.httpRequest(url, data);
  }

  getUserLeaveInfo(data: any) {
    const url = this.kunyekDomain + 'office/entitlement/getbyuser';
    return this.httpRequest(url, data);
  }

  createLeaveTemplate(data: any) {
    const url = this.kunyekDomain + 'office/template/add';
    return this.httpRequest(url, data);
  }

  editLeaveTemplate(data: any) {
    const url = this.kunyekDomain + 'office/template/update';
    return this.httpRequest(url, data);
  }

  createCalendar(data: any) {
    const url = this.kunyekDomain + 'office/calendar/add';
    return this.httpRequest(url, data);
  }

  editCalendar(data: any) {
    const url = this.kunyekDomain + 'office/calendar/update';
    return this.httpRequest(url, data);
  }

  getHolidayList(data: any) {
    const url = this.kunyekDomain + 'office/holiday/get';
    return this.httpRequest(url, data);
  }

  addHoliday(data: any) {
    const url = this.kunyekDomain + 'office/holiday/add';
    return this.httpRequest(url, data);
  }

  editHoliday(data: any) {
    const url = this.kunyekDomain + 'office/holiday/update';
    return this.httpRequest(url, data);
  }

  deleteHoliday(data: any) {
    const url = this.kunyekDomain + 'office/holiday/delete';
    return this.httpRequest(url, data);
  }

  getLeaveOpening(data: any) {
    const url = this.kunyekDomain + 'office/opening/search';
    return this.httpRequest(url, data);
  }

  getLeaveOpeningHistory(data: any) {
    const url = this.kunyekDomain + 'office/opening/gethistory';
    return this.httpRequest(url, data);
  }

  addLeaveOpeningImport(data: any) {
    const url = this.kunyekDomain + 'office/import/opening/sent';
    return this.httpRequest(url, data);
  }
  addOfficeLeaveImport(data: any) {
    const url = this.kunyekDomain + 'office/import/leave/sent';
    return this.httpRequest(url, data);
  }

  getLeaveOpeningImportStatus(data: any) {
    const url = this.kunyekDomain + 'office/import/opening/get';
    return this.httpRequest(url, data);
  }
  getOfficeLeaveImportStatus(data: any) {
    const url = this.kunyekDomain + 'office/import/leave/get';
    return this.httpRequest(url, data);
  }

  updateLeaveOpeningImportStatus(data: any) {
    const url = this.kunyekDomain + 'office/import/opening/update';
    return this.httpRequest(url, data);
  }

  addLeaveOpening(data: any) {
    const url = this.kunyekDomain + 'office/opening/add';
    return this.httpRequest(url, data);
  }

  getOfficeOpening(data: any) {
    const url = this.kunyekDomain + 'office/opening/get';
    return this.httpRequest(url, data);
  }

  getApprovalList(data: any) {
    const url = this.kunyekDomain + 'office/approval/get';
    data = Object.assign(data, {
      leavetype: this.allinoneService.isAttendanceView,
    });
    return this.httpRequest(url, data);
  }

  addApprovalImport(data: any) {
    const url = this.kunyekDomain + 'office/import/leave/sent';
    return this.httpRequest(url, data);
  }

  getApprovalImportStatus(data: any) {
    const url = this.kunyekDomain + 'office/import/leave/get';
    return this.httpRequest(url, data);
  }

  addApprovalList(data: any) {
    const url = this.kunyekDomain + 'office/approval/add';
    return this.httpRequest(url, data);
  }

  updateMultipleClaimPayment(data: any) {
    const url = this.kunyekDomain + 'office/payment/add';
    return this.httpRequest(url, data);
  }

  updateMultipleRequest(data: any) {
    const url = this.kunyekDomain + 'office/approval/addmulti';
    return this.httpRequest(url, data);
  }

  getApprovalCount(data: any) {
    const url = this.kunyekDomain + 'office/count';
    return this.httpRequest(url, data);
  }

  getRequisitionCount(data: any) {
    const url = this.samDomain + 'getrequisitioncounts';
    return this.httpRequest(url, data);
  }

  getWorkingHour(data: any) {
    const url = this.kunyekDomain + 'office/workinghour/get';
    return this.httpRequest(url, data);
  }

  saveWorkingHour(data: any) {
    const url = this.kunyekDomain + 'office/workinghour/add';
    return this.httpRequest(url, data);
  }

  getApprovalExport(data: any) {
    const url = this.kunyekDomain + 'office/request/getexport';
    return this.httpRequest(url, data);
  }

  addOfficeRequest(data: any) {
    const url = this.kunyekDomain + 'office/request/add';
    return this.httpRequest(url, data);
  }

  updateOfficeRequest(data: any) {
    const url = this.kunyekDomain + 'office/request/update';
    return this.httpRequest(url, data);
  }

  deleteOfficeRequest(data: any) {
    const url = 'https://api1.ky.omnicloudapi.com/office/request/delete';
    return this.httpRequest(url, data);
  }

  getLeaveCounting(data: any) {
    const url = this.kunyekDomain + 'office/leave/counting';
    return this.httpRequest(url, data);
  }

  getLeaveSummaryReport(data: any) {
    const url = this.kunyekDomain + 'office/leavesummary/get';
    return this.httpRequest(url, data);
  }

  getAnniversaryMember(data: any) {
    const url = this.kunyekDomain + 'office/joindatemember/get';
    return this.httpRequest(url, data);
  }

  getUserMapping(data: any) {
    const url = this.samDomain + 'getusermapping';
    return this.httpRequest(url, data);
  }

  createUserMapping(data: any) {
    const url = this.samDomain + 'createusermapping';
    return this.httpRequest(url, data);
  }

  editUserMapping(data: any) {
    const url = this.samDomain + 'updateusermapping';
    return this.httpRequest(url, data);
  }

  addPersonalQR(data: any) {
    const url = this.samDomain + 'addpqr';
    return this.httpRequest(url, data);
  }

  getPersonalQR(orgid: string, bymember: boolean) {
    const url = this.samDomain + 'getpqr';
    const param = {
      orgid: orgid,
      byadmin: !bymember,
    };
    return this.httpRequest(url, param);
  }

  updatePersonalQR(data: any) {
    const url = this.samDomain + 'updatepqr';
    return this.httpRequest(url, data);
  }

  deletePersonalQR(data: any) {
    const url = this.samDomain + 'deletepqr';
    return this.httpRequest(url, data);
  }

  getPermissionCRM(data: any) {
    // const url = this.kunyekDomain + 'crm/workflow/getpermission';
    const url = this.samDomain + 'getpermission';
    return this.httpRequest(url, data);
  }

  // Transporation

  adddriver(data: any) {
    const url = this.samDomain + 'adddriver';
    return this.httpRequest(url, data);
  }

  updatedriver(data: any) {
    const url = this.samDomain + 'updatedriver';
    return this.httpRequest(url, data);
  }

  getDriver(data: any) {
    const url = this.samDomain + 'getdriver';
    return this.httpRequest(url, data);
  }

  addVehicle(data: any) {
    const url = this.samDomain + 'addvehicle';
    return this.httpRequest(url, data);
  }

  getVehicle(data: any) {
    const url = this.samDomain + 'getvehicle';
    return this.httpRequest(url, data);
  }
  updateVehicle(data: any) {
    const url = this.samDomain + 'updatevehicle';
    return this.httpRequest(url, data);
  }

  addPickup(data: any) {
    const url = this.samDomain + 'addpickuppoint';
    return this.httpRequest(url, data);
  }

  getPickup(data: any) {
    const url = this.samDomain + 'getpickuppoint';
    return this.httpRequest(url, data);
  }
  updatePickup(data: any) {
    const url = this.samDomain + 'updatepickuppoint';
    return this.httpRequest(url, data);
  }

  deletepickuppoint(data: any) {
    const url = this.samDomain + 'deletepickuppoint';
    return this.httpRequest(url, data);
  }

  addcarway(data: any) {
    const url = this.samDomain + 'addcarway';
    return this.httpRequest(url, data);
  }

  updatecarway(data: any) {
    const url = this.samDomain + 'updatecarway';
    return this.httpRequest(url, data);
  }

  getcarway(data: any) {
    const url = this.samDomain + 'getcarway';
    return this.httpRequest(url, data);
  }

  getcarwayshcedule(data: any) {
    const url = this.samDomain + 'getcarwayschedule';
    return this.httpRequest(url, data);
  }

  deletecarway(data: any) {
    const url = this.samDomain + 'deletecarway';
    return this.httpRequest(url, data);
  }

  getcarwaydetail(data: any) {
    const url = this.samDomain + 'getcarwaydetails';

    return this.httpRequest(url, data);
  }

  getOutstandingRequest(data: any) {
    const url = this.samDomain + 'getoutstandingrequest';
    return this.httpRequest(url, data);
  }
  getRequest(data: any) {
    const url = this.samDomain + 'gettransportation';
    return this.httpRequest(url, data);
  }
  changePickupPoint(data: any) {
    const url = this.samDomain + 'changepickuppoint';
    return this.httpRequest(url, data);
  }

  getAttendanceid(data: any) {
    const url = this.samDomain + 'getattendanceid';
    return this.httpRequest(url, data);
  }
  addEditDeleteAttendanceid(data: any) {
    const url = this.samDomain + 'editattendanceid';
    return this.httpRequest(url, data);
  }

  // Late and earlyout policy
  getLateAndEarlyout(data: any) {
    const url = this.samDomain + 'getlatepolicy';
    return this.httpRequest(url, data);
  }

  addLateAndEarlyout(data: any) {
    const url = this.samDomain + 'addlatepolicy';
    return this.httpRequest(url, data);
  }

  editLateAndEarlyout(data: any) {
    const url = this.samDomain + 'updatelatepolicy';
    return this.httpRequest(url, data);
  }

  deleteLateAndEarlyout(data: any) {
    const url = this.samDomain + 'deletelatepolicy';
    return this.httpRequest(url, data);
  }

  // attendance qr
  getSocketQr(data: any) {
    const url = this.kunyekDomain + 'socket/attendance/connect';
    // const url = 'https://api1.ky.omnicloudapi.com/socket/attendance/connect';
    return this.httpRequestAttendanceQR(url, data);
  }

  renewSockerQr(data: any) {
    const url = this.kunyekDomain + 'socket/attendance/renew';
    return this.httpRequestAttendanceQR(url, data);
  }

  // workpolicy config
  addWpConfig(data: any) {
    const url = this.samDomain + 'addwpconfig';
    return this.httpRequest(url, data);
  }

  getWpConfig(data: any) {
    const url = this.samDomain + 'getwpconfig';
    return this.httpRequest(url, data);
  }

  editWpConfig(data: any) {
    const url = this.samDomain + 'updatewpconfig';
    return this.httpRequest(url, data);
  }

  deleteWpConfig(data: any) {
    const url = this.samDomain + 'deletewpconfig';
    return this.httpRequest(url, data);
  }

  // late report
  getLateReport(data: any) {
    const url = this.samDomain + 'getlatereport';
    return this.httpRequest(url, data);
  }

  // earlyout report
  getEarlyoutReport(data: any) {
    const url = this.samDomain + 'getearlyoutreport';
    return this.httpRequest(url, data);
  }

  getRequisitionRole(data: any) {
    const url = this.samDomain + 'getrequisitionrole';
    return this.httpRequest(url, data);
  }

  updateRequisitionRole(data: any) {
    const url = this.samDomain + 'updaterequisitionrole';
    return this.httpRequest(url, data);
  }

  // Requisition Reason

  getRequisitionReason(data: any) {
    const url = this.samDomain + 'getrequisitionreasons';
    return this.httpRequest(url, data);
  }

  addRequisitionReason(data: any) {
    const url = this.samDomain + 'addrequisitionreason';
    return this.httpRequest(url, data);
  }

  updateRequisitionReason(data: any) {
    const url = this.samDomain + 'updaterequisitionreason';
    return this.httpRequest(url, data);
  }

  getRequisitionUserInfo(data: any) {
    const url = this.samDomainv2 + 'getrequisitionmember';
    return this.httpRequest(url, data);
  }

  // Requisition template
  getRequisitionTemplate(data: any) {
    const url = this.samDomain + 'getrequisitiontemplate';
    return this.httpRequest(url, data);
  }

  createRequisitionTemplate(data: any) {
    const url = this.samDomain + 'addrequisitiontemplate';
    return this.httpRequest(url, data);
  }
  updateRequisitionTemplate(data: any) {
    const url = this.samDomain + 'updaterequisitiontemplate';
    return this.httpRequest(url, data);
  }

  deleteRequisitionTemplate(data: any) {
    const url = this.samDomain + 'deleterequisitiontemplate';
    return this.httpRequest(url, data);
  }

  // Requisition request

  getRejectRequisitionReason(data: any) {
    const url = this.samDomain + 'getrejectreasons';
    return this.httpRequest(url, data);
  }

  addRequisitionRequest(data: any) {
    const url = this.samDomain + 'addrequisition';
    return this.httpRequest(url, data);
  }

  getRequisitionRequest(data: any) {
    const url = this.samDomain + 'getrequisition';
    return this.httpRequest(url, data);
  }

  updateRequisitionRequest(data: any) {
    const url = this.samDomain + 'updaterequisition';
    return this.httpRequest(url, data);
  }

  deleteRequisitionRequest(data: any) {
    const url = this.samDomain + 'deleterequisition';
    return this.httpRequest(url, data);
  }

  getRequisitionApproval(data: any) {
    const url = this.samDomain + 'getallrequisition';
    return this.httpRequest(url, data);
  }

  getHrRequisition(data: any) {
    const url = this.samDomain + 'getapprovedrequisition';
    return this.httpRequest(url, data);
  }

  approveRequisition(data: any) {
    const url = this.samDomain + 'approverequisition';
    return this.httpRequest(url, data);
  }

  // Absent report
  getAbsentReport(data: any) {
    const url = this.kunyekDomain + 'hcm/getattendancereportsummary';
    return this.httpRequestForAbsentReport(url, data);
  }

  getAbsentMethod(data: any) {
    const url =
      'https://lt62v6la6f.execute-api.ap-southeast-1.amazonaws.com/configuration/paydata/pattern';
    // const url = 'https://api1.ky.omnicloudapi.com/configuration/paydata/pattern';
    return this.httpRequestForLogin(url, data);
  }

  getAbsentReportDetails(data: any) {
    const url = this.kunyekDomain + 'hcm/getattendancereportdetail';
    return this.httpRequestForLogin(url, data);
  }

  // Rooms
  getRooms(data: any) {
    const url = this.samDomain + 'getroom';
    return this.httpRequest(url, data);
  }

  addRooms(data: any) {
    const url = this.samDomain + 'addroom';
    return this.httpRequest(url, data);
  }

  updateRooms(data: any) {
    const url = this.samDomain + 'updateroom';
    return this.httpRequest(url, data);
  }

  deleteRooms(data: any) {
    const url = this.samDomain + 'deleteroom';
    return this.httpRequest(url, data);
  }

  // Desks
  getDesks(data: any) {
    const url = this.samDomain + 'getworkstation';
    return this.httpRequest(url, data);
  }

  addDesks(data: any) {
    const url = this.samDomain + 'addworkstation';
    return this.httpRequest(url, data);
  }

  updateDesks(data: any) {
    const url = this.samDomain + 'updateworkstation';
    return this.httpRequest(url, data);
  }

  deleteDesks(data: any) {
    const url = this.samDomain + 'deleteworkstation';
    return this.httpRequest(url, data);
  }

  getAvailableRoomAndDesk(data: any) {
    const url = this.kunyekDomain + 'office/roomdesk/get';
    return this.httpRequest(url, data);
  }

  getWorkstationList(data: any) {
    const url = this.kunyekDomain + 'office/workstation/get';
    return this.httpRequest(url, data);
  }

  // Time card report
  getTimecardReport(data: any) {
    const url = this.samDomain + 'gettimecardreport';
    return this.httpRequest(url, data);
  }

  // Late and Early out time report
  getLateEarlyoutTimeReport(data: any) {
    const url = this.samDomainv2 + 'getlateearlyoutreport';
    return this.httpRequest(url, data);
  }

  getLateEarlyoutPolicyReport(data: any) {
    const url = this.samDomainv2 + 'getlatepolicyreport';
    return this.httpRequest(url, data);
  }

  // Checkin report
  getCheckinReport(data: any) {
    const url = this.samDomainv2 + 'getcheckinreport';
    return this.httpRequest(url, data);
  }

  // Time in out report
  getInOutReport(data: any) {
    const url = this.samDomain + 'gettimeinoutreport';
    return this.httpRequest(url, data);
  }

  // Approval Leave Import
  addApprovalLeaveImport(data: any) {
    const url = this.samDomain + 'checkleaveimport';
    return this.httpRequest(url, data);
  }

  updateApprovalLeaveImport(data: any) {
    const url = this.samDomain + 'updateimportstatus';
    return this.httpRequest(url, data);
  }

  // Rules and Regulation
  getRules(data: any) {
    const url = this.samDomain + 'getrule';
    return this.httpRequest(url, data);
  }

  addRules(data: any) {
    const url = this.samDomain + 'addrule';
    return this.httpRequest(url, data);
  }

  updateRules(data: any) {
    const url = this.samDomain + 'updaterule';
    return this.httpRequest(url, data);
  }

  deleteRules(data: any) {
    const url = this.samDomain + 'deleterule';
    return this.httpRequest(url, data);
  }

  // User ID Mapping
  getUserIDMapping(data: any) {
    const url = this.samDomain + 'getuseridmapping';
    return this.httpRequest(url, data);
  }

  addUserIDMapping(data: any) {
    const url = this.samDomain + 'adduseridmapping';
    return this.httpRequest(url, data);
  }

  updateUserIDMapping(data: any) {
    const url = this.samDomain + 'updateuseridmapping';
    return this.httpRequest(url, data);
  }

  deleteUserIDMapping(data: any) {
    const url = this.samDomain + 'deleteuseridmapping';
    return this.httpRequest(url, data);
  }

  getExportDetial(data: any) {
    const url = this.crmDomain + 'getmemberrolldetail';
    return this.httpRequest(url, data);
  }

  // Incomplete report
  getIncompleteReport(data: any) {
    const url = this.samDomain + 'getincompletereport';
    return this.httpRequest(url, data);
  }

  // User Role Setup
  getUserRole(data: any) {
    const url = this.samDomainv2 + 'getmemberrole';
    return this.httpRequest(url, data);
  }

  addUserRole(data: any) {
    const url = this.samDomainv2 + 'addmemberrole';
    return this.httpRequest(url, data);
  }

  updateUserRole(data: any) {
    const url = this.samDomainv2 + 'updatememberrole';
    return this.httpRequest(url, data);
  }

  // attendance socket connection id update
  updateConnectionID(data: any) {
    const url = this.samDomainv2 + 'updateconnectionid';
    return this.httpRequest(url, data);
  }

  // Leave count report
  getLeaveCountReport(data: any) {
    const url = this.kunyekDomain + 'office/request/gettop';
    return this.httpRequest(url, data);
  }

  getLeaveCountReportDetails(data: any) {
    const url = this.kunyekDomain + 'office/request/gettopbyid';
    return this.httpRequest(url, data);
  }

  getLateEarlyOutShift(data: any) {
    const url = this.kunyekDomain + 'office/request/getshift';
    return this.httpRequest(url, data);
  }

  //
  getLateDetails(data: any) {
    const url = this.kunyekDomain + 'office/request/getlate';
    return this.httpRequest(url, data);
  }

  getEarlyoutDetails(data: any) {
    const url = this.kunyekDomain + 'office/request/getearlyout';
    return this.httpRequest(url, data);
  }

  addPaylevel(data: any) {
    const url = this.samDomainv2 + 'addpaylevel';
    return this.httpRequest(url, data);
  }

  updatePaylevel(data: any) {
    const url = this.samDomainv2 + 'updatepaylevel';
    return this.httpRequest(url, data);
  }

  getPaylevel(data: any) {
    const url = this.samDomainv2 + 'getpaylevel';
    return this.httpRequest(url, data);
  }

  getOfficeCalendar(data: any) {
    const url = this.samDomainv2 + 'getcalendar';
    return this.httpRequest(url, data);
  }

  addOfficeCalendar(data: any) {
    const url = this.samDomainv2 + 'addcalendar';
    return this.httpRequest(url, data);
  }

  updateOfficeCalendar(data: any) {
    const url = this.samDomainv2 + 'updatecalendar';
    return this.httpRequest(url, data);
  }

  deleteOfficeCalendar(data: any) {
    const url = this.samDomainv2 + 'deletecalendar';
    return this.httpRequest(url, data);
  }

  getDeviceInfo(data: any) {
    const url = this.samDomainv2 + 'getdeviceinfo';
    return this.httpRequest(url, data);
  }

  getAddress(lat: number, lng: number) {
    const apiUrl = 'https://maps.googleapis.com/maps/api/geocode/json';
    const params = {
      latlng: `${lat},${lng}`,
      key: 'AIzaSyALDEQP79BENyRXMp6d98w6AzpTvnH5xiA',
    };

    return this.http.get(apiUrl, { params });
  }

  downloadSampleTemplate(path: string, filename: string) {
    let url = this.allinoneService.bucketUrl + path;

    this.http
      .get(url, {
        responseType: 'blob',
      })
      .subscribe((response: any) => {
        saveAs(response, filename);
      }),
      (error: any) => {
        this.messageService.openSnackBar('Download failed.', 'warn');
      };
  }

  getQuestions(data: any) {
    let url = this.crmnodeDomain + 'service/getquestion';
    return this.httpRequest(url, data);
  }

  getLabels(data: any) {
    let url = this.crmnodeDomain + 'service/label/get';
    return this.httpRequest(url, data);
  }

  updateLabel(data: any) {
    let url = this.crmnodeDomain + 'service/label/update';
    return this.httpRequest(url, data);
  }

  getTheme(data: any) {
    let url = environment.kunyekDomain + 'organization/theme/get';
    return this.httpRequest(url, data);
  }

  getPeriodData(data: any) {
    let url = environment.kunyekDomain + 'office/payperiod/get';
    return this.httpRequest(url, data);
  }

  addPeroid(data: any) {
    const url = environment.kunyekDomain + 'office/payperiod/add';

    return this.httpRequest(url, data);
  }

  updatePeroid(data: any) {
    const url = environment.kunyekDomain + 'office/payperiod/update';
    return this.httpRequest(url, data);
  }

  getWarranty(data: any) {
    const url = environment.kunyekDomain + 'crmnodev2/warranty/get';
    return this.httpRequest(url, data);
  }

  addWarranty(data: any) {
    const url = environment.kunyekDomain + 'crmnodev2/warranty/create';
    return this.httpRequest(url, data);
  }

  updateWarranty(data: any) {
    const url = environment.kunyekDomain + 'crmnodev2/warranty/update';
    return this.httpRequest(url, data);
  }

  checkWarranty(data: any) {
    const url = environment.kunyekDomain + 'crmnodev2/warranty/check';
    return this.httpRequest(url, data);
  }

  getServiceWarranty(data: any) {
    const url = environment.kunyekDomain + 'crmnodev2/warranty/service/get';
    return this.httpRequest(url, data);
  }

  getAccessoriesRequestType(data: any) {
    const url = environment.kunyekDomain + 'office/request/hrtype/get';
    return this.httpRequest(url, data);
  }

  getAccessoriesRequest(data: any) {
    const url = environment.kunyekDomain + 'office/request/hr/get';
    return this.httpRequest(url, data);
  }
  createAccessoriesRequest(data: any) {
    const url = environment.kunyekDomain + 'office/request/hr/add';
    return this.httpRequest(url, data);
  }
  updateAccessoriesRequest(data: any) {
    const url = environment.kunyekDomain + 'office/request/hr/update';
    return this.httpRequest(url, data);
  }

  getAccessoriesApproval(data: any) {
    const url = environment.kunyekDomain + 'office/approval/hr/get';
    return this.httpRequest(url, data);
  }

  addAccessoriesApproval(data: any) {
    const url = environment.kunyekDomain + 'office/approval/hr/add';
    return this.httpRequest(url, data);
  }

  getHrCount(data: any) {
    const url = environment.kunyekDomain + 'office/hrcount';
    return this.httpRequest(url, data);
  }

  getOfficeRequest(data: any) {
    const url = environment.kunyekDomain + 'office/request/get';
    return this.httpRequest(url, data);
  }

  getKMembers(data: any) {
    const url = environment.memberDomain + "member/get";
    return this.httpRequest(url, data);
  }

  getKPaymentMembers(data: any) {
    const url = environment.memberDomain + "member/getpayment";
    return this.httpRequest(url, data);
  }

  addKMember(data: any) {
    const url = environment.memberDomain + "member/create";
    // const url = "https://jqd01f6aw5.execute-api.ap-southeast-1.amazonaws.com/member-stage/member/create";
    return this.httpRequest(url, data);
  }

  getKMemberTypesList(data: any) {
    const url = environment.memberDomain + "membertypes/get"
    return this.httpRequest(url, data)
  }

  editKMember(data: any) {
    const url = environment.memberDomain + "member/update"
    return this.httpRequest(url, data)
  }

  getMemberRelations(data: any) {
    const url = environment.memberDomain + "memberrelations/get"
    return this.httpRequest(url, data)
  }

  deleteKMember(data: any) {
    const url = environment.memberDomain + "memberrelations/delete"
    return this.httpRequest(url, data)
  }

  getFeeTypes(data: any) {
    const url = environment.memberDomain + "feetype/get"
    return this.httpRequest(url, data)
  }

  createFeesType(data: any) {
    const url = environment.memberDomain + "feetype/create"
    return this.httpRequest(url, data)
  }

  getMonthlyFeeTypes(data: any) {
    const url = environment.memberDomain + "monthlyfee/get"
    return this.httpRequest(url, data)
  }

  createMonthlyFeeTypes(data: any) {
    const url = environment.memberDomain + "monthlyfee/create"
    return this.httpRequest(url, data)
  }

  createMonthlyFeesTypeDetail(data: any) {
    const url = environment.memberDomain + "monthlyfeedetail/create"
    return this.httpRequest(url, data)
  }

  approveMember(data: any) {
    const url = environment.memberDomain + "member/approve"
    return this.httpRequest(url, data)
  }

  getMemberInfo(data: any) {
    const url = environment.memberDomain + "memberinfo/get";
    return this.httpRequest(url, data);
  }

  payfee(data: any) {
    const url = environment.memberDomain + "member/payfee";
    return this.httpRequest(url, data);
  }

  checkApprover(data: any) {
    const url = environment.memberDomain + "member/checkapprover";
    return this.httpRequest(url, data);
  }

  getRelativeTypes(data: any) {
    const url = environment.memberDomain + "relativetypes";
    return this.httpRequest(url, data);
  }

  getApproveMembers(data: any) {
    const url = environment.memberDomain + "member/getapproveusers";
    return this.httpRequest(url, data);
  }
  getPaymentPeriods(data: any) {
    const url = environment.memberDomain + "payperiods/get";
    return this.httpRequest(url, data);
  }

  deleteMontlyFeeDetail(data: any) {
    const url = environment.memberDomain + "monthlyfeedetail/delete";
    return this.httpRequest(url, data)
  }


  getApproveHistory(data: any) {
    const url = environment.memberDomain + "member/getapprovehistory";
    return this.httpRequest(url, data);
  }

  getPyamentHistory(data: any) {
    const url = environment.memberDomain + "member/getinvoice";
    return this.httpRequest(url, data);
  }

  editMonthlyFees(data: any) {
    const url = environment.memberDomain + "monthlyfees/update";
    return this.httpRequest(url, data);
  }

  deleteMontlyFees(data: any) {
    const url = environment.memberDomain + "monthlyfees/delete";
    return this.httpRequest(url, data)
  }

  getRegisteredMember(data: any) {
    const url = environment.memberDomain + "member/getregistered";
    return this.httpRequest(url, data);
  }

  registerMember(data: any) {
    const url = environment.memberDomain + "member/register";
    return this.httpRequest(url, data);
  }

  genereateMemberId(data: any) {
    const url = environment.memberDomain + "member/generateid";
    return this.httpRequest(url, data);
  }

  createSpecialSwimmer(data: any) {
    const url = environment.memberDomain + "specialswimmer/create";
    return this.httpRequest(url, data);
  }

  getSummerSwimmerList(data: any) {
    const url = environment.memberDomain + "summerswimmer/get";
    return this.httpRequest(url, data);
  }

  getSpecialSwimmerList(data: any) {
    const url = environment.memberDomain + "specialswimmer/get";
    return this.httpRequest(url, data);
  }

  addDefulatOrgMember(param: any) {
    const appid = this.allinoneService.getAppId();
    var userid = this.allinoneService.getKunyekUserId();
    const atoken = this.allinoneService.getAtoken();
    const url = this.samDomain + 'addmember';

    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html,application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${atoken}`,
      }),
    };
    const body = Object.assign(param, {
      appid: appid,
      userid: userid,
      atoken: atoken,
      domainid: '002',
      domain: '',
    });

    return this.http.post(url, body, options).pipe(
      retry(2),
      map((res: any) => {
        if (res.returncode != '300') {
          let promise = new Promise(async (resolve) => {
            const awaitCheckToken = await this.checkTokenFailedHttpRequest();
            resolve('');
          });
        }
        return res;
      }),
      catchError(this.handleError),
    );
  }

  getRoutePolicy(data: any) {
    const url = environment.memberDomain + "member/routepolicy/get";
    return this.httpRequest(url, data);
  }
  summerSwimMember() {
    const url = environment.memberDomain + "summerswimmer/";
    return {

      register: (data: any) => {
        return this.httpRequest(url + 'register', data)
      },
      get: (data: any) => {
        return this.httpRequest(url + 'get', data)
      },
      update: (data: any) => {
        return this.httpRequest(url + 'update', data)
      }
    }
  }
  gymMember() {
    const url = environment.memberDomain + "gymmember/"
    return {
      register: (data: any) => {
        return this.httpRequest(url + 'create', data)
      },
      get: (data?: any) => {
        return this.httpRequest(url + 'get', data)
      },
      update: (data?: any) => {
        return this.httpRequest(url + 'update', data)
      }
    }
  }
  guestMember() {
    const url = environment.memberDomain + "guest/"
    return {
      create: (data: any) => {
        return this.httpRequest(url + 'create', data)
      },
      get: (data?: any) => {
        return this.httpRequest(url + 'get', data)
      },
      update: (data?: any) => {
        return this.httpRequest(url + 'update', data)
      }
    }
  }
  tempMember() {
    const url = environment.memberDomain + "tempswimmer/"
    return {
      register: (data: any) => {
        return this.httpRequest(url + 'create', data)
      },
      get: (data?: any) => {
        return this.httpRequest(url + 'get', data)
      },
      update: (data?: any) => {
        return this.httpRequest(url + 'update', data)
      }
    }
  }
  specialMember() {
    const url = environment.memberDomain + "specialswimmer/"
    return {
      register: (data: any) => {
        return this.httpRequest(url + 'create', data)
      },
      get: (data?: any) => {
        return this.httpRequest(url + 'get', data)
      },
      update: (data?: any) => {
        return this.httpRequest(url + 'update', data)
      }
    }
  }
  leave() {
    const url = environment.memberDomain + "leave/"
    return {
      create: (data: any) => {
        return this.httpRequest(url + 'create', data)
      },
      get: (data?: any) => {
        return this.httpRequest(url + 'get', data)
      },
      getByid: (data: { id: string }) => {
        return this.httpRequest(url + 'get', data)
      },
      approve: ((data: { id: string, approver_id: string }) => {
        return this.httpRequest(url + 'approve', data)
      }),
      reject: ((data: { id: string, approver_id: string }) => {
        return this.httpRequest(url + 'reject', data)
      }),
    }
  }
  getPaymentInvoice() {
    const url = environment.memberDomain + "paymentinvoice/"
    return {
      create: (data: any) => {
        return this.httpRequest(url + 'create', data)
      },
      get: (data?: any) => {
        return this.httpRequest(url + 'get', data)
      },
      update: (data?: any) => {
        return this.httpRequest(url + 'update', data)
      }
    }
  }

  getBatch() {
    const url = environment.memberDomain + "batch/"
    return {
      create: (data: any) => {
        return this.httpRequest(url + 'create', data)
      },
      get: (data?: any) => {
        return this.httpRequest(url + 'get', data)
      },
      update: (data?: any) => {
        return this.httpRequest(url + 'update', data)
      }
    }
  }

  getDirectory() {
    const url = environment.memberDomain + "directory/"
    return {
      get: (data?: any) => {
        return this.httpRequest(url + 'get', data)
      }
    }
  }

}
